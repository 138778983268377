import React from "react";
import { useLoaderData } from "react-router-dom";

import Animation from "@areas/shared/components/chooseLayout/Animation";
import animationStyles from "@areas/shared/components/chooseLayout/styles/animations.module.css";

import Return from "./blocks/Return";
import About from "./blocks/About";

function Page(props) {
  const data = useLoaderData();

  return (
    <div style={{ minHeight: "100vh" }}>
      <Animation
        animationIn={animationStyles.swipeLeftIn}
        animationOut={animationStyles.swipeRightOut}
      >
        <Return data={data} />
        <About data={data} />
      </Animation>
    </div>
  );
}

export default Page;
