import React from "react";
import { Input, Label } from "reactstrap";

import CardArrow from "@areas/shared/components/cards/CardArrow";

import { filter } from "@helpers/data/filter-speciality";

import styles from "./styles/filter.module.css";

function Filter({ set, values }) {
  const setOpenStyles = (target) => {
    if (target.parentElement.classList.contains(styles.active)) {
      target.parentElement.classList.remove(styles.active);
    } else {
      target.parentElement.classList.add(styles.active);
    }
  };
  const generateFilters = (values) => {
    let result = [];
    for (let i = 0; i < filter.length; i++) {
      switch (filter[i].type) {
        case "radioGroup":
          result[result.length] = (
            <div className={styles.radioGroup}>
              <div
                className={styles.radioGroupHeader}
                onClick={(e) => {
                  setOpenStyles(e.currentTarget);
                }}
              >
                {filter[i].title}
              </div>
              {filter[i].options.map((item, index) => (
                <div key={index}>
                  <Input
                    id={filter[i].name + "-" + index}
                    type="radio"
                    className={styles.radio}
                    name={filter[i].name}
                    value={item.value}
                    onChange={() =>
                      set([
                        ...values.filter((a) => a.name !== filter[i].name),
                        {
                          name: filter[i].name,
                          value: item.value,
                        },
                      ])
                    }
                  />
                  <Label
                    className={styles.radioLabel}
                    htmlFor={filter[i].name + "-" + index}
                  >
                    {item.name}
                  </Label>
                </div>
              ))}
            </div>
          );
          break;
        case "input":
          result[result.length] = (
            <div className={styles.inputGroup}>
              <div
                className={styles.inputGroupHeader}
                onClick={(e) => {
                  setOpenStyles(e.currentTarget);
                }}
              >
                {filter[i].title}
              </div>
              <Input
                id={filter[i].name}
                type={filter[i].format}
                className={styles.input}
                name={filter[i].name}
                onChange={(e) =>
                  set([
                    ...values.filter((a) => a.name !== filter[i].name),
                    {
                      name: filter[i].name,
                      value: e.currentTarget.value,
                    },
                  ])
                }
                placeholder={filter[i].title}
              />
            </div>
          );
          break;
        case "between":
          result[result.length] = (
            <div className={styles.betweenGroup}>
              <div
                className={styles.betweenGroupHeader}
                onClick={(e) => {
                  setOpenStyles(e.currentTarget);
                }}
              >
                {filter[i].title}
              </div>
              <div className={styles.betweenInputs}>
                <Input
                  id={filter[i].nameFrom}
                  type={filter[i].format}
                  className={styles.input}
                  name={filter[i].nameFrom}
                  onChange={(e) =>
                    set([
                      ...values.filter((a) => a.name !== filter[i].nameFrom),
                      {
                        name: filter[i].nameFrom,
                        value: e.currentTarget.value,
                      },
                    ])
                  }
                  placeholder="от"
                />
                <Input
                  id={filter[i].nameTo}
                  type={filter[i].format}
                  className={styles.input}
                  name={filter[i].nameTo}
                  onChange={(e) =>
                    set([
                      ...values.filter((a) => a.name !== filter[i].nameTo),
                      {
                        name: filter[i].nameTo,
                        value: e.currentTarget.value,
                      },
                    ])
                  }
                  placeholder="до"
                />
              </div>
            </div>
          );
          break;
        default:
          continue;
      }
    }
    return result;
  };

  return (
    <div>
      <CardArrow
        customStyles={{
          main: styles.cardFilter,
          arrow: styles.arrow,
          border: styles.border,
        }}
        sizeArrow={{ width: 33, height: 33 }}
        rotate="rightTop"
      >
        <div className={styles.content}>
          <div className={styles.header}>Фильтры</div>
          <div className={styles.filters}>
            {generateFilters(values).map((item, index) => (
              <div key={index} className={styles.filter}>
                {item}
              </div>
            ))}
          </div>
        </div>
      </CardArrow>
    </div>
  );
}

export default Filter;
