import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  Form,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Col,
  FormFeedback,
  Input,
  Label,
  Row,
} from "reactstrap";
import { useFormik } from "formik";
import * as Yup from "yup";
import RiseLoader from "react-spinners/RiseLoader";

import { authActions, layoutActions } from "@store";

import styles from "./styles/customModal.module.css";

function RegisterModal() {
  const dispatch = useDispatch();
  const { modalNext } = useSelector((state) => state.layout);
  const [load, setLoad] = useState(false);
  const [authError, setAuthError] = useState(null);

  const inititalValues = () => {
    return {
      lastname: null,
      firstname: null,
      middlename: null,
      inn: null,
      kpp: null,
      email: null,
      password: null,
      confirmPassword: null,
    };
  };

  const validationSchema = () => {
    return Yup.object({
      lastname: Yup.string().required("Фамилия обязателена"),
      firstname: Yup.string().required("Имя обязателено"),
      middlename: Yup.string(),
      inn: Yup.string()
        .matches(/^[0-9]+$/, "Допустимы только цифры")
        .min(10, "ИНН должен содержать 10 цифр")
        .required("ИНН обязателен"),
      kpp: Yup.string()
        .matches(/^[0-9]+$/, "Допустимы только цифры")
        .min(9, "КПП должен содержать 9 цифр")
        .required("КПП обязателен"),
      email: Yup.string()
        .email("Некорректный email")
        .required("Email обязателен"),
      password: Yup.string().required("Пароль обязателен"),
      confirmPassword: Yup.string()
        .required("Повтор пароля обязателен")
        .oneOf([Yup.ref("password"), null], "Пароли не совпадают"),
    });
  };

  const toggle = () => {
    dispatch(layoutActions.setModal(null));
    dispatch(layoutActions.setModalNext(null));
  };

  const validation = useFormik({
    enableReinitialize: true,
    initialValues: inititalValues(),
    validationSchema: validationSchema(),
    onSubmit: ({
      lastname,
      firstname,
      middlename,
      email,
      password,
      inn,
      kpp,
    }) => {
      setLoad(true);
      setAuthError(null);
      return dispatch(
        authActions.registerV2({
          lastname,
          firstname,
          middlename,
          email,
          password,
          inn,
          kpp,
          companyType: 1,
        })
      )
        .unwrap()
        .then((result) => {
          if (!result.message) {
            dispatch(authActions.me()).then(() => {
              dispatch(layoutActions.setModal(modalNext));
              dispatch(layoutActions.setModalNext(null));
              setLoad(false);
            });
          } else {
            setAuthError(result);
            setLoad(false);
          }
        })
        .catch((rejectedValueOrSerializedError) => {
          setAuthError(rejectedValueOrSerializedError);
          setLoad(false);
        });
    },
  });

  return (
    <Modal
      isOpen={true}
      toggle={toggle}
      centered
      size={"md"}
      className={styles.modal}
      modalClassName="zoomIn"
    >
      <ModalHeader toggle={toggle} className={styles.header}>
        <div className={styles.title}>Регистрация</div>
      </ModalHeader>
      <Form
        className="tablelist-form"
        onSubmit={(e) => {
          e.preventDefault();
          validation.handleSubmit();
          return false;
        }}
      >
        <ModalBody className={styles.body}>
          <Row className="g-2">
            <Col lg={12}>
              {authError ? (
                <FormFeedback type="invalid" className={styles.invalid}>
                  {authError.message}
                </FormFeedback>
              ) : null}
            </Col>
            <Col lg={4}>
              <div>
                <Label
                  htmlFor="registerLastname-field"
                  className={styles.label}
                >
                  Фамилия
                </Label>
                <Input
                  name="lastname"
                  id="registerLastname-field"
                  className={`form-control ${styles.input}`}
                  placeholder="Введите фамилию"
                  type="text"
                  value={validation.values.lastname || ""}
                  onChange={validation.handleChange}
                  onBlur={validation.handleBlur}
                  invalid={
                    validation.touched.lastname && validation.errors.lastname
                      ? true
                      : false
                  }
                />
                {validation.touched.lastname && validation.errors.lastname ? (
                  <FormFeedback type="invalid" className={styles.invalid}>
                    {validation.errors.lastname}
                  </FormFeedback>
                ) : null}
              </div>
            </Col>
            <Col lg={4}>
              <div>
                <Label
                  htmlFor="registerFirstname-field"
                  className={styles.label}
                >
                  Имя
                </Label>
                <Input
                  name="firstname"
                  id="registerFirstname-field"
                  className={`form-control ${styles.input}`}
                  placeholder="Введите имя"
                  type="text"
                  value={validation.values.firstname || ""}
                  onChange={validation.handleChange}
                  onBlur={validation.handleBlur}
                  invalid={
                    validation.touched.firstname && validation.errors.firstname
                      ? true
                      : false
                  }
                />
                {validation.touched.firstname && validation.errors.firstname ? (
                  <FormFeedback type="invalid" className={styles.invalid}>
                    {validation.errors.firstname}
                  </FormFeedback>
                ) : null}
              </div>
            </Col>
            <Col lg={4}>
              <div>
                <Label
                  htmlFor="registerMiddlename-field"
                  className={styles.label}
                >
                  Отчество
                </Label>
                <Input
                  name="middlename"
                  id="registerMiddlename-field"
                  className={`form-control ${styles.input}`}
                  placeholder="Введите отчество"
                  type="text"
                  value={validation.values.middlename || ""}
                  onChange={validation.handleChange}
                  onBlur={validation.handleBlur}
                  invalid={
                    validation.touched.middlename &&
                    validation.errors.middlename
                      ? true
                      : false
                  }
                />
                {validation.touched.middlename &&
                validation.errors.middlename ? (
                  <FormFeedback type="invalid" className={styles.invalid}>
                    {validation.errors.middlename}
                  </FormFeedback>
                ) : null}
              </div>
            </Col>
            <Col lg={6}>
              <div>
                <Label htmlFor="registerInn-field" className={styles.label}>
                  ИНН
                </Label>
                <Input
                  name="inn"
                  id="registerInn-field"
                  className={`form-control ${styles.input}`}
                  placeholder="Введите ИНН"
                  type="text"
                  value={validation.values.inn || ""}
                  onChange={validation.handleChange}
                  onBlur={validation.handleBlur}
                  invalid={
                    validation.touched.inn && validation.errors.inn
                      ? true
                      : false
                  }
                />
                {validation.touched.inn && validation.errors.inn ? (
                  <FormFeedback type="invalid" className={styles.invalid}>
                    {validation.errors.inn}
                  </FormFeedback>
                ) : null}
              </div>
            </Col>
            <Col lg={6}>
              <div>
                <Label htmlFor="registerKpp-field" className={styles.label}>
                  КПП
                </Label>
                <Input
                  name="kpp"
                  id="registerKpp-field"
                  className={`form-control ${styles.input}`}
                  placeholder="Введите КПП"
                  type="text"
                  value={validation.values.kpp || ""}
                  onChange={validation.handleChange}
                  onBlur={validation.handleBlur}
                  invalid={
                    validation.touched.kpp && validation.errors.kpp
                      ? true
                      : false
                  }
                />
                {validation.touched.kpp && validation.errors.kpp ? (
                  <FormFeedback type="invalid" className={styles.invalid}>
                    {validation.errors.kpp}
                  </FormFeedback>
                ) : null}
              </div>
            </Col>
            <Col lg={12}>
              <div>
                <Label htmlFor="registerEmail-field" className={styles.label}>
                  Email
                </Label>
                <Input
                  name="email"
                  id="registerEmail-field"
                  className={`form-control ${styles.input}`}
                  placeholder="Введите email"
                  type="text"
                  value={validation.values.email || ""}
                  onChange={validation.handleChange}
                  onBlur={validation.handleBlur}
                  invalid={
                    validation.touched.email && validation.errors.email
                      ? true
                      : false
                  }
                />
                {validation.touched.email && validation.errors.email ? (
                  <FormFeedback type="invalid" className={styles.invalid}>
                    {validation.errors.email}
                  </FormFeedback>
                ) : null}
              </div>
            </Col>
            <Col lg={6}>
              <div>
                <Label
                  htmlFor="registerPassword-field"
                  className={styles.label}
                >
                  Пароль
                </Label>
                <Input
                  name="password"
                  id="registerPassword-field"
                  className={`form-control ${styles.input}`}
                  placeholder="Введите пароль"
                  type="password"
                  value={validation.values.password || ""}
                  onChange={validation.handleChange}
                  onBlur={validation.handleBlur}
                  invalid={
                    validation.touched.password && validation.errors.password
                      ? true
                      : false
                  }
                />
                {validation.touched.password && validation.errors.password ? (
                  <FormFeedback type="invalid" className={styles.invalid}>
                    {validation.errors.password}
                  </FormFeedback>
                ) : null}
              </div>
            </Col>
            <Col lg={6}>
              <div>
                <Label
                  htmlFor="registerConfirmPassword-field"
                  className={styles.label}
                >
                  Повторите пароль
                </Label>
                <Input
                  name="confirmPassword"
                  id="registerConfirmPassword-field"
                  className={`form-control ${styles.input}`}
                  placeholder="Введите повтор пароля"
                  type="password"
                  value={validation.values.confirmPassword || ""}
                  onChange={validation.handleChange}
                  onBlur={validation.handleBlur}
                  invalid={
                    validation.touched.confirmPassword &&
                    validation.errors.confirmPassword
                      ? true
                      : false
                  }
                />
                {validation.touched.confirmPassword &&
                validation.errors.confirmPassword ? (
                  <FormFeedback type="invalid" className={styles.invalid}>
                    {validation.errors.confirmPassword}
                  </FormFeedback>
                ) : null}
              </div>
            </Col>
          </Row>
        </ModalBody>
        <ModalFooter className={`${styles.footer} justify-content-center`}>
          {load ? (
            <div className="d-flex justify-content-center w-100 mb-3">
              <RiseLoader color="#014170" />
            </div>
          ) : (
            <button
              type="submit"
              className={`btn btn-success ${styles.submit} w-50`}
              id="add-btn"
            >
              Зарегистрироваться
            </button>
          )}
        </ModalFooter>
      </Form>
    </Modal>
  );
}

export default RegisterModal;
