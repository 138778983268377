import React, { useCallback, useEffect, useRef, useState } from "react";
import { Link } from "react-router-dom";

import { fetchWrapper } from "@helpers/fetchwrapper";

import CardArrow from "@areas/shared/components/cards/CardArrow";

import MapIcon from "@areas/shared/components/icons/Map";

import defaultLogo from "@content/img/shared/education/png/default-logo.png";
import { ReactComponent as MapPointIcon } from "@content/img/shared/home/svg/map-point-icon.svg";

import Search from "./Search";

import { filterNames } from "@helpers/data/filterNames";

import styles from "./styles/map.module.css";

function Map({ data }) {
  const mapRef = useRef();

  const [load, setLoad] = useState(false);
  const [types, setTypes] = useState([2, 3]);
  const [filter, setFilter] = useState([]);
  const [searchQuery, setSearchQuery] = useState("");
  const [searchResult, setSearchResult] = useState({
    filter: data.Filters || [],
    data: data.Data || [],
    total: data.Total,
    totals: data.Totals || [],
    coordinats: data.Coordinats || [],
  });
  const [showMap, setShowMap] = useState(false);
  const [currentPoint, setCurrentPoint] = useState(null);

  const coordYToPixel = (y) => {
    y = 56.478056 - y;
    return y * (mapRef.current.clientHeight / 5);
  };

  const coordXToPixel = (x) => {
    x = x - 53.133333;
    return x * (mapRef.current.clientWidth / 7);
  };

  useEffect(() => {
    if (mapRef.current) {
      setShowMap(true);
    }
  }, [mapRef]);

  const isIncludeInQuery = (value, query) => {
    if (value && value.toLowerCase().indexOf(query.toLowerCase()) > -1) {
      return true;
    }
    return false;
  };

  const search = useCallback(
    (query, clearFilters = false) => {
      setLoad(true);
      if (query !== undefined) {
        setSearchQuery(query);
      } else {
        query = searchQuery;
      }

      let requestFilters = {};
      if (filter.length > 0 && !clearFilters) {
        for (let i = 0; i < filterNames.length; i++) {
          let name = filterNames[i];
          if (!requestFilters[name]) {
            requestFilters[name] = [];
          }
          filter.forEach((f) => {
            if (f.name === name) {
              requestFilters[name].push(f.value);
            }
          });
        }
      }
      let data = {
        text: query,
        types: types,
        limit: 2,
        offset: 0,
      };

      for (let i = 0; i < filterNames.length; i++) {
        let name = filterNames[i];
        if (requestFilters[name] && requestFilters[name].length > 0) {
          data[name] = requestFilters[name];
        }
      }

      fetchWrapper
        .post(`/v1/api/app/findCompaniesWithFaculty`, data)
        .then((res) => {
          let tempData = res.Data;
          for (let i = 0; i < tempData.length; i++) {
            let temp = [];

            for (let j = 0; j < tempData[i].Faculty.length; j++) {
              if (
                isIncludeInQuery(tempData[i].Faculty[j].Name, query) ||
                isIncludeInQuery(tempData[i].Faculty[j].Title, query)
              ) {
                temp.unshift(tempData[i].Faculty[j]);
              } else {
                temp.push(tempData[i].Faculty[j]);
              }
            }

            tempData[i].Faculty = temp;
          }

          setSearchResult({
            data: tempData,
            filter: res.Filters,
            total: res.Total,
            totals: res.Totals,
            coordinats: res.Coordinats,
          });
          setLoad(false);
        });
    },
    [searchQuery, types, filter]
  );

  const generatePoints = (coordinates) => {
    let res = [];
    for (let i = coordinates.length - 1; i >= 0; i--) {
      for (let j = 0; j < coordinates[i].Coordinates.length; j++) {
        res[res.length] = (
          <div
            key={`${i}-${j}-${coordinates[i].IdType}`}
            style={{
              top: coordYToPixel(coordinates[i].Coordinates[j].GeoLat),
              left: coordXToPixel(coordinates[i].Coordinates[j].GeoLon),
            }}
            className={styles.point}
            onClick={(e) => {
              e.stopPropagation();
              setCurrentPoint(coordinates[i].Coordinates[j]);
            }}
          >
            <MapPointIcon
              className={`${styles.icon} ${
                coordinates[i].IdType === 3 ? styles.college : styles.university
              }`}
            />
            <CardArrow
              customStyles={{
                main: styles.card,
                border: styles.border,
                arrow: styles.arrow,
              }}
              rotate="rightTop"
              sizeArrow={{ width: 17.5, height: 17.5 }}
            >
              <div className={styles.content}>
                {coordinates[i].Coordinates[j].Companies.map((comp, index) => (
                  <Link
                    key={`${i}-${j}-${coordinates[i].IdType}-${comp.Id}-${index}`}
                    to={`/obrazovanie-v-bashkortostane/${
                      comp.IdType === 2 ? "vuzy-rb" : "kolledzhi-rb"
                    }/${comp.Slug}`}
                    className={styles.item}
                    target="_blank"
                  >
                    <div className={styles.header}>
                      <img
                        className={styles.icon}
                        src={defaultLogo}
                        alt="Реестр"
                      />
                      <div className={styles.title}>{comp.Name}</div>
                    </div>
                    <div className={styles.contacts}>
                      <div>
                        <div className={styles.address}>
                          <div className={styles.title}>Адрес:</div>
                          <div className={styles.description}>
                            {comp.Address}
                          </div>
                        </div>
                      </div>
                    </div>
                  </Link>
                ))}
              </div>
            </CardArrow>
          </div>
        );
      }
    }

    return res;
  };

  return (
    <div className={`${styles.block} container`}>
      <div className={styles.header}>
        УЧЕБНЫЕ ЗАВЕДЕНИЯ Республики Башкортостан
      </div>
      <Search
        load={load}
        query={searchQuery}
        search={search}
        types={types}
        setTypes={setTypes}
        filterUser={filter}
        setFilterUser={setFilter}
        results={searchResult}
        setResults={setSearchResult}
      />
      <div className={styles.secondHeader}>
        Посмотреть на карте выбранные учебные заведения
      </div>
      <div className={styles.map} onClick={() => setCurrentPoint(null)}>
        <MapIcon
          ref={mapRef}
          gradientId="mapIcon"
          gradientSteps={[
            {
              offset: 0,
              stopColor: "#CBEC00",
            },
            {
              offset: 0.5,
              stopColor: "#700101",
            },
            {
              offset: 1,
              stopColor: "#D0A024",
            },
          ]}
        />
        {showMap && generatePoints(searchResult.coordinats)}
      </div>
      {currentPoint ? (
        <CardArrow
          customStyles={{
            main: `${styles.card} ${styles.mobile}`,
            border: styles.border,
            arrow: styles.arrow,
          }}
          rotate="rightTop"
          sizeArrow={{ width: 33, height: 33 }}
        >
          <div className={styles.content}>
            {currentPoint.Companies.map((comp) => (
              <Link
                key={comp.Id}
                to={`/obrazovanie-v-bashkortostane/${
                  comp.IdType === 2 ? "vuzy-rb" : "kolledzhi-rb"
                }/${comp.Slug}`}
                className={styles.item}
                target="_blank"
              >
                <div className={styles.header}>
                  <img className={styles.icon} src={defaultLogo} alt="Реестр" />
                  <div className={styles.title}>{comp.Name}</div>
                </div>
                <div className={styles.contacts}>
                  <div>
                    <div className={styles.address}>
                      <div className={styles.title}>Адрес:</div>
                      <div className={styles.description}>{comp.Address}</div>
                    </div>
                  </div>
                </div>
              </Link>
            ))}
          </div>
        </CardArrow>
      ) : null}
    </div>
  );
}

export default Map;
