import React, { useEffect, useRef, useState } from "react";
import ClipLoader from "react-spinners/ClipLoader";

import CardArrow from "@areas/shared/components/cards/CardArrow";

import SearchIcon from "@areas/shared/components/icons/Search";

import { ReactComponent as RunSearchIcon } from "@content/img/shared/home/svg/map-search-run-icon.svg";
import { ReactComponent as ArrowIcon } from "@content/img/shared/home/svg/arrow-icon.svg";
import defaultLogo from "@content/img/shared/education/png/default-logo.png";

import Filter from "./Filter";

import { filterNames } from "@helpers/data/filterNames";

import styles from "./styles/search.module.css";
import { Link } from "react-router-dom";

function Search({
  load,
  query,
  search,
  types,
  setTypes,
  filterUser,
  setFilterUser,
  results,
  setResults,
}) {
  const searchInput = useRef();
  const [filterUrl, setFilterUrl] = useState("");

  const getCountCompaniesByType = (type) => {
    let data = results.totals.filter(
      (p) => p.Name === "companies" && p.IdType === type
    );
    if (data.length > 0) {
      return data[0].Value;
    }
    return 0;
  };

  const getCountSpecialities = () => {
    let data = results.totals.filter((p) => p.Name === "specialities");
    if (data.length > 0) {
      return data[0].Value;
    }
    return 0;
  };

  const getSpecialities = (company) => {
    let res = [];
    company.Faculty.forEach((faculty, index) => {
      if (index < 4) {
        res.push(faculty);
      }
    });
    return res;
  };

  useEffect(() => {
    let url = "";
    for (let i = 0; i < filterNames.length; i++) {
      url += `&${filterNames[i]}=${filterUser
        .filter((p) => p.name === filterNames[i])
        .map((p) => p.value)
        .join(",")}`;
    }
    setFilterUrl(url);
  }, [filterUser]);

  return (
    <CardArrow
      customStyles={{
        main: styles.cardSearch,
        border: styles.border,
        arrow: styles.arrow,
      }}
      sizeArrow={{ width: 33, height: 33 }}
    >
      <div className={styles.inner}>
        <Filter
          load={load}
          data={results.filter}
          values={filterUser}
          set={setFilterUser}
          setTypes={setTypes}
          types={types}
          submit={search}
        />
        <div className={styles.content}>
          <div className={styles.search}>
            <SearchIcon
              style={styles.icon}
              gradientId="mapSearchIcon"
              gradientSteps={[
                {
                  offset: 0,
                  stopColor: "#C12E2E",
                },
                {
                  offset: 1,
                  stopColor: "#CD9F29",
                },
              ]}
            />
            <input
              className={styles.input}
              type="text"
              placeholder="Поиск"
              onKeyDown={(e) => {
                if (e.key === "Enter") {
                  search(searchInput.current.value);
                }
              }}
              onChange={() => {
                setResults((p) => {
                  return {
                    ...p,
                    filter: p.filter.map((f) => ({
                      Type: f.Type,
                      Title: f.Title,
                      Name: f.Name,
                      Options: [],
                    })),
                  };
                });
                setFilterUser([]);
              }}
              ref={searchInput}
            />
            <button
              className={styles.button}
              onClick={() => search(searchInput.current.value)}
            >
              <RunSearchIcon />
            </button>
          </div>
          <div className={styles.info}>
            {load ? (
              <div className="d-flex w-100 justify-content-center mt-4">
                <ClipLoader />
              </div>
            ) : (
              <>
                <div className={styles.totals}>
                  <span>Найдено:</span>
                  <span>ВУЗы -</span>
                  <span>{getCountCompaniesByType(2)},</span>
                  <span>СПО -</span>
                  <span>{getCountCompaniesByType(3)},</span>
                  <span>Специальности -</span>
                  <span>{getCountSpecialities()}</span>
                </div>
                <div className={styles.results}>
                  {results.data.map((item) => (
                    <div key={item.Id} className={styles.result}>
                      <div className={styles.card}>
                        <div className={styles.city}>{item.Address}</div>
                        <div className={styles.info}>
                          <div className={styles.about}>
                            <img src={defaultLogo} alt="Результат" />
                            <div className={styles.title}>
                              {item.Shortname ? item.Shortname : item.Fullname}
                            </div>
                          </div>
                          <Link
                            to={`/obrazovanie-v-bashkortostane/${
                              item.IdType === 2 ? "vuzy-rb" : "kolledzhi-rb"
                            }/${item.Slug}`}
                            className={styles.toCompany} target="_blank"
                          >
                            Перейти к учебному заведению
                            <ArrowIcon />
                          </Link>
                        </div>
                        <div className={styles.specialities}>
                          {item.Faculty.length > 0 ? (
                            <div className={styles.title}>
                              <span>
                                {item.Faculty[0].Name
                                  ? item.Faculty[0].Name
                                  : item.Faculty[0].Title}
                              </span>
                              <span>и</span>
                              <span className={styles.colored}>
                                еще {item.Faculty.length - 1} специальностей
                              </span>
                            </div>
                          ) : (
                            "Нет специальностей"
                          )}
                          {getSpecialities(item).map((faculty) => (
                            <div key={faculty.Id} className={styles.speciality}>
                              <div className={styles.name} title={faculty.Name ? faculty.Name : faculty.Title}>
                                {faculty.Name ? faculty.Name : faculty.Title}
                              </div>
                              <div className={styles.props}>
                                <div className={styles.nowt}>                            
                                  <span className={styles.value}>
                                    {faculty.Form}
                                  </span>
                                  <div className={styles.separator}></div>
                                </div>
                                <div className={styles.prop}>
                                  <span className={styles.name}>
                                    Кол-во бюдж. мест
                                  </span>
                                  <span className={styles.value}>
                                    {faculty.BudgetPlaces}
                                  </span>
                                  <div className={styles.separator}></div>
                                </div>
                                <div className={styles.prop}>
                                  <span className={styles.name}>
                                    Кол-во плат. мест
                                  </span>
                                  <span className={styles.value}>
                                    {faculty.PaidPlaces}
                                  </span>
                                </div>
                                <Link target="_blank"
                                  to={`/obrazovanie-v-bashkortostane/${
                                    item.IdType === 2
                                      ? "vuzy-rb"
                                      : "kolledzhi-rb"
                                  }/${item.Slug}/speciality/${faculty.slug}`}
                                  className={styles.go}
                                >
                                  <ArrowIcon />
                                </Link>
                              </div>
                            </div>
                          ))}
                        </div>
                        <Link hidden="true"
                          to={`/obrazovanie-v-bashkortostane/${
                            item.IdType === 2 ? "vuzy-rb" : "kolledzhi-rb"
                          }/${item.Slug}`}
                          className={styles.more}
                        >
                          <div className={styles.all}>Посмотреть все</div>
                        </Link>
                      </div>
                    </div>
                  ))}
                </div>
                <div className={styles.full}>
                  <Link
                    to={`/company/search?query=${query}&types=${types.join(
                      ","
                    )}${filterUrl}`}
                    className={`${styles.more}`}
                  >
                    <div className={`${styles.all}`}>Посмотреть все</div>
                  </Link>
                </div>
              </>
            )}
          </div>
        </div>
      </div>
    </CardArrow>
  );
}

export default Search;
