import React from "react";

function Search({ style, gradientId, gradientSteps }) {
  return (
    <svg
      width="18"
      height="18"
      viewBox="0 0 18 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={style}
    >
      <path
        d="M17.2499 17.25L13.2689 13.2689M13.2689 13.2689C13.9498 12.5879 14.49 11.7795 14.8586 10.8898C15.2271 10 15.4168 9.04639 15.4168 8.08334C15.4168 7.12029 15.2271 6.16667 14.8586 5.27692C14.49 4.38718 13.9498 3.57874 13.2689 2.89776C12.5879 2.21678 11.7794 1.67659 10.8897 1.30805C9.99995 0.939504 9.04633 0.749817 8.08328 0.749817C7.12023 0.749817 6.1666 0.939504 5.27686 1.30805C4.38712 1.67659 3.57868 2.21678 2.89769 2.89776C1.52239 4.27306 0.749756 6.13837 0.749756 8.08334C0.749756 10.0283 1.52239 11.8936 2.89769 13.2689C4.273 14.6442 6.13831 15.4169 8.08328 15.4169C10.0282 15.4169 11.8936 14.6442 13.2689 13.2689Z"
        stroke={`url(#${gradientId})`}
        strokeWidth="1.25"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <defs>
        <linearGradient
          id={gradientId}
          x1="8.99985"
          y1="0.749817"
          x2="16.7755"
          y2="17.5722"
          gradientUnits="userSpaceOnUse"
        >
          {gradientSteps &&
            gradientSteps.map((gradientStep, index) => (
              <stop
                key={index}
                offset={`${gradientStep.offset}`}
                stopColor={gradientStep.stopColor}
              />
            ))}
        </linearGradient>
      </defs>
    </svg>
  );
}

export default Search;
