import React, { useEffect, useState } from "react";
import { Nav, NavItem, NavLink, TabContent, TabPane } from "reactstrap";
import { Link } from "react-router-dom";
import ClipLoader from "react-spinners/ClipLoader";
import moment from "moment";

import { fetchWrapper } from "@helpers/fetchwrapper";

import styles from "./styles/categories.module.css";

function Categories() {
  const [activeTab, setActiveTab] = useState("1");
  const [load, setLoad] = useState(false);
  const [practices, setPractices] = useState([]);
  const [mentors, setMentors] = useState([]);

  const loadPractices = () => {
    setLoad(true);
    fetchWrapper
      .get(`/v1/api/app/findPracticy?offset=0&limit=6`)
      .then((res) => {
        setLoad(false);
        setPractices(res.data);
      });
  };

  const loadMentors = () => {
    setLoad(true);
    fetchWrapper
      .get(`/v1/api/app/findMentorship?offset=0&limit=6`)
      .then((res) => {
        setLoad(false);
        setMentors(res.data);
      });
  };

  useEffect(() => {
    loadPractices();
  }, []);

  return (
    <div className={`container ${styles.block}`}>
      <Nav tabs className={styles.tabs}>
        <NavItem>
          <NavLink
            className={`${styles.tab} ${styles.start} ${
              activeTab === "1" ? styles.active : null
            }`}
            onClick={() => {
              loadPractices();
              setActiveTab("1");
            }}
          >
            Практики/Стажировки
          </NavLink>
        </NavItem>
        <NavItem>
          <NavLink
            className={`${styles.tab} ${styles.end} ${
              activeTab === "2" ? styles.active : null
            }`}
            onClick={() => {
              loadMentors();
              setActiveTab("2");
            }}
          >
            Наставники
          </NavLink>
        </NavItem>
      </Nav>
      <TabContent activeTab={activeTab}>
        <TabContent activeTab={activeTab}>
          <TabPane tabId="1">
            {load ? (
              <div className="d-flex w-100 justify-content-center mt-4">
                <ClipLoader />
              </div>
            ) : (
              <div className={styles.tabContent}>
                {practices.map((practice) => (
                  <div className={styles.card} key={practice.Id}>
                    <div className={styles.tags}>
                      {practice.Tags.map((tag) => (
                        <div className={styles.tag} key={tag.Id}>
                          {tag.Name}
                        </div>
                      ))}
                    </div>
                    <div className={styles.type}>{practice.NameCompany}</div>
                    <div className={styles.header}>{practice.NameOkved2}</div>
                    <div className={styles.description}>{practice.Tasks}</div>
                    <div className={styles.footer}>
                      <div className={styles.date}>
                        {moment(practice.DateS1).format("DD MMMM YYYY")}
                      </div>
                      <Link
                        to={`/company/${practice.SlugCompany}/practice/${practice.Id}`}
                        target="_blank"
                        className={styles.addition}
                      >
                        Ознакомиться подробнее →
                      </Link>
                    </div>
                  </div>
                ))}
              </div>
            )}
          </TabPane>
          <TabPane tabId="2">
            {load ? (
              <div className="d-flex w-100 justify-content-center mt-4">
                <ClipLoader />
              </div>
            ) : (
              <div className={styles.tabContent}>
                {mentors.map((mentor) => (
                  <div className={styles.card} key={mentor.Id}>
                    <div className={styles.type}>{mentor.NameCompany}</div>
                    <div className={styles.header}>{mentor.Fio}</div>
                    <div className={styles.description}>{mentor.Area}</div>
                    <div className={styles.footer}>
                      <div className={styles.date}></div>
                      <Link
                        to={`/company/${mentor.SlugCompany}/mentorship/${mentor.Id}`}
                        target="_blank"
                        className={styles.addition}
                      >
                        Ознакомиться подробнее →
                      </Link>
                    </div>
                  </div>
                ))}
              </div>
            )}
          </TabPane>
        </TabContent>
      </TabContent>
      <Link
        to={"/company/search-categories"}
        target="_blank"
        className={styles.more}
      >
        <div className={styles.all}>Посмотреть все</div>
      </Link>
    </div>
  );
}

export default Categories;
