import React, { useEffect } from "react";
import { Outlet, useLocation } from "react-router-dom";
import { useDispatch } from "react-redux";

import Animation from "@areas/shared/components/chooseLayout/Animation";
import animationStyles from "@areas/shared/components/chooseLayout/styles/animations.module.css";

import Error from "@areas/shared/pages/error/Error";

import Menu from "./Menu";
import Footer from "./Footer";

import { layoutActions } from "@store";

export default Layout;

function Layout({ error }) {
  const dispatch = useDispatch();
  const location = useLocation();

  useEffect(() => {
    dispatch(layoutActions.init());
  }, [dispatch, location]);

  return (
    <div>
      <Animation
        animationIn={animationStyles.swipeTopIn}
        animationOut={animationStyles.swipeTopOut}
      >
        <Menu />
      </Animation>
      {error ? <Error /> : <Outlet />}
      <Animation
        animationIn={animationStyles.swipeTopIn}
        animationOut={animationStyles.swipeTopOut}
      >
        <Footer />
      </Animation>
    </div>
  );
}
