import React from "react";
import { Col, FormFeedback, Input, Label, Row } from "reactstrap";
import * as Yup from "yup";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";

import { fetchWrapper } from "@helpers/fetchwrapper";

import styles from "@areas/shared/components/personal/modals/styles/customModal.module.css";

const initialValues = (page) => {
  return {
    id: (page && page.Id) || null,
    idType: (page && page.IdType) || 3,
    title: (page && page.Title) || "",
    description: (page && page.Description) || "",
    body: (page && page.Body) || "",
    slug: (page && page.Slug) || "",
  };
};

const validationSchema = () => {
  return Yup.object({
    title: Yup.string().required("Заголовок обязателен"),
    description: Yup.string().required("Описание обязательно"),
    body: Yup.string().required("Текст обязателен"),
    slug: Yup.string().required("Ссылка обязательна"),
    idType: Yup.number().required("Тип страницы обязателен"),
  });
};

const confirm = (values, successFunc) => {
  let data = new FormData();
  data.append("idType", values.idType);
  data.append("title", values.title);
  data.append("description", values.description);
  data.append("body", values.body);
  data.append("slug", values.slug);

  if (values.id) {
    data.append("id", values.id);
    fetchWrapper
      .post(`/v1/api/admin/updatepage`, data, false)
      .then(successFunc);
  } else {
    fetchWrapper
      .post(`/v1/api/admin/insertpage`, data, false)
      .then(successFunc);
  }
};

function ModalBodyAdd({ validation }) {
  return (
    <Row className="g-2">
      <Input
        type="hidden"
        name="id"
        defaultValue={validation.values.id || null}
      />
      <Col lg={12}>
        <div>
          <Label htmlFor="pageTitle-field" className={styles.label}>
            Заголовок
          </Label>
          <Input
            name="title"
            id="pageTitle-field"
            className={`form-control ${styles.input}`}
            placeholder="Введите заголовок"
            type="text"
            validate={{
              required: { value: true },
            }}
            onChange={validation.handleChange}
            onBlur={validation.handleBlur}
            value={validation.values.title || ""}
            invalid={
              validation.touched.title && validation.errors.title ? true : false
            }
          />
          {validation.touched.title && validation.errors.title ? (
            <FormFeedback type="invalid" className={styles.invalid}>
              {validation.errors.title}
            </FormFeedback>
          ) : null}
        </div>
      </Col>
      <Col lg={12}>
        <div>
          <Label htmlFor="pageDescription-field" className={styles.label}>
            Описание
          </Label>
          <Input
            name="description"
            id="pageDescription-field"
            className={`form-control ${styles.input}`}
            placeholder="Введите описание"
            type="text"
            validate={{
              required: { value: true },
            }}
            onChange={validation.handleChange}
            onBlur={validation.handleBlur}
            value={validation.values.description || ""}
            invalid={
              validation.touched.description && validation.errors.description
                ? true
                : false
            }
          />
          {validation.touched.description && validation.errors.description ? (
            <FormFeedback type="invalid" className={styles.invalid}>
              {validation.errors.description}
            </FormFeedback>
          ) : null}
        </div>
      </Col>
      <Col lg={12}>
        <div>
          <Label htmlFor="pageBody-field" className={styles.label}>
            Текст
          </Label>
          <CKEditor
            name="body"
            id="pageBody-field"
            editor={ClassicEditor}
            placeholder="Введите текст новости"
            data={validation.values.body || ""}
            onChange={(event, editor) => {
              const data = editor.getData();
              validation.setFieldValue("body", data);
            }}
            onBlur={() => validation.setFieldTouched("body")}
          />
          {validation.touched.body && validation.errors.body ? (
            <FormFeedback type="invalid" className={styles.invalid}>
              {validation.errors.body}
            </FormFeedback>
          ) : null}
        </div>
      </Col>
      <Col lg={6} sm={12}>
        <div>
          <Label htmlFor="pageSlug-field" className={styles.label}>
            Ссылка
          </Label>
          <Input
            name="slug"
            id="pageSlug-field"
            className={`form-control ${styles.input}`}
            placeholder="Введите ссылку"
            type="text"
            validate={{
              required: { value: true },
            }}
            onChange={validation.handleChange}
            onBlur={validation.handleBlur}
            value={validation.values.slug || ""}
            invalid={
              validation.touched.slug && validation.errors.slug ? true : false
            }
          />
          {validation.touched.slug && validation.errors.slug ? (
            <FormFeedback type="invalid" className={styles.invalid}>
              {validation.errors.slug}
            </FormFeedback>
          ) : null}
        </div>
      </Col>
      <Col lg={6} sm={12}>
        <div>
          <Label htmlFor="pageIdType-field" className={styles.label}>
            Тип
          </Label>
          <Input
            name="idType"
            id="pageIdType-field"
            className={`form-control ${styles.input}`}
            placeholder="Выберите тип"
            type="select"
            validate={{
              required: { value: true },
            }}
            onChange={validation.handleChange}
            onBlur={validation.handleBlur}
            value={validation.values.idType || 3}
            invalid={
              validation.touched.idType && validation.errors.idType
                ? true
                : false
            }
          >
            <option value={3}>Работодателям</option>
            <option value={4}>СПО/ВПО</option>
            <option value={5}>Студентам</option>
            <option value={6}>Абитуриентам</option>
          </Input>
          {validation.touched.idType && validation.errors.idType ? (
            <FormFeedback type="invalid" className={styles.invalid}>
              {validation.errors.idType}
            </FormFeedback>
          ) : null}
        </div>
      </Col>
    </Row>
  );
}

export { initialValues, validationSchema, confirm, ModalBodyAdd };
