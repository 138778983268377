import React, { useEffect } from "react";
import { useDispatch } from "react-redux";
import { useLoaderData } from "react-router-dom";

import Animation from "@areas/shared/components/chooseLayout/Animation";
import animationStyles from "@areas/shared/components/chooseLayout/styles/animations.module.css";

import { metaActions } from "@store";

import About from "./blocks/About";
import Map from "./blocks/Map/Map";
import Categories from "./blocks/Categories";
import Calendar from "./blocks/Calendar";
import News from "./blocks/News";
import Docs from "./blocks/Docs";
import Partners from "./blocks/Partners";

function Page(props) {
  const data = useLoaderData();
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(
      metaActions.setData({
        icon: "/faviconEmployer.ico",
        title: "Работодателям",
        description:
          "Прямая коммуникация с учебными заведениями профессиональной подготовки Республики Башкортостан, взаимодействие с потенциальными сотрудниками: со студентами и профессионалами, ищущими работу.",
      })
    );
  }, [dispatch]);

  return (
    <div>
      <Animation
        animationIn={animationStyles.swipeLeftIn}
        animationOut={animationStyles.swipeRightOut}
      >
        <About />
      </Animation>
      <Animation>
        <Map data={data.Companies} />
      </Animation>
      <Animation>
        <Categories />
      </Animation>
      <Animation>
        <Calendar />
      </Animation>
      <Animation>
        <News data={data.Pages} />
      </Animation>
      <Animation>
        <Docs />
      </Animation>
      <Animation>
        <Partners />
      </Animation>
    </div>
  );
}

export default Page;
