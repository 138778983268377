import React from "react";

function Pesonal({ style, gradientId, gradientSteps }) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="16"
      height="16"
      fill="none"
      viewBox="0 0 16 16"
      className={style}
    >
      <path
        d="M8 8a3 3 0 1 0 0-6 3 3 0 0 0 0 6m2-3a2 2 0 1 1-4 0 2 2 0 0 1 4 0m4 8c0 1-1 1-1 1H3s-1 0-1-1 1-4 6-4 6 3 6 4m-1-.004c-.001-.246-.154-.986-.832-1.664C11.516 10.68 10.289 10 8 10s-3.516.68-4.168 1.332c-.678.678-.83 1.418-.832 1.664z"
        fill={`url(#${gradientId})`}
      />
      <defs>
        <linearGradient
          id={gradientId}
          x1="13.7085"
          y1="0.5"
          x2="25.1153"
          y2="26.4123"
          gradientUnits="userSpaceOnUse"
        >
          {gradientSteps &&
            gradientSteps.map((gradientStep, index) => (
              <stop
                key={index}
                offset={`${gradientStep.offset}`}
                stopColor={gradientStep.stopColor}
              />
            ))}
        </linearGradient>
      </defs>
    </svg>
  );
}

export default Pesonal;
