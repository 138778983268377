import React from "react";

function CircleShadow({ style }) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="89"
      height="105"
      viewBox="0 0 89 105"
      fill="none"
      className={style}
    >
      <g filter="url(#filter0_f_196_660)">
        <ellipse
          cx="43.5399"
          cy="92.7027"
          rx="20.2162"
          ry="2.2973"
          fill="#019A7A"
        />
      </g>
      <circle
        cx="44.08"
        cy="44.08"
        r="44.08"
        fill="url(#paint0_linear_196_660)"
      />
      <defs>
        <filter
          id="filter0_f_196_660"
          x="13.3237"
          y="80.4054"
          width="60.4326"
          height="24.5946"
          filterUnits="userSpaceOnUse"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="BackgroundImageFix"
            result="shape"
          />
          <feGaussianBlur
            stdDeviation="5"
            result="effect1_foregroundBlur_196_660"
          />
        </filter>
        <linearGradient
          id="paint0_linear_196_660"
          x1="38.7516"
          y1="-106.593"
          x2="-4.88034"
          y2="115.404"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#00ECB3" />
          <stop offset="0.864583" stopColor="#24C9BF" />
          <stop offset="1" stopColor="#23C8B0" />
        </linearGradient>
      </defs>
    </svg>
  );
}

export default CircleShadow;
