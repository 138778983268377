import React, { useCallback, useEffect, useState } from "react";

import { fetchWrapper } from "@helpers/fetchwrapper";

import notPhoto from "@content/img/notPhoto.jpg";

import styles from "./styles/partners.module.css";

function Partners() {
  const [data, setData] = useState([]);

  const loadData = useCallback(() => {
    fetchWrapper.get("/data/partners.json").then((res) => {
      setData(res);
    });
  }, []);

  useEffect(() => {
    loadData();
  }, [loadData]);

  return (
    <div className={`${styles.block} container`}>
      <div className={styles.header}>Партнеры</div>
      <div className={styles.partners}>
        {data.map((item, index) => (
          <div key={index} className={styles.partner}>
            <div className={styles.photo}>
              {item.photo ? (
                <img src={item.photo} alt="Фото" />
              ) : (
                <img src={notPhoto} alt="Фото" />
              )}
            </div>
            <div className={styles.info}>
              <div className={styles.title}>
                <div className={styles.name}>{item.name}</div>
              </div>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
}

export default Partners;
