import React from "react";
import { useLocation } from "react-router-dom";
import { IconContext } from "react-icons";
import { useSelector } from "react-redux";

import { SidebarData, area } from "./SidebarData";

import HasSubItems from "./sidebarElems/HasSubItems";
import Item from "./sidebarElems/Item";
import Logo from "./sidebarElems/Logo";
import Separator from "./sidebarElems/Separator";

import styles from "./styles/sidebar.module.css";

function Sidebar({ visible, hide, permission }) {
  const location = useLocation();
  const { company: currentCompany } = useSelector((x) => x.auth);

  const getShortPath = (current) => {
    let splited = current.split("/");
    if (splited.length > 3) {
      return `/${splited[1]}/${splited[2]}`;
    } else {
      return current;
    }
  };

  const comparePaths = (type, current, target) => {
    if (type !== "main") {
      current = getShortPath(current);
    }
    if (current === target || current === target + "/") {
      return true;
    } else {
      return false;
    }
  };

  const checkPermission = (item) => {
    if (permission.some((perm) => `${area}/${perm}` === item.path)) {
      if (currentCompany != null && currentCompany.TypeCompany === 1) {
        if (item.path.indexOf("/personal/education") !== -1) {
          return false;
        }
      } else if (currentCompany == null) {
        if (item.companyPath) {
          return false;
        }
      }
      return true;
    } else {
      return false;
    }
  };

  return (
    <IconContext.Provider value={{ color: "#fff" }}>
      <div className={styles.sidebarWrapper}>
        <nav
          className={
            visible
              ? `${styles.sidebarMenu} ${styles.active}`
              : styles.sidebarMenu
          }
        >
          <div className={styles.background}></div>
          <ul className={styles.sidebarMenuItems}>
            {SidebarData(area).map((item, index) => {
              switch (item.type) {
                case "link":
                  if (item.subNav) {
                    return (
                      <HasSubItems
                        key={index}
                        item={item}
                        hide={hide}
                        location={location}
                        comparePaths={comparePaths}
                        checkPermission={checkPermission}
                      />
                    );
                  } else {
                    return (
                      <Item
                        key={index}
                        item={item}
                        hide={hide}
                        location={location}
                        comparePaths={comparePaths}
                        checkPermission={checkPermission}
                      />
                    );
                  }
                case "logo":
                  return <Logo key={index} item={item} />;
                case "separator":
                  return <Separator key={index} item={item} />;
                default:
                  return null;
              }
            })}
          </ul>
        </nav>
      </div>
      {visible ? <div className={styles.overlay} onClick={hide}></div> : null}
    </IconContext.Provider>
  );
}
export default Sidebar;
