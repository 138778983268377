import React from "react";

import budgetIcon from "@content/img/college/speciality/svg/addition-budget-icon.svg";
import notBudgetIcon from "@content/img/college/speciality/svg/addition-not-budget-icon.svg";
import periodIcon from "@content/img/college/speciality/svg/addition-period-icon.svg";
import examIcon from "@content/img/college/speciality/svg/addition-exam-icon.svg";
import achivmentIcon from "@content/img/college/speciality/svg/addition-achivment-icon.svg";

import styles from "./styles/addition.module.css";

function Addition({ data }) {
  const getNormalizeDuration = (duration) => {
    let years = Math.floor(duration / 12);
    let months = duration % 12;

    let result = `${years} ${
      years > 1 ? (years >= 5 ? "лет" : "года") : "год"
    }`;
    if (months > 0) {
      result += ` ${months} ${months > 1 ? "месяцев" : "месяц"}`;
    }

    return result;
  };
  return (
    <div className={`container ${styles.block}`}>
      <div className={styles.program}>
        <div className={styles.title}>Содержание программы</div>
        <div className={styles.description}>{data.speciality.Program}</div>
      </div>
      <div className={styles.columns}>
        <div className={styles.left}>
          {/* <div className={styles.years}>
            <div className={styles.title}>Учебный год</div>
            <div className={styles.description}>
              <div className="d-flex align-items-center">
                <input type="radio" disabled />
                <label className="ms-2">2022</label>
              </div>
              <div className="d-flex align-items-center">
                <input type="radio" disabled />
                <label className="ms-2">2021</label>
              </div>
              <div className="d-flex align-items-center">
                <input type="radio" disabled />
                <label className="ms-2">2020</label>
              </div>
            </div>
          </div> */}
          <div className={styles.card}>
            <div className={styles.title}>
              <img src={budgetIcon} alt="Прием на бюджетное отделение" />
              <div>Прием на бюджетное отделение</div>
            </div>
            <div className={`${styles.description} ${styles.spaces}`}>
              <div className={styles.item}>
                <div>Конкурс</div>
                <div className={styles.value}>-</div>
              </div>
              <div className={styles.item}>
                <div>Проходной балл</div>
                <div className={styles.value}>-</div>
              </div>
              <div className={styles.item}>
                <div>Число мест</div>
                <div className={styles.value}>
                  {data.speciality.BudgetPlaces
                    ? `${data.speciality.BudgetPlaces}`
                    : "-"}
                </div>
              </div>
            </div>
          </div>
          <div className={styles.card}>
            <div className={styles.title}>
              <img src={notBudgetIcon} alt="Прием на платное отделение" />
              <div>Прием на платное отделение</div>
            </div>
            <div className={`${styles.description} ${styles.spaces}`}>
              <div className={styles.item}>
                <div>Конкурс</div>
                <div className={styles.value}>-</div>
              </div>
              <div className={styles.item}>
                <div>Проходной балл</div>
                <div className={styles.value}>-</div>
              </div>
              <div className={styles.item}>
                <div>Число мест</div>
                <div className={styles.value}>
                  {data.speciality.PaidPlaces
                    ? `${data.speciality.PaidPlaces}`
                    : "-"}
                </div>
              </div>
            </div>
          </div>
          <div className={styles.card}>
            <div className={styles.title}>
              <img src={periodIcon} alt="Срок и стоимость обучения" />
              <div>Срок и стоимость обучения</div>
            </div>
            <div className={`${styles.description} ${styles.spaces}`}>
              <div className={styles.item}>
                <div>Срок обучения</div>
                <div className={styles.value}>
                  {getNormalizeDuration(data.speciality.Duration)}
                </div>
              </div>
              <div className={styles.item}>
                <div>Стоимость обучения в год</div>
                <div className={styles.value}>
                  {data.speciality.Price ? `${data.speciality.Price} ₽` : "-"}
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className={styles.right}>
          {/* <div className={styles.card}>
            <div className={styles.title}>
              <img src={examIcon} alt="Экзамены ЕГЭ" />
              <div>Экзамены ЕГЭ</div>
            </div>
            <div className={styles.description}>
              <div className={`${styles.item} ${styles.fix}`}>
                <div>Значок ГТО</div>
                <div className={styles.value}>5</div>
              </div>
              <div className={`${styles.item} ${styles.fix}`}>
                <div>Значок ГТО</div>
                <div className={styles.value}>5</div>
              </div>
              <div className={`${styles.item} ${styles.fix}`}>
                <div>Значок ГТО</div>
                <div className={styles.value}>5</div>
              </div>
              <div className={`${styles.item} ${styles.fix}`}>
                <div>Значок ГТО</div>
                <div className={styles.value}>5</div>
              </div>
              <div className={`${styles.item} ${styles.fix}`}>
                <div>Значок ГТО</div>
                <div className={styles.value}>5</div>
              </div>
            </div>
          </div>
          <div className={styles.card}>
            <div className={styles.title}>
              <img src={achivmentIcon} alt="Учет индивидуальных достижений" />
              <div>Учет индивидуальных достижений</div>
            </div>
            <div className={styles.description}>
              <div className={`${styles.item} ${styles.fix}`}>
                <div>Значок ГТО</div>
                <div className={styles.value}>5</div>
              </div>
              <div className={`${styles.item} ${styles.fix}`}>
                <div>Значок ГТО</div>
                <div className={styles.value}>5</div>
              </div>
              <div className={`${styles.item} ${styles.fix}`}>
                <div>Значок ГТО</div>
                <div className={styles.value}>5</div>
              </div>
              <div className={`${styles.item} ${styles.fix}`}>
                <div>Значок ГТО</div>
                <div className={styles.value}>5</div>
              </div>
              <div className={`${styles.item} ${styles.fix}`}>
                <div>Значок ГТО</div>
                <div className={styles.value}>5</div>
              </div>
            </div>
          </div> */}
        </div>
      </div>
    </div>
  );
}

export default Addition;
