import React from "react";
import { Input, Label } from "reactstrap";
import ClipLoader from "react-spinners/ClipLoader";

import btnStyles from "@areas/employer/components/buttons/styles/button.module.css";
import styles from "./styles/filter.module.css";

function Filter({ load, data, set, values, setTypes, types, submit }) {
  const setOpenStyles = (target) => {
    if (target.parentElement.classList.contains(styles.active)) {
      target.parentElement.classList.remove(styles.active);
    } else {
      target.parentElement.classList.add(styles.active);
    }
  };
  const chooseType = (type) => {
    let has = types.filter((p) => p === type);
    if (has.length > 0) {
      setTypes((p) => p.filter((p) => p !== type));
    } else {
      setTypes((p) => [...p, type]);
    }
    set([]);
  };
  const checkDefaultOpenStyle = (values, name) => {
    return values.filter((p) => p.name === name).length > 0;
  };

  const generateFilter = (data) => {
    let result = [];
    for (let i = 0; i < data.length; i++) {
      switch (data[i].Type) {
        case "checkboxGroup":
          result[result.length] = (
            <div
              key={i}
              className={`${styles.checkboxGroup}${
                checkDefaultOpenStyle(values, data[i].Name)
                  ? ` ${styles.active}`
                  : ""
              }`}
            >
              <div
                className={styles.checkboxGroupHeader}
                onClick={(e) => {
                  setOpenStyles(e.currentTarget);
                }}
              >
                {data[i].Title}
              </div>
              {data[i].Options.map((item, index) => (
                <div key={index} className={styles.option}>
                  <Input
                    id={`${data[i].Name}-${index}`}
                    type="checkbox"
                    className={styles.checkbox}
                    name={data[i].Name}
                    value={item.Value}
                    defaultChecked={
                      values.filter(
                        (p) => p.name === data[i].Name && p.value === item.Value
                      ).length > 0
                    }
                    onChange={() => {
                      if (
                        values.filter(
                          (p) =>
                            p.name === data[i].Name && p.value === item.Value
                        ).length > 0
                      ) {
                        set((p) => [
                          ...p.filter(
                            (p) =>
                              p.name !== data[i].Name || p.value !== item.Value
                          ),
                        ]);
                      } else {
                        set((p) => [
                          ...p,
                          { name: data[i].Name, value: item.Value },
                        ]);
                      }
                    }}
                  />
                  <Label
                    className={styles.checkboxLabel}
                    htmlFor={`${data[i].Name}-${index}`}
                  >
                    {item.Name}
                  </Label>
                </div>
              ))}
            </div>
          );
          break;
        default:
          continue;
      }
    }
    return result;
  };
  return (
    <div className={`${styles.filter}`}>
      <div className={styles.searchFor}>
        <div
          onClick={() => chooseType(2)}
          className={`${btnStyles.button} ${styles.option}${
            types && types.filter((p) => p === 2).length
              ? ` ${styles.active}`
              : ""
          }`}
        >
          ВУЗ
        </div>
        <div
          onClick={() => chooseType(3)}
          className={`${btnStyles.button} ${styles.option}${
            types && types.filter((p) => p === 3).length
              ? ` ${styles.active}`
              : ""
          }`}
        >
          СПО
        </div>
      </div>
      <div className={styles.inner}>
        {load ? (
          <div className="d-flex w-100 justify-content-center">
            <ClipLoader />
          </div>
        ) : (
          generateFilter(data)
        )}
      </div>
      {data.length > 0 && !load ? (
        <div className={styles.submit}>
          <div
            onClick={() => submit()}
            className={`${btnStyles.button} ${styles.button}`}
          >
            Применить фильтр
          </div>
        </div>
      ) : null}
      {data.length > 0 && !load ? (
        <div className={styles.clear}>
          <div
            onClick={() => {
              set([]);
              submit(undefined, true);
            }}
            className={`${btnStyles.button} ${styles.button}`}
          >
            Сбросить фильтр
          </div>
        </div>
      ) : null}
    </div>
  );
}

export default Filter;
