import React, { useCallback, useState, useEffect } from "react";
import { Card, CardBody, CardHeader } from "reactstrap";
import { TbPlus } from "react-icons/tb";
import { useSelector } from "react-redux";

import Animation from "@areas/shared/components/chooseLayout/Animation";
import animationStyles from "@areas/shared/components/chooseLayout/styles/animations.module.css";

import TableCompanies from "./blocks/TableCompanies";
import TableRequests from "./blocks/TableRequests";
import ButtonDanger from "@areas/shared/components/buttons/ButtonDanger";
import CustomModal from "@areas/shared/components/personal/modals/CustomModal";
import {
  initialValues,
  validationSchema,
  confirm,
  ModalBodyAdd,
} from "./blocks/ModalAdd";

import { fetchWrapper } from "@helpers/fetchwrapper";

function Frame(props) {
  const { company: currentCompany } = useSelector((x) => x.auth);
  const [showModal, setShowModal] = useState(false);
  const [headerModal, setHeaderModal] = useState("");
  const [perPageCompanies, setPerPageCompanies] = useState(10);
  const [pageCompanies, setPageCompanies] = useState(1);
  const [totalCompanies, setTotalCompanies] = useState(0);
  const [dataCompanies, setDataCompanies] = useState([]);
  const [loadCompanies, setLoadCompanies] = useState(false);
  const [selectedCompany, setSelectedCompany] = useState(0);
  const [perPageRequests, setPerPageRequests] = useState(10);
  const [pageRequests, setPageRequests] = useState(1);
  const [totalRequests, setTotalRequests] = useState(0);
  const [dataRequests, setDataRequests] = useState([]);
  const [loadRequests, setLoadRequests] = useState(false);
  const [request, setRequest] = useState([]);

  const loadDataCompanies = useCallback(() => {
    setLoadCompanies(true);
    fetchWrapper
      .get(
        `/v1/personal/getTicketsOutgoing?offset=${
          pageCompanies * perPageCompanies - perPageCompanies
        }&limit=${perPageCompanies}&idCompany=${currentCompany.IdCompany}`
      )
      .then((res) => {
        setDataCompanies(res.data);
        setTotalCompanies(res.total);
        setLoadCompanies(false);
      });
  }, [pageCompanies, perPageCompanies, currentCompany]);

  useEffect(() => {
    loadDataCompanies();
  }, [loadDataCompanies]);

  const handlePageCompaniesChange = (page) => {
    setPageCompanies(page);
  };

  const handlePerRowsCompaniesChange = async (newPerPage, page) => {
    setPerPageCompanies(newPerPage);
    setPageCompanies(page);
  };

  const loadDataRequests = useCallback(
    (company) => {
      setLoadRequests(true);
      fetchWrapper
        .get(
          `/v1/personal/getTicketsOutgoingList?offset=${
            pageRequests * perPageRequests - perPageRequests
          }&limit=${perPageRequests}&idCompany=${
            currentCompany.IdCompany
          }&idCompanyOutgoing=${company}`
        )
        .then((res) => {
          setDataRequests(res.data);
          setTotalRequests(res.total);
          setLoadRequests(false);
        });
    },
    [pageRequests, perPageRequests, currentCompany]
  );

  useEffect(() => {
    if (selectedCompany > 0) {
      loadDataRequests(selectedCompany);
    }
  }, [loadDataRequests, selectedCompany]);

  const handlePageRequestsChange = (page) => {
    setPageRequests(page);
  };

  const handlePerRowsRequestsChange = async (newPerPage, page) => {
    setPerPageRequests(newPerPage);
    setPageRequests(page);
  };

  const toggle = useCallback(
    (request) => {
      if (showModal) {
        setShowModal(false);
      } else {
        setRequest(request);
        if (!request) {
          setHeaderModal("Добавление заявки");
        } else {
          setHeaderModal("Редактирование заявки");
        }
        setShowModal(true);
      }
    },
    [showModal]
  );

  return (
    <div>
      <Animation
        animationIn={animationStyles.fadeIn}
        animationOut={animationStyles.fadeOut}
      >
        <Card>
          <CardHeader>
            <div className="d-flex justify-content-between align-items-center">
              <h5 className="mb-0">Исходящие заявки к компаниям</h5>
              <ButtonDanger onClick={() => toggle()}>
                <TbPlus />
                Добавить заявку
              </ButtonDanger>
            </div>
          </CardHeader>
          <CardBody>
            <TableCompanies
              data={dataCompanies}
              load={loadCompanies}
              total={totalCompanies}
              handlePageChange={handlePageCompaniesChange}
              handlePerRowsChange={handlePerRowsCompaniesChange}
              company={selectedCompany}
              setCompany={setSelectedCompany}
            />
          </CardBody>
        </Card>
        <Card>
          <CardHeader>
            <div className="d-flex justify-content-between align-items-center">
              <h5 className="mb-0">Заявки</h5>
            </div>
          </CardHeader>
          <CardBody>
            <TableRequests
              data={dataRequests}
              load={loadRequests}
              total={totalRequests}
              handlePageChange={handlePageRequestsChange}
              handlePerRowsChange={handlePerRowsRequestsChange}
              toggleModal={toggle}
            />
          </CardBody>
        </Card>
      </Animation>
      <CustomModal
        isOpen={showModal}
        toggle={toggle}
        header={headerModal}
        body={(validation) => <ModalBodyAdd validation={validation} />}
        validateYupSchema={validationSchema}
        inititalValues={() => initialValues(request, currentCompany)}
        cancel="Закрыть"
        confirm="Сохранить"
        confirmFunction={(values) => {
          confirm(values, loadDataCompanies);
        }}
      />
    </div>
  );
}

export default Frame;
