export const filter = [
  {
    name: "budgetPlaces",
    title: "Бюджетное",
    type: "radioGroup",
    options: [
      {
        name: "Да",
        value: "true",
      },
      {
        name: "Нет",
        value: "false",
      },
    ],
  },
  {
    name: "paidPlaces",
    title: "Платное",
    type: "radioGroup",
    options: [
      {
        name: "Да",
        value: "true",
      },
      {
        name: "Нет",
        value: "false",
      },
    ],
  },
  {
    name: "idForm",
    title: "Форма обучения",
    type: "radioGroup",
    options: [
      {
        name: "Очное",
        value: "1",
      },
      {
        name: "Заочное",
        value: "2",
      },
    ],
  },
];
