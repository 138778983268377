import React from "react";
import styles from "./styles/results.module.css";
import { Link } from "react-router-dom";
import moment from "moment";
import 'moment/locale/ru';

function Practicy({ data }) {
  return (
    <>
      {data.map((practice) => (
        <div className={styles.result}>
          <div className={styles.card} key={practice.Id}>
            <div className={styles.tags}>
              {practice.Tags.map((tag) => (
                <div className={styles.tag} key={tag.Id}>
                  {tag.Name}
                </div>
              ))}
            </div>
            <div className={styles.type}>{practice.NameCompany}</div>
            <div className={styles.header}>{practice.NameOkved2}</div>
            <div className={styles.description}>{practice.Tasks}</div>
            <div className={styles.footer}>
              <div className={styles.date}>
                {practice.DateS1
                  ? moment(practice.DateS1).locale("ru").format("DD MMMM YYYY")
                  : ""}
              </div>
              <Link
                to={`/company/${practice.SlugCompany}/practice/${practice.Id}`}
                target="_blank"
                className={styles.addition}
              >
                Ознакомиться подробнее →
              </Link>
            </div>
          </div>
        </div>
      ))}
    </>
  );
}
export default Practicy;
