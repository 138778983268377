import React, { useRef, useState, useCallback, useEffect } from "react";
import moment from "moment";
import ClipLoader from "react-spinners/ClipLoader";

import { fetchWrapper } from "@helpers/fetchwrapper";

import CardArrow from "@areas/shared/components/cards/CardArrow";
import Pagination from "@areas/shared/components/paginations/Pagination";
import CalendarElement from "@areas/shared/components/calendar/Calendar";

import SearchIcon from "@areas/shared/components/icons/Search";

import { ReactComponent as RunSearchIcon } from "@content/img/shared/home/svg/map-search-run-icon.svg";

import styles from "./styles/calendar.module.css";

function Calendar({ data }) {
  const searchInput = useRef();

  const [dateStart, setDateStart] = useState(moment().format("YYYY-MM-DD"));
  const [searchQuery, setSearchQuery] = useState("");
  const [searchResult, setSearchResult] = useState({
    data: data || [],
    total: 0,
  });
  const [showsFullData, setShowsFullData] = useState({
    data: data || [],
    total: 0,
  });
  const [showsData, setShowsData] = useState([]);
  const [load, setLoad] = useState(false);

  const changeShows = useCallback(
    (offset) => {
      if (showsFullData.data.length >= 5) {
        setShowsData(showsFullData.data.slice(offset, offset + 5));
      } else {
        setShowsData(showsFullData.data);
      }
    },
    [showsFullData, setShowsData]
  );

  const search = useCallback(
    (date = null) => {
      setLoad(true);
      if (date) {
        setDateStart(date);
      } else {
        date = dateStart;
      }

      fetchWrapper
        .get(`/v1/api/app/findEvent?limit=100&offset=0&type=1&dat=${date}`)
        .then((res) => {
          setSearchResult(res);
        })
        .finally(() => setLoad(false));
    },
    [dateStart]
  );

  const searchInData = useCallback(
    (query = "") => {
      if (query !== undefined) {
        setSearchQuery(query);
      } else {
        query = searchQuery;
      }
      let find = [];
      if (query) {
        find = searchResult.data.filter(
          (p) => p.Name.toLowerCase().indexOf(query.toLowerCase()) > -1
        );
      } else {
        find = searchResult.data;
      }
      setShowsFullData({
        data: find,
        total: find.length,
      });
    },
    [searchQuery, searchResult]
  );

  useEffect(() => {
    setShowsFullData(searchResult);
  }, [searchResult]);

  useEffect(() => {
    changeShows(0);
  }, [showsFullData, changeShows]);

  return (
    <div className={`${styles.block} container`}>
      <div className={styles.header}>Календарь мероприятий</div>
      <div className={`${styles.inner}`}>
        <div className={styles.calendar}>
          <CalendarElement
            onClick={(date) => {
              search(date);
            }}
          />
        </div>
        <CardArrow
          customStyles={{
            main: styles.cardSearch,
            border: styles.border,
            arrow: styles.arrow,
          }}
          rotate="rightTop"
          sizeArrow={{ width: 33, height: 33 }}
        >
          <div className={styles.content}>
            <div className={styles.search}>
              <SearchIcon
                style={styles.icon}
                gradientId="mapSearchIcon"
                gradientSteps={[
                  {
                    offset: -0.2,
                    stopColor: "#6E9A10",
                  },
                  {
                    offset: 1,
                    stopColor: "#D0A024",
                  },
                ]}
              />
              <input
                className={styles.input}
                type="text"
                placeholder="Поиск"
                ref={searchInput}
              />
              <button
                className={styles.button}
                onClick={() => searchInData(searchInput.current.value)}
              >
                <RunSearchIcon />
              </button>
            </div>
            {load ? (
              <div className="d-flex justify-content-center w-100 mt-5">
                <ClipLoader />
              </div>
            ) : (
              <div className={styles.info}>
                <div className={styles.results}>
                  {showsData.map((item) => (
                    <div key={item.Id} className={styles.result}>
                      <div className={styles.card}>
                        <div className={styles.title}>{item.Name}</div>
                        <div className={styles.date}>
                          {moment(item.DateStart).format("DD.MM.YYYY")}
                        </div>
                      </div>
                    </div>
                  ))}
                </div>
              </div>
            )}
            <Pagination
              load={load}
              itemsPerPage={5}
              totalItems={showsFullData.total}
              onChangePage={changeShows}
            />
          </div>
        </CardArrow>
      </div>
    </div>
  );
}

export default Calendar;
