import React from "react";
import { Col, FormFeedback, Input, Label, Row } from "reactstrap";
import * as Yup from "yup";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";

import { fetchWrapper } from "@helpers/fetchwrapper";

import stylesModal from "@areas/shared/components/personal/modals/styles/customModal.module.css";

const initialValues = (company, page) => {
  return {
    id: (page && page.Id) || null,
    idCompany: (company && company.IdCompany) || null,
    body: (page && page.Body) || null,
  };
};

const validationSchema = () => {
  return Yup.object({
    body: Yup.string().required("Текст обязателен"),
  });
};

const confirm = (values, successFunc) => {
  successFunc(true);
  let data = new FormData();
  data.append("idType", 8);
  data.append("title", "Центр карьеры");
  data.append("description", "Страница центра карьеры");
  data.append("body", values.body);
  data.append("slug", "career-center");

  if (values.idCompany) {
    if (values.id) {
      data.append("id", values.id);
      fetchWrapper
        .post(`/v1/personal/updatepage?id=${values.id}`, data, false)
        .then(() => successFunc(false));
    } else {
      fetchWrapper
        .post(
          `/v1/personal/insertPage?idCompany=${values.idCompany}`,
          data,
          false
        )
        .then(() => successFunc(false));
    }
  }
};

function FormBody({ validation, load }) {
  return (
    <Row className="g-2">
      <Input
        type="hidden"
        name="id"
        defaultValue={validation.values.id || null}
      />
      <Input
        type="hidden"
        name="idCompany"
        defaultValue={validation.values.idCompany || null}
      />
      <Col lg={12}>
        <div>
          <Label htmlFor="career-centerBody-field" className={stylesModal.label}>
            Контент
          </Label>
          <CKEditor
            name="body"
            id="career-centerBody-field"
            editor={ClassicEditor}
            placeholder="Введите текст страницы практики"
            data={validation.values.body || ""}
            onChange={(event, editor) => {
              const data = editor.getData();
              validation.setFieldValue("body", data);
            }}
            onBlur={() => validation.setFieldTouched("body")}
          />
          {validation.touched.body && validation.errors.body ? (
            <FormFeedback type="invalid" className={stylesModal.invalid}>
              {validation.errors.body}
            </FormFeedback>
          ) : null}
        </div>
      </Col>
    </Row>
  );
}

export { initialValues, validationSchema, confirm, FormBody };
