import React, { useRef } from "react";

import SearchIcon from "@areas/shared/components/icons/Search";

import { ReactComponent as RunSearchIcon } from "@content/img/shared/home/svg/map-search-run-icon.svg";

import styles from "./styles/search.module.css";

function Search({ query, sumbit, setResults, setFilterUser }) {
  const searchInput = useRef();

  return (
    <div className={styles.search}>
      <SearchIcon
        style={styles.icon}
        gradientId="mapSearchIcon"
        gradientSteps={[
          {
            offset: 0,
            stopColor: "#6E9A10",
          },
          {
            offset: 1,
            stopColor: "#D0A024",
          },
        ]}
      />
      <input
        className={styles.input}
        type="text"
        placeholder="Поиск"
        onKeyDown={(e) => {
          if (e.key === "Enter") {
            sumbit(searchInput.current.value);
          }
        }}
        onChange={() => {
          setResults((p) => {
            return {
              ...p,
              filter: p.filter.map((f) => ({
                Type: f.Type,
                Title: f.Title,
                Name: f.Name,
                Options: [],
              })),
            };
          });
          setFilterUser([]);
        }}
        defaultValue={query}
        ref={searchInput}
      />
      <button
        className={styles.button}
        onClick={() => sumbit(searchInput.current.value)}
      >
        <RunSearchIcon />
      </button>
    </div>
  );
}

export default Search;
