import React from "react";
import { Link } from "react-router-dom";

import styles from "../styles/sidebar.module.css";

const Item = ({ hide, item, comparePaths, location, checkPermission }) => {
  return (
    checkPermission(item) && (
      <div
        className={
          comparePaths("main", location.pathname, item.path)
            ? `${item.cName} ${styles.active}`
            : item.cName
        }
        roles={item.roles}
        onClick={hide}
      >
        <Link to={item.path}>
          {item.icon}
          <span>{item.title}</span>
        </Link>
      </div>
    )
  );
};

export default Item;
