import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { IconContext } from "react-icons";
import { TbUserCircle } from "react-icons/tb";
import Select from "react-select";

import { useSelector, useDispatch } from "react-redux";

import { history } from "@helpers/history";
import { authActions, layoutActions } from "@store";

import Hamburger from "../icons/Hamburger";

import styles from "./styles/navbar.module.css";

function Navbar({ setShowSidebar, showSidebar, mobile }) {
  const dispatch = useDispatch();
  const { user: authUser, company: currentCompany } = useSelector(
    (x) => x.auth
  );
  const [rolesAnimation, setRolesAnimation] = useState(false);
  const [companies, setCompanies] = useState([]);

  useEffect(() => {
    if (authUser.Usercompanies && authUser.Usercompanies.length > 0) {
      setCompanies([]);
      authUser.Usercompanies.forEach((company) => {
        setCompanies((companies) => [
          ...companies,
          { value: company.IdCompany, label: company.NameCompany },
        ]);
      });
    }
  }, [authUser]);

  function logout() {
    return dispatch(authActions.logout())
      .unwrap()
      .then(() => {
        history.navigate("/personal/login");
      });
  }

  return (
    <>
      <IconContext.Provider value={{ color: "#fff" }}>
        <div className={styles.navbar}>
          <Link to="#" className={styles.burger}>
            <Hamburger
              onClick={() => setShowSidebar(!showSidebar)}
              open={!showSidebar}
            />
          </Link>
          <div className="d-flex">
            {currentCompany ? (
              <li className={`${styles.navItem} ${styles.desktop}`}>
                <Select
                  className={`${styles.select}`}
                  classNamePrefix="company-select"
                  options={companies}
                  onChange={(e) => dispatch(authActions.selectCompany(e.value))}
                  defaultValue={
                    currentCompany
                      ? {
                          value: currentCompany.IdCompany,
                          label: currentCompany.NameCompany,
                        }
                      : null
                  }
                />
              </li>
            ) : null}
            <li className={`${styles.navItem} dropdown`}>
              <Link
                to="#"
                className={`nav-link dropdown-toggle ${styles.dropdownToggle}`}
                id="nav-user"
                role="button"
                data-bs-toggle="dropdown"
                aria-expanded="false"
              >
                <TbUserCircle color="#fff" size={40} />
                <div>
                  <div>{authUser.Firstname}</div>
                  <div className={styles.roles}>
                    <div
                      className={`${styles.role} ${
                        rolesAnimation ? null : styles.active
                      }`}
                      style={{
                        animation: `${
                          rolesAnimation ? styles.scrollDown : styles.scrollTop
                        } 5s`,
                      }}
                      onAnimationEnd={(e) => setRolesAnimation(!rolesAnimation)}
                    >
                      {authUser.Userroles.map((x, index) => (
                        <div key={index}>{x.RoleName}</div>
                      ))}
                    </div>
                  </div>
                </div>
              </Link>
              <ul className="dropdown-menu" aria-labelledby="navbarDropdown">
                <h6 className="dropdown-header">
                  Привет, {authUser.Firstname}!
                </h6>
                {currentCompany ? (
                  <li>
                    <Select
                      className={`${styles.select} ${styles.mobile} dropdown-item`}
                      classNamePrefix="company-select-mobile"
                      options={companies}
                      defaultValue={
                        currentCompany
                          ? {
                              value: currentCompany.IdCompany,
                              label: currentCompany.NameCompany,
                            }
                          : null
                      }
                    />
                  </li>
                ) : null}
                <li>
                  <Link className="dropdown-item" to="/personal/profile">
                    Профиль
                  </Link>
                </li>
                <li>
                  <Link className="dropdown-item" to="#">
                    Настройки
                  </Link>
                </li>
                <li>
                  <div className="dropdown-divider"></div>
                </li>
                <li>
                  <Link
                    className="dropdown-item"
                    to="#"
                    onClick={() => {
                      dispatch(layoutActions.setMount(false));
                      setTimeout(() => {
                        dispatch(layoutActions.clearElems());
                      }, 500);
                    }}
                  >
                    На главную
                  </Link>
                </li>
                <li>
                  <div className="dropdown-divider"></div>
                </li>
                <li>
                  <Link className="dropdown-item" to="#" onClick={logout}>
                    Выход
                  </Link>
                </li>
              </ul>
            </li>
          </div>
        </div>
      </IconContext.Provider>
    </>
  );
}

export default Navbar;
