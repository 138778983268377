import React, { useEffect, useState } from "react";

import { fetchWrapper } from "@helpers/fetchwrapper";

import CardArrow from "@areas/shared/components/cards/CardArrow";

import defaultCompany from "@content/img/jpg/default-company.jpg";
import notPhoto1 from "@content/img/notPhoto1.jpg";

import styles from "./styles/about.module.css";

function About({ data }) {
  const [person, setPerson] = useState({
    fio: null,
    positions: [],
    emails: [],
    phones: [],
  });

  useEffect(() => {
    if (data.company.Contacts.length > 0) {
      let persons = data.company.Contacts.filter((p) => p.IdType === 4);
      if (persons.length > 0) {
        fetchWrapper
          .get(
            `/v1/api/app/getContacts?id_parent=${persons[0].Id}&id_parent_type=4`
          )
          .then((res) => {
            setPerson({
              fio: persons[0].Value,
              emails: res.filter((p) => p.IdType === 1),
              phones: res.filter((p) => p.IdType === 2),
              positions: res.filter((p) => p.IdType === 5),
            });
          });
      }
    }
  }, [data]);

  const getSite = (data) => {
    if (data.Contacts.length > 0) {
      let sites = data.Contacts.filter((p) => p.IdType === 3);
      if (sites.length > 0) {
        return sites[0].Value;
      }
    }
    return "Нет сайта";
  };

  const getPhone = (data) => {
    if (data.Contacts.length > 0) {
      let phones = data.Contacts.filter((p) => p.IdType === 2);
      if (phones.length > 0) {
        return phones[0].Value;
      }
    }
    return "Нет телефона";
  };

  return (
    <div className={styles.block}>
      <div className={`container ${styles.second}`}>
        <div className={styles.mainPhoto}>
          <img
            src={data.company.LogoLink ? data.company.LogoLink : defaultCompany}
            alt="Логотип"
          />
        </div>
        <div className={styles.info}>
          <div className={styles.header}>
            <h1>{data.mentorship.Fio}</h1>
            <h3>{data.mentorship.JobTitle}</h3>
          </div>
          <div className={styles.description}>
            {data.mentorship.Description}
          </div>
          <div className={styles.addresses}>
            <div className={styles.title}>Адрес:</div>
            <div className={styles.address}>
              {data.company.Addresses
                ? data.company.Addresses[0].Value
                : "Нет адреса"}
            </div>
          </div>
          <div className={styles.contacts}>
            <div className={styles.contact}>
              <div className={styles.title}>ИНН:</div>
              <div className={styles.description}>{data.company.Inn}</div>
            </div>
            <div className={styles.contact}>
              <div className={styles.title}>Сайт:</div>
              <a
                href={
                  getSite(data.company) !== "Нет сайта"
                    ? getSite(data.company)
                    : null
                }
                className={`${styles.description} ${styles.link}`}
              >
                {getSite(data.company)}
              </a>
            </div>
            <div className={styles.contact}>
              <div className={styles.title}>Телефон:</div>
              <a
                href={`tel:${getPhone(data.company)}`}
                className={styles.description}
              >
                {getPhone(data.company)}
              </a>
            </div>
          </div>
        </div>
      </div>
      <div className={`container ${styles.second}`}>
        <div className={styles.tags}></div>
        {person.fio ? (
          <div className={styles.contact}>
            <CardArrow
              sizeArrow={{ width: 33, height: 33 }}
              customStyles={{
                main: styles.card,
                border: styles.border,
                arrow: styles.arrow,
              }}
              rotate="rightTop"
            >
              <div className={styles.inner}>
                <div className={styles.title}>Контактное лицо</div>
                <div className={styles.about}>
                  <img className={styles.photo} src={notPhoto1} alt="Фото" />
                  <div className={styles.contacts}>
                    <div className={styles.name}>{person.fio}</div>
                    <div className={styles.positions}>
                      <div className={styles.title}>Должность:</div>
                      {person.positions.map((item) => (
                        <div key={item.Id} className={styles.position}>
                          {item.Value}
                        </div>
                      ))}
                    </div>
                    <div className={styles.links}>
                      <div className={styles.phones}>
                        <div className={styles.title}>Телефон:</div>
                        {person.phones.map((item) => (
                          <div key={item.Id} className={styles.phone}>
                            {item.Value}
                          </div>
                        ))}
                      </div>
                      <div className={styles.emails}>
                        <div className={styles.title}>Почта:</div>
                        {person.emails.map((item) => (
                          <div key={item.Id} className={styles.email}>
                            {item.Value}
                          </div>
                        ))}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </CardArrow>
          </div>
        ) : null}
      </div>
    </div>
  );
}

export default About;
