import React, { useState, useEffect } from "react";
import { useSearchParams } from "react-router-dom";

import Login from "./blocks/Login";

import { fetchWrapper } from "@helpers/fetchwrapper";

import Animation from "@areas/shared/components/chooseLayout/Animation";
import animationStyles from "@areas/shared/components/chooseLayout/styles/animations.module.css";

function Frame() {
  const [searchParams] = useSearchParams();
  const [confirmed, setConfirmed] = useState(false);
  const [error, setError] = useState(null);

  useEffect(() => {
    let login = searchParams.get("Login");
    let hash = searchParams.get("HashId");

    if (login && hash) {
      fetchWrapper
        .post(`/v1/personal/confirm?login=${login}&hashId=${hash}`)
        .then((result) => {
          if (result) {
            setConfirmed(true);
          } else {
            setError("Не удалось подтвердить Email");
            setConfirmed(false);
          }
        })
        .catch((rejectedValueOrSerializedError) => {
          setError(rejectedValueOrSerializedError);
          setConfirmed(false);
        });
    } else {
      setConfirmed(false);
    }
  }, [searchParams]);

  return (
    <div style={{ minHeight: "calc(100vh - 320px)" }}>
      <Animation
        animationIn={animationStyles.fadeIn}
        animationOut={animationStyles.fadeOut}
      >
        <Login confirmed={confirmed} error={error} />
      </Animation>
    </div>
  );
}

export default Frame;
