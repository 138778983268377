import React from "react";
import { Col, FormFeedback, Input, Label, Row } from "reactstrap";
import * as Yup from "yup";

import { fetchWrapper } from "@helpers/fetchwrapper";

import stylesModal from "@areas/shared/components/personal/modals/styles/customModal.module.css";

const initialValues = (company, сommittee) => {
  return {
    id: (сommittee && сommittee.Id) || null,
    idCompany: (company && company.IdCompany) || null,
    about: (сommittee && сommittee.About) || null,
    description: (сommittee && сommittee.Description) || null,
  };
};

const validationSchema = () => {
  return Yup.object({
    about: Yup.string().required("Текст обязателен"),
    description: Yup.string().required("Текст обязателен"),
  });
};

const confirm = (values, successFunc) => {
  successFunc(true);
  let data = new FormData();
  data.append("idCompany", values.idCompany);
  data.append("about", values.about);
  data.append("description", values.description);

  if (values.id) {
    data.append("id", values.id);
    fetchWrapper
      .post(`/v1/personal/updateSelectionCommittee`, data, false)
      .then(() => successFunc(false));
  } else {
    fetchWrapper
      .post(`/v1/personal/insertSelectionCommittee`, data, false)
      .then(() => successFunc(false));
  }
};

function FormBody({ validation }) {
  return (
    <Row className={stylesModal.body}>
      <Input
        type="hidden"
        name="id"
        defaultValue={validation.values.id || null}
      />
      <Input
        type="hidden"
        name="idCompany"
        defaultValue={validation.values.idCompany || null}
      />
      <Col lg={12}>
        <div>
          <Label
            htmlFor="selectionCommitteeAbout-field"
            className={stylesModal.label}
          >
            Информация
          </Label>
          <Input
            name="about"
            id="selectionCommitteeAbout-field"
            className={`form-control ${stylesModal.input}`}
            placeholder="Введите информацию"
            type="textarea"
            value={validation.values.about || ""}
            onChange={validation.handleChange}
            onBlur={validation.handleBlur}
            invalid={
              validation.touched.about && validation.errors.about ? true : false
            }
            style={{ height: 110, resize: "none" }}
          />
          {validation.touched.about && validation.errors.about ? (
            <FormFeedback type="invalid" className={stylesModal.invalid}>
              {validation.errors.about}
            </FormFeedback>
          ) : null}
        </div>
      </Col>
      <Col lg={12}>
        <div>
          <Label
            htmlFor="selectionCommitteeDescription-field"
            className={stylesModal.label}
          >
            Описание
          </Label>
          <Input
            name="description"
            id="selectionCommitteeDescription-field"
            className={`form-control ${stylesModal.input}`}
            placeholder="Введите описание"
            type="textarea"
            value={validation.values.description || ""}
            onChange={validation.handleChange}
            onBlur={validation.handleBlur}
            invalid={
              validation.touched.description && validation.errors.description
                ? true
                : false
            }
            style={{ height: 110, resize: "none" }}
          />
          {validation.touched.description && validation.errors.description ? (
            <FormFeedback type="invalid" className={stylesModal.invalid}>
              {validation.errors.description}
            </FormFeedback>
          ) : null}
        </div>
      </Col>
    </Row>
  );
}

export { initialValues, validationSchema, confirm, FormBody };
