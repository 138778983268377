import React from "react";

import { history } from "@helpers/history";

import ButtonArrow from "@areas/shared/components/buttons/ButtonArrow";
import Honeycombs from "@areas/shared/components/background/Honeycombs";
import TextFilter from "@areas/shared/components/text/TextFilter";
import Helper from "@areas/shared/components/helper/Helper";
import Chooser from "@areas/college/components/choose/Chooser";

import btnStyles from "@areas/college/components/buttons/styles/button.module.css";
import styles from "./styles/about.module.css";

function About(props) {
  return (
    <div className={styles.block}>
      <Honeycombs style={styles.background} filter="#00ECB3" />
      <div className={`container ${styles.content}`}>
        <div>
          <div className={styles.info}>
            <TextFilter
              customStyles={{
                all: styles.header,
                text: styles.text,
                filter: styles.filter,
              }}
            >
              СПО/ВПО
            </TextFilter>
            <div className={styles.subheader}>
            Для учреждений среднего и высшего профессионального образования (СПО, ВПО) 
            Республики Башкортостан 
              <ul>
                <li>
                Прямая коммуникация с предприятиями и школами РБ
                </li>
                <li>
                Новые площадки для производственной практики студентов
                </li>
                <li>
                Привлечение наставников с производства
                </li>
                <li>
                Настройка образовательных программ в сотрудничестве с предприятиями 
                </li>
                <li>
                Размещение своей информации о мероприятиях (Дни открытых дверей)
                </li>
              </ul>
            </div>
            <ButtonArrow
              customStyles={{
                main: styles.more,
                border: styles.border,
                arrow: styles.arrow,
                fill: styles.fill,
              }}
              onClick={() => history.navigate(`/about`)}
            >
              Подробнее о нас
            </ButtonArrow>
          </div>
        </div>
        <Helper
          cardStyles={{
            main: styles.card,
            border: styles.border,
            arrow: styles.arrow,
          }}
          buttonStyle={btnStyles.button}
          filterBackground="#00ECB3"
          filename="helper-college.json"
        />
      </div>
      <Chooser
        gradientId="chooserBackground"
        gradientSteps={[
          {
            offset: 0,
            stopColor: "#00ecb3",
          },
          {
            offset: 0.5,
            stopColor: "#24c9bf",
          },
          {
            offset: 1,
            stopColor: "#23c8b0",
          },
        ]}
      />
    </div>
  );
}

export default About;
