import React from "react";
import ClipLoader from "react-spinners/ClipLoader";

import CardArrow from "@areas/shared/components/cards/CardArrow";

import styles from "./styles/filter.module.css";

function Filter({ load, submit, searchFor }) {

  return (
    <CardArrow
      customStyles={{
        main: styles.cardFilter,
        arrow: styles.arrow,
        border: styles.border,
      }}
      sizeArrow={{ width: 33, height: 33 }}
    >
      <div className={`${styles.content}`}>
        <div className={styles.header}>Фильтры</div>
        <div className={styles.inner}>
          {load ? (
            <div className="d-flex w-100 justify-content-center">
              <ClipLoader />
            </div>
          ) : (
            <>
              <div
                className={`${styles.option}${
                  searchFor === "findPracticy" ? ` ${styles.active}` : ""
                }`}
                onClick={() => submit("findPracticy")}
              >
                <div>Практики/Стажировки</div>
              </div>
              <div
                className={`${styles.option}${
                  searchFor === "findMentorship" ? ` ${styles.active}` : ""
                }`}
                onClick={() => submit("findMentorship")}
              >
                <div>Наставники</div>
              </div>
            </>
          )}
        </div>
      </div>
    </CardArrow>
  );
}

export default Filter;
