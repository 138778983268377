import React from "react";

import Layout from "@areas/shared/components/personal/Layout";
import LayoutPublic from "@areas/shared/components/shared/Layout";

import Login from "./frames/login/Page";
import Register from "./frames/register/Page";
import Forgot from "./frames/forgot/Page";

import Profile from "./frames/profile/Frame";
import News from "./frames/news/Frame";

import Company from "./frames/company/Frame";
import CompanyInfo from "./frames/company/info/Frame";
import CompanyContactPersons from "./frames/company/contact-persons/Frame";
import CompanyPractices from "./frames/company/practices/Frame";
import CompanyVacancies from "./frames/company/vacancies/Frame";
import CompanyMentorships from "./frames/company/mentorships/Frame";
import CompanyProps from "./frames/company/props/Frame";

import Education from "./frames/education/Frame";
import EducationСareerСenter from "./frames/education/career-center/Frame";
import EducationProjectsStudents from "./frames/education/projects-students/Frame";
import EducationEvents from "./frames/education/events/Frame";
import EducationSpecialities from "./frames/education/specialities/Frame";
import EducationInstituties from "./frames/education/instituties/Frame";
import EducationSelectionCommittee from "./frames/education/selection-committee/Frame";

import Content from "./frames/content/Frame";
import ContentShared from "./frames/content/shared/Frame";
import ContentSchoolboy from "./frames/content/schoolboy/Frame";
import ContentAbout from "./frames/content/about/Frame";
import ContentPages from "./frames/content/pages/Frame";
import ContentPagesAdditional from "./frames/content/pages-additional/Frame";

import Requests from "./frames/requests/Frame";
import RequestsListTo from "./frames/requests/list-to/Frame";
import RequestsListFrom from "./frames/requests/list-from/Frame";
import RequestsDetail from "./frames/requests/detail/Frame";

import Admin from "./frames/admin/Frame";
import AdminUsers from "./frames/admin/users/Frame";
import AdminRoles from "./frames/admin/roles/Frame";
import AdminCompanies from "./frames/admin/companies/Frame";
import AdminOKSO from "./frames/admin/okso/Frame";

import Statistics from "./frames/statistics/Frame";
import StatisticsContracts from "./frames/statistics/contracts/Frame";
import StatisticsVacancies from "./frames/statistics/vacancies/Frame";
import StatisticsEducationals from "./frames/statistics/educationals/Frame";

import Support from "./frames/support/Frame";

export const routes = [
  {
    path: "",
    element: <Layout />,
    handle: {
      personalCrumbs: () => ({ name: "Личный кабинет", link: true }),
    },
    children: [
      {
        path: "profile",
        element: <Profile />,
        handle: {
          personalCrumbs: () => ({ name: "Профиль", link: true }),
        },
      },
      {
        path: "news",
        element: <News />,
        handle: {
          personalCrumbs: () => ({ name: "Новости", link: true }),
        },
      },
      {
        path: "company",
        element: <Company />,
        handle: {
          personalCrumbs: () => ({ name: "Компания", link: false }),
        },
        children: [
          {
            path: "info",
            element: <CompanyInfo />,
            handle: {
              personalCrumbs: () => ({ name: "Информация", link: true }),
            },
          },
          {
            path: "contact-persons",
            element: <CompanyContactPersons />,
            handle: {
              personalCrumbs: () => ({ name: "Контактные лица", link: true }),
            },
          },
          {
            path: "practices",
            element: <CompanyPractices />,
            handle: {
              personalCrumbs: () => ({ name: "Практики", link: true }),
            },
          },
          {
            path: "vacancies",
            element: <CompanyVacancies />,
            handle: {
              personalCrumbs: () => ({ name: "Вакансии", link: true }),
            },
          },
          {
            path: "mentorships",
            element: <CompanyMentorships />,
            handle: {
              personalCrumbs: () => ({ name: "Наставники", link: true }),
            },
          },
          {
            path: "props",
            element: <CompanyProps />,
            handle: {
              personalCrumbs: () => ({ name: "Параметры", link: true }),
            },
          },
        ],
      },
      {
        path: "education",
        element: <Education />,
        handle: {
          personalCrumbs: () => ({ name: "Образование", link: false }),
        },
        children: [
          {
            path: "career-center",
            element: <EducationСareerСenter />,
            handle: {
              personalCrumbs: () => ({ name: "Центр карьеры", link: true }),
            },
          },
          {
            path: "projects-students",
            element: <EducationProjectsStudents />,
            handle: {
              personalCrumbs: () => ({ name: "Проекты студентов", link: true }),
            },
          },
          {
            path: "events",
            element: <EducationEvents />,
            handle: {
              personalCrumbs: () => ({
                name: "Мероприятия",
                link: true,
              }),
            },
          },
          {
            path: "specialities",
            element: <EducationSpecialities />,
            handle: {
              personalCrumbs: () => ({
                name: "Специальности",
                link: true,
              }),
            },
          },
          {
            path: "instituties",
            element: <EducationInstituties />,
            handle: {
              personalCrumbs: () => ({
                name: "Институты",
                link: true,
              }),
            },
          },
          {
            path: "selection-committee",
            element: <EducationSelectionCommittee />,
            handle: {
              personalCrumbs: () => ({
                name: "Приемная комиссия",
                link: true,
              }),
            },
          },
        ],
      },
      {
        path: "content",
        element: <Content />,
        handle: {
          personalCrumbs: () => ({ name: "Контент", link: false }),
        },
        children: [
          {
            path: "shared",
            element: <ContentShared />,
            handle: {
              personalCrumbs: () => ({ name: "Общая", link: true }),
            },
          },
          {
            path: "schoolboy",
            element: <ContentSchoolboy />,
            handle: {
              personalCrumbs: () => ({ name: "Абитуриенты", link: true }),
            },
          },
          {
            path: "about",
            element: <ContentAbout />,
            handle: {
              personalCrumbs: () => ({ name: "О нас", link: true }),
            },
          },
          {
            path: "pages",
            element: <ContentPages />,
            handle: {
              personalCrumbs: () => ({ name: "Страницы", link: true }),
            },
          },
          {
            path: "pages-additional",
            element: <ContentPagesAdditional />,
            handle: {
              personalCrumbs: () => ({
                name: "Дополнение к страницам",
                link: true,
              }),
            },
          },
        ],
      },
      {
        path: "requests",
        element: <Requests />,
        handle: {
          personalCrumbs: () => ({ name: "Заявки", link: false }),
        },
        children: [
          {
            path: "list-to",
            element: <RequestsListTo />,
            handle: {
              personalCrumbs: () => ({ name: "Входящие заявки", link: true }),
            },
          },
          {
            path: "list-from",
            element: <RequestsListFrom />,
            handle: {
              personalCrumbs: () => ({ name: "Исходящие заявки", link: true }),
            },
          },
          {
            path: "detail",
            element: <RequestsDetail />,
            handle: {
              personalCrumbs: () => ({ name: "Детали", link: true }),
            },
          },
        ],
      },
      {
        path: "admin",
        element: <Admin />,
        handle: {
          personalCrumbs: () => ({ name: "Администрирование", link: false }),
        },
        children: [
          {
            path: "users",
            element: <AdminUsers />,
            handle: {
              personalCrumbs: () => ({ name: "Пользователи", link: true }),
            },
          },
          {
            path: "roles",
            element: <AdminRoles />,
            handle: {
              personalCrumbs: () => ({ name: "Роли", link: true }),
            },
          },
          {
            path: "companies",
            element: <AdminCompanies />,
            handle: {
              personalCrumbs: () => ({ name: "Компании", link: true }),
            },
          },
          {
            path: "okso",
            element: <AdminOKSO />,
            handle: {
              personalCrumbs: () => ({ name: "ОКСО", link: true }),
            },
          },
        ],
      },
      {
        path: "statistics",
        element: <Statistics />,
        handle: {
          personalCrumbs: () => ({ name: "Статистика", link: false }),
        },
        children: [
          {
            path: "contracts",
            element: <StatisticsContracts />,
            handle: {
              personalCrumbs: () => ({ name: "Договоры", link: true }),
            },
          },
          {
            path: "vacancies",
            element: <StatisticsVacancies />,
            handle: {
              personalCrumbs: () => ({ name: "Вакансии", link: true }),
            },
          },
          {
            path: "educationals",
            element: <StatisticsEducationals />,
            handle: {
              personalCrumbs: () => ({ name: "Учебные заведения", link: true }),
            },
          },
        ],
      },
      {
        path: "support",
        element: <Support />,
        handle: {
          personalCrumbs: () => ({ name: "Поддержка", link: true }),
        },
      },
    ],
  },
  {
    path: "",
    element: <LayoutPublic />,
    children: [
      {
        path: "login",
        element: <Login />,
      },
      {
        path: "register",
        element: <Register />,
      },
      {
        path: "forgot",
        element: <Forgot />,
      },
    ],
  },
];
