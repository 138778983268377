import React from "react";

import { store } from "@store";
import { layoutActions } from "@store/layout.slice";

import ChooserBackground from "@areas/shared/components/background/ChooserBackground";

import styles from "./styles/chooser.module.css";

function Chooser({gradientId, gradientSteps}) {
  const setLayout = (value) => {
    store.dispatch(layoutActions.setMount(false));
    store.dispatch(layoutActions.setWho(value));
  };

  return (
    <div className={styles.container}>
      <div className={styles["background-container"]}>
        <ChooserBackground scale={0.68} gradientId={gradientId} gradientSteps={gradientSteps} />
      </div>
      <div className={styles.background}></div>
      <div
        className={`${styles.button} ${styles.top}`}
        onClick={() => setLayout("shared")}
      >
        Общая
      </div>
      <div className={styles.line}></div>
      <div className={`${styles.button}`} onClick={() => setLayout("employer")}>
        Работодателям
      </div>
      <div className={styles.line}></div>
      <div
        className={`${styles.button} ${styles.bottom}`}
        onClick={() => setLayout("college")}
      >
        СПО/ВПО
      </div>
    </div>
  );
}

export default Chooser;
