import React from "react";

import {TbDots} from "react-icons/tb";

import styles from "../styles/sidebar.module.css";

const Logo = ({ item }) => {
  return (
    <div className={item.cName}>
      <TbDots className={styles.icon} />
      <span className={styles.title}>{item.title}</span>
    </div>
  );
};

export default Logo;
