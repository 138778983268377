import React, { useEffect, useState } from "react";

import CardArrow from "@areas/shared/components/cards/CardArrow";

import { fetchWrapper } from "@helpers/fetchwrapper";

import defaultCompany from "@content/img/jpg/default-company.jpg";
import notPhoto1 from "@content/img/notPhoto1.jpg";
import { ReactComponent as StarIcon } from "@content/img/shared/home/svg/reviews-star.svg";

import styles from "./styles/about.module.css";

function About({ data }) {
  const [person, setPerson] = useState({
    fio: null,
    positions: [],
    emails: [],
    phones: [],
  });

  useEffect(() => {
    if (data && data.Contacts.length > 0) {
      let persons = data.Contacts.filter((p) => p.IdType === 4);
      if (persons.length > 0) {
        fetchWrapper
          .get(
            `/v1/api/app/getContacts?id_parent=${persons[0].Id}&id_parent_type=4`
          )
          .then((res) => {
            setPerson({
              fio: persons[0].Value,
              emails: res.filter((p) => p.IdType === 1),
              phones: res.filter((p) => p.IdType === 2),
              positions: res.filter((p) => p.IdType === 5),
            });
          });
      }
    }
  }, [data]);

  const getSite = (data) => {
    if (data.Contacts.length > 0) {
      let sites = data.Contacts.filter((p) => p.IdType === 3);
      if (sites.length > 0) {
        return sites[0].Value;
      }
    }
    return "Нет сайта";
  };

  const getPhone = (data) => {
    if (data.Contacts.length > 0) {
      let phones = data.Contacts.filter((p) => p.IdType === 2);
      if (phones.length > 0) {
        return phones[0].Value;
      }
    }
    return "Нет телефона";
  };

  const getEmployees = (data) => {
    if (data?.Properties?.length > 0) {
      let employees = data.Properties.filter((p) => p.Key === "Сотрудников");
      if (employees.length > 0) {
        return employees[0].Value;
      }
    }
    return '-';
  }

  return (
    <div className={styles.block}>
      <div className="container d-flex justify-content-between">
        <div className={styles.logo}>
          <img
            src={data.LogoLink ? data.LogoLink : defaultCompany}
            alt="Логотип"
          />
        </div>
        <div className={styles.info}>
          <div className={styles.header}>
            <h1>{data.Shortname ? data.Shortname : data.Fullname}</h1>
            <div className={styles.rating}>
              <div className={styles.title}>Рейтинг:</div>
              <div className={styles.stars}>
                <StarIcon className={styles.active} />
                <StarIcon className={styles.active} />
                <StarIcon className={styles.active} />
                <StarIcon className={styles.active} />
                <StarIcon className={styles.active} />
              </div>
            </div>
          </div>
          <div className={styles.description}>{data.Description}</div>
          <div className={styles.addresses}>
            <div className={styles.title}>Адрес:</div>
            <div className={styles.address}>
              {data.Addresses ? data.Addresses[0].Value : null}
            </div>
          </div>
          <div className={styles.contacts}>
            <div className={styles.contact}>
              <div className={styles.title}>ИНН:</div>
              <div className={styles.description}>{data.Inn}</div>
            </div>
            <div className={styles.contact}>
              <div className={styles.title}>Сайт:</div>
              <a
                href={getSite(data) !== "Нет сайта" ? getSite(data) : null}
                className={`${styles.description} ${styles.link}`}
              >
                {getSite(data)}
              </a>
            </div>
            <div className={styles.contact}>
              <div className={styles.title}>Телефон:</div>
              <a href={`tel:${getPhone(data)}`} className={styles.description}>
                {getPhone(data)}
              </a>
            </div>
          </div>
        </div>
      </div>
      <div className="container d-flex flex-wrap justify-content-between">
        <div className={styles.finances}>
          <div className={styles.card}>
            <div className={styles.count}>{getEmployees(data)}</div>
            <div className={styles.description}>Сотрудников</div>
          </div>
          <div className={styles.card}>
            <div className={styles.count}>-</div>
            <div className={styles.description}>Средняя заработная плата</div>
          </div>
          <div className={styles.card}>
            <div className={styles.count}>-</div>
            <div className={styles.description}>Сотрудников</div>
          </div>
          <div className={styles.info}>
            <div className={styles.title}>Руководитель</div>
            <div className={styles.description}>-</div>
          </div>
          <div className={styles.info}>
            <div className={styles.title}>Дата регистрации</div>
            <div className={styles.description}>-</div>
          </div>
          <div className={styles.info}>
            <div className={styles.title}>Статус</div>
            <div className={styles.description}>-</div>
          </div>
        </div>
        {person.fio ? (
          <div className={styles.contact}>
            <CardArrow
              sizeArrow={{ width: 33, height: 33 }}
              customStyles={{
                main: styles.card,
                arrow: styles.arrow,
                border: styles.border,
              }}
              rotate="rightTop"
            >
              <div className={styles.inner}>
                <div className={styles.title}>
                  Ответственный за взаимодействие
                </div>
                <div className={styles.about}>
                  <img className={styles.photo} src={notPhoto1} alt="Фото" />
                  <div className={styles.contacts}>
                    <div className={styles.name}>{person.fio}</div>
                    <div className={styles.positions}>
                      <div className={styles.title}>Должность:</div>
                      {person.positions.map((item) => (
                        <div key={item.Id} className={styles.position}>
                          {item.Value}
                        </div>
                      ))}
                    </div>
                    <div className={styles.links}>
                      <div className={styles.phones}>
                        <div className={styles.title}>Телефон:</div>
                        {person.phones.map((item) => (
                          <div key={item.Id} className={styles.phone}>
                            {item.Value}
                          </div>
                        ))}
                      </div>
                      <div className={styles.emails}>
                        <div className={styles.title}>Почта:</div>
                        {person.emails.map((item) => (
                          <div key={item.Id} className={styles.email}>
                            {item.Value}
                          </div>
                        ))}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </CardArrow>
            <div className={styles.typeActivity}>
              <div className={styles.title}>Виды деятельности</div>
              <div className={styles.description}>-</div>
            </div>
          </div>
        ) : null}
      </div>
    </div>
  );
}

export default About;
