import LoginModal from "./LoginModal";
import RegisterModal from "./RegisterModal";
import MentorshipModal from "./MentorshipModal";
import PracticeModal from "./PracticeModal";
import ChooseEducationModal from "./ChooseEducation/Modal";
import VacancyModal from "./VacancyModal";
import InfoModal from "./InfoModal";

export const getCurrentModal = (name) => {
  switch (name) {
    case "login":
      return <LoginModal />;
    case "register":
      return <RegisterModal />;
    case "mentorship":
      return <MentorshipModal />;
    case "practice":
      return <PracticeModal />;
    case "chooseEducation":
      return <ChooseEducationModal />;
    case "vacancy":
      return <VacancyModal />;
    case "info":
      return <InfoModal />;
    default:
      return null;
  }
};
