import React, { useState, useCallback, useEffect } from "react";
import {
  Card,
  CardBody,
  CardHeader,
  UncontrolledDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
} from "reactstrap";
import ClipLoader from "react-spinners/ClipLoader";
import { TbEdit, TbSettings, TbPlus, TbTrash } from "react-icons/tb";

import { fetchWrapper } from "@helpers/fetchwrapper";

import {
  initialValues,
  validationSchema,
  confirm,
  FormBody,
} from "./ContactPersonForm";

import ButtonDanger from "@areas/shared/components/buttons/ButtonDanger";
import LoadBlock from "@areas/shared/components/personal/loads/LoadBlock";
import CustomModal from "@areas/shared/components/personal/modals/CustomModal";
import CustomModalDelete from "@areas/shared/components/personal/modals/CustomModalDelete";

import styles from "./styles/contactPersonTable.module.css";

function ContactPersonTable({ company, person, setPerson }) {
  const [load, setLoad] = useState(false);
  const [data, setData] = useState([]);
  const [showModal, setShowModal] = useState(false);
  const [showModalDelete, setShowModalDelete] = useState(false);
  const [headerModal, setHeaderModal] = useState("");
  const [contact, setContact] = useState(null);

  const loadData = (id) => {
    setLoad(true);
    fetchWrapper
      .get(`/v1/api/app/getContacts?id_parent=${id}&id_parent_type=1`)
      .then((res) => {
        setData(res);
        setLoad(false);
      });
  };

  useEffect(() => {
    if (company) {
      loadData(company.IdCompany);
    }
  }, [company]);

  const toggle = useCallback(
    (data) => {
      if (showModal) {
        setShowModal(false);
      } else {
        if (!data) {
          setHeaderModal("Добавление контактного лица");
          setContact(null);
        } else {
          setHeaderModal("Редактирование контактного лица");
          setContact(data);
        }
        setShowModal(true);
      }
    },
    [showModal]
  );

  const handleDelete = () => {
    if (contact) {
      fetchWrapper
        .post(`/v1/personal/deletecontact?idContact=${contact.Id}`, null, false)
        .then(() => {
          setShowModalDelete(false);
          loadData(company.IdCompany);
        });
    }
  };

  return (
    <>
      <Card>
        <CardHeader>
          <div className="d-flex justify-content-between align-items-center">
            <h5 className="mb-0">Контактные лица</h5>
            <ButtonDanger
              className="ps-1 pe-1 pt-1 pb-1 d-flex"
              onClick={() => toggle()}
            >
              <TbPlus size={14} className="me-0" />
            </ButtonDanger>
          </div>
        </CardHeader>
        <CardBody className="overflow-scroll" style={{ height: "520px" }}>
          <table className="table w-100">
            <thead>
              <tr>
                <th>Фото</th>
                <th>ФИО</th>
                <th></th>
              </tr>
            </thead>
            <tbody>
              {data &&
                data
                  .filter((item) => item.IdType === 4)
                  .map((item) => (
                    <tr
                      key={item.Id}
                      className={item.Id === person?.Id ? styles.active : null}
                      onClick={() => setPerson(item)}
                    >
                      <td style={{ width: "80px" }}>
                        <img
                          src="https://cdn-icons-png.flaticon.com/512/149/149071.png"
                          alt=""
                          width="40"
                          height="40"
                        />
                      </td>
                      <td className="w-100 align-middle">{item.Value}</td>
                      <td className={`${styles.settings} align-middle`}>
                        <UncontrolledDropdown className="dropdown d-inline-block">
                          <DropdownToggle
                            className={`${styles.main} btn btn-outline-secondary btn-sm d-flex justify-content-center`}
                            tag="button"
                          >
                            <TbSettings className={styles.icon} size={18} />
                          </DropdownToggle>
                          <DropdownMenu className="dropdown-menu-end">
                            <DropdownItem
                              className="edit-item-btn"
                              onClick={() => toggle(item)}
                            >
                              <TbEdit size={16} className="me-2" />
                              Изменить
                            </DropdownItem>
                            <DropdownItem
                              className="remove-item-btn"
                              onClick={() => setShowModalDelete(true)}
                            >
                              <TbTrash size={16} className="me-2" />
                              Удалить
                            </DropdownItem>
                          </DropdownMenu>
                        </UncontrolledDropdown>
                      </td>
                    </tr>
                  ))}
            </tbody>
          </table>
          <LoadBlock visible={load}>
            <ClipLoader />
          </LoadBlock>
        </CardBody>
      </Card>
      <CustomModal
        isOpen={showModal}
        toggle={toggle}
        header={headerModal}
        body={(validation) => <FormBody validation={validation} />}
        validateYupSchema={validationSchema}
        inititalValues={() => initialValues(company, contact)}
        cancel="Закрыть"
        confirm="Сохранить"
        confirmFunction={(values) => {
          confirm(values, loadData);
        }}
      />
      <CustomModalDelete
        isOpen={showModalDelete}
        onDeleteClick={handleDelete}
        onCloseClick={() => setShowModalDelete(false)}
      />
    </>
  );
}

export default ContactPersonTable;
