import React from "react";

import styles from "./styles/loadBlock.module.css";

function LoadBlock({ visible, children }) {
  return (
    <>
      {visible ? (
        <div
          className={`${styles.block} ${styles.active}`}
          onClick={(e) => e.preventDefault()}
          onDoubleClick={(e) => e.preventDefault()}
          onMouseDown={(e) => e.preventDefault()}
          onMouseMove={(e) => e.preventDefault()}
          onFocus={(e) => e.preventDefault()}
        >
          {children}
        </div>
      ) : null}
    </>
  );
}
export default LoadBlock;
