import React, { useEffect, useState } from "react";

import { fetchWrapper } from "@helpers/fetchwrapper";

import CardArrow from "@areas/shared/components/cards/CardArrow";

import defaultLogo from "@content/img/shared/education/png/default-logo.png";
import defaultVuz from "@content/img/jpg/default-vuz.jpg";
import noPhoto from "@content/img/notPhoto1.jpg";

import styles from "./styles/about.module.css";

function About({ data }) {
  const [person, setPerson] = useState({
    fio: null,
    positions: [],
    emails: [],
    phones: [],
  });
  const [committee, setCommittee] = useState({
    about: null,
    description: null,
    emails: [],
    phones: [],
    groupsVK: [],
  });

  useEffect(() => {
    if (data.company.Contacts.length > 0) {
      let persons = data.company.Contacts.filter((p) => p.IdType === 4);
      if (persons.length > 0) {
        fetchWrapper
          .get(
            `/v1/api/app/getContacts?id_parent=${persons[0].Id}&id_parent_type=4`
          )
          .then((res) => {
            setPerson({
              fio: persons[0].Value,
              emails: res.filter((p) => p.IdType === 1),
              phones: res.filter((p) => p.IdType === 2),
              positions: res.filter((p) => p.IdType === 5),
            });
          });
      }
    }
    if (data.company.SelectionCommittees.length > 0) {
      let committee = data.company.SelectionCommittees[0];
      setCommittee({
        about: committee.About,
        description: committee.Description,
        emails: committee.Contacts.filter((p) => p.IdType === 1),
        phones: committee.Contacts.filter((p) => p.IdType === 2),
        groupsVK: committee.Contacts.filter((p) => p.IdType === 6),
      });
    }
  }, [data]);

  const getSite = (data) => {
    if (data.Contacts.length > 0) {
      let sites = data.Contacts.filter((p) => p.IdType === 3);
      if (sites.length > 0) {
        return sites[0].Value;
      }
    }
    return "Нет сайта";
  };

  const getPhone = (data) => {
    if (data.Contacts.length > 0) {
      let phones = data.Contacts.filter((p) => p.IdType === 2);
      if (phones.length > 0) {
        return phones[0].Value;
      }
    }
    return "Нет телефона";
  };

  const getGroupVK = (data) => {
    if (data.Contacts.length > 0) {
      let group = data.Contacts.filter((p) => p.IdType === 6);
      if (group.length > 0) {
        return group[0].Value;
      }
    }
    return "Нет телефона";
  };

  return (
    <div className={styles.block}>
      <div className={`container ${styles.second}`}>
        <div className={styles.mainPhoto}>
          <img
            src={data.company.LogoLink ? data.company.LogoLink : defaultVuz}
            alt="Логотип"
          />
        </div>
        <div className={styles.info}>
          <div className={styles.header}>
            <img
              className={styles.logo}
              src={data.company.LogoLink ? data.company.LogoLink : defaultLogo}
              alt="Логотип"
            />
            <h1>{`${data.speciality.Code} ${
              data.speciality.Name
                ? data.speciality.Name
                : data.speciality.Title
            }`}</h1>
          </div>
          <div className={styles.description}>
            {data.speciality.Description}
          </div>
          <div className={styles.addresses}>
            <div className={styles.title}>Адрес:</div>
            <div className={styles.address}>
              {data.company.Addresses
                ? data.company.Addresses[0].Value
                : "Нет адреса"}
            </div>
          </div>
          <div className={styles.contacts}>
            <div className={styles.contact}>
              <div className={styles.title}>ИНН:</div>
              <div className={styles.description}>{data.company.Inn}</div>
            </div>
            <div className={styles.contact}>
              <div className={styles.title}>Сайт:</div>
              <a
                href={
                  getSite(data.company) !== "Нет сайта"
                    ? getSite(data.company)
                    : null
                }
                className={`${styles.description} ${styles.link}`}
              >
                {getSite(data.company)}
              </a>
            </div>
            <div className={styles.contact}>
              <div className={styles.title}>Телефон:</div>
              <a
                href={`tel:${getPhone(data.company)}`}
                className={styles.description}
              >
                {getPhone(data.company)}
              </a>
            </div>
            <div className={styles.contact}>
              <div className={styles.title}>Группа ВК:</div>
              <a
                href={`tel:${getGroupVK(data.company)}`}
                className={styles.description}
              >
                {getGroupVK(data.company)}
              </a>
            </div>
          </div>
        </div>
      </div>
      <div className={`container ${styles.second}`}>
        <div className={styles.tags}>
          <div className={styles.title}>Информация для абитуриентов:</div>
          <div className={styles.options}>
            <div className={`${styles.option} ${styles.active}`}>
              {data.speciality.Form}
            </div>
            <div className={`${styles.option} ${styles.active}`}>
              {data.speciality.Foundation}
            </div>
          </div>
        </div>
        <div className={styles.contacts}>
          <div className={styles.contact}>
            <CardArrow
              sizeArrow={{ width: 33, height: 33 }}
              customStyles={{
                main: styles.card,
                border: styles.border,
                arrow: styles.arrow,
              }}
              rotate="rightTop"
            >
              <div className={styles.inner}>
                <div className={styles.title}>Приемная комиссия</div>
                <div className={styles.about}>
                  <div className={styles.contacts}>
                    <div className={styles.name}>{committee.about}</div>
                    <div className={styles.name}>{committee.description}</div>
                    <div className={styles.links}>
                      <div className={styles.phones}>
                        <div className={styles.title}>Телефон:</div>
                        {committee.phones.map((item) => (
                          <div key={item.Id} className={styles.phone}>
                            {item.Value}
                          </div>
                        ))}
                      </div>
                      <div className={styles.emails}>
                        <div className={styles.title}>Почта:</div>
                        {committee.emails.map((item) => (
                          <div key={item.Id} className={styles.email}>
                            {item.Value}
                          </div>
                        ))}
                      </div>
                      <div className={styles.groupsVK}>
                        <div className={styles.title}>Группа ВК:</div>
                        {committee.groupsVK.map((item) => (
                          <div key={item.Id} className={styles.groupVK}>
                            {item.Value}
                          </div>
                        ))}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </CardArrow>
          </div>
          {person.fio ? (
            <div className={styles.contact}>
              <CardArrow
                sizeArrow={{ width: 33, height: 33 }}
                customStyles={{
                  main: styles.card,
                  border: styles.border,
                  arrow: styles.arrow,
                }}
                rotate="rightTop"
              >
                <div className={styles.inner}>
                  <div className={styles.title}>Контактное лицо</div>
                  <div className={styles.about}>
                    <img className={styles.photo} src={noPhoto} alt="Фото" />
                    <div className={styles.contacts}>
                      <div className={styles.name}>{person.fio}</div>
                      <div className={styles.positions}>
                        <div className={styles.title}>Должность:</div>
                        {person.positions.map((item) => (
                          <div key={item.Id} className={styles.position}>
                            {item.Value}
                          </div>
                        ))}
                      </div>
                      <div className={styles.links}>
                        <div className={styles.phones}>
                          <div className={styles.title}>Телефон:</div>
                          {person.phones.map((item) => (
                            <div key={item.Id} className={styles.phone}>
                              {item.Value}
                            </div>
                          ))}
                        </div>
                        <div className={styles.emails}>
                          <div className={styles.title}>Почта:</div>
                          {person.emails.map((item) => (
                            <div key={item.Id} className={styles.email}>
                              {item.Value}
                            </div>
                          ))}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </CardArrow>
            </div>
          ) : null}
        </div>
      </div>
    </div>
  );
}

export default About;
