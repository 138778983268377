import React, { useEffect, useRef } from "react";

import styles from "./styles/buttonArrow.module.css";

function ButtonArrow({ children, customStyles, rotate, sizeArrow, onClick }) {
  const cardRef = useRef();

  const getStyleRotate = () => {
    switch (rotate) {
      case "leftBottom":
        return styles.leftBottom;
      case "rightTop":
        return styles.rightTop;
      case "rightBottom":
        return styles.rightBottom;
      default:
        return styles.leftTop;
    }
  };

  const setVarSizeCss = (name, value) => {
    if (cardRef && cardRef.current) {
      cardRef.current.style.setProperty(name, `${value}px`);
    }
  };

  useEffect(() => {
    if (sizeArrow) {
      setVarSizeCss("--arrow-width", sizeArrow.width);
      setVarSizeCss("--arrow-height", sizeArrow.height);
    } else {
      setVarSizeCss("--arrow-width", 10);
      setVarSizeCss("--arrow-height", 10);
    }
  }, [sizeArrow]);

  return (
    <div
      className={`${styles.button} ${getStyleRotate()} ${
        customStyles ? (customStyles.main ? customStyles.main : null) : null
      }`}
      ref={cardRef}
      onClick={onClick}
    >
      <div className={styles.background}>
        <div
          className={`${styles.fill} ${
            customStyles
              ? customStyles.fill
                ? customStyles.fill
                : null
              : null
          }`}
        ></div>
        <div
          className={`${styles.border} ${
            customStyles
              ? customStyles.border
                ? customStyles.border
                : null
              : null
          }`}
        ></div>
        <div
          className={`${styles.arrow} ${
            customStyles
              ? customStyles.arrow
                ? customStyles.arrow
                : null
              : null
          }`}
        ></div>
      </div>
      <div className={styles.content}>{children}</div>
    </div>
  );
}

export default ButtonArrow;
