export const upperCaseFirstLetterWords = (string) =>
  string
    .toLowerCase()
    .split(" ")
    .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
    .join(" ");

export const getIdBySlug = (slug) => {
  let items = slug.split("-");
  return parseInt(items[items.length - 1]);
};
