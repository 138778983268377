import React, { useRef } from "react";

import SearchIcon from "@areas/shared/components/icons/Search";

import { ReactComponent as RunSearchIcon } from "@content/img/shared/home/svg/map-search-run-icon.svg";

import styles from "./styles/search.module.css";

function Search({ setSearchQuery, loadData, className }) {
  const searchInput = useRef();

  return (
    <div className={`${className} ${styles.search}`}>
      <SearchIcon
        style={styles.icon}
        gradientId="searchIcon"
        gradientSteps={[
          {
            offset: 0,
            stopColor: "#00ECB3",
          },
          {
            offset: 0,
            stopColor: "#1CD1BD",
          },
          {
            offset: 1,
            stopColor: "#24D07D",
          },
        ]}
      />
      <input
        className={styles.input}
        type="text"
        placeholder="Поиск"
        onKeyDown={(e) => {
          if (e.key === "Enter") {
            setSearchQuery(searchInput.current.value);
          }
        }}
        ref={searchInput}
      />
      <button
        className={styles.button}
        onClick={() => {
          setSearchQuery(searchInput.current.value);
        }}
      >
        <RunSearchIcon />
      </button>
    </div>
  );
}

export default Search;
