import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { Outlet, Navigate, useLocation } from "react-router-dom";
import PerfectScrollbar from "react-perfect-scrollbar";
import { ToastContainer } from "react-toastify";

import Sidebar from "./Sidebar";
import Navbar from "./Navbar";
import Breadcrumb from "./BreadCrumb";

import { history } from "@helpers/history";

import styles from "./styles/layout.module.css";
import "./styles/custom.css";
import "flatpickr/dist/themes/material_green.css";
import "filepond/dist/filepond.min.css";
import "filepond-plugin-image-preview/dist/filepond-plugin-image-preview.css";
import "react-toastify/dist/ReactToastify.css";

export default Layout;

function Layout(props) {
  const location = useLocation();
  const { user: authUser, token: authToken } = useSelector((x) => x.auth);
  const [windowDimensions, setWindowDimensions] = useState(
    getWindowDimensions()
  );
  const [showSidebar, setShowSidebar] = useState(
    windowDimensions.width <= 900 ? false : true
  );

  const hideSidebar = () => {
    if (windowDimensions.width <= 900) {
      setShowSidebar(false);
    }
  };

  function getWindowDimensions() {
    const { innerWidth: width, innerHeight: height } = window;
    return {
      width,
      height,
    };
  }

  useEffect(() => {
    function handleResize() {
      setWindowDimensions(getWindowDimensions());
    }

    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  useEffect(() => {
    if (location) {
      if (location.pathname === "/personal") {
        history.navigate("/personal/profile", { replace: true });
      }
    }
  }, [location]);

  return (
    <>
      {authToken ? (
        <>
          {authUser ? (
            <div className={`${styles.layout} personal`}>
              <ToastContainer
                position="bottom-right"
                autoClose={5000}
                hideProgressBar={false}
                newestOnTop={false}
                closeOnClick
                pauseOnFocusLoss
                draggable
                pauseOnHover
                theme="light"
              />
              <Sidebar
                visible={showSidebar}
                hide={hideSidebar}
                permission={authUser.AreaPermissions}
              />
              <div
                className={
                  showSidebar
                    ? `${styles.content} ${styles.sidebarActive}`
                    : styles.content
                }
              >
                <Navbar
                  setShowSidebar={setShowSidebar}
                  showSidebar={showSidebar}
                />
                <Breadcrumb />
                <PerfectScrollbar className="content-personal">
                  <Outlet />
                </PerfectScrollbar>
              </div>
            </div>
          ) : null}
        </>
      ) : (
        <Navigate to="/personal/login" state={{ from: history.location }} />
      )}
    </>
  );
}
