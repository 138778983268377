import React from "react";

import Layout from "./components/shared/Layout";

import Home from "./pages/home/Page";
import News from "./pages/news/Page";
import AllNews from "./pages/all_news/Page";
import Action from "./pages/action/Page";
import Search from "./pages/search/Page";
import About from "./pages/about/Page";
import Sitemap from "./pages/sitemap/Page";

import { routes as PersonalRoutes } from "./pages/personal/PageRoutes";

import { fetchWrapper } from "@helpers/fetchwrapper";

export const routes = [
  {
    path: "",
    element: <Layout />,
    errorElement: <Layout error={true} />,
    children: [
      {
        path: "",
        element: <Home />,
        loader: async () => {
          return fetchWrapper.get(
            `/v1/api/app/mainPageDataAdvanced?climit=2&plimit=6&rlimit=6&elimit=5&type=collegesAndInstitutions`
          );
        },
      },
      {
        path: "news/:slug",
        element: <News />,
        loader: async ({ params }) => {
          return fetchWrapper.get(`/v1/api/app/getPageBySlug?slug=${params.slug}`);
        },
      },
      {
        path: "blog/:slug",
        element: <Action />,
        loader: async ({ params }) => {
          return fetchWrapper.get(`/v1/api/app/getPageBySlug?slug=${params.slug}`);
        },
      },
      {
        path: "search",
        element: <Search />,
      },
      {
        path: "about",
        element: <About />,
        loader: async () => {
          return fetchWrapper.get(`/v1/api/app/typePage?type=11`);
        },
      },
      {
        path: "all_news",
        element: <AllNews />,
        loader: async () => {
          return fetchWrapper.get(`/v1/api/App/AllPages/2?offset=0&limit=50`);
        },
      },
      {
        path: "page/:slug",
        element: <Action />,
        loader: async ({ params }) => {
          return fetchWrapper.get(`/v1/api/app/getPageBySlug?slug=${params.slug}`);
        },
      },
    ],
  },
  {
    path: "personal",
    children: PersonalRoutes,
  },
  {
    path: "sitemap",
    element: <Sitemap/>
  }
];
