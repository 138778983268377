import React, { useState, useCallback, useEffect } from "react";

import styles from "./styles/pagination.module.css";

function Pagination({
  load,
  itemsPerPage,
  totalItems,
  onChangePage,
  customStyles,
}) {
  const [currentPage, setCurrentPages] = useState(1);
  const [totalPages, setTotalPages] = useState(totalItems);
  const [showPages, setShowPages] = useState([]);

  const getPages = useCallback(() => {
    let result = [];
    if (currentPage > 2) {
      result[result.length] = "...";
    }
    let iterator = currentPage > 1 ? currentPage - 1 : 1;
    for (let i = iterator; i <= totalPages && i <= iterator + 2; i++) {
      result[result.length] = i;
      if (i === iterator + 2) {
        result[result.length] = "...";
      }
    }
    setShowPages(result);
  }, [currentPage, totalPages]);

  const changePage = useCallback(
    (number) => {
      if (number !== currentPage) {
        setCurrentPages(number);
        onChangePage((number - 1) * itemsPerPage);
      }
    },
    [currentPage, itemsPerPage, onChangePage]
  );

  useEffect(() => {
    setTotalPages(Math.ceil(totalItems / itemsPerPage));
    getPages();
  }, [totalItems, itemsPerPage, getPages]);

  return (
    <>
      {load ? null : (
        <div className={styles.pages}>
          {currentPage > 1 ? (
            <div
              className={`${styles.prev}${
                customStyles && customStyles.prev
                  ? ` ${customStyles.prev}`
                  : ""
              }`}
              onClick={() => changePage(currentPage - 1)}
            ></div>
          ) : null}
          <div className={styles.numbers}>
            {showPages.map((item, index) => (
              <span
                key={index}
                className={
                  currentPage === item
                    ? `${styles.current}${
                        customStyles && customStyles.current
                          ? ` ${customStyles.current}`
                          : ""
                      }`
                    : item !== "..."
                    ? `${styles.number}${
                        customStyles && customStyles.number
                          ? ` ${customStyles.number}`
                          : ""
                      }`
                    : null
                }
                onClick={() => changePage(item)}
              >
                {item}
              </span>
            ))}
          </div>
          {currentPage < totalPages ? (
            <div
              className={`${styles.next}${
                customStyles && customStyles.next
                  ? ` ${customStyles.next}`
                  : ""
              }`}
              onClick={() => changePage(currentPage + 1)}
            ></div>
          ) : null}
        </div>
      )}
    </>
  );
}

export default Pagination;
