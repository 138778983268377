import React, { useEffect, useState } from "react";
import { Form, Card, CardBody, CardHeader, Row, Col } from "reactstrap";
import { useFormik } from "formik";
import { useSelector } from "react-redux";
import ClipLoader from "react-spinners/ClipLoader";

import Animation from "@areas/shared/components/chooseLayout/Animation";
import animationStyles from "@areas/shared/components/chooseLayout/styles/animations.module.css";

import {
  FormBody,
  initialValues,
  validationSchema,
  confirm,
} from "./blocks/SharedForm";
import ContactsTable from "./blocks/ContactsTable";
import LoadBlock from "@areas/shared/components/personal/loads/LoadBlock";

import { fetchWrapper } from "@helpers/fetchwrapper";

import styles from "@areas/shared/components/personal/modals/styles/customModal.module.css";

function Frame(props) {
  const { company: currentCompany } = useSelector((x) => x.auth);
  const [load, setLoad] = useState(false);
  const [data, setData] = useState([]);

  const loadData = (id) => {
    setLoad(true);
    fetchWrapper.get(`/v1/api/app/company/${id}`).then((res) => {
      setData(res);
      setLoad(false);
    });
  };

  useEffect(() => {
    if (currentCompany) {
      loadData(currentCompany.IdCompany);
    }
  }, [currentCompany]);

  const inititalValues = () => {
    return initialValues(data);
  };

  const validation = useFormik({
    enableReinitialize: true,
    initialValues: inititalValues(),
    validationSchema: validationSchema(),
    onSubmit: (values) => {
      confirm(values, setLoad);
    },
  });

  return (
    <div>
      <Animation
        animationIn={animationStyles.fadeIn}
        animationOut={animationStyles.fadeOut}
      >
        <Row className="ms-0 me-0">
          <Col className="ps-0 pe-lg-2 pe-0" lg={8}>
            <Card>
              <CardHeader>
                <div className="d-flex justify-content-between align-items-center">
                  <h5 className="mb-0">Общее</h5>
                </div>
              </CardHeader>
              <CardBody>
                <Form
                  className="tablelist-form"
                  onSubmit={(e) => {
                    e.preventDefault();
                    validation.handleSubmit();
                    return false;
                  }}
                >
                  <FormBody validation={validation} />
                  <div className={`mt-3 ${styles.footer}`}>
                    <div className="hstack gap-2 justify-content-end">
                      <button
                        type="submit"
                        className={`btn btn-success ${styles.submit}`}
                        id="add-btn"
                      >
                        Сохранить
                      </button>
                    </div>
                  </div>
                </Form>
                <LoadBlock visible={load}>
                  <ClipLoader />
                </LoadBlock>
              </CardBody>
            </Card>
          </Col>
          <Col className="ps-lg-3 ps-0 pe-0" lg={4}>
            <ContactsTable load={load} company={currentCompany} />
          </Col>
        </Row>
      </Animation>
    </div>
  );
}

export default Frame;
