import React, { useEffect } from "react";
import { useLoaderData } from "react-router-dom";
import { useDispatch } from "react-redux";

import Animation from "@areas/shared/components/chooseLayout/Animation";
import animationStyles from "@areas/shared/components/chooseLayout/styles/animations.module.css";

import { metaActions } from "@store";

import About from "./blocks/About";
import Map from "./blocks/Map/Map";
import Calendar from "./blocks/Calendar";
import News from "./blocks/News";
import Partners from "./blocks/Partners";

function Page() {
  const data = useLoaderData();
  const dispatch = useDispatch();

  useEffect(()=>{
    dispatch(
      metaActions.setData({
        icon: "/faviconCollege.ico",
        title: "СПО/ВПО",
        description:
          "ВУЗы и ССУЗы смогут напрямую оперативно налаживать связи с работодателями республики поможет решить проблему с производственной практикой и трудоустройством выпускников.",
      })
    );
  }, [dispatch])

  return (
    <div>
      <Animation
        animationIn={animationStyles.swipeLeftIn}
        animationOut={animationStyles.swipeRightOut}
      >
        <About />
      </Animation>
      <Animation>
        <Map data={data.Companies} />
      </Animation>
      <Animation>
        <Calendar data={data.Events} />
      </Animation>
      <Animation>
        <News data={data.Pages} />
      </Animation>
      <Animation>
        <Partners />
      </Animation>
    </div>
  );
}

export default Page;
