import React, { Fragment, useState, useRef, useEffect } from "react";
import { IoReload } from "react-icons/io5";

import { history } from "@helpers/history";
import { fetchWrapper } from "@helpers/fetchwrapper";

import Button from "@areas/shared/components/buttons/Button";
import CardArrow from "@areas/shared/components/cards/CardArrow";

import Honeycombs from "@areas/shared/components/background/Honeycombs";

import helperIcon from "@content/img/shared/home/png/about-helper.png";

import styles from "./styles/helper.module.css";
import { Input } from "reactstrap";

function Helper({ cardStyles, buttonStyle, filterBackground, filename }) {
  const helperRef = useRef();
  const [data, setData] = useState(null);
  const [messageIds, setMessageIds] = useState([{ id: 1 }]);
  const [result, setResult] = useState({});
  const [resultM, setResultM] = useState([]);
  const [answerInput, setAnswerInput] = useState("");
  


  const buttonClick = (elem,sender)=>{

    
  }


  const helperMAnswer = (elem) =>{

    if(elem.type==="one_m_button" || elem.type==="send_n_button"){
      let messages = data.dialog.filter(
        (p) => p.type === "message" && p.id === elem.next
      );
      messages.forEach((m) => {
        setMessageIds((messageIds) => [
          ...messageIds,
          { id: m.id, prev: elem.id },
        ]);
      });
    }
   
    
    if(elem.type==="send_n_button"){

      var form_data = new FormData();
        for (var key in resultM) {
          let ans = "";
          for(var val in resultM[key]){
           
            if(resultM[key][val]!="")
              ans=ans+resultM[key][val]+";";
          }
          form_data.append(key, ans);
        }
        //form_data.append(elem.name, value);
        fetchWrapper.post(elem.link, form_data, false).then(() => {
          setResult({});
        });
        console.log(form_data);
    }

        if(elem.selected===0){
          if(resultM[elem.name]==undefined) resultM[elem.name]=[];
          if(resultM[elem.name][elem.id]==undefined) resultM[elem.name][elem.id]="";
          resultM[elem.name][elem.id]=elem[elem.valueBy];
          //setResultM({ ...resultM, [elem.name] : {[elem.id]:[elem[elem.valueBy]] }});
          elem.selected=1;
         
        }else{
          //setResultM({ ...resultM, [elem.name] : {[elem.id]:[""] }});
  
          resultM[elem.name][elem.id]="";
          elem.selected=0;
        }
        setResultM(resultM);


      

      console.log(resultM);
      
     
  };

  const helperAnswer = (elem, value) => {
    let messages = data.dialog.filter(
      (p) => p.type === "message" && p.after === elem.id
    );
    if(elem.type==="n_button"){
      messages = data.dialog.filter(
        (p) => p.type === "message" && p.id === elem.next
      );
    }
   
    messages.forEach((m) => {
      setMessageIds((messageIds) => [
        ...messageIds,
        { id: m.id, prev: elem.id },
      ]);
    });
    if (elem.name) {
      if (elem.type !== "inputTextSubmit") {
        setResult((result) => ({ ...result, [elem.name]: elem[elem.valueBy] }));
      } else {
        var form_data = new FormData();
        for (var key in result) {
          form_data.append(key, result[key]);
        }
        form_data.append(elem.name, value);
        fetchWrapper.post(elem.link, form_data, false).then(() => {
          setResult({});
        });
      }

      console.log(result);
    }
  };

  useEffect(() => {
    if (helperRef.current) {
      helperRef.current.scrollTop = helperRef.current.scrollHeight;
    }
  }, [messageIds]);

  useEffect(() => {
    fetchWrapper
      .get(`/data/${filename ? filename : "helper.json"}`)
      .then((res) => {
        setData(res);
      });
  }, [filename]);

  return (
    <div className={styles.helper}>
      <Honeycombs style={styles.background} filter={filterBackground} />
      <div className={styles.content}>
        <CardArrow
          customStyles={{
            main: cardStyles
              ? `${cardStyles.main} ${styles.card}`
              : styles.card,
            border: cardStyles ? cardStyles.border : null,
            arrow: cardStyles ? cardStyles.arrow : null,
          }}
          sizeArrow={{ width: 33, height: 33 }}
        >
          <div ref={helperRef} className={styles.inner}>
            {data ? (
              <>
                <div className={styles.hello}>
                  <div
                    className={
                      cardStyles && cardStyles.message
                        ? cardStyles.message
                        : styles.message
                    }
                    dangerouslySetInnerHTML={{ __html: data.hello }}
                  ></div>
                  <img
                    className={styles.icon}
                    src={helperIcon}
                    alt="Космонавт"
                  />
                </div>
                <div className={styles.choose}>
                  {messageIds.map((item, index) => {
                    let nextId = null;
                    if (messageIds.length > index + 1) {
                      nextId = messageIds[index + 1];
                    }
                    return (
                      <Fragment key={index}>
                        {data.dialog
                          .filter(
                            (p) => p.type === "message" && p.id === item.id
                          )
                          .map((elem, elemIndex) => {
                            return (
                              <div key={elemIndex} className={styles.title}>
                                {elem.text}
                              </div>
                            );
                          })}
                        <div className={styles.elements}>
                          {data.dialog
                            .filter((p) => p.with === item.id)
                            .map((elem, elemIndex) => {
                              switch (elem.type) {
                                case "inputTextSubmit":
                                  return (
                                    <Fragment key={elemIndex}>
                                      <Input
                                        placeholder={elem.text}
                                        onChange={(e) =>
                                          setAnswerInput(e.target.value)
                                        }
                                        disabled={
                                          nextId && nextId.prev === elem.id
                                            ? true
                                            : false
                                        }
                                      />
                                      <Button
                                        style={buttonStyle ? buttonStyle : null}
                                        disable={nextId ? true : false}
                                        active={
                                          nextId && nextId.prev === elem.id
                                            ? true
                                            : false
                                        }
                                        onClick={() => {
                                          helperAnswer(elem, answerInput);
                                        }}
                                        
                                      >
                                        Отправить
                                      </Button>
                                    </Fragment>
                                  );
                                default:                               
                                  return (
                                    <Fragment key={elemIndex}>
                                      <Button
                                        style={buttonStyle ? buttonStyle : null} 
                                        disable={nextId ? true : false}
                                        active={
                                          nextId && nextId.prev === elem.id
                                            ? true
                                            : false
                                        }
                                        selected={elem.selected>0 ? true:false}
                                        issel= {true}
                                        onClick={() => {
                                          switch (elem.type) {
                                            case "button":
                                              helperAnswer(elem);
                                              break;
                                              case "n_button":
                                                helperAnswer(elem);
                                              break;
                                              case "m_button":
                                                helperMAnswer(elem);
                                              break;
                                              case "one_m_button":
                                                helperMAnswer(elem);
                                              break;
                                              case "send_n_button":
                                                helperMAnswer(elem);
                                              break;
                                            case "link":
                                              history.navigate(elem.link);
                                              break;
                                            default:
                                              break;
                                          }                                         
                                        }}
                                      >
                                        {elem.text}
                                      </Button>
                                    </Fragment>
                                  );
                              }
                            })}
                          {!nextId && item.id !== 1 ? (
                            <Button
                              style={`${buttonStyle ? buttonStyle : null} ${
                                styles.reload
                              }`}
                              onClick={() => { 
                                setMessageIds([{ id: 1 }]);
                                setResult({});
                              }}
                            >
                              <IoReload size={15} />
                            </Button>
                          ) : null}
                        </div>
                      </Fragment>
                    );
                  })}
                </div>
              </>
            ) : null}
          </div>
        </CardArrow>
      </div>
    </div>
  );
}
export default Helper;
