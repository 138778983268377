import { store, authActions } from "../store";

export const fetchWrapper = {
  get: request("GET"),
  post: request("POST"),
  put: request("PUT"),
  delete: request("DELETE"),
};

function request(method) {
  return (url, body, jsonBody = true, fileResponse, filename) => {
    const requestOptions = {
      method,
      headers: {},
    };
    requestOptions.headers["Authorization"] = authHeader(url);
    if (body) {
      if (jsonBody) {
        requestOptions.headers["Content-Type"] = "application/json";
        requestOptions.body = JSON.stringify(body);
      } else {
        requestOptions.body = body;
      }
    }
    if (fileResponse) {
      return fetch(url, requestOptions)
        .then((response) => handleResponseFile(filename, response))
        .catch((response) => {
          if (response === "Unauthorized") {
            return store
              .dispatch(authActions.refresh())
              .unwrap()
              .then(() => {
                requestOptions.headers["Authorization"] = authHeader(url);
                return fetch(url, requestOptions).then(handleResponse);
              });
          } else {
            return Promise.reject(response);
          }
        });
    } else {
      return fetch(url, requestOptions)
        .then(handleResponse)
        .catch((response) => {
          if (response === "Unauthorized") {
            return store
              .dispatch(authActions.refresh())
              .unwrap()
              .then(() => {
                requestOptions.headers["Authorization"] = authHeader(url);
                return fetch(url, requestOptions).then(handleResponse);
              });
          } else {
            return Promise.reject(response);
          }
        });
    }
  };
}

function authHeader(url) {
  const token = authToken();
  const isLoggedIn = !!token;
  const isApiUrl = url;
  if (isLoggedIn && isApiUrl) {
    return `Bearer ${token}`;
  } else {
    return {};
  }
}

function authToken() {
  let token = store.getState().auth.token;
  return token ? token : null;
}

function handleResponseFile(filename, response) {
  return response.blob().then((blob) => {
    var url = window.URL.createObjectURL(blob);
    var a = document.createElement("a");
    a.href = url;
    a.download = filename;
    document.body.appendChild(a);
    a.click();
    a.remove();
  });
}

function handleResponse(response) {
  return response.text().then((text) => {
    const data = text && JSON.parse(text);

    if (!response.ok) {
      const error = (data && data.message) || response.statusText;
      return Promise.reject(error);
    }

    return data;
  });
}
