import { createSlice } from "@reduxjs/toolkit";

const name = "findInterns";
const initialState = createInitialState();
const reducers = createReducers();
const slice = createSlice({ name, initialState, reducers });

export const findInternsActions = { ...slice.actions };
export const findInternsReducer = slice.reducer;

function createInitialState() {
  return {
    practice: localStorage.getItem("findInternsPractice"),
    request: localStorage.getItem("findInternsRequest"),
  };
}

function createReducers() {
  return {
    setPractice,
    setRequest,
  };

  function setPractice(state, practice) {
    if (!practice.payload) {
      localStorage.removeItem("findInternsPractice");
      state.practice = null;
    } else {
      localStorage.setItem("findInternsPractice", practice.payload);
      state.practice = practice.payload;
    }
  }

  function setRequest(state, request) {
    if (!request.payload) {
      localStorage.removeItem("findInternsRequest");
      state.request = null;
    } else {
      localStorage.setItem("findInternsRequest", request.payload);
      state.request = request.payload;
    }
  }
}
