import React from "react";

import styles from "./styles/buttonSuccess.module.css";

function ButtonDanger({ children, onClick }) {
  return (
    <button className={`btn btn-success ${styles.custom}`} onClick={onClick}>
      {children}
    </button>
  );
}

export default ButtonDanger;
