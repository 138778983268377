import React from "react";

import backgroundSpace from "@content/img/shared/home/png/background-space.png";

function Logo({ filter }) {
  return (
    <svg
      width="59"
      height="68"
      viewBox="0 0 59 68"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      fill="none"
    >
      <path
        id="Vector"
        fillRule="evenodd"
        clipRule="evenodd"
        fill="url(#pattern0)"
        d="M24 3.045C21.525 4.633 17.7 6.899 15.5 8.081C13.3 9.263 8.912 11.786 5.75 13.687L0 17.145V34.072C0 45.427 0.352 51 1.07 51C1.658 51 4.102 52.35 6.5 54C8.898 55.65 11.103 57 11.399 57C11.696 57 15.861 59.317 20.655 62.148L29.372 67.297L34.436 64.509C41.645 60.54 41.779 60.324 38.833 57.378C36.381 54.926 36.038 54.857 32.579 56.108C29.539 57.207 28.559 57.206 26.789 56.1C25.617 55.368 22.597 53.641 20.079 52.261C17.56 50.881 14.024 48.864 12.221 47.777L8.942 45.801L9.221 33.301L9.5 20.801L14 18.288C16.475 16.906 20.99 14.322 24.034 12.546C29.359 9.438 29.688 9.374 32.779 10.837C35.823 12.279 36.119 12.237 38.464 10.034C39.825 8.755 40.727 7.367 40.468 6.948C39.659 5.64 30.713 -0.00399947 29.579 0.0790005C28.985 0.122001 26.475 1.457 24 3.045ZM49 8.621C42.514 10.813 40.551 12.091 34.476 18.078C30.614 21.885 27.126 25 26.726 25C26.327 25 26 23.875 26 22.5C26 20.25 25.6 20 22 20H18V34V48H22C25.6 48 26 47.75 26 45.5C26 44.125 26.326 43 26.725 43C27.124 43 30.454 45.981 34.125 49.624C40.008 55.462 41.792 56.589 49.15 59.117C53.743 60.695 57.657 61.989 57.849 61.993C58.041 61.997 52.078 55.912 44.599 48.472C37.12 41.031 31 34.47 31 33.892C31 33.314 37.004 26.802 44.343 19.421C51.682 12.039 57.42 6.02 57.093 6.044C56.767 6.068 53.125 7.228 49 8.621ZM50.603 16.893C48.235 19.364 48.102 19.84 49.461 20.968C50.687 21.985 51 24.644 51 34.055C51 45.164 50.876 45.905 48.906 46.53C46.863 47.178 46.88 47.266 49.593 50.097C52.63 53.267 54.562 53.648 57.25 51.608C58.793 50.437 59 48.316 59 33.665V17.051L56.142 15.573C53.423 14.167 53.152 14.232 50.603 16.893Z"
      />
      <path
        id="Vector"
        fillRule="evenodd"
        clipRule="evenodd"
        fill={filter}
        fillOpacity="0.6"
        d="M24 3.045C21.525 4.633 17.7 6.899 15.5 8.081C13.3 9.263 8.912 11.786 5.75 13.687L0 17.145V34.072C0 45.427 0.352 51 1.07 51C1.658 51 4.102 52.35 6.5 54C8.898 55.65 11.103 57 11.399 57C11.696 57 15.861 59.317 20.655 62.148L29.372 67.297L34.436 64.509C41.645 60.54 41.779 60.324 38.833 57.378C36.381 54.926 36.038 54.857 32.579 56.108C29.539 57.207 28.559 57.206 26.789 56.1C25.617 55.368 22.597 53.641 20.079 52.261C17.56 50.881 14.024 48.864 12.221 47.777L8.942 45.801L9.221 33.301L9.5 20.801L14 18.288C16.475 16.906 20.99 14.322 24.034 12.546C29.359 9.438 29.688 9.374 32.779 10.837C35.823 12.279 36.119 12.237 38.464 10.034C39.825 8.755 40.727 7.367 40.468 6.948C39.659 5.64 30.713 -0.00399947 29.579 0.0790005C28.985 0.122001 26.475 1.457 24 3.045ZM49 8.621C42.514 10.813 40.551 12.091 34.476 18.078C30.614 21.885 27.126 25 26.726 25C26.327 25 26 23.875 26 22.5C26 20.25 25.6 20 22 20H18V34V48H22C25.6 48 26 47.75 26 45.5C26 44.125 26.326 43 26.725 43C27.124 43 30.454 45.981 34.125 49.624C40.008 55.462 41.792 56.589 49.15 59.117C53.743 60.695 57.657 61.989 57.849 61.993C58.041 61.997 52.078 55.912 44.599 48.472C37.12 41.031 31 34.47 31 33.892C31 33.314 37.004 26.802 44.343 19.421C51.682 12.039 57.42 6.02 57.093 6.044C56.767 6.068 53.125 7.228 49 8.621ZM50.603 16.893C48.235 19.364 48.102 19.84 49.461 20.968C50.687 21.985 51 24.644 51 34.055C51 45.164 50.876 45.905 48.906 46.53C46.863 47.178 46.88 47.266 49.593 50.097C52.63 53.267 54.562 53.648 57.25 51.608C58.793 50.437 59 48.316 59 33.665V17.051L56.142 15.573C53.423 14.167 53.152 14.232 50.603 16.893Z"
      />
      <defs>
        <pattern
          id="pattern0"
          patternContentUnits="objectBoundingBox"
          width="1"
          height="1"
        >
          <use
            xlinkHref="#b"
            transform="matrix(0.0010756 0 0 0.00095872 -0.263126 -0.00119958)"
          />
        </pattern>
        <image id="b" width="1680" height="1050" xlinkHref={backgroundSpace} />
      </defs>
    </svg>
  );
}

export default Logo;
