import React, { useEffect, useState } from "react";
import { Col, FormFeedback, Input, Label, Row } from "reactstrap";
import * as Yup from "yup";
import Select from "react-select";
import CreatableSelect from "react-select/creatable";

import { fetchWrapper } from "@helpers/fetchwrapper";

import SelectAsyncPaginate from "@areas/shared/components/select/SelectAsyncPaginate";

import styles from "@areas/shared/components/personal/modals/styles/customModal.module.css";

const initialValues = (speciality, company) => {
  let specialitySelect = null;
  if (speciality) {
    specialitySelect = {
      value: speciality.IdOkso,
      label: `${speciality.Code} ${speciality.Title}`,
    };
  }

  let tags = [];

  if (speciality && speciality.Tags) {
    speciality.Tags.forEach((element) => {
      tags.push({ value: element, label: element });
    });
  }

  return {
    id: (speciality && speciality.Id) || null,
    speciality: specialitySelect,
    name: (speciality && speciality.Name) || "",
    shortDescription: (speciality && speciality.ShortDescription) || "",
    fullDescription: (speciality && speciality.Description) || "",
    program: (speciality && speciality.Program) || "",
    form: (speciality && speciality.IdForm) || null,
    foundation: (speciality && speciality.IdFoundation) || null,
    paidPlaces: (speciality && speciality.PaidPlaces) || 0,
    budgetPlaces: (speciality && speciality.BudgetPlaces) || 0,
    duration: (speciality && speciality.Duration) || 0,
    price: (speciality && speciality.Price) || 0,
    tags: tags,
    idCompany: (company && company.IdCompany) || 0,
  };
};

const validationSchema = () => {
  return Yup.object({});
};

const confirm = (values, successFunc) => {
  let data = new FormData();
  console.log(values);
  data.append("IdOkso", values.speciality.value);
  data.append("name", values.name);
  data.append("shortDescription", values.shortDescription);
  data.append("description", values.fullDescription);
  data.append("program", values.program);
  data.append("idForm", values.form);
  data.append("idFoundation", values.foundation);
  data.append("budgetPlaces", values.budgetPlaces);
  data.append("paidPlaces", values.paidPlaces);
  data.append("duration", values.duration);
  data.append("price", values.price);
  data.append("idCompany", values.idCompany);

  if (values.tags) {
    values.tags.forEach((element) => {
      data.append("tags[]", element.value);
    });
  }

  if (values.id) {
    data.append("id", values.id);

    fetchWrapper
      .post(`/v1/personal/updatefaculties`, data, false)
      .then(() => successFunc(values.idCompany));
  } else {
    fetchWrapper
      .post(`/v1/personal/insertfaculties`, data, false)
      .then(() => successFunc(values.idCompany));
  }
};

function ModalBodyAdd({ validation }) {
  const [forms, setForms] = useState([]);
  const [foundations, setFoundations] = useState([]);

  const loadForms = () => {
    fetchWrapper.get(`/v1/api/app/allfacultyforms`).then((data) => {
      setForms(data.map((e) => ({ value: e.Id, label: e.Name })));
    });
  };

  const loadFoundations = () => {
    fetchWrapper.get(`/v1/api/app/allfacultyfoundations`).then((data) => {
      setFoundations(data.map((e) => ({ value: e.Id, label: e.Name })));
    });
  };

  useEffect(() => {
    loadForms();
    loadFoundations();
  }, []);

  return (
    <Row className="g-2">
      <Input
        type="hidden"
        name="id"
        defaultValue={validation.values.id || null}
      />
      <Input
        type="hidden"
        name="idCompany"
        defaultValue={validation.values.idCompany || null}
      />
      <Col sm={12}>
        <div>
          <Label htmlFor="specialitySpeciality-field" className={styles.label}>
            Направление
          </Label>
          <SelectAsyncPaginate
            name="speciality"
            id="specialitySpeciality-field"
            url="/v1/api/app/getOkso"
            labelFunc={(e) => `${e.Code} ${e.Title}`}
            valueFunc={(e) => e.Id}
            placeholder="Выберите направление"
            value={validation.values.speciality || null}
            onChangeValue={(e) => {
              validation.setFieldValue("speciality", e);
            }}
            onBlurInput={() => validation.setFieldTouched("speciality")}
          />
        </div>
      </Col>
      <Col sm={12}>
        <div>
          <Label htmlFor="specialityName-field" className={styles.label}>
            Наименование
          </Label>
          <Input
            name="name"
            id="specialityName-field"
            className={`form-control ${styles.input}`}
            placeholder="Введите наименование"
            type="text"
            validate={{
              required: { value: true },
            }}
            onChange={validation.handleChange}
            onBlur={validation.handleBlur}
            value={validation.values.name || ""}
            invalid={
              validation.touched.name && validation.errors.name ? true : false
            }
          />
          {validation.touched.name && validation.errors.name ? (
            <FormFeedback type="invalid" className={styles.invalid}>
              {validation.errors.name}
            </FormFeedback>
          ) : null}
        </div>
      </Col>
      <Col sm={12}>
        <div>
          <Label
            htmlFor="specialityShortDescription-field"
            className={styles.label}
          >
            Краткое описание
          </Label>
          <Input
            name="shortDescription"
            id="specialityShortDescription-field"
            className={`form-control ${styles.input}`}
            placeholder="Введите краткое описание"
            type="textarea"
            validate={{
              required: { value: true },
            }}
            onChange={validation.handleChange}
            onBlur={validation.handleBlur}
            value={validation.values.shortDescription || ""}
            invalid={
              validation.touched.shortDescription &&
              validation.errors.shortDescription
                ? true
                : false
            }
          />
          {validation.touched.shortDescription &&
          validation.errors.shortDescription ? (
            <FormFeedback type="invalid" className={styles.invalid}>
              {validation.errors.shortDescription}
            </FormFeedback>
          ) : null}
        </div>
      </Col>
      <Col sm={12}>
        <div>
          <Label
            htmlFor="specialityFullDescription-field"
            className={styles.label}
          >
            Полние описание
          </Label>
          <Input
            name="fullDescription"
            id="specialityFullDescription-field"
            className={`form-control ${styles.input}`}
            placeholder="Введите полное описание"
            type="textarea"
            validate={{
              required: { value: true },
            }}
            onChange={validation.handleChange}
            onBlur={validation.handleBlur}
            value={validation.values.fullDescription || ""}
            invalid={
              validation.touched.fullDescription &&
              validation.errors.fullDescription
                ? true
                : false
            }
          />
          {validation.touched.fullDescription &&
          validation.errors.fullDescription ? (
            <FormFeedback type="invalid" className={styles.invalid}>
              {validation.errors.fullDescription}
            </FormFeedback>
          ) : null}
        </div>
      </Col>
      <Col sm={12}>
        <div>
          <Label htmlFor="specialityProgram-field" className={styles.label}>
            Программа
          </Label>
          <Input
            name="program"
            id="specialityProgram-field"
            className={`form-control ${styles.input}`}
            placeholder="Введите программу"
            type="textarea"
            validate={{
              required: { value: true },
            }}
            onChange={validation.handleChange}
            onBlur={validation.handleBlur}
            value={validation.values.program || ""}
            invalid={
              validation.touched.program && validation.errors.program
                ? true
                : false
            }
          />
          {validation.touched.program && validation.errors.program ? (
            <FormFeedback type="invalid" className={styles.invalid}>
              {validation.errors.program}
            </FormFeedback>
          ) : null}
        </div>
      </Col>
      <Col sm={6}>
        <div>
          <Label htmlFor="specialityForm-field" className={styles.label}>
            Форма обучения
          </Label>
          <Select
            name="form"
            id="specialityForm-field"
            options={forms}
            value={
              validation.values.form
                ? forms.find((p) => p.value === validation.values.form)
                : []
            }
            placeholder="Выберите форму"
            onChange={(e) => {
              validation.setFieldValue("form", e.value);
            }}
            onBlur={() => validation.setFieldTouched("form")}
          />
        </div>
      </Col>
      <Col sm={6}>
        <div>
          <Label htmlFor="specialityFoundation-field" className={styles.label}>
            Основа обучения
          </Label>
          <Select
            name="foundation"
            id="specialityFoundation-field"
            options={foundations}
            value={
              validation.values.foundation
                ? foundations.find(
                    (p) => p.value === validation.values.foundation
                  )
                : null
            }
            placeholder="Выберите основу"
            onChange={(e) => {
              validation.setFieldValue("foundation", e.value);
            }}
            onBlur={() => validation.setFieldTouched("foundation")}
          />
        </div>
      </Col>
      <Col sm={3}>
        <div>
          <Label
            htmlFor="specialityBudgetPlaces-field"
            className={styles.label}
          >
            Бюжетных мест
          </Label>
          <Input
            name="budgetPlaces"
            id="specialityBudgetPlaces-field"
            className={`form-control ${styles.input}`}
            placeholder="Введите места"
            type="number"
            validate={{
              required: { value: true },
            }}
            onChange={validation.handleChange}
            onBlur={validation.handleBlur}
            value={validation.values.budgetPlaces || ""}
            invalid={
              validation.touched.budgetPlaces && validation.errors.budgetPlaces
                ? true
                : false
            }
          />
          {validation.touched.budgetPlaces && validation.errors.budgetPlaces ? (
            <FormFeedback type="invalid" className={styles.invalid}>
              {validation.errors.budgetPlaces}
            </FormFeedback>
          ) : null}
        </div>
      </Col>
      <Col sm={3}>
        <div>
          <Label htmlFor="specialityPaidPlaces-field" className={styles.label}>
            Платных мест
          </Label>
          <Input
            name="paidPlaces"
            id="specialityPaidPlaces-field"
            className={`form-control ${styles.input}`}
            placeholder="Введите места"
            type="number"
            validate={{
              required: { value: true },
            }}
            onChange={validation.handleChange}
            onBlur={validation.handleBlur}
            value={validation.values.paidPlaces || ""}
            invalid={
              validation.touched.paidPlaces && validation.errors.paidPlaces
                ? true
                : false
            }
          />
          {validation.touched.paidPlaces && validation.errors.paidPlaces ? (
            <FormFeedback type="invalid" className={styles.invalid}>
              {validation.errors.paidPlaces}
            </FormFeedback>
          ) : null}
        </div>
      </Col>
      <Col sm={3}>
        <div>
          <Label htmlFor="specialityDuration-field" className={styles.label}>
            Срок обучения, мес.
          </Label>
          <Input
            name="duration"
            id="specialityDuration-field"
            className={`form-control ${styles.input}`}
            placeholder="Введите срок"
            type="number"
            validate={{
              required: { value: true },
            }}
            onChange={validation.handleChange}
            onBlur={validation.handleBlur}
            value={validation.values.duration || ""}
            invalid={
              validation.touched.duration && validation.errors.duration
                ? true
                : false
            }
          />
          {validation.touched.duration && validation.errors.duration ? (
            <FormFeedback type="invalid" className={styles.invalid}>
              {validation.errors.duration}
            </FormFeedback>
          ) : null}
        </div>
      </Col>
      <Col sm={3}>
        <div>
          <Label htmlFor="specialityPrice-field" className={styles.label}>
            Стоимость обучения, руб.
          </Label>
          <Input
            name="price"
            id="specialityPrice-field"
            className={`form-control ${styles.input}`}
            placeholder="Введите стоимость"
            type="number"
            validate={{
              required: { value: true },
            }}
            onChange={validation.handleChange}
            onBlur={validation.handleBlur}
            value={validation.values.price || ""}
            invalid={
              validation.touched.price && validation.errors.price ? true : false
            }
          />
          {validation.touched.price && validation.errors.price ? (
            <FormFeedback type="invalid" className={styles.invalid}>
              {validation.errors.price}
            </FormFeedback>
          ) : null}
        </div>
      </Col>
      <Col sm={12}>
        <div>
          <Label htmlFor="specialityTags-field" className={styles.label}>
            Теги
          </Label>
          <CreatableSelect
            name="tags"
            id="specialityTags-field"
            placeholder="Введите теги"
            options={[]}
            isMulti={true}
            value={validation.values.tags || []}
            onChange={(e) => {
              validation.setFieldValue("tags", e);
            }}
            formatCreateLabel={(value) => `Добавить ${value}`}
            noOptionsMessage={() => "Ничего не найдено"}
            onBlur={() => validation.setFieldTouched("tags")}
          />
        </div>
      </Col>
    </Row>
  );
}

export { initialValues, validationSchema, confirm, ModalBodyAdd };
