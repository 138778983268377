import React from "react";

function Login({ style, gradientId, gradientSteps }) {
  return (
    <svg
      width="27"
      height="26"
      viewBox="0 0 27 26"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={style}
    >
      <path
        d="M11.8335 11.75V8L18.0835 13L11.8335 18V14.25H0.583496V11.75H11.8335ZM2.406 16.75H5.061C5.93355 18.8988 7.52563 20.6778 9.56489 21.7824C11.6042 22.8871 13.9639 23.2489 16.2404 22.8059C18.517 22.363 20.5689 21.1428 22.0452 19.354C23.5214 17.5653 24.3303 15.3192 24.3335 13C24.3348 10.6783 23.5282 8.42854 22.0521 6.63653C20.576 4.84451 18.5224 3.62195 16.2435 3.17852C13.9645 2.73509 11.6023 3.09843 9.56197 4.20622C7.52163 5.31402 5.93033 7.09723 5.061 9.25H2.406C3.9985 4.17875 8.736 0.5 14.3335 0.5C21.2372 0.5 26.8335 6.09625 26.8335 13C26.8335 19.9037 21.2372 25.5 14.3335 25.5C8.736 25.5 3.9985 21.8213 2.406 16.75Z"
        fill={`url(#${gradientId})`}
      />
      <defs>
        <linearGradient
          id={gradientId}
          x1="13.7085"
          y1="0.5"
          x2="25.1153"
          y2="26.4123"
          gradientUnits="userSpaceOnUse"
        >
          {gradientSteps &&
            gradientSteps.map((gradientStep, index) => (
              <stop
                key={index}
                offset={`${gradientStep.offset}`}
                stopColor={gradientStep.stopColor}
              />
            ))}
        </linearGradient>
      </defs>
    </svg>
  );
}

export default Login;
