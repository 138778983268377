import React, { useState } from "react";
import moment from "moment";
import Select from "react-select";

import Cell from "./Cell";

import { yearMonths } from "@helpers/data/calendar";

import styles from "./styles/calendar.module.css";

const weeks = ["ПН", "ВТ", "СР", "ЧТ", "ПТ", "СБ", "ВС"];

function Calendar({ value = new Date(), onClick, customStyles }) {
  const [dateValue, setDateValue] = useState(value);
  const [focusIndex, setFocusIndex] = useState(-1);
  const startDate = moment(dateValue).startOf("month");
  const endDate = moment(dateValue).endOf("month");
  const numDays = endDate.diff(startDate, "days") + 1;

  const prefixDays = startDate.day() - 1 >= 0 ? startDate.day() - 1 : 6;
  const suffixDays = endDate.day() - 1 >= 0 ? 7 - endDate.day() : 0;
  const countWeeks = Math.ceil((prefixDays + numDays + suffixDays) / 7, 0);

  const getYears = () => {
    let years = [];
    for (let i = 2020; i <= moment().year() + 5; i++) {
      years.push({
        value: i,
        label: i,
      });
    }
    return years;
  };

  const years = getYears();

  return (
    <div className={styles.element}>
      <div className={styles.inputs}>
        <Select
          className={`${styles.select} ${styles.months} dropdown-item`}
          classNamePrefix={"calendar-select"}
          options={yearMonths}
          defaultValue={yearMonths[startDate.month()]}
          isSearchable={false}
          onChange={(e) => {
            setDateValue(new Date(`${dateValue.getFullYear()}-${e.value}-1`));
            setFocusIndex(-1);
          }}
        />
        <Select
          className={`${styles.select} ${styles.years} dropdown-item`}
          classNamePrefix={"calendar-select"}
          options={years}
          defaultValue={years.find((p) => p.value === startDate.year())}
          isSearchable={false}
          onChange={(e) => {
            setDateValue(new Date(`${e.value}-${dateValue.getMonth() + 1}-1`));
            setFocusIndex(-1);
          }}
        />
      </div>
      <div className={styles.calendar}>
        {weeks.map((week, index) => (
          <div key={index} className={styles.weekDayTitle}>
            {week}
          </div>
        ))}

        {Array.from({ length: prefixDays }).map((_, index) => (
          <Cell key={index} first={index === 0} top={true} />
        ))}

        {Array.from({ length: numDays }).map((_, index) => {
          const date = index + 1;
          const isCurrentDate =
            date === value.getDate() &&
            startDate.year() === value.getFullYear() &&
            startDate.month() === value.getMonth();
          const first = moment(startDate).add(index, "days").day() === 1;
          const last = moment(startDate).add(index, "days").day() === 0;
          const top = prefixDays + index < 7;
          const bottom =
            Math.ceil((prefixDays + index + 1) / 7, 0) === countWeeks;

          return (
            <Cell
              key={date}
              isActive={isCurrentDate}
              isFocus={index === focusIndex}
              first={first}
              last={last}
              top={top}
              bottom={bottom}
              onClick={() => {
                setFocusIndex(index);
                onClick(
                  moment(startDate).add(index, "days").format("YYYY-MM-DD")
                );
              }}
              customStyles={customStyles}
            >
              {date}
            </Cell>
          );
        })}

        {Array.from({ length: suffixDays }).map((_, index) => (
          <Cell key={index} bottom={true} last={index === suffixDays - 1} />
        ))}
      </div>
    </div>
  );
}

export default Calendar;
