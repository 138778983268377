import React from "react";
import { Link } from "react-router-dom";
import DataTable from "react-data-table-component";
import ClipLoader from "react-spinners/ClipLoader";

import styles from "./styles/table.module.css";

function TableRequests({
  load,
  data,
  total,
  handlePageChange,
  handlePerRowsChange,
}) {
  const paginationOptions = {
    rowsPerPageText: "Данных на странице:",
    rangeSeparatorText: "из",
    noRowsPerPage: false,
    selectAllRowsItem: false,
    selectAllRowsItemText: "Все",
  };
  const columns = [
    {
      name: <span className="font-weight-bold fs-13">№</span>,
      selector: (row) => (
        <Link to={`/personal/requests/detail?list=from&id=${row.Id}`}>
          {row.Id}
        </Link>
      ),
      sortable: true,
      width: "10%",
    },
    {
      name: <span className="font-weight-bold fs-13">Тип</span>,
      selector: (row) => (
        <Link to={`/personal/requests/detail?list=from&id=${row.Id}`}>
          {row.Type}
        </Link>
      ),
      sortable: true,
      width: "20%",
    },
    {
      name: <span className="font-weight-bold fs-13">Тема</span>,
      selector: (row) => (
        <Link to={`/personal/requests/detail?list=from&id=${row.Id}`}>
          {row.Subject}
        </Link>
      ),
      sortable: true,
      width: "40%",
    },
    {
      name: <span className="font-weight-bold fs-13">Статус</span>,
      selector: (row) => (
        <div className={`badge rounded-pill bg-info fs-12 ${styles.status}`}>
          {row.Status}
        </div>
      ),
      sortable: true,
      width: "20%",
    },
  ];

  return (
    <DataTable
      columns={columns}
      data={data}
      progressComponent={<ClipLoader />}
      progressPending={load}
      paginationTotalRows={total}
      onChangeRowsPerPage={handlePerRowsChange}
      onChangePage={handlePageChange}
      pagination
      paginationServer
      paginationComponentOptions={paginationOptions}
    />
  );
}

export default TableRequests;
