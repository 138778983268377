import { createSlice } from "@reduxjs/toolkit";

const name = "meta";
const initialState = createInitialState();
const reducers = createReducers();
const slice = createSlice({ name, initialState, reducers });

export const metaActions = { ...slice.actions };
export const metaReducer = slice.reducer;

function createInitialState() {
  return {
    icon: "/faviconShared.ico",
    title: "Команда будущего",
    description:
      "Единая цифровая платформа, объединяющая колледжи, вузы и работодателей Республики Башкортостан",
    meta: [],
    link: [],
  };
}

function createReducers() {
  return {
    setData,
  };

  function setData(state, action) {
    state.icon = action.payload.icon
      ? action.payload.icon
      : "/faviconShared.ico";
    state.title = action.payload.title;
    state.description = action.payload.description;
    state.meta = action.payload.meta ? action.payload.meta : [];
    state.link = action.payload.link ? action.payload.link : [];
  }
}
