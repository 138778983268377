import React from "react";
import { Col, FormFeedback, Input, Label, Row } from "reactstrap";
import * as Yup from "yup";

import { fetchWrapper } from "@helpers/fetchwrapper";

import stylesModal from "@areas/shared/components/personal/modals/styles/customModal.module.css";

const initialValues = (company, person, contact) => {
  return {
    id: (contact && contact.Id) || null,
    type: (contact && contact.IdType) || 1,
    value: (contact && contact.Value) || "",
    idPerson: (person && person.Id) || null,
    idCompany: (company && company.IdCompany) || null,
  };
};

const validationSchema = () => {
  return Yup.object({
    value: Yup.string().required("Значение обязателено"),
  });
};

const confirm = (values, successFunc) => {
  let data = new FormData();
  data.append("idType", values.type);
  data.append("value", values.value);

  if (values.idCompany && values.idPerson) {
    if (values.id) {
      data.append("id", values.id);
      fetchWrapper
        .post(`/v1/personal/updatecontact`, data, false)
        .then(() => successFunc(values.idPerson));
    } else {
      fetchWrapper
        .post(
          `/v1/personal/insertcontact?idCompany=${values.idCompany}&idParent=${values.idPerson}&typeParent=4`,
          data,
          false
        )
        .then(() => successFunc(values.idPerson));
    }
  }
};

function FormBody({ validation }) {
  return (
    <Row className="g-2">
      <Input
        type="hidden"
        name="id"
        defaultValue={validation.values.id || null}
      />
      <Input
        type="hidden"
        name="idPerson"
        defaultValue={validation.values.idPerson || null}
      />
      <Input
        type="hidden"
        name="idCompany"
        defaultValue={validation.values.idCompany || null}
      />
      <Col lg={4}>
        <div>
          <Label htmlFor="contactType-field" className={stylesModal.label}>
            Тип
          </Label>
          <Input
            name="type"
            id="contactType-field"
            className={`form-control ${stylesModal.input}`}
            placeholder="Выберите тип"
            type="select"
            value={validation.values.type || 1}
            onChange={validation.handleChange}
            onBlur={validation.handleBlur}
            invalid={
              validation.touched.type && validation.errors.type ? true : false
            }
          >
            <option value={1}>Email</option>
            <option value={2}>Телефон</option>
            <option value={3}>Сайт</option>
            <option value={5}>Должность</option>
          </Input>
          {validation.touched.type && validation.errors.type ? (
            <FormFeedback type="invalid" className={stylesModal.invalid}>
              {validation.errors.type}
            </FormFeedback>
          ) : null}
        </div>
      </Col>
      <Col lg={8}>
        <div>
          <Label htmlFor="contactValue-field" className={stylesModal.label}>
            Значение
          </Label>
          <Input
            name="value"
            id="contactValue-field"
            className={`form-control ${stylesModal.input}`}
            placeholder="Введите значение"
            type="text"
            value={validation.values.value || ""}
            onChange={validation.handleChange}
            onBlur={validation.handleBlur}
            invalid={
              validation.touched.value && validation.errors.value ? true : false
            }
          />
          {validation.touched.value && validation.errors.value ? (
            <FormFeedback type="invalid" className={stylesModal.invalid}>
              {validation.errors.value}
            </FormFeedback>
          ) : null}
        </div>
      </Col>
    </Row>
  );
}

export { initialValues, validationSchema, confirm, FormBody };
