import React from "react";
import { Col, Input, Label, Row } from "reactstrap";
import * as Yup from "yup";

import { fetchWrapper } from "@helpers/fetchwrapper";

import SelectAsyncPaginate from "@areas/shared/components/select/SelectAsyncPaginate";

import "flatpickr/dist/themes/material_green.css";
import styles from "@areas/shared/components/personal/modals/styles/customModal.module.css";

const initialValues = (user) => {
  let companies = [];
  let roles = [];
  if (user) {
    if (user.Usercompanies) {
      user.Usercompanies.forEach((element) => {
        companies.push({
          label: element.NameCompany,
          value: element.IdCompany,
        });
      });
    }
    if (user.Userroles) {
      user.Userroles.forEach((element) => {
        roles.push({
          label: element.RoleName,
          value: element.Roleid,
        });
      });
    }
  }
  return {
    id: (user && user.Id) || null,
    firstName: (user && user.Firstname) || "",
    lastName: (user && user.Lastname) || "",
    middleName: (user && user.Middlename) || "",
    email: (user && user.Email) || "",
    emailConfirm: (user && user.EmailConfirm) || false,
    companies: companies,
    roles: roles,
  };
};

const validationSchema = () => {
  return Yup.object({});
};

const confirm = (values, successFunc) => {
  let data = [];
  values.companies.forEach((element) => {
    data.push(element.value);
  });
  fetchWrapper
    .post(`/v1/api/admin/setUserToCompanies?idUser=${values.id}`, data)
    .then(() => {
      data = [];
      values.roles.forEach((element) => {
        data.push(element.value);
      });
      fetchWrapper
        .post(`/v1/api/admin/setUserRoles?idUser=${values.id}`, data)
        .then(successFunc);
    });
};

function ModalBodyAdd({ validation }) {
  const loadCompaniesOptions = async (searchQuery, loadedOptions, { page }) => {
    const url = `/v1/api/app/findCompanies/?text=${searchQuery}&offset=${
      page * 10
    }&limit=10`;

    let result = await fetchWrapper.post(url, [1, 2, 3]);

    let options = result.data.map((p) => ({
      value: p.Id,
      label: p.Shortname ? p.Shortname : p.Fullname,
    }));
    return {
      options: options,
      hasMore: result.total > page * 10,
      additional: {
        page: searchQuery ? 1 : page + 1,
      },
    };
  };

  return (
    <Row className="g-2">
      <Input
        type="hidden"
        name="id"
        defaultValue={validation.values.id || null}
      />
      <Col sm={4}>
        <div>
          <Label htmlFor="userLastname-field" className={styles.label}>
            Фамилия
          </Label>
          <Input
            name="lastName"
            id="userLastname-field"
            className={`form-control ${styles.input}`}
            placeholder="Введите фамилию"
            type="text"
            value={validation.values.lastName || ""}
            disabled={true}
          />
        </div>
      </Col>
      <Col sm={4}>
        <div>
          <Label htmlFor="userFirstname-field" className={styles.label}>
            Имя
          </Label>
          <Input
            name="firstName"
            id="userFirstname-field"
            className={`form-control ${styles.input}`}
            placeholder="Введите имя"
            type="text"
            value={validation.values.firstName || ""}
            disabled={true}
          />
        </div>
      </Col>
      <Col sm={4}>
        <div>
          <Label htmlFor="userMiddlename-field" className={styles.label}>
            Отчество
          </Label>
          <Input
            name="middleName"
            id="userMiddlename-field"
            className={`form-control ${styles.input}`}
            placeholder="Введите отчество"
            type="text"
            value={validation.values.middleName || ""}
            disabled={true}
          />
        </div>
      </Col>
      <Col sm={12}>
        <div>
          <Label htmlFor="userEmail-field" className={styles.label}>
            Email
            {validation.values.emailConfirm ? (
              <span className="text-success ms-2">Подтвержден</span>
            ) : (
              <span className="text-danger ms-2">Не подтвержден</span>
            )}
          </Label>
          <Input
            name="email"
            id="userEmail-field"
            className={`form-control ${styles.input}`}
            placeholder="Введите email"
            type="text"
            value={validation.values.email || ""}
            disabled={true}
          />
        </div>
      </Col>
      <Col sm={12}>
        <div>
          <Label htmlFor="userCompany-field" className={styles.label}>
            Компания
          </Label>
          <SelectAsyncPaginate
            name="company"
            id="userCompany-field"
            isMulti={true}
            loadFunc={loadCompaniesOptions}
            labelFunc={(e) => e.Shortname ? e.Shortname : e.Fullname}
            valueFunc={(e) => e.Id}
            value={validation.values.companies || []}
            placeholder="Выберите компании"
            onChangeValue={(e) => {
              validation.setFieldValue("companies", e);
            }}
            onBlurInput={() => validation.setFieldTouched("companies")}
          />
        </div>
      </Col>
      <Col sm={12}>
        <div>
          <Label htmlFor="userRole-field" className={styles.label}>
            Роли
          </Label>
          <SelectAsyncPaginate
            name="role"
            id="userRole-field"
            isMulti={true}
            url="/v1/api/admin/getAllRoles"
            labelFunc={(e) => e.Name}
            valueFunc={(e) => e.Id}
            value={validation.values.roles || []}
            placeholder="Выберите роли"
            onChangeValue={(e) => {
              validation.setFieldValue("roles", e);
            }}
            onBlurInput={() => validation.setFieldTouched("roles")}
          />
        </div>
      </Col>
    </Row>
  );
}

export { initialValues, validationSchema, confirm, ModalBodyAdd };
