import React, { useRef } from "react";

import SearchIcon from "@areas/shared/components/icons/Search";

import { ReactComponent as RunSearchIcon } from "@content/img/shared/home/svg/map-search-run-icon.svg";

import styles from "./styles/search.module.css";

function Search({ query, sumbit, sortBy, submitSortBy }) {
  const searchInput = useRef();

  return (
    <>
      <div className={styles.order}>
        <div className={styles.title}>Сортировать по:</div>
        <select
          className={styles.select}
          defaultValue={sortBy}
          onChange={(e) => submitSortBy(e.target.value)}
        >
          <option value={"date"}>Дате публикации</option>
          <option value={"name"}>Названию</option>
        </select>
      </div>
      <div className={styles.search}>
        <SearchIcon
          style={styles.icon}
          gradientId="mapSearchIcon"
          gradientSteps={[
            {
              offset: 0,
              stopColor: "#C12E2E",
            },
            {
              offset: 1,
              stopColor: "#CD9F29",
            },
          ]}
        />
        <input
          className={styles.input}
          type="text"
          placeholder="Поиск"
          onKeyDown={(e) => {
            if (e.key === "Enter") {
              sumbit(searchInput.current.value);
            }
          }}
          defaultValue={query}
          ref={searchInput}
        />
        <button
          className={styles.button}
          onClick={() => {
            sumbit(searchInput.current.value);
          }}
        >
          <RunSearchIcon />
        </button>
      </div>
    </>
  );
}

export default Search;
