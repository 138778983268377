import React, { useCallback, useState, useEffect } from "react";
import { Card, CardBody, CardHeader, Row, Col } from "reactstrap";
import { useSelector } from "react-redux";

import { TbPlus } from "react-icons/tb";

import Animation from "@areas/shared/components/chooseLayout/Animation";
import animationStyles from "@areas/shared/components/chooseLayout/styles/animations.module.css";

import { fetchWrapper } from "@helpers/fetchwrapper";

import { companyProps } from "@helpers/data/companyProps";

import ButtonDanger from "@areas/shared/components/buttons/ButtonDanger";
import CustomModal from "@areas/shared/components/personal/modals/CustomModal";
import CustomModalDelete from "@areas/shared/components/personal/modals/CustomModalDelete";

import ContactsTable from "./blocks/PropsTable";
import {
  initialValues,
  validationSchema,
  confirm,
  FormBody,
} from "./blocks/PropsForm";

function Frame(props) {
  const { company: currentCompany } = useSelector((x) => x.auth);
  const [load, setLoad] = useState(false);
  const [data, setData] = useState([]);
  const [perPage, setPerPage] = useState(10);
  const [page, setPage] = useState(1);
  const [total, setTotal] = useState(0);
  const [showModal, setShowModal] = useState(false);
  const [showModalDelete, setShowModalDelete] = useState(false);
  const [headerModal, setHeaderModal] = useState("");
  const [prop, setProp] = useState(null);

  const loadData = useCallback(
    (id) => {
      setLoad(true);
      fetchWrapper
        .get(
          `/v1/api/app/getCompanyProperties?idCompany=${id}&offset=${
            page * perPage - perPage
          }&limit=${perPage}`
        )
        .then((res) => {
          companyProps.forEach((element) => {
            let find = res.data.find((x) => x.Key === element.name);
            if (!find) {
              res.data.push({
                Id: -1,
                Key: element.name,
                Value: element.type === 4 ? "0" : "",
                IdType: element.type,
                IdCompany: id,
              });
            }
          });

          setData(res.data);
          setTotal(res.total);
          setLoad(false);
        });
    },
    [page, perPage]
  );

  const toggle = useCallback(
    (data) => {
      if (showModal) {
        setShowModal(false);
        setProp(null);
      } else {
        if (!data) {
          setHeaderModal("Добавление параметра");
          setProp(null);
        } else {
          setHeaderModal("Редактирование параметра");
          setProp(data);
        }
        setShowModal(true);
      }
    },
    [showModal]
  );

  const toggleDelete = (item) => {
    setProp(item);
    setShowModalDelete(true);
  };

  const handleDelete = () => {
    if (prop) {
      fetchWrapper
        .post(
          `/v1/personal/deleteCompanyProperty?idProperty=${prop.Id}`,
          null,
          false
        )
        .then(() => {
          setShowModalDelete(false);
          loadData(currentCompany.IdCompany);
        });
    }
  };

  const handlePageChange = (page) => {
    setPage(page);
  };

  const handlePerRowsChange = async (newPerPage, page) => {
    setPerPage(newPerPage);
    setPage(page);
  };

  useEffect(() => {
    if (currentCompany) {
      loadData(currentCompany.IdCompany);
    }
  }, [currentCompany, loadData]);

  return (
    <div>
      <Animation
        animationIn={animationStyles.fadeIn}
        animationOut={animationStyles.fadeOut}
      >
        <Row className="ms-0 me-0">
          <Col className="ps-0 pe-0">
            <Card>
              <CardHeader>
                <div className="d-flex justify-content-between align-items-center">
                  <h5 className="mb-0">Параметры</h5>
                  <ButtonDanger
                    className="ps-1 pe-1 pt-1 pb-1 d-flex"
                    onClick={() => toggle()}
                  >
                    <TbPlus size={14} className="me-0" />
                  </ButtonDanger>
                </div>
              </CardHeader>
              <CardBody>
                <ContactsTable
                  data={data}
                  load={load}
                  total={total}
                  handlePageChange={handlePageChange}
                  handlePerRowsChange={handlePerRowsChange}
                  toggle={toggle}
                  toggleDelete={toggleDelete}
                />
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Animation>
      <CustomModal
        isOpen={showModal}
        toggle={toggle}
        header={headerModal}
        body={(validation) => <FormBody validation={validation} />}
        validateYupSchema={validationSchema}
        inititalValues={() => initialValues(currentCompany, prop)}
        cancel="Закрыть"
        confirm="Сохранить"
        confirmFunction={(values) => {
          confirm(values, loadData);
        }}
        size="sm"
      />
      <CustomModalDelete
        isOpen={showModalDelete}
        onDeleteClick={handleDelete}
        onCloseClick={() => setShowModalDelete(false)}
      />
    </div>
  );
}

export default Frame;
