import React from "react";

import Layout from "./components/shared/Layout";

import Home from "./pages/home/Page";
import Action from "./pages/action/Page";
import Search from "./pages/search/Page";

import { routes as PersonalRoutes } from "@areas/shared/pages/personal/PageRoutes";

import { fetchWrapper } from "@helpers/fetchwrapper";

export const routes = [
  {
    path: "",
    element: <Layout />,
    errorElement: <Layout error={true} />,
    children: [
      {
        path: "",
        element: <Home />,
        loader: async () => {
          return fetchWrapper.get(
            `/v1/api/app/mainPageDataAdvanced?climit=2&plimit=6&rlimit=6&elimit=5&type=collegesAndInstitutions`
          );
        },
      },
      {
        path: "action/:slug",
        element: <Action />,
        loader: async ({ params }) => {
          return fetchWrapper.get(`/v1/api/app/getPageBySlug?slug=${params.slug}`);
        },
      },
      {
        path: "search",
        element: <Search />,
      },
    ],
  },
  {
    path: "personal",
    children: PersonalRoutes,
  },
];
