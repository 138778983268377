import React from "react";

import { history } from "@helpers/history";

import Chooser from "@areas/shared/components/chooseLayout/Chooser";
import ButtonArrow from "@areas/shared/components/buttons/ButtonArrow";
import Honeycombs from "@areas/shared/components/background/Honeycombs";
import Helper from "@areas/shared/components/helper/Helper";

import styles from "./styles/about.module.css";

function About(props) {
  return (
    <div className={styles.block}>
      <Honeycombs style={styles.background} />
      <div className={`container ${styles.content}`}>
        <div>
          <div className={styles.info}>
            <div className={styles.header}>
              <p className={styles.first}>Команда</p>
              <p className={styles.second}>будущего</p>
            </div>
            <div className={styles.subheader}>
              <span>
                Единая цифровая платформа, объединяющая колледжи, вузы и
                работодателей Республики Башкортостан
              </span>
            </div>
            <div className={styles.description}>
              а также абитуриентов, студентов и{" "}
              <span>
                профессионалов
              </span>
              , желающих получить дополнительную квалификацию или найти работу (
              <span>
                тех, кто хочет найти, поменять работу, получить дополнительные
                квалификации
              </span>
              )
            </div>
            <ButtonArrow
              customStyles={{ main: styles.more }}
              onClick={() => history.navigate(`/about`)}
            >
              Подробнее о нас
            </ButtonArrow>
          </div>
        </div>
        <Helper />
      </div>
      <Chooser
        gradientId="chooserBackground"
        gradientSteps={[
          {
            offset: 0,
            stopColor: "#00B3EC",
          },
          {
            offset: 0.5,
            stopColor: "#014170",
          },
          {
            offset: 1,
            stopColor: "#8424D0",
          },
        ]}
      />
    </div>
  );
}

export default About;
