import { createSlice } from "@reduxjs/toolkit";
import React from "react";
const name = "layout";
const initialState = createInitialState();
const reducers = createReducers();
const slice = createSlice({ name, initialState, reducers });

export const layoutActions = { ...slice.actions };
export const layoutReducer = slice.reducer;

function createInitialState() {
  return {
    who: localStorage.getItem("who"),
    countElems: 0,
    mount: true,
    modal: null,
    modalText: null,
    modalBefore: null,
    modalNext: null,
  };
}

function createReducers() {
  return {
    setWho,
    clearWho,
    addElem,
    removeElem,
    clearElems,
    setMount,
    setModal,
    setModalText,
    setModalBefore,
    setModalNext,
    init,
  };

  function init(state) {
    state.countElems = 0;
    state.mount = true;
  }

  function setWho(state, who) {
    if (!who.payload) {
      clearWho(state);
    } else {
      localStorage.setItem("who", who.payload);
      state.who = who.payload;
    }
  }

  function clearWho(state) {
    state.who = null;
    localStorage.removeItem("who");
  }

  function addElem(state) {
    state.countElems = state.countElems + 1;
  }

  function removeElem(state) {
    state.countElems = state.countElems - 1;
  }

  function clearElems(state) {
    state.countElems = 0;
  }

  function setMount(state, mount) {
    state.mount = mount.payload;
  }
  function setModal(state, modal) {
    state.modal = modal.payload;
  }
  function setModalText(state, text) {
    state.modalText = text.payload;
  }
  function setModalBefore(state, modal) {
    state.modalBefore = modal.payload;
  }
  function setModalNext(state, modal) {
    state.modalNext = modal.payload;
  }
}
