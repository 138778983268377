import React, { useEffect, useState, useCallback } from "react";
import { Card, CardBody, CardHeader } from "reactstrap";
import { useSelector } from "react-redux";
import { TbPlus } from "react-icons/tb";

import Animation from "@areas/shared/components/chooseLayout/Animation";
import animationStyles from "@areas/shared/components/chooseLayout/styles/animations.module.css";

import ButtonDanger from "@areas/shared/components/buttons/ButtonDanger";
import CustomModal from "@areas/shared/components/personal/modals/CustomModal";

import { fetchWrapper } from "@helpers/fetchwrapper";

import Table from "./blocks/Table";
import {
  initialValues,
  validationSchema,
  confirm,
  ModalBodyAdd,
} from "./blocks/ModalAdd";

function Frame(props) {
  const { company: currentCompany } = useSelector((x) => x.auth);
  const [showModal, setShowModal] = useState(false);
  const [headerModal, setHeaderModal] = useState("");
  const [perPage, setPerPage] = useState(10);
  const [page, setPage] = useState(1);
  const [total, setTotal] = useState(0);
  const [data, setData] = useState([]);
  const [load, setLoad] = useState(false);
  const [institute, setInstitute] = useState(null);
  const [specialities, setSpecialities] = useState([]);

  const loadData = useCallback(() => {
    setLoad(true);
    fetchWrapper
      .get(
        `/v1/api/app/getInstituteByIdCompany?idCompany=${
          currentCompany.IdCompany
        }&offset=${page * perPage - perPage}&limit=${perPage}`
      )
      .then((res) => {
        setData(res.data);
        setTotal(res.total);
        setLoad(false);
      });
  }, [page, perPage, currentCompany]);

  useEffect(() => {
    loadData();
  }, [loadData]);

  const handlePageChange = (page) => {
    setPage(page);
  };

  const handlePerRowsChange = async (newPerPage, page) => {
    setPerPage(newPerPage);
    setPage(page);
  };

  const toggle = useCallback(
    (institute) => {
      if (showModal) {
        setShowModal(false);
        setInstitute(null);
      } else {
        setInstitute(institute);
        if (!institute) {
          setHeaderModal("Добавление института");
          setShowModal(true);
        } else {
          setHeaderModal("Редактирование института");
          fetchWrapper
            .get(`/v1/api/App/GetFacultiyByInstitute/${institute.Id}`)
            .then((res) => {
              setSpecialities(res);
              setShowModal(true);
            });
        }
      }
    },
    [showModal]
  );

  return (
    <div>
      <Animation
        animationIn={animationStyles.fadeIn}
        animationOut={animationStyles.fadeOut}
      >
        <Card>
          <CardHeader>
            <div className="d-flex justify-content-between align-items-center">
              <h5 className="mb-0">Институты</h5>
              <ButtonDanger onClick={() => toggle()}>
                <TbPlus />
                Добавить институт
              </ButtonDanger>
            </div>
          </CardHeader>
          <CardBody>
            <Table
              data={data}
              load={load}
              total={total}
              handlePageChange={handlePageChange}
              handlePerRowsChange={handlePerRowsChange}
              toggleModal={toggle}
            />
          </CardBody>
        </Card>
        <CustomModal
          isOpen={showModal}
          toggle={toggle}
          header={headerModal}
          body={(validation) => <ModalBodyAdd validation={validation} />}
          validateYupSchema={validationSchema}
          inititalValues={() => initialValues(institute, specialities, currentCompany)}
          cancel="Закрыть"
          confirm="Сохранить"
          confirmFunction={(values) => {
            confirm(values, loadData);
          }}
        />
      </Animation>
    </div>
  );
}

export default Frame;
