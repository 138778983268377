import React from "react";
import { Link } from "react-router-dom";

import { Swiper, SwiperSlide } from "swiper/react";
import { Scrollbar } from "swiper";
import moment from "moment";
import { useLoaderData } from "react-router-dom";
import Honeycombs from "@areas/shared/components/background/Honeycombs";

import notPhoto from "@content/img/notPhoto.jpg";

import styles from "./styles/news.module.css";
function News() {
    const data = useLoaderData();
   
    return (
      <div className={`${styles.block} container`}>
        <div className={styles.header}>События и новости</div>
        <Honeycombs style={styles.background} />
          <div className={styles.news}>
         
            {data
              ? data.data.map((item, index) => (
                 
                    <div className={styles.card}>
                      <div className={styles.image}>
                        {item.ImageLink ? (
                          <img
                            src={`/v1/api/fileManager/getFile?path=${item.ImageLink}`}
                            alt="Новость"
                          />
                        ) : (
                          <img src={notPhoto} alt="Новость" />
                        )}
                      </div>
                      <div className={styles.info}>
                        <div>
                          <div className={styles.title}>{item.Title}</div>
                          <div className={styles.description}>
                            {item.Description}
                          </div>
                        </div>
                        <div className={styles.addition}>
                          <div className={styles.date}>
                           
                          </div>
                          <Link
                            to={`/news/${item.Slug}`}
                            className={styles.more}
                            target="_blank"
                          >
                            Подробнее
                          </Link>
                        </div>
                      </div>
                    </div>
                  
                ))
              : null}
          
        </div>
      </div>
    );
  }
  
  export default News;
  