import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";

import CardArrow from "@areas/shared/components/cards/CardArrow";

import { fetchWrapper } from "@helpers/fetchwrapper";

import styles from "./styles/action.module.css";

function Action() {
  const [dataProfessions, setDataProfessions] = useState([]);
  const [dataPartJob, setDataPartJob] = useState([]);

  const loadDataProfessions = () => {
    fetchWrapper
      .post("/v1/api/app/GetPagesByTypes?limit=10", [12])
      .then((res) => {
        setDataProfessions(res.data);
      });
  };

  const loadDataPartJob = () => {
    fetchWrapper
      .post("/v1/api/app/GetPagesByTypes?limit=10", [13])
      .then((res) => {
        setDataPartJob(res.data);
      });
  };

  useEffect(() => {
    loadDataProfessions();
    loadDataPartJob();
  }, []);

  return (
    <div className={`container ${styles.block}`}>
      <CardArrow
        customStyles={{
          main: styles.card,
          border: styles.border,
          arrow: styles.arrow,
        }}
        sizeArrow={{ width: 33, height: 33 }}
      >
        <div className={styles.content}>
          <div className={styles.header}>ПРОФОРИЕНТАЦИЯ</div>
          <div className={styles.list}>
            {dataProfessions.map((item, index) => (
              <Link
                key={index}
                to={`/schoolboy/action/${item.Slug}`}
                className={styles.link}
              >
                <div className={styles.dot}></div>
                <div className={styles.title}>{item.Title}</div>
              </Link>
            ))}
          </div>
        </div>
      </CardArrow>
      <CardArrow
        customStyles={{
          main: styles.card,
          border: styles.border,
          arrow: styles.arrow,
        }}
        sizeArrow={{ width: 33, height: 33 }}
      >
        <div className={styles.content}>
          <div className={styles.header}>Полезное</div>
          <div className={styles.list}>
            {dataPartJob.map((item, index) => (
              <Link
                key={index}
                to={`/schoolboy/action/${item.Slug}`}
                className={styles.link}
              >
                <div className={styles.dot}></div>
                <div className={styles.title}>{item.Title}</div>
              </Link>
            ))}
          </div>
        </div>
      </CardArrow>
    </div>
  );
}

export default Action;
