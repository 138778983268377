import React from "react";

function ChooserBackground({ scale = 0.75, gradientId, gradientSteps }) {
  return (
    <svg
      width="55"
      height="528"
      viewBox="0 0 55 528"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      style={{ transform: `scaleY(${scale})` }}
    >
      <path
        d="M39.1042 2.6031C45.7119 -2.22021 55 2.4993 55 10.6802L55 517.65C55 525.768 45.8378 530.503 39.2157 525.807L4.21571 500.989C1.57137 499.114 0 496.074 0 492.832L0 36.2283C0 33.0351 1.52499 30.0339 4.10417 28.1512L39.1042 2.6031Z"
        fill={`url(#${gradientId})`}
      />
      <defs>
        <linearGradient
          id={gradientId}
          x1="121.5"
          y1="231"
          x2="-22.2903"
          y2="228.153"
          gradientUnits="userSpaceOnUse"
        >
          {gradientSteps &&
            gradientSteps.map((gradientStep, index) => (
              <stop
                key={index}
                offset={`${gradientStep.offset}`}
                stopColor={gradientStep.stopColor}
              />
            ))}
        </linearGradient>
      </defs>
    </svg>
  );
}

export default ChooserBackground;
