import React from "react";
import { Outlet, Navigate } from "react-router-dom";
import { useSelector } from "react-redux";

function Frame(props) {
  const { company: currentCompany } = useSelector((x) => x.auth);

  return (
    <>
      {currentCompany !== null && currentCompany.TypeCompany === 1 ? (
        <Navigate to="/personal" />
      ) : (
        <Outlet />
      )}
    </>
  );
}

export default Frame;
