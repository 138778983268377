import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import Spinner from "react-bootstrap/Spinner";

import { fetchWrapper } from "../../../../helpers/fetchwrapper";

import styles from "./styles/search.module.css";

function Search(props) {
  const [results, setResults] = useState(null);
  const [load, setLoad] = useState(false);

  const getResults = (query) => {
    setResults([]);
    setLoad(true);
    let body = {
      query: query,
    };
    fetchWrapper.post("api/search", body).then((result) => {
      setResults(result);
      setLoad(false);
    });
  };

  useEffect(() => {
    if (props.query) {
      getResults(props.query);
    } else {
      setResults(null);
    }
  }, [props.query]);

  return (
    <>
      {results && props.query ? (
        <div className={styles.container}>
          <div className="mb-2" style={{ fontSize: "1.2vw" }}>
            Результаты поиска
          </div>
          <div>
            {load ? (
              <div className="d-flex justify-content-center align-content-center">
                <Spinner />
              </div>
            ) : results.length > 0 ? (
              <div
                style={{ fontSize: "1vw", overflow: "auto", maxHeight: 100 }}
              >
                {results.map((item, index) => {
                  return (
                    <div key={index}>
                      <div className="w-100">
                        {item.sectionName}: {item.results.length}
                      </div>
                      {item.results.map((result, i) => {
                        return (
                          <div className="border-bottom ps-2" key={i}>
                            <div
                              style={{
                                cursor: "pointer",
                                fontStyle: "italic",
                              }}
                            >
                              <Link
                                to={`${item.sectionType}/${result.slug}`}
                                className="mb-0"
                              >
                                {result.shortname}
                              </Link>
                            </div>
                            <p className="mb-1" style={{ color: "green" }}>
                              {result.description}
                            </p>
                          </div>
                        );
                      })}
                    </div>
                  );
                })}
              </div>
            ) : (
              <div className="d-flex justify-content-center align-content-center">
                Ничего не найдено
              </div>
            )}
          </div>
        </div>
      ) : (
        <></>
      )}
    </>
  );
}

export default Search;
