import React, { useRef, useState, useCallback } from "react";
import { Link } from "react-router-dom";
import moment from "moment";
import ClipLoader from "react-spinners/ClipLoader";

import { fetchWrapper } from "@helpers/fetchwrapper";

import CardArrow from "@areas/shared/components/cards/CardArrow";
import Pagination from "@areas/shared/components/paginations/Pagination";
import CalendarElement from "@areas/shared/components/calendar/Calendar";

import SearchIcon from "@areas/shared/components/icons/Search";
import AdvancedSearchIcon from "@areas/shared/components/icons/AdvancedSearch";

import { ReactComponent as RunSearchIcon } from "@content/img/shared/home/svg/map-search-run-icon.svg";

import styles from "./styles/calendar.module.css";

function Calendar({ data }) {
  const searchInput = useRef();

  const [dateStart, setDateStart] = useState(moment().format("YYYY-MM-DD"));
  const [searchQuery, setSearchQuery] = useState("");
  const [searchResult, setSearchResult] = useState({
    data: data || [],
    total: 0,
  });
  const [load, setLoad] = useState(false);

  const search = useCallback(
    (offset, query = "", date = null) => {
      setLoad(true);
      if (query) {
        setSearchQuery(query);
      } else {
        query = searchQuery;
      }
      if (date) {
        setDateStart(date);
      } else {
        date = dateStart;
      }

      fetchWrapper
        .get(
          `/v1/api/app/findEvent?text=${query}&limit=6&offset=${offset}&type=1&dat=${date}`
        )
        .then((res) => setSearchResult(res))
        .finally(() => setLoad(false));
    },
    [searchQuery, dateStart]
  );

  return (
    <div className={`${styles.block} container`}>
      <div className={styles.header}>Календарь мероприятий</div>
      <div className={`${styles.inner}`}>
        <CalendarElement
          customStyles={{
            isActive: styles.toDay,
            isFocus: styles.focusDay,
          }}
          onClick={(date) => {
            search(0, null, date);
          }}
        />
        <CardArrow
          customStyles={{
            main: styles.cardSearch,
            border: styles.border,
            arrow: styles.arrow,
          }}
          rotate="rightTop"
          sizeArrow={{ width: 33, height: 33 }}
        >
          <div className={styles.content}>
            <div className={styles.search}>
              <SearchIcon
                style={styles.icon}
                gradientId="mapSearchIcon"
                gradientSteps={[
                  {
                    offset: -0.2,
                    stopColor: "#C12E2E",
                  },
                  {
                    offset: 1,
                    stopColor: "#CD9F29",
                  },
                ]}
              />
              <input
                className={styles.input}
                type="text"
                placeholder="Поиск"
                ref={searchInput}
              />
              <div className={styles.searchAdvanced}>
                <Link to="/search">
                  <AdvancedSearchIcon
                    gradientId="mapAdvancedSearchIcon"
                    gradientSteps={[
                      {
                        offset: 0,
                        stopColor: "#C12E2E",
                      },
                      {
                        offset: 0.5,
                        stopColor: "#CD9F29",
                      },
                      {
                        offset: 1,
                        stopColor: "#C12E2E",
                      },
                    ]}
                  />
                </Link>
              </div>
              <button
                className={styles.button}
                onClick={() => search(1, searchInput.current.value)}
              >
                <RunSearchIcon />
              </button>
            </div>
            {load ? (
              <div className="d-flex justify-content-center w-100 mt-5">
                <ClipLoader />
              </div>
            ) : (
              <div className={styles.info}>
                <div className={styles.results}>
                  {searchResult.data.map((item) => (
                    <div key={item.Id} className={styles.result}>
                      <div className={styles.card}>
                        <div className={styles.title}>{item.Name}</div>
                        <div className={styles.date}>
                          {moment(item.DateStart).format("DD.MM.YYYY")}
                        </div>
                      </div>
                    </div>
                  ))}
                </div>
                <Pagination
                  itemsPerPage={6}
                  totalItems={searchResult.total}
                  onChangePage={search}
                />
              </div>
            )}
          </div>
        </CardArrow>
      </div>
    </div>
  );
}

export default Calendar;
