import React from "react";
import DataTable from "react-data-table-component";
import ClipLoader from "react-spinners/ClipLoader";

function Table({ load, data, total, handlePageChange, handlePerRowsChange }) {
  const paginationOptions = {
    rowsPerPageText: "Данных на странице:",
    rangeSeparatorText: "из",
    noRowsPerPage: false,
    selectAllRowsItem: false,
    selectAllRowsItemText: "Все",
  };
  const columns = [
    {
      name: <span className="font-weight-bold fs-13">Код</span>,
      selector: (row) => row.Id,
      sortable: true,
      width: "20%",
    },
    {
      name: <span className="font-weight-bold fs-13">ИНН</span>,
      selector: (row) => row.Inn,
      sortable: true,
      width: "20%",
    },
    {
      name: <span className="font-weight-bold fs-13">КПП</span>,
      selector: (row) => row.Kpp,
      sortable: true,
      width: "20%",
    },
    {
      name: <span className="font-weight-bold fs-13">Наименование</span>,
      selector: (row) => row.Shortname ? row.Shortname : row.Fullname,
      sortable: true,
      width: "60%",
    },
  ];

  return (
    <DataTable
      columns={columns}
      data={data}
      progressComponent={<ClipLoader />}
      progressPending={load}
      paginationTotalRows={total}
      onChangeRowsPerPage={handlePerRowsChange}
      onChangePage={handlePageChange}
      pagination
      paginationServer
      paginationComponentOptions={paginationOptions}
    />
  );
}

export default Table;
