import React, { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import PerfectScrollbar from "react-perfect-scrollbar";

import { Outlet } from "react-router-dom";

import { history } from "@helpers/history";
import { layoutActions, authActions } from "@store";

import SEO from "./SEO";

import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap/dist/js/bootstrap.min.js";
import "swiper/swiper-bundle.css";
import "react-perfect-scrollbar/dist/css/styles.css";
import "./styles/layout.css";

const getRouteWho = (who) => {
  switch (who) {
    case "college":
      return "obrazovanie-v-bashkortostane";
    case "employer":
      return "company";
    case "schoolboy":
      return "schoolboy";
    case "shared":
      return "shared";
    default:
      return "shared";
  }
};

function Layout() {
  const dispatch = useDispatch();
  const { user: authUser, token: authToken } = useSelector((x) => x.auth);
  const { who, countElems, mount } = useSelector((x) => x.layout);

  useEffect(() => {
    if (!mount && countElems === 0) {
      dispatch(layoutActions.setMount(true));
      if (who) {
        if (who === "shared") {
          history.navigate(`/`);
        } else {
          history.navigate(`/${getRouteWho(who)}`);
        }
      } else {
        history.navigate(`/`);
      }
    }
  }, [who, countElems, mount, dispatch]);

  useEffect(() => {
    if (authToken && !authUser) {
      dispatch(authActions.me());
    }
  }, [authToken, authUser, dispatch]);

  return (
    <>
      <SEO />
      <PerfectScrollbar className="content">
        <Outlet layout={who} />
      </PerfectScrollbar>
    </>
  );
}

export default Layout;
