import React from "react";

import styles from "./styles/buttonDanger.module.css";

function ButtonDanger({ children, onClick, className }) {
  return (
    <button
      className={`btn btn-danger ${styles.custom} ${
        className ? className : ""
      }`}
      onClick={onClick}
    >
      {children}
    </button>
  );
}

export default ButtonDanger;
