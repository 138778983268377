import React from "react";

function Search({ style, gradientId, gradientSteps }) {
  return (
    <svg
      width="25"
      height="25"
      viewBox="0 0 25 25"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={style}
    >
      <path
        d="M4.0625 0.6875H20.9375C22.8073 0.6875 24.3125 2.19275 24.3125 4.0625V20.9375C24.3125 22.8073 22.8073 24.3125 20.9375 24.3125H4.0625C2.19275 24.3125 0.6875 22.8073 0.6875 20.9375V4.0625C0.6875 2.19275 2.19275 0.6875 4.0625 0.6875Z"
        stroke={`url(#${gradientId})`}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M5.75 5.75H7.4375"
        stroke={`url(#${gradientId})`}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M9.125 7.4375C8.67745 7.4375 8.24822 7.25971 7.93176 6.94324C7.61529 6.62678 7.4375 6.19755 7.4375 5.75C7.4375 5.30245 7.61529 4.87322 7.93176 4.55676C8.24822 4.24029 8.67745 4.0625 9.125 4.0625C9.3466 4.0625 9.56604 4.10615 9.77078 4.19095C9.97551 4.27576 10.1615 4.40006 10.3182 4.55676C10.4749 4.71346 10.5992 4.89948 10.684 5.10422C10.7689 5.30896 10.8125 5.52839 10.8125 5.75C10.8125 5.97161 10.7689 6.19104 10.684 6.39578C10.5992 6.60052 10.4749 6.78654 10.3182 6.94324C10.1615 7.09994 9.97551 7.22424 9.77078 7.30905C9.56604 7.39385 9.3466 7.4375 9.125 7.4375Z"
        stroke={`url(#${gradientId})`}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M10.8125 5.75008L19.25 5.75"
        stroke={`url(#${gradientId})`}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M19.25 12.5H17.5625"
        stroke={`url(#${gradientId})`}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M15.875 14.1875C16.3226 14.1875 16.7518 14.0097 17.0682 13.6932C17.3847 13.3768 17.5625 12.9476 17.5625 12.5C17.5625 12.0524 17.3847 11.6232 17.0682 11.3068C16.7518 10.9903 16.3226 10.8125 15.875 10.8125C15.4274 10.8125 14.9982 10.9903 14.6818 11.3068C14.3653 11.6232 14.1875 12.0524 14.1875 12.5C14.1875 12.9476 14.3653 13.3768 14.6818 13.6932C14.9982 14.0097 15.4274 14.1875 15.875 14.1875Z"
        stroke={`url(#${gradientId})`}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M14.1875 12.5001L5.75 12.5"
        stroke={`url(#${gradientId})`}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M5.75 19.25H7.4375"
        stroke={`url(#${gradientId})`}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M9.125 20.9375C8.67745 20.9375 8.24822 20.7597 7.93176 20.4432C7.61529 20.1268 7.4375 19.6976 7.4375 19.25C7.4375 18.8024 7.61529 18.3732 7.93176 18.0568C8.24822 17.7403 8.67745 17.5625 9.125 17.5625C9.3466 17.5625 9.56604 17.6061 9.77078 17.691C9.97551 17.7758 10.1615 17.9001 10.3182 18.0568C10.4749 18.2135 10.5992 18.3995 10.684 18.6042C10.7689 18.809 10.8125 19.0284 10.8125 19.25C10.8125 19.4716 10.7689 19.691 10.684 19.8958C10.5992 20.1005 10.4749 20.2865 10.3182 20.4432C10.1615 20.5999 9.97551 20.7242 9.77078 20.809C9.56604 20.8939 9.3466 20.9375 9.125 20.9375Z"
        stroke={`url(#${gradientId})`}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M10.8125 19.2501L19.25 19.25"
        stroke={`url(#${gradientId})`}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <defs>
        <linearGradient
          id={gradientId}
          x1="14.5213"
          y1="19.2499"
          x2="14.5213"
          y2="19.2501"
          gradientUnits="userSpaceOnUse"
        >
          {gradientSteps &&
            gradientSteps.map((gradientStep, index) => (
              <stop
                key={index}
                offset={`${gradientStep.offset}`}
                stopColor={gradientStep.stopColor}
              />
            ))}
        </linearGradient>
      </defs>
    </svg>
  );
}

export default Search;
