import React from "react";
import { Col, FormFeedback, Input, Label, Row } from "reactstrap";
import * as Yup from "yup";

import { fetchWrapper } from "@helpers/fetchwrapper";

import Select from "react-select";
import SelectAsyncPaginate from "@areas/shared/components/select/SelectAsyncPaginate";

import styles from "@areas/shared/components/personal/modals/styles/customModal.module.css";

const initialValues = (institute, specialities, company) => {
  return {
    id: (institute && institute.Id) || null,
    name: (institute && institute.Name) || "",
    type: (institute && institute.IdType) || 0,
    description: (institute && institute.Description) || "",
    idCompany: (company && company.IdCompany) || 0,
    parent:
      (institute &&
        institute.IdParent && {
          value: institute.IdParent,
          label: institute.NameParent,
        }) ||
      null,
    specialities: specialities
      ? specialities.map((e) => ({
          value: e.Id,
          label: `${e.Code} ${e.Name ? e.Name : e.Title}`,
        }))
      : [],
  };
};

const validationSchema = () => {
  return Yup.object({});
};

const confirm = (values, successFunc) => {
  let data = new FormData();
  data.append("name", values.name);
  data.append("description", values.description);
  data.append("idCompany", values.idCompany);
  data.append("idType", values.type);

  if (values.parent) {
    data.append("idParent", values.parent.value);
  }

  if (values.id) {
    data.append("id", values.id);

    fetchWrapper.post(`/v1/personal/updateInstitute`, data, false).then(() => {
      fetchWrapper
        .post(
          `/v1/personal/setInstituteFaculties?IdInstitute=${values.id}`,
          values.specialities.map((element) => element.value)
        )
        .then(() => successFunc(values.idCompany));
    });
  } else {
    fetchWrapper
      .post(`/v1/personal/insertInstitute`, data, false)
      .then(() => successFunc(values.idCompany));
  }
};

function ModalBodyAdd({ validation }) {
  const types = [
    {
      value: 0,
      label: "Институт",
    },
    {
      value: 1,
      label: "Факультет",
    },
    {
      value: 2,
      label: "Кафедра",
    },
  ];

  const loadParentsOptions = async (searchQuery, loadedOptions, { page }) => {
    const url = `/v1/api/app/getInstituteByIdCompany?idCompany=${
      validation.values.idCompany
    }&text=${searchQuery}&offset=${page * 10}&limit=10`;

    let result = await fetchWrapper.get(url);

    let options = result.data
      .filter(
        (p) =>
          p.Id !== validation.values.id && p.IdType !== validation.values.type
      )
      .map((p) => ({
        value: p.Id,
        label: p.Name,
      }));
    return {
      options: options,
      hasMore: result.total > page * 10,
      additional: {
        page: searchQuery ? 1 : page + 1,
      },
    };
  };

  return (
    <Row className="g-2">
      <Input
        type="hidden"
        name="id"
        defaultValue={validation.values.id || null}
      />
      <Input
        type="hidden"
        name="idCompany"
        defaultValue={validation.values.idCompany || null}
      />
      <Col sm={12}>
        <div>
          <Label htmlFor="instituteName-field" className={styles.label}>
            Наименование
          </Label>
          <Input
            name="name"
            id="instituteName-field"
            className={`form-control ${styles.input}`}
            placeholder="Введите наименование"
            type="text"
            validate={{
              required: { value: true },
            }}
            onChange={validation.handleChange}
            onBlur={validation.handleBlur}
            value={validation.values.name || ""}
            invalid={
              validation.touched.name && validation.errors.name ? true : false
            }
          />
          {validation.touched.name && validation.errors.name ? (
            <FormFeedback type="invalid" className={styles.invalid}>
              {validation.errors.name}
            </FormFeedback>
          ) : null}
        </div>
      </Col>
      <Col sm={12}>
        <div>
          <Label htmlFor="instituteDescription-field" className={styles.label}>
            Описание
          </Label>
          <Input
            name="description"
            id="instituteDescription-field"
            className={`form-control ${styles.input}`}
            placeholder="Введите описание"
            type="textarea"
            validate={{
              required: { value: true },
            }}
            onChange={validation.handleChange}
            onBlur={validation.handleBlur}
            value={validation.values.description || ""}
            invalid={
              validation.touched.description && validation.errors.description
                ? true
                : false
            }
          />
          {validation.touched.description && validation.errors.description ? (
            <FormFeedback type="invalid" className={styles.invalid}>
              {validation.errors.description}
            </FormFeedback>
          ) : null}
        </div>
      </Col>
      <Col sm={4}>
        <div>
          <Label htmlFor="instituteType-field" className={styles.label}>
            Тип
          </Label>
          <Select
            name="type"
            id="instituteType-field"
            value={
              validation.values.type
                ? types.filter((p) => p.value === validation.values.type)
                : types[0]
            }
            options={types}
            placeholder="Выберите тип"
            onChange={(e) => {
              validation.setFieldValue("type", e.value);
            }}
            onBlur={() => validation.setFieldTouched("type")}
          />
        </div>
      </Col>
      <Col sm={8}>
        <div>
          <Label htmlFor="instituteParent-field" className={styles.label}>
            Родитель
          </Label>
          <SelectAsyncPaginate
            name="parent"
            id="instituteParent-field"
            isMulti={false}
            loadFunc={loadParentsOptions}
            labelFunc={(e) => e.Name}
            valueFunc={(e) => e.Id}
            value={validation.values.parent || null}
            placeholder="Выберите родителя"
            onChangeValue={(e) => {
              validation.setFieldValue("parent", e);
            }}
            onBlurInput={() => validation.setFieldTouched("parent")}
          />
        </div>
      </Col>
      {validation.values.id ? (
        <Col sm={12}>
          <div>
            <Label
              htmlFor="instituteSpecialities-field"
              className={styles.label}
            >
              Специальности
            </Label>
            <SelectAsyncPaginate
              name="specialities"
              id="instituteSpecialities-field"
              isMulti={true}
              url={`/v1/api/app/getFacultiyByCompany/${validation.values.idCompany}`}
              labelFunc={(e) => `${e.Code} ${e.Name ? e.Name : e.Title}`}
              valueFunc={(e) => e.Id}
              value={validation.values.specialities || []}
              placeholder="Выберите специальности"
              onChangeValue={(e) => {
                validation.setFieldValue("specialities", e);
              }}
              onBlurInput={() => validation.setFieldTouched("specialities")}
            />
          </div>
        </Col>
      ) : null}
    </Row>
  );
}

export { initialValues, validationSchema, confirm, ModalBodyAdd };
