import React from "react";
import { Col, FormFeedback, Input, Label, Row } from "reactstrap";
import * as Yup from "yup";
import { FilePond, registerPlugin } from "react-filepond";

import FilePondPluginImageExifOrientation from "filepond-plugin-image-exif-orientation";
import FilePondPluginImagePreview from "filepond-plugin-image-preview";

import { fetchWrapper } from "@helpers/fetchwrapper";

import stylesModal from "@areas/shared/components/personal/modals/styles/customModal.module.css";

registerPlugin(FilePondPluginImageExifOrientation, FilePondPluginImagePreview);

const initialValues = (company) => {
  return {
    id: (company && company.Id) || null,
    type: (company && company.IdType) || null,
    fullname: (company && company.Fullname) || null,
    shortname: (company && company.Shortname) || null,
    description: (company && company.Description) || null,
    inn: (company && company.Inn) || null,
    kpp: (company && company.Kpp) || null,
    logo:
      company && company.LogoLink
        ? `/v1/api/fileManager/getFile?path=${company.LogoLink}`
        : null,
  };
};

const validationSchema = () => {
  return Yup.object({
    fullname: Yup.string().required("Текст обязателен"),
    shortname: Yup.string().required("Текст обязателен"),
    description: Yup.string().required("Текст обязателен"),
    inn: Yup.string().required("Текст обязателен"),
    kpp: Yup.string().required("Текст обязателен"),
  });
};

const confirm = (values, successFunc) => {
  if (values.id) {
    successFunc(true);
    let data = new FormData();
    data.append("id", values.id);
    data.append("idType", values.type);
    data.append("fullname", values.fullname);
    data.append("shortname", values.shortname);
    data.append("inn", values.inn);
    data.append("kpp", values.kpp);
    data.append("description", values.description);
    if (values.logo && values.logo.length > 0) {
      data.append("logo", values.logo[0].file);
    }

    fetchWrapper
      .post(`/v1/personal/updatecompany`, data, false)
      .then(() => successFunc(false));
  }
};

function FormBody({ validation }) {
  return (
    <Row className={stylesModal.body}>
      <Input
        type="hidden"
        name="id"
        defaultValue={validation.values.id || null}
      />
      <Input
        type="hidden"
        name="type"
        defaultValue={validation.values.type || null}
      />
      <FilePond
        files={validation.values.logo || null}
        onupdatefiles={(e) => {
          validation.setFieldValue("logo", e);
        }}
        name="logo"
        className="filepond filepond-input-multiple"
        labelIdle="Перетащите и нажмите, чтобы загрузить"
        onaddfile={() => validation.setFieldTouched("logo")}
      />
      <Col lg={12}>
        <div>
          <Label htmlFor="infoFullname-field" className={stylesModal.label}>
            Наименование
          </Label>
          <Input
            name="fullname"
            id="infoFullname-field"
            className={`form-control ${stylesModal.input}`}
            placeholder="Введите наименование"
            type="text"
            value={validation.values.fullname || ""}
            onChange={validation.handleChange}
            onBlur={validation.handleBlur}
            invalid={
              validation.touched.fullname && validation.errors.fullname
                ? true
                : false
            }
          />
          {validation.touched.fullname && validation.errors.fullname ? (
            <FormFeedback type="invalid" className={stylesModal.invalid}>
              {validation.errors.fullname}
            </FormFeedback>
          ) : null}
        </div>
      </Col>
      <Col lg={12}>
        <div>
          <Label htmlFor="infoShortname-field" className={stylesModal.label}>
            Сокращенное наименование
          </Label>
          <Input
            name="shortname"
            id="infoShortname-field"
            className={`form-control ${stylesModal.input}`}
            placeholder="Введите сокращенное наименование"
            type="text"
            value={validation.values.shortname || ""}
            onChange={validation.handleChange}
            onBlur={validation.handleBlur}
            invalid={
              validation.touched.shortname && validation.errors.shortname
                ? true
                : false
            }
          />
          {validation.touched.shortname && validation.errors.shortname ? (
            <FormFeedback type="invalid" className={stylesModal.invalid}>
              {validation.errors.shortname}
            </FormFeedback>
          ) : null}
        </div>
      </Col>
      <Col lg={12}>
        <div>
          <Label htmlFor="infoDescription-field" className={stylesModal.label}>
            Описание
          </Label>
          <Input
            name="description"
            id="infoDescription-field"
            className={`form-control ${stylesModal.input}`}
            placeholder="Введите описание"
            type="textarea"
            value={validation.values.description || ""}
            onChange={validation.handleChange}
            onBlur={validation.handleBlur}
            invalid={
              validation.touched.description && validation.errors.description
                ? true
                : false
            }
            style={{ height: 110, resize: "none" }}
          />
          {validation.touched.description && validation.errors.description ? (
            <FormFeedback type="invalid" className={stylesModal.invalid}>
              {validation.errors.description}
            </FormFeedback>
          ) : null}
        </div>
      </Col>
      <Col lg={6}>
        <div>
          <Label htmlFor="infoINN-field" className={stylesModal.label}>
            ИНН
          </Label>
          <Input
            name="inn"
            id="infoINN-field"
            className={`form-control ${stylesModal.input}`}
            placeholder="Введите ИНН"
            type="text"
            value={validation.values.inn || ""}
            onChange={validation.handleChange}
            onBlur={validation.handleBlur}
            invalid={
              validation.touched.inn && validation.errors.inn ? true : false
            }
          />
          {validation.touched.inn && validation.errors.inn ? (
            <FormFeedback type="invalid" className={stylesModal.invalid}>
              {validation.errors.inn}
            </FormFeedback>
          ) : null}
        </div>
      </Col>
      <Col lg={6}>
        <div>
          <Label htmlFor="infoKPP-field" className={stylesModal.label}>
            КПП
          </Label>
          <Input
            name="kpp"
            id="infoKPP-field"
            className={`form-control ${stylesModal.input}`}
            placeholder="Введите КПП"
            type="text"
            value={validation.values.kpp || ""}
            onChange={validation.handleChange}
            onBlur={validation.handleBlur}
            invalid={
              validation.touched.kpp && validation.errors.kpp ? true : false
            }
          />
          {validation.touched.kpp && validation.errors.kpp ? (
            <FormFeedback type="invalid" className={stylesModal.invalid}>
              {validation.errors.kpp}
            </FormFeedback>
          ) : null}
        </div>
      </Col>
    </Row>
  );
}

export { initialValues, validationSchema, confirm, FormBody };
