import React, { useCallback, useEffect, useState } from "react";
import { Col, FormFeedback, Input, Label, Row } from "reactstrap";
import Select from "react-select";
import * as Yup from "yup";
import moment from "moment";

import { fetchWrapper } from "@helpers/fetchwrapper";

import SelectAsyncPaginate from "@areas/shared/components/select/SelectAsyncPaginate";

import styles from "@areas/shared/components/personal/modals/styles/customModal.module.css";

const forOptions = [
  { value: "Вуза", label: "Вуза" },
  { value: "Колледжа", label: "Колледжа" },
];

const sessionOptions = [
  { value: "1-2", label: "1-2" },
  { value: "3-4", label: "3-4" },
  { value: "5-6", label: "5-6" },
  { value: "Выпускники", label: "Выпускники" },
];

const workScheduleOptions = [
  { value: "Полная", label: "Полная" },
  { value: "Частичная", label: "Частичная" },
];

const payOptions = [
  { value: 1, label: "Да" },
  { value: 2, label: "Нет" },
];

const initialValues = (practicy, company) => {
  return {
    id: (practicy && practicy.Id) || null,
    name: (practicy && practicy.Name) || "",
    from: (practicy && practicy.DateS1) || "",
    to: (practicy && practicy.DatePo) || "",
    address: (practicy && practicy.Address) || "",
    contactFioId: 0,
    contactFio: null,
    contactPositionId: 0,
    contactPosition: null,
    contactSiteId: 0,
    contactSite: null,
    contactPhoneId: 0,
    contactPhone: null,
    contactEmailId: 0,
    contactEmail: null,
    for: (practicy && practicy.For) || "",
    session: (practicy && practicy.Course) || "",
    industry: practicy
      ? {
          value: practicy.IdOkved2,
          label: practicy.NameOkved2,
        }
      : null,
    workSchedule: (practicy && practicy.WorkSchedule) || "",
    pay: practicy ? (practicy.IsPaid ? 1 : 2) : 1,
    income: (practicy && practicy.Wage) || 0,
    skills: (practicy && practicy.Skills) || "",
    tasks: (practicy && practicy.Tasks) || "",
    conditions: (practicy && practicy.Terms) || "",
    program: (practicy && practicy.Program) || "-",
    area: (practicy && practicy.Area) || "-",
    dates: (practicy && practicy.Dates) || "-",
    idCompany: (company && company.IdCompany) || 0,
  };
};

const validationSchema = () => {
  return Yup.object({
    name: Yup.string().required("Значение обязателено"),
    from: Yup.string().required("Значение обязателено"),
    to: Yup.string().required("Значение обязателено"),
    address: Yup.string().required("Значение обязателено"),
    for: Yup.string().required("Значение обязателено"),
    session: Yup.string().required("Значение обязателено"),
    industry: Yup.object().required("Значение обязателено"),
    workSchedule: Yup.string().required("Значение обязателено"),
    pay: Yup.string().required("Значение обязателено"),
    income: Yup.number(),
    skills: Yup.string().required("Значение обязателено"),
    tasks: Yup.string().required("Значение обязателено"),
    conditions: Yup.string().required("Значение обязателено"),
    contactFio: Yup.string().required("Значение обязателено"),
  });
};

const confirm = (values, successFunc) => {
  let data = new FormData();

  let id = values.id;

  data.append("name", values.name);
  data.append("dateS1", values.from);
  data.append("datePo", values.to);
  data.append("address", values.address);
  data.append("for", values.for);
  data.append("course", values.session);
  data.append("idOkved2", values.industry.value);
  data.append("workSchedule", values.workSchedule);
  data.append("isPaid", values.pay === 1 ? true : false);
  data.append("wage", values.income);
  data.append("skills", values.skills);
  data.append("tasks", values.tasks);
  data.append("terms", values.conditions);
  data.append("program", values.program);
  data.append("area", values.area);
  data.append("dates", values.dates);
  data.append("idCompany", values.idCompany);

  if (id) {
    data.append("id", id);
    fetchWrapper
      .post(`/v1/personal/updatepractice`, data, false)
      .then((res) => {
        checkContact(
          values.contactFioId,
          4,
          values.contactFio,
          values.idCompany,
          1,
          false,
          values.idCompany
        ).then((res) => {
          let contactId = 0;

          if (res.Id) {
            contactId = res.Id;
            checkContact(
              res.Id,
              4,
              values.contactFio,
              id,
              12,
              true,
              values.idCompany
            );
          } else {
            contactId = values.contactFioId;
            checkContact(
              values.contactFioId,
              4,
              values.contactFio,
              id,
              12,
              true,
              values.idCompany
            );
          }

          checkContact(
            values.contactEmailId,
            1,
            values.contactEmail,
            contactId,
            4,
            false,
            values.idCompany
          );
          checkContact(
            values.contactPhoneId,
            2,
            values.contactPhone,
            contactId,
            4,
            false,
            values.idCompany
          );
          checkContact(
            values.contactPositionId,
            5,
            values.contactPosition,
            contactId,
            4,
            false,
            values.idCompany
          );
          checkContact(
            values.contactSiteId,
            3,
            values.contactSite,
            contactId,
            4,
            false,
            values.idCompany
          );
          successFunc();
        });
      });
  } else {
    fetchWrapper
      .post(`/v1/personal/insertPractice`, data, false)
      .then((pract) => {
        checkContact(
          values.contactFioId,
          4,
          values.contactFio,
          values.idCompany,
          1,
          false,
          values.idCompany
        ).then((res) => {
          let contactId = 0;

          if (res.Id) {
            contactId = res.Id;
            checkContact(
              res.Id,
              4,
              values.contactFio,
              pract.Id,
              12,
              true,
              values.idCompany
            );
          } else {
            contactId = values.contactFioId;
            checkContact(
              values.contactFioId,
              4,
              values.contactFio,
              pract.Id,
              12,
              true,
              values.idCompany
            );
          }

          checkContact(
            values.contactEmailId,
            1,
            values.contactEmail,
            contactId,
            4,
            false,
            values.idCompany
          );
          checkContact(
            values.contactPhoneId,
            2,
            values.contactPhone,
            contactId,
            4,
            false,
            values.idCompany
          );
          checkContact(
            values.contactPositionId,
            5,
            values.contactPosition,
            contactId,
            4,
            false,
            values.idCompany
          );
          checkContact(
            values.contactSiteId,
            3,
            values.contactSite,
            contactId,
            4,
            false,
            values.idCompany
          );

          successFunc();
        });
      });
  }
};

const checkContact = (
  id,
  type,
  value,
  parent,
  typeParent,
  needInsert,
  idCompany
) => {
  if (id > 0 && !needInsert) {
    let data = new FormData();
    data.append("id", id);
    data.append("idType", type);
    data.append("value", value);
    return fetchWrapper.post(`/v1/personal/updatecontact`, data, false);
  } else {
    let data = new FormData();
    if (id !== undefined && id !== null) {
      data.append("id", id);
    } else {
      data.append("id", 0);
    }
    data.append("idType", type);
    data.append("value", value);
    data.append("idParent", typeParent);
    return fetchWrapper.post(
      `/v1/personal/insertcontact?idCompany=${idCompany}&idParent=${parent}&typeParent=${typeParent}`,
      data,
      false
    );
  }
};

function ModalBodyAdd({ validation }) {
  const [contactPersons, setContactPersons] = useState([]);

  const { setFieldValue } = validation;

  const loadIndustryOptions = async (searchQuery, loadedOptions, { page }) => {
    const url = `/v1/api/app/getOkved2/?text=${searchQuery}&offset=${
      page * 10
    }&limit=10`;

    let result = await fetchWrapper.get(url);

    let options = result.data.map((p) => ({
      value: p.Id,
      label: p.Name,
    }));
    return {
      options: options,
      hasMore: result.total > page * 10,
      additional: {
        page: searchQuery ? 1 : page + 1,
      },
    };
  };

  const setContactPerson = useCallback(
    (id, value) => {
      if (id > 0) {
        fetchWrapper
          .get(`/v1/api/app/getContacts/?id_parent=${id}&id_parent_type=4`)
          .then((res) => {
            let sites = res.filter((p) => p.IdType === 3);
            let phones = res.filter((p) => p.IdType === 2);
            let emails = res.filter((p) => p.IdType === 1);
            let positions = res.filter((p) => p.IdType === 5);
            setFieldValue("contactFioId", id);
            setFieldValue("contactFio", value);
            setFieldValue(
              "contactPositionId",
              positions.length > 0 ? positions[0].Id : null
            );
            setFieldValue(
              "contactPosition",
              positions.length > 0 ? positions[0].Value : null
            );
            setFieldValue(
              "contactSiteId",
              sites.length > 0 ? sites[0].Id : null
            );
            setFieldValue(
              "contactSite",
              sites.length > 0 ? sites[0].Value : null
            );
            setFieldValue(
              "contactPhoneId",
              phones.length > 0 ? phones[0].Id : null
            );
            setFieldValue(
              "contactPhone",
              phones.length > 0 ? phones[0].Value : null
            );
            setFieldValue(
              "contactEmailId",
              emails.length > 0 ? emails[0].Id : null
            );
            setFieldValue(
              "contactEmail",
              emails.length > 0 ? emails[0].Value : null
            );
          });
      } else {
        setFieldValue("contactFio", null);
        setFieldValue("contactPositionId", 0);
        setFieldValue("contactPosition", null);
        setFieldValue("contactSiteId", 0);
        setFieldValue("contactSite", null);
        setFieldValue("contactPhoneId", 0);
        setFieldValue("contactPhone", null);
        setFieldValue("contactEmailId", 0);
        setFieldValue("contactEmail", null);
      }
    },
    [setFieldValue]
  );

  useEffect(() => {
    if (validation.values.idCompany) {
      fetchWrapper
        .get(
          `/v1/api/app/getContacts/?id_parent=${validation.values.idCompany}&id_parent_type=1`
        )
        .then((res) => {
          let data = res
            .filter((e) => e.IdType === 4)
            .map((e) => {
              return {
                value: e.Id,
                label: e.Value,
              };
            });
          data.unshift({
            value: 0,
            label: "Выберите контактное лицо из списка или заполните данные нового ниже",
          });
          setContactPersons(data);
        });
    }
  }, [validation.values.idCompany]);

  useEffect(() => {
    if (validation.values.id) {
      fetchWrapper
        .get(
          `/v1/api/app/getContacts/?id_parent=${validation.values.id}&id_parent_type=12`
        )
        .then((res) => {
          if (res.length > 0) {
            setContactPerson(res[0].Id, res[0].Value);
          }
        });
    }
  }, [validation.values.id, setContactPerson]);

  return (
    <Row className="g-2">
      <Input
        type="hidden"
        name="id"
        defaultValue={validation.values.id || null}
      />
      <Input
        type="hidden"
        name="idCompany"
        defaultValue={validation.values.idCompany || null}
      />
      <Col lg={12}>
        <div>
          <Label htmlFor="practiceName-field" className={styles.label}>
            Название
          </Label>
          <Input
            name="name"
            id="practiceName-field"
            className={`form-control ${styles.input}`}
            placeholder="Введите название"
            type="text"
            value={validation.values.name || ""}
            onChange={validation.handleChange}
            onBlur={validation.handleBlur}
            invalid={
              validation.touched.name && validation.errors.name ? true : false
            }
          />
          {validation.touched.name && validation.errors.name ? (
            <FormFeedback type="invalid" className={styles.invalid}>
              {validation.errors.name}
            </FormFeedback>
          ) : null}
        </div>
      </Col>
      <Col lg={6}>
        <div>
          <Label htmlFor="practiceFrom-field" className={styles.label}>
            С
          </Label>
          <Input
            name="from"
            id="practiceFrom-field"
            className={`form-control ${styles.input}`}
            placeholder="Введите начало стажеровки"
            type="date"
            value={
              validation.values.from
                ? moment(validation.values.from).format("YYYY-MM-DD")
                : ""
            }
            onChange={validation.handleChange}
            onBlur={validation.handleBlur}
            invalid={
              validation.touched.from && validation.errors.from ? true : false
            }
          />
          {validation.touched.from && validation.errors.from ? (
            <FormFeedback type="invalid" className={styles.invalid}>
              {validation.errors.from}
            </FormFeedback>
          ) : null}
        </div>
      </Col>
      <Col lg={6}>
        <div>
          <Label htmlFor="practiceTo-field" className={styles.label}>
            По
          </Label>
          <Input
            name="to"
            id="practiceTo-field"
            className={`form-control ${styles.input}`}
            placeholder="Введите конец стажировки"
            type="date"
            value={
              validation.values.to
                ? moment(validation.values.to).format("YYYY-MM-DD")
                : ""
            }
            onChange={validation.handleChange}
            onBlur={validation.handleBlur}
            invalid={
              validation.touched.to && validation.errors.to ? true : false
            }
          />
          {validation.touched.to && validation.errors.to ? (
            <FormFeedback type="invalid" className={styles.invalid}>
              {validation.errors.to}
            </FormFeedback>
          ) : null}
        </div>
      </Col>
      <Col lg={12}>
        <div>
          <Label htmlFor="practiceAddress-field" className={styles.label}>
            Адрес
          </Label>
          <Input
            name="address"
            id="practiceAddress-field"
            className={`form-control ${styles.input}`}
            placeholder="Введите адрес"
            type="text"
            value={validation.values.address || ""}
            onChange={validation.handleChange}
            onBlur={validation.handleBlur}
            invalid={
              validation.touched.address && validation.errors.address
                ? true
                : false
            }
          />
          {validation.touched.address && validation.errors.address ? (
            <FormFeedback type="invalid" className={styles.invalid}>
              {validation.errors.address}
            </FormFeedback>
          ) : null}
        </div>
      </Col>
      <div className="border rounded p-2 border-2">
        <Col sm={12}>
          <div>
            <Label
              htmlFor="practiceSelectContactPerson-field"
              className={styles.label}
            >
              Контактное лицо
            </Label>
            <Select
              name="selectContactPerson"
              id="practiceSelectContactPerson-field"
              options={contactPersons}
              value={{
                value: validation.values.contactFioId || 0,
                label: validation.values.contactFio || "Выберите контактное лицо из списка или заполните данные нового ниже",
              }}
              placeholder="Выберите контактное лицо из списка или заполните данные нового ниже"
              onChange={(e) => {
                setContactPerson(e.value, e.label);
              }}
            />
          </div>
        </Col>
        <Col lg={12}>
          <div>
            <Label htmlFor="practiceContactFio-field" className={styles.label}>
              ФИО
            </Label>
            <Input
              name="contactFio"
              id="practiceContactFio-field"
              className={`form-control ${styles.input}`}
              placeholder="Введите ФИО нового контактного лица"
              type="text"
              value={validation.values.contactFio || ""}
              onChange={validation.handleChange}
              onBlur={validation.handleBlur}
              invalid={
                validation.touched.contactFio && validation.errors.contactFio
                  ? true
                  : false
              }
            />
            {validation.touched.contactFio && validation.errors.contactFio ? (
              <FormFeedback type="invalid" className={styles.invalid}>
                {validation.errors.contactFio}
              </FormFeedback>
            ) : null}
          </div>
        </Col>
        <Row>
          <Col lg={6}>
            <div>
              <Label
                htmlFor="practiceContactPosition-field"
                className={styles.label}
              >
                Должность
              </Label>
              <Input
                name="contactPosition"
                id="practiceContactPosition-field"
                className={`form-control ${styles.input}`}
                placeholder="Введите должность контактного лица"
                type="text"
                value={validation.values.contactPosition || ""}
                onChange={validation.handleChange}
                onBlur={validation.handleBlur}
                invalid={
                  validation.touched.contactPosition &&
                  validation.errors.contactPosition
                    ? true
                    : false
                }
              />
              {validation.touched.contactPosition &&
              validation.errors.contactPosition ? (
                <FormFeedback type="invalid" className={styles.invalid}>
                  {validation.errors.contactPosition}
                </FormFeedback>
              ) : null}
            </div>
          </Col>
          <Col lg={6}>
            <div>
              <Label
                htmlFor="practiceContactSite-field"
                className={styles.label}
              >
                Сайт
              </Label>
              <Input
                name="contactSite"
                id="practiceContactSite-field"
                className={`form-control ${styles.input}`}
                placeholder="Введите сайт контактного лица"
                type="text"
                value={validation.values.contactSite || ""}
                onChange={validation.handleChange}
                onBlur={validation.handleBlur}
                invalid={
                  validation.touched.contactSite &&
                  validation.errors.contactSite
                    ? true
                    : false
                }
              />
              {validation.touched.contactSite &&
              validation.errors.contactSite ? (
                <FormFeedback type="invalid" className={styles.invalid}>
                  {validation.errors.contactSite}
                </FormFeedback>
              ) : null}
            </div>
          </Col>
          <Col lg={6}>
            <div>
              <Label
                htmlFor="practiceContactPhone-field"
                className={styles.label}
              >
                Телефон
              </Label>
              <Input
                name="contactPhone"
                id="practiceContactPhone-field"
                className={`form-control ${styles.input}`}
                placeholder="Введите телефон контактного лица"
                type="text"
                value={validation.values.contactPhone || ""}
                onChange={validation.handleChange}
                onBlur={validation.handleBlur}
                invalid={
                  validation.touched.contactPhone &&
                  validation.errors.contactPhone
                    ? true
                    : false
                }
              />
              {validation.touched.contactPhone &&
              validation.errors.contactPhone ? (
                <FormFeedback type="invalid" className={styles.invalid}>
                  {validation.errors.contactPhone}
                </FormFeedback>
              ) : null}
            </div>
          </Col>
          <Col lg={6}>
            <div>
              <Label
                htmlFor="practiceContactEmail-field"
                className={styles.label}
              >
                Email
              </Label>
              <Input
                name="contactEmail"
                id="practiceContactEmail-field"
                className={`form-control ${styles.input}`}
                placeholder="Введите почту контактного лица"
                type="text"
                value={validation.values.contactEmail || ""}
                onChange={validation.handleChange}
                onBlur={validation.handleBlur}
                invalid={
                  validation.touched.contactEmail &&
                  validation.errors.contactEmail
                    ? true
                    : false
                }
              />
              {validation.touched.contactEmail &&
              validation.errors.contactEmail ? (
                <FormFeedback type="invalid" className={styles.invalid}>
                  {validation.errors.contactEmail}
                </FormFeedback>
              ) : null}
            </div>
          </Col>
        </Row>
      </div>
      <Col sm={6}>
        <div>
          <Label htmlFor="practiceFor-field" className={styles.label}>
            Учащиеся
          </Label>
          <Select
            name="for"
            id="practiceFor-field"
            options={forOptions}
            placeholder="Выберите учащихся"
            value={
              validation.values.for
                ? forOptions.find((p) => p.value === validation.values.for)
                : null
            }
            onChange={(e) => {
              validation.setFieldValue("for", e.value);
            }}
            onBlur={() => validation.setFieldTouched("for")}
          />
          {validation.touched.for && validation.errors.for ? (
            <FormFeedback type="invalid" className={styles.invalid}>
              {validation.errors.for}
            </FormFeedback>
          ) : null}
        </div>
      </Col>
      <Col sm={6}>
        <div>
          <Label htmlFor="practiceSession-field" className={styles.label}>
            Курс обучения
          </Label>
          <Select
            name="session"
            id="practiceSession-field"
            options={sessionOptions}
            placeholder="Выберите курс обучения"
            value={
              validation.values.session
                ? sessionOptions.find(
                    (p) => p.value === validation.values.session
                  )
                : null
            }
            onChange={(e) => {
              validation.setFieldValue("session", e.value);
            }}
            onBlur={() => validation.setFieldTouched("session")}
          />
          {validation.touched.session && validation.errors.session ? (
            <FormFeedback type="invalid" className={styles.invalid}>
              {validation.errors.session}
            </FormFeedback>
          ) : null}
        </div>
      </Col>
      <Col sm={12}>
        <div>
          <Label htmlFor="practiceIndustry-field" className={styles.label}>
            Отрасль
          </Label>
          <SelectAsyncPaginate
            name="industry"
            id="practiceIndustry-field"
            isMulti={false}
            loadFunc={loadIndustryOptions}
            labelFunc={(e) => e.Name}
            valueFunc={(e) => e.Id}
            value={validation.values.industry || 0}
            placeholder="Выберите отрасли"
            onChangeValue={(e) => {
              validation.setFieldValue("industry", e);
            }}
            onBlurInput={() => validation.setFieldTouched("industry")}
          />
          {validation.touched.industry && validation.errors.industry ? (
            <FormFeedback type="invalid" className={styles.invalid}>
              {validation.errors.industry}
            </FormFeedback>
          ) : null}
        </div>
      </Col>
      <Col sm={4}>
        <div>
          <Label htmlFor="practiceWorkSchedule-field" className={styles.label}>
            Занятость
          </Label>
          <Select
            name="workSchedule"
            id="practiceWorkSchedule-field"
            options={workScheduleOptions}
            placeholder="Выберите занятость"
            value={
              validation.values.workSchedule
                ? workScheduleOptions.find(
                    (p) => p.value === validation.values.workSchedule
                  )
                : null
            }
            onChange={(e) => {
              validation.setFieldValue("workSchedule", e.value);
            }}
            onBlur={() => validation.setFieldTouched("workSchedule")}
          />
          {validation.touched.workSchedule && validation.errors.workSchedule ? (
            <FormFeedback type="invalid" className={styles.invalid}>
              {validation.errors.workSchedule}
            </FormFeedback>
          ) : null}
        </div>
      </Col>
      <Col sm={4}>
        <div>
          <Label htmlFor="practicePay-field" className={styles.label}>
            Оплачиваемая
          </Label>
          <Select
            name="pay"
            id="practicePay-field"
            options={payOptions}
            placeholder="Выберите занятость"
            value={
              validation.values.pay
                ? payOptions.find((p) => p.value === validation.values.pay)
                : null
            }
            onChange={(e) => {
              validation.setFieldValue("pay", e.value);
            }}
            onBlur={() => validation.setFieldTouched("pay")}
          />
          {validation.touched.pay && validation.errors.pay ? (
            <FormFeedback type="invalid" className={styles.invalid}>
              {validation.errors.pay}
            </FormFeedback>
          ) : null}
        </div>
      </Col>
      <Col lg={4}>
        <div>
          <Label htmlFor="practiceIncome-field" className={styles.label}>
            Зарплата, рублей в месяц
          </Label>
          <Input
            name="income"
            id="practiceIncome-field"
            className={`form-control ${styles.input}`}
            placeholder="Введите адрес"
            type="number"
            value={validation.values.income || 0}
            onChange={validation.handleChange}
            onBlur={validation.handleBlur}
            invalid={
              validation.touched.income && validation.errors.income
                ? true
                : false
            }
          />
          {validation.touched.income && validation.errors.income ? (
            <FormFeedback type="invalid" className={styles.invalid}>
              {validation.errors.income}
            </FormFeedback>
          ) : null}
        </div>
      </Col>
      <Col lg={12}>
        <div>
          <Label htmlFor="practiceSkills-field" className={styles.label}>
            Нужные навыки
          </Label>
          <Input
            name="skills"
            id="practiceSkills-field"
            className={`form-control ${styles.input}`}
            placeholder="Введите описание навыков"
            type="textarea"
            value={validation.values.skills || ""}
            onChange={validation.handleChange}
            onBlur={validation.handleBlur}
            invalid={
              validation.touched.skills && validation.errors.skills
                ? true
                : false
            }
            style={{ height: 60, resize: "none" }}
          />
          {validation.touched.skills && validation.errors.skills ? (
            <FormFeedback type="invalid" className={styles.invalid}>
              {validation.errors.skills}
            </FormFeedback>
          ) : null}
        </div>
      </Col>
      <Col lg={12}>
        <div>
          <Label htmlFor="practiceTasks-field" className={styles.label}>
            Какие задачи выполнять
          </Label>
          <Input
            name="tasks"
            id="practiceTasks-field"
            className={`form-control ${styles.input}`}
            placeholder="Введите описание задач"
            type="textarea"
            value={validation.values.tasks || ""}
            onChange={validation.handleChange}
            onBlur={validation.handleBlur}
            invalid={
              validation.touched.tasks && validation.errors.tasks ? true : false
            }
            style={{ height: 60, resize: "none" }}
          />
          {validation.touched.tasks && validation.errors.tasks ? (
            <FormFeedback type="invalid" className={styles.invalid}>
              {validation.errors.tasks}
            </FormFeedback>
          ) : null}
        </div>
      </Col>
      <Col lg={12}>
        <div>
          <Label htmlFor="practiceConditions-field" className={styles.label}>
            Условия
          </Label>
          <Input
            name="conditions"
            id="practiceConditions-field"
            className={`form-control ${styles.input}`}
            placeholder="Введите описание условий"
            type="textarea"
            value={validation.values.conditions || ""}
            onChange={validation.handleChange}
            onBlur={validation.handleBlur}
            invalid={
              validation.touched.conditions && validation.errors.conditions
                ? true
                : false
            }
            style={{ height: 60, resize: "none" }}
          />
          {validation.touched.conditions && validation.errors.conditions ? (
            <FormFeedback type="invalid" className={styles.invalid}>
              {validation.errors.conditions}
            </FormFeedback>
          ) : null}
        </div>
      </Col>
    </Row>
  );
}

export { initialValues, validationSchema, confirm, ModalBodyAdd };
