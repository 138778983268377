import React, { useCallback, useEffect, useState } from "react";
import ClipLoader from "react-spinners/ClipLoader";
import { Swiper, SwiperSlide } from "swiper/react";
import { Scrollbar, Navigation } from "swiper";
import { Link } from "react-router-dom";

import { useCheckMobileScreen } from "@common";

import CardArrow from "@areas/shared/components/cards/CardArrow";
import Pagination from "@areas/shared/components/paginations/Pagination";
import CircleShadow from "@areas/shared/components/background/CircleShadow";
import Button from "@areas/shared/components/buttons/Button";

import specialtyLogo from "@content/img/shared/education/png/specialty-logo.png";

import styles from "../blocks/styles/addition.module.css";
import paginationStyles from "@areas/college/components/pagination/styles/pagination.module.css";
import Filter from "../blocks/Filter";
import Search from "../blocks/Search";

function SpecialitiesTab({
  load,
  initDirection,
  directions,
  institutes,
  results,
  limit,
  companyId,
  onChangeInstitute,
  onChangePage,
}) {
  const [query, setQuery] = useState("");
  const [filter, setFilter] = useState([]);
  const [activeIntitute, setActiveInstitute] = useState([0, 0, 0]);
  const [activeDirection, setActiveDirection] = useState(0);
  const [groups, setGroups] = useState([]);
  const isMobile = useCheckMobileScreen();

  const groupByArray = (xs, key) => {
    return xs.reduce(function (rv, x) {
      let v = key instanceof Function ? key(x) : x[key];
      let el = rv.find((r) => r && r.key === v);
      if (el) {
        el.values.push(x);
      } else {
        rv.push({ key: v, values: [x] });
      }
      return rv;
    }, []);
  };

  const getParentInstitutes = useCallback(() => {
    let groups = groupByArray(institutes.data, "IdType");
    if (groups.length > 0) {
      let ordered = [];
      groups.forEach((element) => {
        let childed = false;
        groups.forEach((child) => {
          child.values.forEach((item) => {
            if (item.Childs) {
              let find = item.Childs.filter((p) => p.Id === element.Id);
              if (find.length > 0) {
                childed = true;
              }
            }
          });
        });
        if (!childed) {
          ordered.unshift(element);
        } else {
          ordered.push(element);
        }
      });
      let activities = [];
      ordered.forEach((element) => {
        activities.push(0);
      });
      setActiveInstitute(activities);
      setGroups(ordered);
    }
  }, [institutes.data]);

  useEffect(() => {
    getParentInstitutes();
  }, [getParentInstitutes]);

  const chooseInstitute = (prepareArray, item) => {
    let activities = prepareArray;
    if (groups.length > prepareArray.length) {
      groups
        .slice(0, groups.length - prepareArray.length)
        .forEach((element) => {
          activities.push(0);
        });
    }
    setActiveInstitute(activities);
    setActiveDirection(0);
    if (item) {
      onChangeInstitute(companyId, item.Id);
      setFilter([]);
    }
  };

  const getInstitutesFilter = (groups, activeIntitute) => {
    let result = [];
    for (let i = 1; i < activeIntitute.length; i++) {
      if (activeIntitute[i - 1] > 0) {
        let filtered = groups[i].values.filter(
          (p) => p.IdParent === activeIntitute[i - 1]
        );
        result.push(
          <div className={styles.institutes}>
            <Swiper
              modules={[Scrollbar, Navigation]}
              scrollbar={{
                hide: false,
                draggable: true,
              }}
              navigation={!isMobile}
              slidesPerView={"auto"}
              spaceBetween={20}
              className={styles.swiper}
            >
              {filtered.map((item) => (
                <SwiperSlide key={item.Id} className={styles.slide}>
                  <div className={styles.card}>
                    <CircleShadow style={styles.tabDirectionLogo} />
                    <Button
                      style={`${styles.tabDirection} ${
                        activeIntitute[i] === item.Id ? styles.active : null
                      }`}
                      onClick={() => {
                        let activities = [];
                        activeIntitute.forEach((p, index) => {
                          if (index < i) {
                            activities.push(p);
                          }
                        });
                        activities.push(item.Id);
                        chooseInstitute(activities, item);
                      }}
                    >
                      {item.Name}
                    </Button>
                  </div>
                </SwiperSlide>
              ))}
            </Swiper>
          </div>
        );
      } else {
        break;
      }
    }

    return result;
  };

  useEffect(() => {
    if (activeIntitute.length > 0) {
      onChangePage(
        0,
        query,
        filter,
        activeIntitute[activeIntitute.length - 1],
        activeDirection,
        companyId
      );
    } else {
      onChangePage(0, query, filter, 0, activeDirection, companyId);
    }
  }, [query, filter, activeDirection, activeIntitute, companyId, onChangePage]);

  useEffect(() => {
    setActiveDirection(initDirection);
  }, [initDirection]);

  return (
    <>
      <div className={styles.tabContent}>
        <div className={styles.directions}>
          {groups.length > 0 ? (
            <Swiper
              modules={[Scrollbar, Navigation]}
              scrollbar={{
                hide: false,
                draggable: true,
              }}
              navigation={!isMobile}
              slidesPerView={"auto"}
              spaceBetween={20}
              className={styles.swiper}
            >
              <SwiperSlide className={styles.slide}>
                <div className={styles.card}>
                  <CircleShadow style={styles.tabDirectionLogo} />
                  <Button
                    style={`${styles.tabDirection} ${
                      activeIntitute[0] === 0 ? styles.active : null
                    }`}
                    onClick={() => chooseInstitute([], null)}
                  >
                    Все
                  </Button>
                </div>
              </SwiperSlide>
              {groups[0].values.map((item, index) => (
                <SwiperSlide key={item.Id} className={styles.slide}>
                  <div className={styles.card}>
                    <CircleShadow style={styles.tabDirectionLogo} />
                    <Button
                      style={`${styles.tabDirection} ${
                        activeIntitute[0] === item.Id ? styles.active : null
                      }`}
                      onClick={() => chooseInstitute([item.Id], item)}
                    >
                      {item.Name}
                    </Button>
                  </div>
                </SwiperSlide>
              ))}
            </Swiper>
          ) : (
            <Swiper
              modules={[Scrollbar, Navigation]}
              scrollbar={{
                hide: false,
                draggable: true,
              }}
              navigation={!isMobile}
              slidesPerView={"auto"}
              spaceBetween={20}
              className={styles.swiper}
            >
              <SwiperSlide className={styles.slide}>
                <div className={styles.card}>
                  <CircleShadow style={styles.tabDirectionLogo} />
                  <Button
                    style={`${styles.tabDirection} ${
                      activeDirection === 0 ? styles.active : null
                    }`}
                    onClick={() => {
                      setActiveDirection(0);
                    }}
                  >
                    Все
                  </Button>
                </div>
              </SwiperSlide>
              {directions.map((item, index) => (
                <SwiperSlide key={item.Id} className={styles.slide}>
                  <div className={styles.card}>
                    <CircleShadow style={styles.tabDirectionLogo} />
                    <Button
                      style={`${styles.tabDirection} ${
                        activeDirection === item.Id ? styles.active : null
                      }`}
                      onClick={() => {
                        setActiveDirection(item.Id);
                        setFilter([]);
                      }}
                    >
                      {item.Title}
                    </Button>
                  </div>
                </SwiperSlide>
              ))}
            </Swiper>
          )}
        </div>
        {getInstitutesFilter(groups, activeIntitute).map((item, index) => (
          <div key={index}>{item}</div>
        ))}
        {groups.length > 0 && activeIntitute[activeIntitute.length - 1] > 0 ? (
          <div className={styles.institutes}>
            <Swiper
              modules={[Scrollbar, Navigation]}
              scrollbar={{
                hide: false,
                draggable: true,
              }}
              navigation={!isMobile}
              slidesPerView={"auto"}
              spaceBetween={20}
              className={styles.swiper}
            >
              {directions.map((item, index) => (
                <SwiperSlide key={item.Id} className={styles.slide}>
                  <div className={styles.card}>
                    <CircleShadow style={styles.tabDirectionLogo} />
                    <Button
                      style={`${styles.tabDirection} ${
                        activeDirection === item.Id ? styles.active : null
                      }`}
                      onClick={() => {
                        setActiveDirection(item.Id);
                        setFilter([]);
                      }}
                    >
                      {item.Title}
                    </Button>
                  </div>
                </SwiperSlide>
              ))}
            </Swiper>
          </div>
        ) : null}
        {directions.length > 0 || groups.length > 0 ? (
          <div className={styles.specialties}>
            <div className={styles.header}>Специальности</div>
            {isMobile &&
            activeDirection === 0 &&
            ((activeIntitute.length > 0 && activeIntitute[0] === 0) ||
              activeIntitute.length === 0) ? (
              <Filter set={setFilter} values={filter} />
            ) : null}
            <div
              className={`${styles.results}${
                activeDirection === 0 &&
                ((activeIntitute.length > 0 && activeIntitute[0] === 0) ||
                  activeIntitute.length === 0)
                  ? ` ${styles.filter}`
                  : ""
              }`}
            >
              <Search setSearchQuery={setQuery} />
              {load ? (
                <div className="d-flex justify-content-center w-100">
                  <ClipLoader />
                </div>
              ) : results.data.length > 0 ? (
                <div className="mt-4">
                  {results.data.map((item, index) => (
                    <CardArrow
                      key={item.Id}
                      sizeArrow={{ width: 17, height: 17 }}
                      customStyles={{
                        main: styles.card,
                        border: styles.border,
                        arrow: styles.arrow,
                      }}
                      rotate="leftTop"
                    >
                      <div className={styles.inner}>
                        <img src={specialtyLogo} alt="specialty" />
                        <div className={styles.description}>
                          <Link
                            to={`speciality/${item.slug}`}
                            className={styles.title}
                            target="_blank"
                          >
                            {`${item.Code} ${
                              item.Name ? item.Name : item.Title
                            }`}
                          </Link>
                          <div className={styles.options}>
                            <div
                              className={`${styles.option} ${styles.active}`}
                            >
                              {item.Form}
                            </div>
                            <div
                              className={`${styles.option} ${styles.active}`}
                            >
                              {item.Price > 0 ? "Платное" : "Бюджетное"}
                            </div>
                          </div>
                        </div>
                      </div>
                    </CardArrow>
                  ))}
                </div>
              ) : (
                <div className="d-flex justify-content-center w-100">
                  Ничего не найдено
                </div>
              )}
            </div>
            {!isMobile &&
            activeDirection === 0 &&
            ((activeIntitute.length > 0 && activeIntitute[0] === 0) ||
              activeIntitute.length === 0) ? (
              <Filter set={setFilter} values={filter} />
            ) : null}
          </div>
        ) : null}
      </div>
      <Pagination
        load={load}
        itemsPerPage={limit}
        totalItems={results.total}
        onChangePage={(offset) => {
          if (activeIntitute.length > 0) {
            onChangePage(
              offset,
              query,
              filter,
              activeIntitute[activeIntitute.length - 1],
              activeDirection,
              companyId
            );
          } else {
            onChangePage(offset, query, filter, 0, activeDirection, companyId);
          }
        }}
        customStyles={{
          prev: paginationStyles.prev,
          current: paginationStyles.current,
          next: paginationStyles.next,
        }}
      />
    </>
  );
}

export default SpecialitiesTab;
