import React from "react";
import { Link, useMatches } from "react-router-dom";
import { Col, Row } from "reactstrap";

import styles from "./styles/breadCrumb.module.css";

function Breadcrumb({ title, pageTitle }) {
  let matches = useMatches();
  let crumbs = matches
    .filter((match) => Boolean(match.handle?.personalCrumbs))
    .map((match) => {
      return {
        props: match.handle.personalCrumbs(),
        path: match.pathname,
      };
    });
  return (
    <Row className="m-0">
      <Col className="p-0" xs={12}>
        <div className={styles.box}>
          <h4 className="mb-sm-0">{crumbs[crumbs.length - 1].title}</h4>
          <div className="page-title-right">
            <ol className="breadcrumb m-0">
              {crumbs.length > 1 ? (
                <>
                  {crumbs.slice(0, -1).map((crumb, index) => (
                    <li
                      key={index}
                      className={`${styles.item} ${
                        crumb.props.link ? null : styles.active
                      }`}
                    >
                      {crumb.props.link ? (
                        <Link to={crumb.path}>{crumb.props.name}</Link>
                      ) : (
                        crumb.props.name
                      )}
                    </li>
                  ))}
                  <li className={`${styles.item} ${styles.active}`}>
                    {crumbs[crumbs.length - 1].props.name}
                  </li>
                </>
              ) : null}
            </ol>
          </div>
        </div>
      </Col>
    </Row>
  );
}

export default Breadcrumb;
