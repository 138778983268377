import React, { useEffect, useRef, useState, useCallback } from "react";
import { Card, CardBody, Col, Label, Row } from "reactstrap";
import moment from "moment";
import ClipLoader from "react-spinners/ClipLoader";

import { fetchWrapper } from "@helpers/fetchwrapper";

import AttachmentIcon from "@areas/shared/components/icons/Attachment";

import LoadBlock from "@areas/shared/components/personal/loads/LoadBlock";
import CustomModal from "@areas/shared/components/personal/modals/CustomModal";

import {
  initialValues,
  validationSchema,
  ModalBodyAddFiles,
} from "./ModalFilesAdd";

import styles from "./styles/description.module.css";

function Description({ load, data, refreshData }) {
  const [showModal, setShowModal] = useState(false);
  const [loadMessages, setLoadMessages] = useState(false);
  const [newMessage, setNewMessage] = useState("");
  const [messages, setMessages] = useState([]);
  const [files, setFiles] = useState([]);
  const messageInputRef = useRef();

  const toggle = useCallback(() => {
    if (showModal) {
      setShowModal(false);
    } else {
      setShowModal(true);
    }
  }, [showModal]);

  const getMessages = (id) => {
    setLoadMessages(true);
    fetchWrapper
      .get(`/v1/personal/getTicketMessages?idTicket=${id}`)
      .then((res) => {
        setMessages(res);
        setLoadMessages(false);
      });
  };

  useEffect(() => {
    if (data.Id) {
      getMessages(data.Id);
    }
  }, [data]);

  const sendMessage = (message, ticketId) => {
    setLoadMessages(true);
    let data = new FormData();
    data.append("idTicket", ticketId);
    data.append("message", message);
    fetchWrapper
      .post(`/v1/personal/sendTicketMessage`, data, false)
      .then((res) => {
        messageInputRef.current.value = "";
        setNewMessage("");
        getMessages(ticketId);
      });
  };

  const sendFiles = (files, ticketId) => {
    if (files.length > 0) {
      let data = new FormData();
      files.forEach((info) => {
        data.append("files", info);
      });

      fetchWrapper
        .post(
          `/v1/personal/uploadFilesTicket?idTicket=${ticketId}`,
          data,
          false
        )
        .then(() => {
          setFiles([]);
          refreshData(ticketId);
        });
    }
  };

  return (
    <React.Fragment>
      <Col xxl={9}>
        <Card className="position-relative">
          <CardBody className="p-4">
            <h6 className="fw-semibold text-uppercase mb-3">Описание заявки</h6>
            <div dangerouslySetInnerHTML={{ __html: data.Description }}></div>
          </CardBody>
          <CardBody className="p-4">
            <h5 className="card-title mb-4">Обсуждение</h5>
            <div
              style={{ height: "300px" }}
              className={`px-3 mx-n3 ${styles.messages}`}
            >
              {messages.map((m) => {
                return (
                  <div key={m.Id} className={`d-flex ${styles.message}`}>
                    <div className="flex-grow-1 ms-3">
                      <div className={styles.author}>
                        {m.NameUser}
                        <small className={`text-muted ${styles.date}`}>
                          {moment(m.SysPeriod.LowerBound).format(
                            "DD.MM.YYYY HH:mm:ss"
                          )}
                        </small>
                      </div>
                      <p className="text-muted">{m.Message}</p>
                    </div>
                  </div>
                );
              })}
            </div>
            <Row className="g-3">
              <Col lg={12}>
                <Label htmlFor="newMessage" className="form-label">
                  Сообщение
                </Label>
                <textarea
                  className="form-control bg-light border-light"
                  id="newMessage"
                  rows="3"
                  placeholder="Введите сообщение"
                  onChange={(e) => setNewMessage(e.target.value)}
                  ref={messageInputRef}
                ></textarea>
              </Col>
              <Col
                lg={12}
                className="d-flex justify-content-end align-items-center gap-3"
              >
                <div className={styles.attachment} onClick={() => toggle()}>
                  <AttachmentIcon />
                  {files && files.length > 0 && (
                    <span className={styles.badge}>{files.length}</span>
                  )}
                </div>
                <button
                  className={`btn btn-success ${styles.btnSend}`}
                  onClick={() => {
                    sendFiles(files, data.Id);
                    sendMessage(newMessage, data.Id);
                  }}
                  disabled={!newMessage}
                >
                  Отправить сообщение
                </button>
              </Col>
            </Row>
          </CardBody>
          <LoadBlock visible={load || loadMessages}>
            <ClipLoader />
          </LoadBlock>
        </Card>
      </Col>
      <CustomModal
        isOpen={showModal}
        toggle={toggle}
        header={"Добавление файлов"}
        body={(validation) => <ModalBodyAddFiles validation={validation} />}
        validateYupSchema={validationSchema}
        inititalValues={() => initialValues(data, files)}
        cancel="Закрыть"
        confirm="Сохранить"
        confirmFunction={(values) => {
          let files = [];
          values.files.forEach((info) => {
            files.push(info.file);
          });
          console.log(files);
          setFiles(files);
        }}
      />
    </React.Fragment>
  );
}

export default Description;
