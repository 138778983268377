import React, { Fragment } from "react";
import { Col, Input, Label, Row } from "reactstrap";
import * as Yup from "yup";

import { fetchWrapper } from "@helpers/fetchwrapper";

import { SidebarData } from "@areas/shared/components/personal/SidebarData";

import "flatpickr/dist/themes/material_green.css";
import styles from "@areas/shared/components/personal/modals/styles/customModal.module.css";

const initialValues = (role) => {
  return {
    id: (role && role.Id) || null,
    name: (role && role.Name) || "",
    areas: (role && role.Areas) || [],
  };
};

const validationSchema = () => {
  return Yup.object({});
};

const confirm = (values, successFunc) => {
  let data = [];
  values.areas.forEach((element) => {
    data.push(element);
  });
  fetchWrapper.post(
    `/v1/api/admin/setuserareapermission?RoleId=${values.id}`,
    data
  ).then(successFunc);
};

function ModalBodyAdd({ validation }) {
  const getClearPath = (path) => {
    if (path) {
      if (path.charAt(0) === "/") {
        path = path.substring(1);
      }
    }
    return path;
  };
  const getCheckedArea = (areas, path) => {
    if (areas) {
      if (areas.some((perm) => perm === getClearPath(path))) {
        return true;
      }
    }

    return false;
  };

  return (
    <Row className="g-2">
      <Input
        type="hidden"
        name="id"
        defaultValue={validation.values.id || null}
      />
      <Col lg={12}>
        <div>
          <Label htmlFor="roleName-field" className={styles.label}>
            Название
          </Label>
          <Input
            name="name"
            id="roleName-field"
            className={`form-control ${styles.input}`}
            placeholder="Введите заголовок новости"
            type="text"
            value={validation.values.name || ""}
            disabled={true}
          />
        </div>
      </Col>
      <div id="checkbox-group" className={styles.label}>
        Области
      </div>
      <div
        role="group"
        className={styles.checkboxGroup}
        aria-labelledby="checkbox-group"
      >
        {SidebarData("").map((item, index) => {
          if (item.type === "link") {
            return (
              <Fragment key={index}>
                <div className="form-check">
                  <input
                    className="form-check-input"
                    type="checkbox"
                    id={item.title}
                    name="areas"
                    value={getClearPath(item.path)}
                    onChange={(e) =>
                      validation.setFieldValue(
                        "areas",
                        e.target.checked
                          ? [
                              ...validation.values.areas,
                              getClearPath(item.path),
                            ]
                          : validation.values.areas.filter(
                              (x) => x !== getClearPath(item.path)
                            )
                      )
                    }
                    onBlur={validation.handleBlur}
                    checked={
                      getCheckedArea(validation.values.areas, item.path) ||
                      false
                    }
                  />
                  <label className="form-check-label" htmlFor={item.title}>
                    {item.title}
                  </label>
                </div>
                {item.subNav &&
                  item.subNav.map((subItem, subIndex) => (
                    <div key={`${index}-${subIndex}`} className="form-check">
                      <input
                        className="form-check-input"
                        type="checkbox"
                        id={subItem.title}
                        name="areas"
                        value={getClearPath(subItem.path)}
                        onChange={(e) =>
                          validation.setFieldValue(
                            "areas",
                            e.target.checked
                              ? [
                                  ...validation.values.areas,
                                  getClearPath(subItem.path),
                                ]
                              : validation.values.areas.filter(
                                  (x) => x !== getClearPath(subItem.path)
                                )
                          )
                        }
                        onBlur={validation.handleBlur}
                        checked={
                          getCheckedArea(
                            validation.values.areas,
                            subItem.path
                          ) || false
                        }
                      />
                      <label
                        className="form-check-label"
                        htmlFor={subItem.title}
                      >
                        {`${item.title} > ${subItem.title}`}
                      </label>
                    </div>
                  ))}
              </Fragment>
            );
          }
          return null;
        })}
      </div>
    </Row>
  );
}

export { initialValues, validationSchema, confirm, ModalBodyAdd };
