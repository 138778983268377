import { useCallback, useEffect } from "react";

import { fetchWrapper } from "@helpers/fetchwrapper";
import { routes } from "@helpers/routes";

function Page() {
  const getChildrenRoutes = useCallback((items, parent) => {
    const temp = [];
    for (const route of items) {
      if (route.path !== "*" && route.path !== "personal") {
        let currentParent = "";
        if (route.path === "/" || route.path === "") {
          currentParent = parent;
          temp.push(`${parent}`);
        } else {
          if (parent === "" || parent === "/") {
            if (route.path.indexOf("/") === 0) {
              currentParent = `${route.path}`;
            } else {
              currentParent = `/${route.path}`;
            }
          } else {
            currentParent = `${parent}/${route.path}`;
          }
          temp.push(currentParent);
        }
        if (route.children != null) {
          temp.push(...getChildrenRoutes(route.children, currentParent));
        }
      }
    }

    return temp;
  }, []);
  const getRoutes = useCallback(() => {
    const temp = [];
    for (const route of routes) {
      if (route.path !== "*") {
        if (route.children != null) {
          temp.push(...getChildrenRoutes(route.children, route.path));
        } else {
          temp.push(route.path);
        }
      }
    }
    let unique = [...new Set(temp.map((item) => item))];
    return unique;
  }, [getChildrenRoutes]);

  const sendRoutes = useCallback(() => {
    let data = new FormData();
    data.append("routes", JSON.stringify(getRoutes()));
    fetchWrapper.post("/v1/api/app/sitemapGenerate", data, false);
  }, [getRoutes]);

  useEffect(() => {
    sendRoutes();
  }, [sendRoutes]);

  return <></>;
}

export default Page;
