import React, { useEffect, useState, useCallback } from "react";
import {
  Card,
  CardBody,
  CardHeader,
  UncontrolledDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
} from "reactstrap";
import ClipLoader from "react-spinners/ClipLoader";
import { TbEdit, TbSettings, TbPlus, TbTrash } from "react-icons/tb";

import { fetchWrapper } from "@helpers/fetchwrapper";

import {
  initialValues,
  validationSchema,
  confirm,
  FormBody,
} from "./ContactForm";

import ButtonDanger from "@areas/shared/components/buttons/ButtonDanger";
import LoadBlock from "@areas/shared/components/personal/loads/LoadBlock";
import CustomModal from "@areas/shared/components/personal/modals/CustomModal";
import CustomModalDelete from "@areas/shared/components/personal/modals/CustomModalDelete";

function ContactsTable({ company }) {
  const [load, setLoad] = useState(false);
  const [data, setData] = useState([]);
  const [showModal, setShowModal] = useState(false);
  const [showModalDelete, setShowModalDelete] = useState(false);
  const [headerModal, setHeaderModal] = useState("");
  const [contact, setContact] = useState(null);

  const loadData = (id) => {
    setLoad(true);
    fetchWrapper
      .get(`/v1/api/app/getContacts?id_parent=${id}&id_parent_type=1`)
      .then((res) => {
        setData(res);
        setLoad(false);
      });
  };

  useEffect(() => {
    if (company) {
      loadData(company.IdCompany);
    }
  }, [company]);

  const toggle = useCallback(
    (data) => {
      if (showModal) {
        setShowModal(false);
        setContact(null);
      } else {
        if (!data) {
          setHeaderModal("Добавление контакта");
          setContact(null);
        } else {
          setHeaderModal("Редактирование контакта");
          setContact(data);
        }
        setShowModal(true);
      }
    },
    [showModal]
  );

  const handleDelete = () => {
    if (contact) {
      fetchWrapper
        .post(`/v1/personal/deletecontact?idContact=${contact.Id}`, null, false)
        .then(() => {
          setShowModalDelete(false);
          loadData(company.IdCompany);
        });
    }
  };

  const getNameType = (id) => {
    switch (id) {
      case 1:
        return "Email";
      case 2:
        return "Телефон";
      case 3:
        return "Сайт";
      case 6:
        return "Группа ВК";
      default:
        return "Тип контакта не определён";
    }
  };

  return (
    <>
      <Card>
        <CardHeader>
          <div className="d-flex justify-content-between align-items-center">
            <h5 className="mb-0">Контакты</h5>
            <ButtonDanger
              className="ps-1 pe-1 pt-1 pb-1 d-flex"
              onClick={() => toggle()}
            >
              <TbPlus size={14} className="me-0" />
            </ButtonDanger>
          </div>
        </CardHeader>
        <CardBody style={{ height: "520px" }} className="overflow-scroll">
          <table className="w-100 table">
            <thead>
              <tr>
                <th className="w-25">Тип</th>
                <th className="w-75">Значение</th>
                <th></th>
              </tr>
            </thead>
            <tbody>
              {data &&
                data.map((contact) => {
                  if (contact.IdType !== 4) {
                    return (
                      <tr key={contact.Id}>
                        <td className="align-middle">
                          {getNameType(contact.IdType)}
                        </td>
                        <td className="align-middle">{contact.Value}</td>
                        <td>
                          <UncontrolledDropdown className="dropdown d-inline-block">
                            <DropdownToggle
                              className="btn btn-outline-secondary btn-sm d-flex justify-content-center"
                              tag="button"
                            >
                              <TbSettings size={18} />
                            </DropdownToggle>
                            <DropdownMenu className="dropdown-menu-end">
                              <DropdownItem
                                className="edit-item-btn"
                                onClick={() => toggle(contact)}
                              >
                                <TbEdit size={16} className="me-2" />
                                Изменить
                              </DropdownItem>
                              <DropdownItem
                                className="remove-item-btn"
                                onClick={() => {
                                  setContact(contact);
                                  setShowModalDelete(true);
                                }}
                              >
                                <TbTrash size={16} className="me-2" />
                                Удалить
                              </DropdownItem>
                            </DropdownMenu>
                          </UncontrolledDropdown>
                        </td>
                      </tr>
                    );
                  } else {
                    return null;
                  }
                })}
            </tbody>
          </table>
          <LoadBlock visible={load}>
            <ClipLoader />
          </LoadBlock>
        </CardBody>
      </Card>
      <CustomModal
        isOpen={showModal}
        toggle={toggle}
        header={headerModal}
        body={(validation) => <FormBody validation={validation} />}
        validateYupSchema={validationSchema}
        inititalValues={() => initialValues(company, contact)}
        cancel="Закрыть"
        confirm="Сохранить"
        confirmFunction={(values) => {
          confirm(values, loadData);
        }}
      />
      <CustomModalDelete
        isOpen={showModalDelete}
        onDeleteClick={handleDelete}
        onCloseClick={() => setShowModalDelete(false)}
      />
    </>
  );
}

export default ContactsTable;
