import React from "react";
import { useLoaderData } from "react-router-dom";

import Animation from "@areas/shared/components/chooseLayout/Animation";
import animationStyles from "@areas/shared/components/chooseLayout/styles/animations.module.css";

import About from "./blocks/About";
import Actions from "./blocks/Actions";
import Info from "./blocks/Info";
import News from "./blocks/News";
import Partners from "./blocks/Partners";

function Page() {
  const data = useLoaderData();

  return (
    <div>
      <Animation
        animationIn={animationStyles.swipeLeftIn}
        animationOut={animationStyles.swipeRightOut}
      >
        <About />
      </Animation>
      <Animation>
        <Actions />
      </Animation>
      <Animation>
        <Info />
      </Animation>
      <Animation>
        <News data={data.Pages} />
      </Animation>
      <Animation>
        <Partners />
      </Animation>
    </div>
  );
}

export default Page;
