import React, { useCallback, useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import { useSearchParams } from "react-router-dom";

import Animation from "@areas/shared/components/chooseLayout/Animation";
import animationStyles from "@areas/shared/components/chooseLayout/styles/animations.module.css";

import { metaActions } from "@store";

import Filter from "./blocks/Filter";
import Search from "./blocks/Search";
import Results from "./blocks/Results";

import { fetchWrapper } from "@helpers/fetchwrapper";

import styles from "./styles/page.module.css";

function Page(props) {
  const [searchParams, setSearchParams] = useSearchParams();
  const dispatch = useDispatch();

  const [load, setLoad] = useState(false);
  const [searchResult, setSearchResult] = useState({
    data: [],
    total: 0,
    totals: [],
  });

  const submitSearch = useCallback(
    (query) => {
      let result = {};
      result.searchFor = searchParams.get("searchFor") || "findPracticy";
      result.sortBy = searchParams.get("sortBy") || "date";
      result.query = query;
      setSearchParams(result);
    },
    [searchParams, setSearchParams]
  );

  const submitFilter = useCallback(
    (searchFor) => {
      setSearchResult({
        data: [],
        total: 0,
        totals: [],
      });
      let result = {};
      result.searchFor = searchFor;
      result.sortBy = searchParams.get("sortBy") || "date";
      result.query = searchParams.get("query") || "";
      setSearchParams(result);
    },
    [searchParams, setSearchParams]
  );

  const submitSortBy = useCallback(
    (sortBy) => {
      setSearchResult({
        data: [],
        total: 0,
        totals: [],
      });
      let result = {};
      result.searchFor = searchParams.get("searchFor") || "findPracticy";
      result.sortBy = sortBy;
      result.query = searchParams.get("query") || "";
      setSearchParams(result);
    },
    [searchParams, setSearchParams]
  );

  const search = useCallback(
    (offset) => {
      setLoad(true);
      let searchFor = searchParams.get("searchFor") || "findPracticy";
      let sortBy = searchParams.get("sortBy") || "date";
      let query = searchParams.get("query") || "";

      fetchWrapper
        .get(`/v1/api/app/${searchFor}?text=${query}&sortBy=${sortBy}&limit=6&offset=${offset}`)
        .then((res) => {
          setSearchResult({
            data: res.data,
            total: res.total,
          });
          setLoad(false);
        });
    },
    [searchParams]
  );

  useEffect(() => {
    search(0);
  }, [searchParams, search]);

  useEffect(() => {
    dispatch(
      metaActions.setData({
        icon: "/faviconEmployer.ico",
        title: "Работодателям",
        description:
          "Прямая коммуникация с учебными заведениями профессиональной подготовки Республики Башкортостан, взаимодействие с потенциальными сотрудниками: со студентами и профессионалами, ищущими работу.",
        link: [
          {
            rel: "canonical",
            href: "/company/search-categories",
          },
        ],
      })
    );
  }, [dispatch]);

  return (
    <div className="container mt-xl-5 ps-xl-0 pe-xl-0 ps-3 pe-3">
      <Animation
        animationIn={animationStyles.swipeLeftIn}
        animationOut={animationStyles.swipeRightOut}
      >
        <div className="row justify-content-center p-1">
          <div className={`col-xl-3 col-12 ${styles.block}`}>
            <Filter
              load={load}
              data={searchResult.filter}
              submit={submitFilter}
              searchFor={searchParams.get("searchFor") || "findPracticy"}
            />
          </div>
          <div className={`offset-xl-1 col-xl-8 col-12 ${styles.block}`}>
            <Search
              query={searchParams.get("query")}
              sumbit={submitSearch}
              sortBy={searchParams.get("sortBy") || "date"}
              submitSortBy={submitSortBy}
              className="mb-4"
            />
            <Results
              load={load}
              results={searchResult}
              onChangePage={search}
              searchFor={searchParams.get("searchFor")}
            />
          </div>
        </div>
      </Animation>
    </div>
  );
}

export default Page;
