import React, { useEffect, useState } from "react";
import { Col, FormFeedback, Input, Label, Row } from "reactstrap";
import * as Yup from "yup";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import Select from "react-select";
import SelectAsyncPaginate from "@areas/shared/components/select/SelectAsyncPaginate";
import { FilePond, registerPlugin } from "react-filepond";

import FilePondPluginImageExifOrientation from "filepond-plugin-image-exif-orientation";
import FilePondPluginImagePreview from "filepond-plugin-image-preview";

import { fetchWrapper } from "@helpers/fetchwrapper";

import "flatpickr/dist/themes/material_green.css";
import styles from "@areas/shared/components/personal/modals/styles/customModal.module.css";

registerPlugin(FilePondPluginImageExifOrientation, FilePondPluginImagePreview);

const initialValues = (request, company) => {
  let companyFrom = {
    value: (company && company.IdCompany) || 0,
    label: (company && company.NameCompany) || "",
  };
  let companyTo = {
    value: (request && request.ToIdCompany) || 0,
    label: (request && request.ToCompany) || "",
  };
  return {
    id: (request && request.Id) || null,
    subject: (request && request.Subject) || "",
    description: (request && request.Description) || "",
    type: (request && request.IdType) || 0,
    status: (request && request.IdStatus) || 0,
    companyFrom: companyFrom.value === 0 ? null : companyFrom,
    companyTo: companyTo.value === 0 ? null : companyTo,
    files:
      (request &&
        request.Filelinks &&
        request.Filelinks.map(
          (p) => `/v1/api/fileManager/getFile?path=${p.Link}`
        )) ||
      [],
  };
};

const validationSchema = () => {
  return Yup.object({});
};

const confirm = (values, successFunc) => {
  let data = new FormData();
  data.append("subject", values.subject);
  data.append("description", values.description);
  data.append("idType", values.type);
  data.append("idStatus", values.status);
  data.append("fromIdCompany", values.companyFrom.value);
  data.append("toIdCompany", values.companyTo.value);
  if (values.id) {
    data.append("id", values.id);
  } else {
    values.files.forEach((info) => {
      data.append("files", info.file, info.file.name);
    });
  }

  fetchWrapper
    .post(`/v1/personal/sendTicketModel`, data, false)
    .then(() => successFunc(values.companyFrom.value));
};

function ModalBodyAdd({ validation }) {
  const [types, setTypes] = useState([]);
  const [statuses, setStatuses] = useState([]);

  const loadCompaniesOptions = async (searchQuery, loadedOptions, { page }) => {
    const url = `/v1/api/app/findCompanies/?text=${searchQuery}&offset=${
      page * 10
    }&limit=10`;

    let result = await fetchWrapper.post(url, [1, 2, 3]);

    let options = result.data.map((p) => ({
      value: p.Id,
      label: p.Shortname ? p.Shortname : p.Fullname,
    }));
    return {
      options: options,
      hasMore: result.total > page * 10,
      additional: {
        page: searchQuery ? 1 : page + 1,
      },
    };
  };
  const loadTypesRequests = () => {
    fetchWrapper.get("/v1/personal/getTicketTypes").then((res) => {
      setTypes(res.map((r) => ({ label: r.Name, value: r.Id })));
    });
  };

  const loadStatusesRequests = () => {
    fetchWrapper.get("/v1/personal/getTicketStatuses").then((res) => {
      setStatuses(res.map((r) => ({ label: r.Name, value: r.Id })));
    });
  };

  useEffect(() => {
    loadTypesRequests();
    loadStatusesRequests();
  }, []);

  return (
    <Row className="g-2">
      <Input
        type="hidden"
        name="id"
        defaultValue={validation.values.id || null}
      />
      <Col sm={12}>
        <div>
          <Label htmlFor="requestSubject-field" className={styles.label}>
            Тема
          </Label>
          <Input
            name="subject"
            id="requestSubject-field"
            className={`form-control ${styles.input}`}
            placeholder="Введите тему"
            type="text"
            value={validation.values.subject || ""}
            onChange={validation.handleChange}
            onBlur={validation.handleBlur}
            invalid={
              validation.touched.subject && validation.errors.subject
                ? true
                : false
            }
          />
        </div>
      </Col>
      <Col lg={12}>
        <div>
          <Label htmlFor="requestDescription-field" className={styles.label}>
            Описание
          </Label>
          <CKEditor
            name="description"
            id="requestDescription-field"
            editor={ClassicEditor}
            placeholder="Введите описание"
            data={validation.values.description || ""}
            onChange={(event, editor) => {
              const data = editor.getData();
              validation.setFieldValue("description", data);
            }}
            onBlur={() => validation.setFieldTouched("description")}
          />
          {validation.touched.description && validation.errors.description ? (
            <FormFeedback type="invalid" className={styles.invalid}>
              {validation.errors.description}
            </FormFeedback>
          ) : null}
        </div>
      </Col>
      {validation.values.id ? null : (
        <Col lg={12}>
          <FilePond
            files={validation.values.files || []}
            onupdatefiles={(e) => {
              validation.setFieldValue("files", e);
            }}
            name="files"
            allowMultiple={true}
            className="filepond filepond-input-multiple"
            labelIdle="Перетащите или нажмите, чтобы загрузить"
            onaddfile={() => validation.setFieldTouched("files")}
          />
        </Col>
      )}
      <Col sm={6}>
        <div>
          <Label htmlFor="requestType-field" className={styles.label}>
            Тип
          </Label>
          <Select
            name="type"
            id="requestType-field"
            value={
              validation.values.type
                ? types.filter((p) => p.value === validation.values.type)
                : types[0]
            }
            options={types}
            placeholder="Выберите тип"
            onChange={(e) => {
              validation.setFieldValue("type", e.value);
            }}
            onBlur={() => validation.setFieldTouched("type")}
          />
        </div>
      </Col>
      <Col sm={6}>
        <div>
          <Label htmlFor="requestStatus-field" className={styles.label}>
            Статус
          </Label>
          <Select
            name="status"
            id="requestStatus-field"
            value={
              validation.values.status
                ? statuses.filter((p) => p.value === validation.values.status)
                : statuses[0]
            }
            options={statuses}
            placeholder="Выберите статус"
            onChange={(e) => {
              validation.setFieldValue("status", e.value);
            }}
            onBlur={() => validation.setFieldTouched("status")}
          />
        </div>
      </Col>
      <Col sm={12}>
        <div>
          <Label htmlFor="requestCompanyTo-field" className={styles.label}>
            Компания
          </Label>
          <SelectAsyncPaginate
            name="companyTo"
            id="requestCompanyTo-field"
            loadFunc={loadCompaniesOptions}
            labelFunc={(e) => e.Shortname ? e.Shortname : e.Fullname}
            valueFunc={(e) => e.Id}
            value={validation.values.companyTo || null}
            placeholder="Выберите компанию"
            onChangeValue={(e) => {
              validation.setFieldValue("companyTo", e);
            }}
            onBlurInput={() => validation.setFieldTouched("companyTo")}
          />
        </div>
      </Col>
    </Row>
  );
}

export { initialValues, validationSchema, confirm, ModalBodyAdd };
