import React from "react";
import { Col, FormFeedback, Input, Label, Row } from "reactstrap";
import * as Yup from "yup";

import { fetchWrapper } from "@helpers/fetchwrapper";

import stylesModal from "@areas/shared/components/personal/modals/styles/customModal.module.css";

const initialValues = () => {
  return {
    name: null,
    message: null,
    contact: null,
  };
};

const validationSchema = () => {
  return Yup.object({
    name: Yup.string().required("Значение обязателено"),
    message: Yup.string().required("Значение обязателено"),
    contact: Yup.string().required("Значение обязателено"),
  });
};

const confirm = (values, successFunc) => {
  let data = new FormData();
  data.append("name", values.name);
  data.append("message", values.message);
  data.append("contact", values.contact);

  fetchWrapper
    .post(`/v1/api/app/insertBugsAndSuggestions`, data, false)
    .then(() => {
      successFunc();
    });
};

function FormBody({ validation }) {
  return (
    <Row className="g-2">
      <Col lg={12}>
        <div>
          <Label htmlFor="feedbackName-field" className={stylesModal.label}>
            Ваше имя
          </Label>
          <Input
            name="name"
            id="feedbackName-field"
            className={`form-control ${stylesModal.input}`}
            placeholder="Введите имя"
            type="text"
            value={validation.values.name || ""}
            onChange={validation.handleChange}
            onBlur={validation.handleBlur}
            invalid={
              validation.touched.name && validation.errors.name ? true : false
            }
          />
          {validation.touched.name && validation.errors.name ? (
            <FormFeedback type="invalid" className={stylesModal.invalid}>
              {validation.errors.name}
            </FormFeedback>
          ) : null}
        </div>
      </Col>{" "}
      <Col lg={12}>
        <div>
          <Label htmlFor="feedbackMessage-field" className={stylesModal.label}>
            Сообщение
          </Label>
          <Input
            name="message"
            id="feedbackMessage-field"
            className={`form-control ${stylesModal.input}`}
            placeholder="Введите сообщение"
            type="textarea"
            value={validation.values.message || ""}
            onChange={validation.handleChange}
            onBlur={validation.handleBlur}
            invalid={
              validation.touched.message && validation.errors.message
                ? true
                : false
            }
            style={{ height: 110, resize: "none" }}
          />
          {validation.touched.message && validation.errors.message ? (
            <FormFeedback type="invalid" className={stylesModal.invalid}>
              {validation.errors.message}
            </FormFeedback>
          ) : null}
        </div>
      </Col>
      <Col lg={12}>
        <div>
          <Label htmlFor="feedbackContact-field" className={stylesModal.label}>
            Контактные данные
          </Label>
          <Input
            name="contact"
            id="feedbackContact-field"
            className={`form-control ${stylesModal.input}`}
            placeholder="Введите номер телефона или email"
            type="text"
            value={validation.values.contact || ""}
            onChange={validation.handleChange}
            onBlur={validation.handleBlur}
            invalid={
              validation.touched.contact && validation.errors.contact
                ? true
                : false
            }
          />
          {validation.touched.contact && validation.errors.contact ? (
            <FormFeedback type="invalid" className={stylesModal.invalid}>
              {validation.errors.contact}
            </FormFeedback>
          ) : null}
        </div>
      </Col>
    </Row>
  );
}

export { initialValues, validationSchema, confirm, FormBody };
