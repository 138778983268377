import React, { useState } from "react";
import {
  Card,
  CardBody,
  Col,
  Input,
  Nav,
  NavItem,
  NavLink,
  Row,
  TabContent,
  Table,
  TabPane,
} from "reactstrap";
import {
  TbActivity,
  TbBook,
  TbEdit,
  TbSchool,
  TbUpload,
  TbViewfinder,
} from "react-icons/tb";
import classnames from "classnames";

import ButtonDanger from "@areas/shared/components/buttons/ButtonDanger";
import ButtonSuccess from "@areas/shared/components/buttons/ButtonSuccess";

import styles from "./styles/content.module.css";

function Content({ user }) {
  const [activeTab, setActiveTab] = useState("1");

  const toggleTab = (tab) => {
    if (activeTab !== tab) {
      setActiveTab(tab);
    }
  };

  return (
    <div className={styles.block}>
      <Row>
        <Col lg={12}>
          <div>
            <div className="d-flex">
              <Nav
                pills
                className="animation-nav profile-nav gap-2 gap-lg-3 flex-grow-1"
                role="tablist"
              >
                <NavItem>
                  <NavLink
                    href="#overview-tab"
                    className={classnames({ active: activeTab === "1" })}
                    onClick={() => {
                      toggleTab("1");
                    }}
                  >
                    <TbViewfinder
                      size={20}
                      className="d-inline-block d-md-none"
                    />
                    <span className="d-none d-md-inline-block">
                      Предпросмотр
                    </span>
                  </NavLink>
                </NavItem>
                <NavItem>
                  <NavLink
                    href="#activities"
                    className={classnames({ active: activeTab === "2" })}
                    onClick={() => {
                      toggleTab("2");
                    }}
                  >
                    <TbActivity
                      size={20}
                      className="d-inline-block d-md-none"
                    />
                    <span className="d-none d-md-inline-block">Активности</span>
                  </NavLink>
                </NavItem>
                <NavItem>
                  <NavLink
                    href="#documents"
                    className={classnames({ active: activeTab === "3" })}
                    onClick={() => {
                      toggleTab("3");
                    }}
                  >
                    <TbBook size={20} className="d-inline-block d-md-none" />
                    <span className="d-none d-md-inline-block">Документы</span>
                  </NavLink>
                </NavItem>
              </Nav>
              <div className="flex-shrink-0 f_hidden">
                <ButtonSuccess>
                  <TbEdit size={15} className="d-inline-block" />
                  <span className="d-none d-md-inline-block">
                    Изменить профиль
                  </span>
                </ButtonSuccess>
              </div>
            </div>

            <TabContent activeTab={activeTab} className="pt-4">
              <TabPane tabId="1">
                <Row>
                  <Col xxl={4}>
                    <Card>
                      <CardBody>
                        <h5 className="card-title mb-3">Информация</h5>
                        <div className="table-responsive">
                          <Table className="table-borderless mb-0">
                            <tbody>
                              <tr>
                                <th className="ps-0" scope="row">
                                  Полное имя :
                                </th>
                                <td className="text-muted">{`${user.Lastname} ${user.Firstname}`}</td>
                              </tr>
                              <tr>
                                <th className="ps-0" scope="row">
                                  E-mail :
                                </th>
                                <td className="text-muted">{user.Email}</td>
                              </tr>
                            </tbody>
                          </Table>
                        </div>
                      </CardBody>
                    </Card>
                  </Col>
                  <Col xxl={8}>
                    <Card>
                      <CardBody>
                        <h5 className="card-title mb-3">Обо мне</h5>
                        <p></p>
                        <Row>
                          <Col xs={6} md={4}>
                            <div className="d-flex mt-4">
                              <div className="flex-shrink-0 avatar-xs align-self-center me-3">
                                <div className="avatar-title bg-light rounded-circle fs-16 text-primary p-2">
                                  <TbSchool size={25} />
                                </div>
                              </div>
                              <div className="flex-grow-1 overflow-hidden">
                                <p className="mb-1">Профессия :</p>
                                <h6 className="text-truncate mb-0">
                                  
                                </h6>
                              </div>
                            </div>
                          </Col>
                        </Row>
                      </CardBody>
                    </Card>
                  </Col>
                </Row>
              </TabPane>
              <TabPane tabId="2">
                <Card>
                  <CardBody>
                    <h5 className="card-title mb-3">Активности</h5>
                  </CardBody>
                </Card>
              </TabPane>
              <TabPane tabId="3">
                <Card>
                  <CardBody>
                    <div className="d-flex align-items-center mb-4">
                      <h5 className="card-title flex-grow-1 mb-0">Документы</h5>
                      <div className="flex-shrink-0">
                        <Input
                          className="form-control d-none"
                          type="file"
                          id="formFile"
                        />
                        <ButtonDanger>
                          <TbUpload size={15} />
                          <span>Загрузить файл</span>
                        </ButtonDanger>
                      </div>
                    </div>
                    <Row>
                      <Col lg={12}>
                        <div className="table-responsive">
                          <Table className="table-borderless align-middle mb-0">
                            <thead className="table-light">
                              <tr>
                                <th scope="col">Имя файла</th>
                                <th scope="col">Тип</th>
                                <th scope="col">Размер</th>
                                <th scope="col">Дата загрузки</th>
                                <th scope="col">Действия</th>
                              </tr>
                            </thead>
                            <tbody></tbody>
                          </Table>
                        </div>
                      </Col>
                    </Row>
                  </CardBody>
                </Card>
              </TabPane>
            </TabContent>
          </div>
        </Col>
      </Row>
    </div>
  );
}

export default Content;
