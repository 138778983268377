import { configureStore } from "@reduxjs/toolkit";

import { layoutReducer } from "./layout.slice";
import { authReducer } from "./auth.slice";
import { metaReducer } from "./meta.slice";
import { offerMentoringReducer } from "./offerMentoring.slice";
import { findInternsReducer } from "./findInterns.slice";

export * from "./layout.slice";
export * from "./auth.slice";
export * from "./meta.slice";
export * from "./offerMentoring.slice";
export * from "./findInterns.slice";

export const store = configureStore({
  reducer: {
    layout: layoutReducer,
    auth: authReducer,
    meta: metaReducer,
    offerMentoring: offerMentoringReducer,
    findInterns: findInternsReducer,
  },
});
