import React from "react";

import styles from "./styles/textFilter.module.css";

function TextFilter({ children, customStyles }) {
  return (
    <div
      className={`${styles.container} ${
        customStyles ? (customStyles.all ? customStyles.all : null) : null
      }`}
    >
      <div
        className={`${styles.text} 
        ${
          customStyles ? (customStyles.text ? customStyles.text : null) : null
        }`}
      >
        {children}
      </div>
      <div
        className={`${styles.filter} 
        ${
          customStyles
            ? customStyles.filter
              ? customStyles.filter
              : null
            : null
        }`}
      >
        {children}
      </div>
    </div>
  );
}

export default TextFilter;
