import React, { useEffect, useState, useCallback } from "react";
import { Link } from "react-router-dom";

import { fetchWrapper } from "@helpers/fetchwrapper";

import CustomModal from "@areas/shared/components/modals/CustomModal";
import CustomModalInfo from "@areas/shared/components/modals/CustomModalInfo";

import {
  initialValues,
  validationSchema,
  confirm,
  FormBody,
} from "./FeedbackForm";

import { FormBody as FormBodyInfo } from "./FeedbackFormInfo";

import telegramLogo from "@content/img/shared/layout/svg/telegram-logo.svg";
import whatsappLogo from "@content/img/shared/layout/svg/whatsapp-logo.svg";
import vkLogo from "@content/img/shared/layout/svg/vk-logo.svg";
import bugIcon from "@content/img/shared/layout/svg/bug.svg";

import styles from "./styles/footer.module.css";

function Footer() {
  const [data, setData] = useState(null);
  const [showModal, setShowModal] = useState(false);
  const [showModalInfo, setShowModalInfo] = useState(false);

  const loadData = useCallback(() => {
    fetchWrapper.get("/data/footer.json").then((res) => {
      setData(res);
    });
  }, []);

  const toggle = useCallback(() => {
    if (showModal) {
      setShowModal(false);
    } else {
      setShowModal(true);
    }
  }, [showModal]);

  const getIcon = (name) => {
    switch (name) {
      case "telegram":
        return telegramLogo;
      case "whatsapp":
        return whatsappLogo;
      case "vk":
        return vkLogo;
      default:
        return telegramLogo;
    }
  };

  useEffect(() => {
    loadData();
  }, [loadData]);

  return (
    <footer className={`border-top mb-3 pt-3 ${styles.block}`}>
      {data ? (
        <div className="container d-flex justify-content-between align-items-center">
          <div className="d-flex flex-column align-items-center ms-2">
            <img
              src={data.byIcon}
              width={150}
              alt="logo"
              className="mb-2 mt-2"
            />
            <p className={`${styles.copyright}`}>
              Copyright &copy; {new Date().getFullYear()}
            </p>
            <div className={`${styles.feedback}`} onClick={toggle}>
              <span>Обратная связь</span>
              <img src={bugIcon} alt="Обратная связь" />
            </div>
          </div>
          <div className={styles.contacts}>
            {data.social.map((item, index) => (
              <div
                key={index}
                className={`d-flex justify-content-end align-items-center ${styles.social}`}
              >
                <Link to={item.link} target="_blank" className={styles.link}>
                  {item.title}
                </Link>
                <img
                  width={item.iconWidth}
                  src={getIcon(item.icon)}
                  alt={item.title}
                />
              </div>
            ))}
          </div>
        </div>
      ) : null}
      <CustomModal
        isOpen={showModal}
        toggle={toggle}
        header={"Обратная связь"}
        body={(validation) => <FormBody validation={validation} />}
        validateYupSchema={validationSchema}
        inititalValues={() => initialValues()}
        cancel="Закрыть"
        confirm="Отправить"
        confirmFunction={(values) => {
          confirm(values, () => setShowModalInfo(true));
        }}
        size="md"
      />
      <CustomModalInfo
        isOpen={showModalInfo}
        toggle={() => setShowModalInfo(false)}
        header={"Уведомление"}
        body={() => <FormBodyInfo />}
        cancel="Закрыть"
        size="md"
      />
    </footer>
  );
}

export default Footer;
