import React from "react";
import { Col, FormFeedback, Input, Label, Row } from "reactstrap";
import * as Yup from "yup";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";

import { fetchWrapper } from "@helpers/fetchwrapper";

import stylesModal from "@areas/shared/components/personal/modals/styles/customModal.module.css";

const initialValues = (page) => {
  return {
    id: (page && page.Id) || null,
    body: (page && page.Body) || null,
  };
};

const validationSchema = () => {
  return Yup.object({
    body: Yup.string().required("Текст обязателен"),
  });
};

const confirm = (values, successFunc) => {
  successFunc(true);
  let data = new FormData();
  data.append("idType", 11);
  data.append("title", "О нас");
  data.append("description", "Страница о нас");
  data.append("body", values.body);
  data.append("slug", "about-us");

  if (values.id) {
    data.append("id", values.id);
    fetchWrapper
      .post(`/v1/api/admin/updatepage`, data, false)
      .then(() => successFunc(false));
  } else {
    fetchWrapper
      .post(`/v1/api/admin/insertpage`, data, false)
      .then(() => successFunc(false));
  }
};

function FormBody({ validation, load }) {
  return (
    <Row className="g-2">
      <Input
        type="hidden"
        name="id"
        defaultValue={validation.values.id || null}
      />
      <Col lg={12}>
        <div>
          <Label
            htmlFor="career-centerBody-field"
            className={stylesModal.label}
          >
            Контент
          </Label>
          <CKEditor
            name="body"
            id="career-centerBody-field"
            editor={ClassicEditor}
            placeholder="Введите текст страницы о нас"
            data={validation.values.body || ""}
            onChange={(event, editor) => {
              const data = editor.getData();
              validation.setFieldValue("body", data);
            }}
            onBlur={() => validation.setFieldTouched("body")}
          />
          {validation.touched.body && validation.errors.body ? (
            <FormFeedback type="invalid" className={stylesModal.invalid}>
              {validation.errors.body}
            </FormFeedback>
          ) : null}
        </div>
      </Col>
    </Row>
  );
}

export { initialValues, validationSchema, confirm, FormBody };
