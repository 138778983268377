import React, { useState } from "react";
import ClipLoader from "react-spinners/ClipLoader";

import Pagination from "@areas/shared/components/paginations/Pagination";

import { ReactComponent as ArrowIcon } from "@content/img/shared/home/svg/arrow-icon.svg";
import defaultLogo from "@content/img/shared/education/png/default-logo.png";
import checked from "@content/img/svg/checked.svg";

import styles from "./styles/results.module.css";
import paginationStyles from "@areas/employer/components/pagination/styles/pagination.module.css";
import { Link } from "react-router-dom";

function Results({ selected, setSelected, load, results, onChangePage }) {
  const [showSelected, setShowSelected] = useState(false);
  const getSpecialities = (company) => {
    let res = [];
    company.Faculty.forEach((faculty, index) => {
      if (index < 4) {
        res.push(faculty);
      }
    });
    return res;
  };

  return (
    <div className={styles.info}>
      {load ? (
        <div className="d-flex w-100 justify-content-center mt-4">
          <ClipLoader />
        </div>
      ) : (
        <>
          <div className={styles.totals}>
            <span>Выбрано {selected.length}</span>
            <div className={styles.actions}>
              <span
                className={styles.show}
                onClick={() => setShowSelected(!showSelected)}
              >
                {showSelected ? "Показать всё" : "Показать выбранное"}
              </span>
              <span className={styles.clear} onClick={() => setSelected([])}>
                Очистить
              </span>
            </div>
          </div>
          <div className={styles.results}>
            {showSelected
              ? selected.map((item) => (
                  <div key={item.Id} className={styles.result}>
                    <div className={styles.card}>
                      <div className={styles.city}>{item.Address}</div>
                      <div className={styles.info}>
                        <div className={styles.about}>
                          <div
                            className={`${styles.select}${
                              selected.find((p) => p.Id === item.Id)
                                ? ` ${styles.selected}`
                                : ""
                            }`}
                            onClick={() => {
                              if (!selected.find((p) => p.Id === item.Id)) {
                                setSelected([...selected, item]);
                              } else {
                                setSelected(
                                  selected.filter((i) => i.Id !== item.Id)
                                );
                              }
                            }}
                          >
                            <img src={checked} alt="Выбрано" />
                          </div>
                          <img src={defaultLogo} alt="Результат" />
                          <div className={styles.title}>
                            {item.Shortname ? item.Shortname : item.Fullname}
                          </div>
                        </div>
                        <Link
                          to={`/obrazovanie-v-bashkortostane/${
                            item.IdType === 2 ? "vuzy-rb" : "kolledzhi-rb"
                          }/${item.Id}`}
                          className={styles.toCompany}
                        >
                          Перейти к учебному заведению
                          <ArrowIcon />
                        </Link>
                      </div>
                      <div className={styles.specialities}>
                        {item.Faculty.length > 0 ? (
                          <div className={styles.title}>
                            <span>
                              {item.Faculty[0].Name
                                ? item.Faculty[0].Name
                                : item.Faculty[0].Title}
                            </span>
                            <span>и</span>
                            <span className={styles.colored}>
                              еще {item.Faculty.length - 1} специальностей
                            </span>
                          </div>
                        ) : (
                          "Нет специальностей"
                        )}
                        {getSpecialities(item).map((faculty) => (
                          <div key={faculty.Id} className={styles.speciality}>
                            <div className={styles.name}>
                              {faculty.Name ? faculty.Name : faculty.Title}
                            </div>
                            <div className={styles.props}>
                              <div className={styles.prop}>
                                <span className={styles.name}>
                                  Кол-во бюдж. мест
                                </span>
                                <span className={styles.value}>
                                  {faculty.BudgetPlaces}
                                </span>
                                <div className={styles.separator}></div>
                              </div>
                              <div className={styles.prop}>
                                <span className={styles.name}>
                                  Кол-во плат. мест
                                </span>
                                <span className={styles.value}>
                                  {faculty.PaidPlaces}
                                </span>
                              </div>
                              <Link
                                to={`/obrazovanie-v-bashkortostane/${
                                  item.IdType === 2 ? "vuzy-rb" : "kolledzhi-rb"
                                }/${item.Id}/speciality/${faculty.slug}`}
                                className={styles.go}
                              >
                                <ArrowIcon />
                              </Link>
                            </div>
                          </div>
                        ))}
                      </div>
                      <Link
                        to={`/obrazovanie-v-bashkortostane/${
                          item.IdType === 2 ? "vuzy-rb" : "kolledzhi-rb"
                        }/${item.Slug}`}
                        className={styles.more}
                      >
                        <div className={styles.all}>Посмотреть все</div>
                      </Link>
                    </div>
                  </div>
                ))
              : results.data.map((item) => (
                  <div key={item.Id} className={styles.result}>
                    <div className={styles.card}>
                      <div className={styles.city}>{item.Address}</div>
                      <div className={styles.info}>
                        <div className={styles.about}>
                          <div
                            className={`${styles.select}${
                              selected.find((p) => p.Id === item.Id)
                                ? ` ${styles.selected}`
                                : ""
                            }`}
                            onClick={() => {
                              if (!selected.find((p) => p.Id === item.Id)) {
                                setSelected([...selected, item]);
                              } else {
                                setSelected(
                                  selected.filter((i) => i.Id !== item.Id)
                                );
                              }
                            }}
                          >
                            <img src={checked} alt="Выбрано" />
                          </div>
                          <img src={defaultLogo} alt="Результат" />
                          <div className={styles.title}>
                            {item.Shortname ? item.Shortname : item.Fullname}
                          </div>
                        </div>
                        <Link
                          to={`/obrazovanie-v-bashkortostane/${
                            item.IdType === 2 ? "vuzy-rb" : "kolledzhi-rb"
                          }/${item.Id}`}
                          className={styles.toCompany}
                        >
                          Перейти к учебному заведению
                          <ArrowIcon />
                        </Link>
                      </div>
                      <div className={styles.specialities}>
                        {item.Faculty.length > 0 ? (
                          <div className={styles.title}>
                            <span>
                              {item.Faculty[0].Name
                                ? item.Faculty[0].Name
                                : item.Faculty[0].Title}
                            </span>
                            <span>и</span>
                            <span className={styles.colored}>
                              еще {item.Faculty.length - 1} специальностей
                            </span>
                          </div>
                        ) : (
                          "Нет специальностей"
                        )}
                        {getSpecialities(item).map((faculty) => (
                          <div key={faculty.Id} className={styles.speciality}>
                            <div className={styles.name}>
                              {faculty.Name ? faculty.Name : faculty.Title}
                            </div>
                            <div className={styles.props}>
                              <div className={styles.prop}>
                                <span className={styles.name}>
                                  Кол-во бюдж. мест
                                </span>
                                <span className={styles.value}>
                                  {faculty.BudgetPlaces}
                                </span>
                                <div className={styles.separator}></div>
                              </div>
                              <div className={styles.prop}>
                                <span className={styles.name}>
                                  Кол-во плат. мест
                                </span>
                                <span className={styles.value}>
                                  {faculty.PaidPlaces}
                                </span>
                              </div>
                              <Link
                                to={`/obrazovanie-v-bashkortostane/${
                                  item.IdType === 2 ? "vuzy-rb" : "kolledzhi-rb"
                                }/${item.Id}/speciality/${faculty.slug}`}
                                className={styles.go}
                              >
                                <ArrowIcon />
                              </Link>
                            </div>
                          </div>
                        ))}
                      </div>
                      <Link
                        to={`/obrazovanie-v-bashkortostane/${
                          item.IdType === 2 ? "vuzy-rb" : "kolledzhi-rb"
                        }/${item.Slug}`}
                        className={styles.more}
                      >
                        <div className={styles.all}>Посмотреть все</div>
                      </Link>
                    </div>
                  </div>
                ))}
          </div>
        </>
      )}
      {showSelected ? null : (
        <Pagination
          load={load}
          itemsPerPage={2}
          totalItems={results.total}
          onChangePage={onChangePage}
          customStyles={{
            prev: paginationStyles.prev,
            current: paginationStyles.current,
            next: paginationStyles.next,
          }}
        />
      )}
    </div>
  );
}

export default Results;
