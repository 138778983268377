import React from "react";
import { useSelector } from "react-redux";

import Animation from "@areas/shared/components/chooseLayout/Animation";
import animationStyles from "@areas/shared/components/chooseLayout/styles/animations.module.css";

import Header from "./blocks/Header";
import Content from "./blocks/Content";

function Frame(props) {
  const authUser = useSelector((x) => x.auth.user);

  return (
    <div>
      <Animation
        animationIn={animationStyles.fadeIn}
        animationOut={animationStyles.fadeOut}
      >
        <Header user={authUser} />
        <Content user={authUser} />
      </Animation>
    </div>
  );
}

export default Frame;
