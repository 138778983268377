import React, { useEffect, useState } from "react";
import { useSearchParams } from "react-router-dom";
import { Row } from "reactstrap";
import moment from "moment";

import Animation from "@areas/shared/components/chooseLayout/Animation";
import animationStyles from "@areas/shared/components/chooseLayout/styles/animations.module.css";

import Section from "./blocks/Section";
import Description from "./blocks/Description";
import Details from "./blocks/Details";

import { fetchWrapper } from "@helpers/fetchwrapper";

function Frame(props) {
  const [searchParams] = useSearchParams();
  const [load, setLoad] = useState(false);
  const [data, setData] = useState({});

  const loadData = (id) => {
    setLoad(true);
    fetchWrapper
      .get(`/v1/personal/getTicketById?idTicket=${id}`)
      .then((res) => {
        switch (res.IdType) {
          case 2:
            fetchWrapper
              .get(`/v1/personal/getPracticeByTicket?idTicket=${id}`)
              .then((practice) => {
                if (practice) {
                  fetchWrapper
                    .get(`/v1/api/app/getOkved2ById/${practice.IdOkved2}`)
                    .then((okved) => {
                      let info = "<br/>";
                      info += `<b>Информация о практике</b>`;
                      info += `<div>Название: ${practice.Name}</div>`;
                      info += `<div>Адрес: ${practice.Address}</div>`;
                      info += `<div>Дата проведения: ${moment(
                        practice.DatePo
                      ).format("DD.MM.YYYY")} - ${moment(
                        practice.DateS1
                      ).format("DD.MM.YYYY")}</div>`;
                      info += `<div>Отрасль: ${okved ? okved.Name : ""}</div>`;
                      info += `<div>Для учащихся: ${practice.For} ${practice.Course} курс(а)</div>`;
                      info += `<div>Занятость: ${practice.WorkSchedule}</div>`;
                      info += `<div>Оплачивается: ${
                        practice.IsPaid ? "Да" : "Нет"
                      }</div>`;
                      info += `<div>Заработная плата: ${practice.Wage} рублей в месяц</div>`;
                      info += `<div>Навыки: ${practice.Skills}</div>`;
                      info += `<div>Задачи: ${practice.Tasks}</div>`;
                      info += `<div>Условия: ${practice.Terms}</div>`;
                      if (res.Description !== null) {
                        res.Description += info;
                      } else {
                        res.Description = info;
                      }
                      setData(res);
                      setLoad(false);
                    });
                } else {
                  setData(res);
                  setLoad(false);
                }
              });
            break;
          case 3:
            fetchWrapper
              .get(`/v1/personal/getMentorshipByTicket?idTicket=${id}`)
              .then((mentorship) => {
                if (mentorship) {
                  let info = "<br/>";
                  info += `<b>Информация о наставнике</b>`;
                  info += `<div>ФИО: ${mentorship.Fio}</div>`;
                  info += `<div>Должность: ${mentorship.JobTitle}</div>`;
                  info += `<div>Описание: ${mentorship.Description}</div>`;
                  if (res.Description !== null) {
                    res.Description += info;
                  } else {
                    res.Description = info;
                  }
                }
                setData(res);
                setLoad(false);
              });
            break;
          default:
            setData(res);
            setLoad(false);
            break;
        }
      });
  };

  useEffect(() => {
    let id = searchParams.get("id");
    if (id) {
      loadData(id);
    }
  }, [searchParams]);

  return (
    <div>
      <Animation
        animationIn={animationStyles.fadeIn}
        animationOut={animationStyles.fadeOut}
      >
        <Row>
          <Section load={load} data={data} />
        </Row>
        <Row>
          <Description load={load} data={data} refreshData={loadData} />
          <Details
            load={load}
            data={data}
            refreshData={loadData}
            list={searchParams.get("list")}
          />
        </Row>
      </Animation>
    </div>
  );
}

export default Frame;
