import React from "react";
import { Col, FormFeedback, Input, Label, Row } from "reactstrap";
import * as Yup from "yup";

import { fetchWrapper } from "@helpers/fetchwrapper";

import SelectAsyncPaginate from "@areas/shared/components/select/SelectAsyncPaginate";

import styles from "@areas/shared/components/personal/modals/styles/customModal.module.css";

const initialValues = (mentorship, company) => {
  return {
    id: (mentorship && mentorship.Id) || null,
    fio: (mentorship && mentorship.Fio) || null,
    jobTitle: (mentorship && mentorship.JobTitle) || null,
    description: (mentorship && mentorship.Description) || null,
    industry: mentorship
      ? {
          value: mentorship.IdOkved2,
          label: mentorship.Area,
        }
      : null,
    public: (mentorship && mentorship.IsPublic) || false,
    idCompany: (company && company.IdCompany) || 0,
  };
};

const validationSchema = () => {
  return Yup.object({
    fio: Yup.string().required("Значение обязателено"),
    jobTitle: Yup.string().required("Значение обязателено"),
    description: Yup.string().required("Значение обязателено"),
    industry: Yup.object().required("Значение обязателено"),
  });
};

const confirm = (values, successFunc) => {
  let data = new FormData();

  let id = values.id;

  data.append("fio", values.fio);
  data.append("jobTitle", values.jobTitle);
  data.append("description", values.description);
  data.append("idOkved2", values.industry.value);
  data.append("isPublic", values.public);
  data.append("idCompany", values.idCompany);

  if (id) {
    data.append("id", id);
    fetchWrapper.post(`/v1/personal/updateMentorship`, data, false).then(() => {
      successFunc();
    });
  } else {
    fetchWrapper.post(`/v1/personal/insertMentorship`, data, false).then(() => {
      successFunc();
    });
  }
};

const loadIndustryOptions = async (searchQuery, loadedOptions, { page }) => {
  const url = `/v1/api/app/getOkved2/?text=${searchQuery}&offset=${
    page * 10
  }&limit=10`;

  let result = await fetchWrapper.get(url);

  let options = result.data.map((p) => ({
    value: p.Id,
    label: p.Name,
  }));
  return {
    options: options,
    hasMore: result.total > page * 10,
    additional: {
      page: searchQuery ? 1 : page + 1,
    },
  };
};

function ModalBodyAdd({ validation }) {
  return (
    <Row className="g-2">
      <Input
        type="hidden"
        name="id"
        defaultValue={validation.values.id || null}
      />
      <Input
        type="hidden"
        name="idCompany"
        defaultValue={validation.values.idCompany || null}
      />
      <Col lg={12}>
        <div>
          <Label htmlFor="mentorshipFio-field" className={styles.label}>
            ФИО
          </Label>
          <Input
            name="fio"
            id="mentorshipFio-field"
            className={`form-control ${styles.input}`}
            placeholder="Введите ФИО контактного лица"
            type="text"
            value={validation.values.fio || ""}
            onChange={validation.handleChange}
            onBlur={validation.handleBlur}
            invalid={
              validation.touched.fio && validation.errors.fio ? true : false
            }
          />
          {validation.touched.fio && validation.errors.fio ? (
            <FormFeedback type="invalid" className={styles.invalid}>
              {validation.errors.fio}
            </FormFeedback>
          ) : null}
        </div>
      </Col>
      <Col lg={12}>
        <div>
          <Label htmlFor="mentorshipJobTitle-field" className={styles.label}>
            Должность
          </Label>
          <Input
            name="jobTitle"
            id="mentorshipJobTitle-field"
            className={`form-control ${styles.input}`}
            placeholder="Введите должность контактного лица"
            type="text"
            value={validation.values.jobTitle || ""}
            onChange={validation.handleChange}
            onBlur={validation.handleBlur}
            invalid={
              validation.touched.jobTitle && validation.errors.jobTitle
                ? true
                : false
            }
          />
          {validation.touched.jobTitle && validation.errors.jobTitle ? (
            <FormFeedback type="invalid" className={styles.invalid}>
              {validation.errors.jobTitle}
            </FormFeedback>
          ) : null}
        </div>
      </Col>
      <Col lg={12}>
        <div>
          <Label htmlFor="mentorshipDescription-field" className={styles.label}>
            Описание
          </Label>
          <Input
            name="description"
            id="mentorshipDescription-field"
            className={`form-control ${styles.input}`}
            placeholder="Опишите кратко свое предложение о наставничестве"
            type="textarea"
            value={validation.values.description || ""}
            onChange={validation.handleChange}
            onBlur={validation.handleBlur}
            invalid={
              validation.touched.description && validation.errors.description
                ? true
                : false
            }
            style={{ height: 60, resize: "none" }}
          />
          {validation.touched.description && validation.errors.description ? (
            <FormFeedback type="invalid" className={styles.invalid}>
              {validation.errors.description}
            </FormFeedback>
          ) : null}
        </div>
      </Col>
      <Col sm={12}>
        <div>
          <Label htmlFor="practiceIndustry-field" className={styles.label}>
            Направление
          </Label>
          <SelectAsyncPaginate
            name="industry"
            id="practiceIndustry-field"
            isMulti={false}
            loadFunc={loadIndustryOptions}
            labelFunc={(e) => e.Name}
            valueFunc={(e) => e.Id}
            value={validation.values.industry || null}
            placeholder="Выберите направление"
            onChangeValue={(e) => {
              validation.setFieldValue("industry", e);
            }}
            onBlurInput={() => validation.setFieldTouched("industry")}
          />
          {validation.touched.industry && validation.errors.industry ? (
            <FormFeedback type="invalid" className={styles.invalid}>
              {validation.errors.industry}
            </FormFeedback>
          ) : null}
        </div>
      </Col>
      <Col lg={12}>
        <div className="d-flex gap-2 align-items-center">
          <Input
            name="public"
            id="mentorshipPublic-field"
            className={`form-control ${styles.inputCheckbox}`}
            placeholder="Введите описание условий"
            type="checkbox"
            value={validation.values.public || ""}
            checked={validation.values.public || false}
            onChange={validation.handleChange}
            onBlur={validation.handleBlur}
            invalid={
              validation.touched.public && validation.errors.public
                ? true
                : false
            }
            style={{ height: 20, width: 20, resize: "none" }}
          />
          {validation.touched.public && validation.errors.public ? (
            <FormFeedback type="invalid" className={styles.invalid}>
              {validation.errors.public}
            </FormFeedback>
          ) : null}
          <Label
            htmlFor="mentorshipPublic-field"
            className={`${styles.label} mt-2`}
          >
            Открыть просмотр всем
          </Label>
        </div>
      </Col>
    </Row>
  );
}

export { initialValues, validationSchema, confirm, ModalBodyAdd };
