import React, { useEffect } from "react";
import { useLoaderData } from "react-router-dom";
import { useDispatch } from "react-redux";

import Animation from "@areas/shared/components/chooseLayout/Animation";
import animationStyles from "@areas/shared/components/chooseLayout/styles/animations.module.css";

import { metaActions } from "@store";
import { upperCaseFirstLetterWords } from "@helpers/extension";

import About from "./blocks/About";
import Addition from "./blocks/Addition";

function Page(props) {
  const data = useLoaderData();
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(
      metaActions.setData({
        icon: "/faviconCollege.ico",
        title: `${data.Shortname ? `${data.Shortname} - ` : ""}${
          upperCaseFirstLetterWords(data.Fullname)
        }${data.Addresses ? `, ${data.Addresses[0].City}` : ""}`,
        description: `${upperCaseFirstLetterWords(data.Fullname)}${
          data.Shortname ? ` (${data.Shortname})` : ""
        }${
          data.Addresses ? `, ${data.Addresses[0].City}.` : "."
        } Направления обучения. Специальности. Условия поступления.  ✅ Приемная комиссия. Адрес. Официальный сайт.`,
      })
    );
  }, [dispatch, data]);

  return (
    <div style={{ minHeight: "100vh" }}>
      <Animation
        animationIn={animationStyles.swipeLeftIn}
        animationOut={animationStyles.swipeRightOut}
      >
        <About data={data} />
        <Addition data={data} />
      </Animation>
    </div>
  );
}

export default Page;
