import React, { useCallback, useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import { useSearchParams } from "react-router-dom";

import Animation from "@areas/shared/components/chooseLayout/Animation";
import animationStyles from "@areas/shared/components/chooseLayout/styles/animations.module.css";

import { metaActions } from "@store";

import Filter from "./blocks/Filter";
import Search from "./blocks/Search";
import Results from "./blocks/Results";

import { fetchWrapper } from "@helpers/fetchwrapper";
import { filterNames } from "@helpers/data/filterNames";

import styles from "./styles/page.module.css";

function Page(props) {
  const [searchParams, setSearchParams] = useSearchParams();
  const dispatch = useDispatch();

  const getTypes = useCallback(() => {
    let result = searchParams.get("types");
    if (result) {
      return result.split(",").map((t) => {
        return parseInt(t);
      });
    }
    return [2, 3];
  }, [searchParams]);

  const getFilter = useCallback(() => {
    let result = [];
    for (let i = 0; i < filterNames.length; i++) {
      let params = searchParams.get(filterNames[i]);
      if (params) {
        result = result.concat(
          params.split(",").map((t) => {
            return {
              name: filterNames[i],
              value: t,
            };
          })
        );
      }
    }
    return result;
  }, [searchParams]);

  const [load, setLoad] = useState(false);
  const [types, setTypes] = useState(getTypes());
  const [filter, setFilter] = useState(getFilter());
  const [searchResult, setSearchResult] = useState({
    filter: [],
    data: [],
    total: 0,
    totals: [],
  });

  const submitSearch = useCallback(
    (query) => {
      let result = {};
      result.query = query;
      result.types = types.join(",");
      setSearchParams(result);
    },
    [types, setSearchParams]
  );

  const submitFilter = useCallback(() => {
    let result = {};
    result.query = searchParams.get("query") || "";
    result.types = types.join(",");
    for (let i = 0; i < filterNames.length; i++) {
      result[filterNames[i]] = filter
        .filter((p) => p.name === filterNames[i])
        .map((p) => p.value)
        .join(",");
    }
    setSearchParams(result);
  }, [types, filter, searchParams, setSearchParams]);

  const submitFilterClear = useCallback(() => {
    let result = {};
    result.query = searchParams.get("query") || "";
    result.types = types.join(",");
    for (let i = 0; i < filterNames.length; i++) {
      result[filterNames[i]] = filter
        .filter((p) => p.name === filterNames[i])
        .map(() => "")
        .join(",");
    }
    setSearchParams(result);
  }, [types, filter, searchParams, setSearchParams]);

  const isIncludeInQuery = (value, query) => {
    if (value && value.toLowerCase().indexOf(query.toLowerCase()) > -1) {
      return true;
    }
    return false;
  };

  const search = useCallback(
    (offset) => {
      setLoad(true);
      let query = searchParams.get("query") || "";

      let filter = getFilter();
      let requestFilters = {};
      if (filter.length > 0) {
        for (let i = 0; i < filterNames.length; i++) {
          let name = filterNames[i];
          if (!requestFilters[name]) {
            requestFilters[name] = [];
          }
          filter.forEach((f) => {
            if (f.name === name) {
              requestFilters[name].push(f.value);
            }
          });
        }
      }

      let data = {
        text: query,
        types: getTypes(),
        limit: 5,
        offset: offset,
      };

      for (let i = 0; i < filterNames.length; i++) {
        let name = filterNames[i];
        if (requestFilters[name] && requestFilters[name].length > 0) {
          data[name] = requestFilters[name];
        }
      }

      fetchWrapper
        .post(`/v1/api/app/findCompaniesWithFaculty`, data)
        .then((res) => {
          let tempData = res.Data;
          for (let i = 0; i < tempData.length; i++) {
            let temp = [];

            for (let j = 0; j < tempData[i].Faculty.length; j++) {
              if (
                isIncludeInQuery(tempData[i].Faculty[j].Name, query) ||
                isIncludeInQuery(tempData[i].Faculty[j].Title, query)
              ) {
                temp.unshift(tempData[i].Faculty[j]);
              } else {
                temp.push(tempData[i].Faculty[j]);
              }
            }

            tempData[i].Faculty = temp;
          }

          setSearchResult({
            data: tempData,
            filter: res.Filters,
            total: res.Total,
            totals: res.Totals,
          });
          setLoad(false);
        });
    },
    [searchParams, getTypes, getFilter]
  );

  useEffect(() => {
    search(0);
  }, [searchParams, search]);

  useEffect(() => {
    setTypes(getTypes());
    setFilter(getFilter());
  }, [getTypes, getFilter]);

  useEffect(() => {
    dispatch(
      metaActions.setData({
        icon: "/faviconEmployer.ico",
        title: "Работодателям",
        description:
          "Прямая коммуникация с учебными заведениями профессиональной подготовки Республики Башкортостан, взаимодействие с потенциальными сотрудниками: со студентами и профессионалами, ищущими работу.",
        link: [
          {
            rel: "canonical",
            href: "/company/search",
          },
        ],
      })
    );
  }, [dispatch]);

  return (
    <div className="container mt-xl-5 ps-xl-0 pe-xl-0 ps-3 pe-3">
      <Animation
        animationIn={animationStyles.swipeLeftIn}
        animationOut={animationStyles.swipeRightOut}
      >
        <div className="row justify-content-center p-1">
          <div className={`col-xl-3 col-12 ${styles.block}`}>
            <Filter
              load={load}
              data={searchResult.filter}
              values={filter}
              set={setFilter}
              types={types}
              setTypes={setTypes}
              submit={submitFilter}
              clear={submitFilterClear}
            />
          </div>
          <div className={`col-xl-9 col-12 ${styles.block}`}>
            <Search
              query={searchParams.get("query")}
              sumbit={submitSearch}
              setResults={setSearchResult}
              setFilterUser={setFilter}
              className="mb-4"
            />
            <Results load={load} results={searchResult} onChangePage={search} />
          </div>
        </div>
      </Animation>
    </div>
  );
}

export default Page;
