import React from "react";
import ClipLoader from "react-spinners/ClipLoader";
import { Link } from "react-router-dom";
import moment from "moment";

import CardArrow from "@areas/shared/components/cards/CardArrow";
import Pagination from "@areas/shared/components/paginations/Pagination";

import styles from "../blocks/styles/addition.module.css";
import paginationStyles from "@areas/college/components/pagination/styles/pagination.module.css";

function EventsTab({ load, results, limit, companyId, onChangePage }) {
  return (
    <>
      {load ? (
        <div className="d-flex justify-content-center w-100">
          <ClipLoader />
        </div>
      ) : results.data.length > 0 ? (
        <div className={styles.tabContent}>
          <div className={styles.events}>
            {results.data.map((item) => (
              <CardArrow
                key={item.Id}
                sizeArrow={{ width: 17, height: 17 }}
                customStyles={{
                  main: styles.card,
                  border: styles.border,
                  arrow: styles.arrow,
                }}
                rotate="leftTop"
              >
                <div className={styles.inner}>
                  <div className={styles.dates}>
                    Срок: {moment(item.DateStart).format("DD.MM.YYYY")}-
                    {moment(item.DateEnd).format("DD.MM.YYYY")}
                  </div>
                  <Link
                    to={`event/${item.Id}`}
                    className={styles.title}
                    target="_blank"
                  >
                    {item.Name}
                  </Link>
                  <div className={styles.description}>
                    <div className={styles.title}>Описание:</div>
                    {item.Description}
                  </div>
                </div>
              </CardArrow>
            ))}
          </div>
        </div>
      ) : (
        <div className="d-flex justify-content-center w-100">
          Ничего не найдено
        </div>
      )}
      <Pagination
        load={load}
        itemsPerPage={limit}
        totalItems={results.total}
        onChangePage={onChangePage}
        customStyles={{
          prev: paginationStyles.prev,
          current: paginationStyles.current,
          next: paginationStyles.next,
        }}
      />
    </>
  );
}

export default EventsTab;
