import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { Modal, ModalBody, ModalHeader } from "reactstrap";

import { layoutActions } from "@store";

import styles from "./styles/customModal.module.css";

function InfoModal() {
  const dispatch = useDispatch();
  const { modalText } = useSelector((state) => state.layout);

  const toggle = () => {
    dispatch(layoutActions.setModalText(null));
    dispatch(layoutActions.setModal(null));
  };

  return (
    <Modal
      isOpen={true}
      toggle={toggle}
      centered
      size={"sm"}
      className={styles.modal}
      modalClassName="zoomIn"
    >
      <ModalHeader toggle={toggle} className={styles.header}>
        <div className={styles.title}>Информация</div>
      </ModalHeader>
      <ModalBody className={styles.body}>{modalText}</ModalBody>
    </Modal>
  );
}

export default InfoModal;
