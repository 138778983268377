import React from "react";

import Pagination from "@areas/shared/components/paginations/Pagination";

import styles from "./styles/allTable.module.css";

function ChoosedTable({
  data,
  load,
  onChangePage
}) {
  const { results, total } = data;
  return (
    <div className="g-2">
      <table className={styles.table}>
        <thead className={styles.header}>
          <tr>
            <th className={styles.main}>Компании</th>
          </tr>
        </thead>
        <tbody>
          {results.map((item, index) => (
            <tr
              className={`${styles.row}`}
              key={index}
            >
              <td className={styles.main}>{item.FromCompany}</td>
            </tr>
          ))}
        </tbody>
      </table>
      <Pagination
        load={load}
        itemsPerPage={10}
        totalItems={total}
        onChangePage={onChangePage}
      />
    </div>
  );
}

export default ChoosedTable;
