import React, { useState, useEffect } from "react";
import { Card, CardBody, CardHeader } from "reactstrap";
import { Link } from "react-router-dom";
import ClipLoader from "react-spinners/ClipLoader";

import Animation from "@areas/shared/components/chooseLayout/Animation";
import animationStyles from "@areas/shared/components/chooseLayout/styles/animations.module.css";

import { fetchWrapper } from "@helpers/fetchwrapper";

function Frame(props) {
  const [load, setLoad] = useState(false);
  const [data, setData] = useState([]);

  const loadData = () => {
    setLoad(true);
    fetchWrapper.get("/data/support.json").then((res) => {
      setData(res);
      setLoad(false);
    });
  };

  useEffect(() => {
    loadData();
  }, []);

  return (
    <div>
      <Animation
        animationIn={animationStyles.fadeIn}
        animationOut={animationStyles.fadeOut}
      >
        <Card>
          <CardHeader>
            <div className="d-flex justify-content-between align-items-center">
              <h5 className="mb-0">Поддержка</h5>
            </div>
          </CardHeader>
          <CardBody>
            {load ? (
              <ClipLoader />
            ) : (
              <div>
                <div>{data.title}</div>
                <Link to={`mailto:${data.email}`}>{data.email}</Link>
              </div>
            )}
          </CardBody>
        </Card>
      </Animation>
    </div>
  );
}

export default Frame;
