import React from "react";
import { Col, FormFeedback, Input, Label, Row } from "reactstrap";
import * as Yup from "yup";

import { fetchWrapper } from "@helpers/fetchwrapper";

import stylesModal from "@areas/shared/components/personal/modals/styles/customModal.module.css";

const initialValues = (company, contact) => {
  return {
    id: (contact && contact.Id) || null,
    type: (contact && contact.IdType) || 4,
    value: (contact && contact.Value) || "",
    position: "",
    email: "",
    phone: "",
    site: "",
    idCompany: (company && company.IdCompany) || null,
  };
};

const validationSchema = () => {
  return Yup.object({
    value: Yup.string().required("Текст обязателен"),
  });
};

const sendOtherData = (company, parent, type, value) => {
  let data = new FormData();
  data.append("idType", type);
  data.append("value", value);

  fetchWrapper.post(
    `/v1/personal/insertcontact?idCompany=${company}&idParent=${parent}&typeParent=4`,
    data,
    false
  );
};

const confirm = (values, successFunc) => {
  let data = new FormData();
  data.append("idType", values.type);
  data.append("value", values.value);

  if (values.idCompany) {
    if (values.id) {
      data.append("id", values.id);
      fetchWrapper
        .post(`/v1/personal/updatecontact`, data, false)
        .then(() => successFunc(values.idCompany));
    } else {
      fetchWrapper
        .post(
          `/v1/personal/insertcontact?idCompany=${values.idCompany}&idParent=${values.idCompany}&typeParent=1`,
          data,
          false
        )
        .then((res) => {
          sendOtherData(values.idCompany, res.Id, 1, values.email);
          sendOtherData(values.idCompany, res.Id, 2, values.phone);
          sendOtherData(values.idCompany, res.Id, 3, values.site);
          sendOtherData(values.idCompany, res.Id, 5, values.position);
          successFunc(values.idCompany);
        });
    }
  }
};

function FormBody({ validation }) {
  return (
    <Row className="g-2">
      <Input
        type="hidden"
        name="id"
        defaultValue={validation.values.id || null}
      />
      <Input
        type="hidden"
        name="idCompany"
        defaultValue={validation.values.idCompany || null}
      />
      <Col lg={12}>
        <div>
          <Label
            htmlFor="contactPersonValue-field"
            className={stylesModal.label}
          >
            ФИО
          </Label>
          <Input
            name="value"
            id="contactPersonValue-field"
            className={`form-control ${stylesModal.input}`}
            placeholder="Введите ФИО"
            type="text"
            value={validation.values.value || ""}
            onChange={validation.handleChange}
            onBlur={validation.handleBlur}
            invalid={
              validation.touched.value && validation.errors.value ? true : false
            }
          />
          {validation.touched.value && validation.errors.value ? (
            <FormFeedback type="invalid" className={stylesModal.invalid}>
              {validation.errors.value}
            </FormFeedback>
          ) : null}
        </div>
      </Col>
      {validation.values.id ? null : (
        <>
          <Col lg={3}>
            <div>
              <Label
                htmlFor="contactPersonPosition-field"
                className={stylesModal.label}
              >
                Должность
              </Label>
              <Input
                name="position"
                id="contactPersonPosition-field"
                className={`form-control ${stylesModal.input}`}
                placeholder="Введите должность"
                type="text"
                value={validation.values.position || ""}
                onChange={validation.handleChange}
                onBlur={validation.handleBlur}
                invalid={
                  validation.touched.position && validation.errors.position
                    ? true
                    : false
                }
              />
              {validation.touched.position && validation.errors.position ? (
                <FormFeedback type="invalid" className={stylesModal.invalid}>
                  {validation.errors.position}
                </FormFeedback>
              ) : null}
            </div>
          </Col>
          <Col lg={3}>
            <div>
              <Label
                htmlFor="contactPersonEmail-field"
                className={stylesModal.label}
              >
                Email
              </Label>
              <Input
                name="email"
                id="contactPersonEmail-field"
                className={`form-control ${stylesModal.input}`}
                placeholder="Введите email"
                type="text"
                value={validation.values.email || ""}
                onChange={validation.handleChange}
                onBlur={validation.handleBlur}
                invalid={
                  validation.touched.email && validation.errors.email
                    ? true
                    : false
                }
              />
              {validation.touched.email && validation.errors.email ? (
                <FormFeedback type="invalid" className={stylesModal.invalid}>
                  {validation.errors.email}
                </FormFeedback>
              ) : null}
            </div>
          </Col>
          <Col lg={3}>
            <div>
              <Label
                htmlFor="contactPersonPhone-field"
                className={stylesModal.label}
              >
                Телефон
              </Label>
              <Input
                name="phone"
                id="contactPersonPhone-field"
                className={`form-control ${stylesModal.input}`}
                placeholder="Введите телефон"
                type="text"
                value={validation.values.phone || ""}
                onChange={validation.handleChange}
                onBlur={validation.handleBlur}
                invalid={
                  validation.touched.phone && validation.errors.phone
                    ? true
                    : false
                }
              />
              {validation.touched.phone && validation.errors.phone ? (
                <FormFeedback type="invalid" className={stylesModal.invalid}>
                  {validation.errors.phone}
                </FormFeedback>
              ) : null}
            </div>
          </Col>
          <Col lg={3}>
            <div>
              <Label
                htmlFor="contactPersonSite-field"
                className={stylesModal.label}
              >
                Сайт
              </Label>
              <Input
                name="site"
                id="contactPersonSite-field"
                className={`form-control ${stylesModal.input}`}
                placeholder="Введите сайт"
                type="text"
                value={validation.values.site || ""}
                onChange={validation.handleChange}
                onBlur={validation.handleBlur}
                invalid={
                  validation.touched.site && validation.errors.site
                    ? true
                    : false
                }
              />
              {validation.touched.site && validation.errors.site ? (
                <FormFeedback type="invalid" className={stylesModal.invalid}>
                  {validation.errors.site}
                </FormFeedback>
              ) : null}
            </div>
          </Col>
        </>
      )}
    </Row>
  );
}

export { initialValues, validationSchema, confirm, FormBody };
