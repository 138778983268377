import React, { useCallback, useEffect, useState } from "react";
import { Nav, NavItem, NavLink, TabContent, TabPane } from "reactstrap";

import PracticesTab from "../results/PracticesTab";
import MentorshipsTab from "../results/MentorshipsTab";

import { fetchWrapper } from "@helpers/fetchwrapper";

import styles from "./styles/addition.module.css";

function Addition({ data }) {
  const [activeTab, setActiveTab] = useState("1");
  const [practices, setPractices] = useState({
    data: [],
    total: 0,
  });
  const [mentorships, setMentorships] = useState({
    data: [],
    total: 0,
  });
  const [limit] = useState(8);
  const [loadData, setLoadData] = useState(false);

  const loadPractices = useCallback(
    (offset) => {
      setLoadData(true);
      fetchWrapper
        .get(
          `/v1/api/app/getPracticyByIdCompany?idCompany=${data.Id}&limit=${limit}&offset=${offset}`
        )
        .then((res) => {
          setPractices(res);
        })
        .finally(() => {
          setLoadData(false);
        });
    },
    [data, limit]
  );

  const loadMentorships = useCallback(
    (offset) => {
      setLoadData(true);
      fetchWrapper
        .get(
          `/v1/api/app/getMentorshipsByCompanyId?idCompany=${data.Id}&limit=${limit}&offset=${offset}`
        )
        .then((res) => {
          setMentorships(res);
        })
        .finally(() => {
          setLoadData(false);
        });
    },
    [data, limit]
  );

  useEffect(() => {
    loadPractices(0);
  }, [loadPractices]);

  return (
    <div className={`${styles.block} container`}>
      <Nav tabs className={styles.tabs}>
        <NavItem>
          <NavLink
            className={`${styles.tab} ${
              activeTab === "1" ? styles.active : null
            }`}
            onClick={() => {
              loadPractices(0);
              setActiveTab("1");
            }}
          >
            ПРАКТИКИ
          </NavLink>
        </NavItem>
        <NavItem>
          <NavLink
            className={`${styles.tab} ${
              activeTab === "2" ? styles.active : null
            }`}
            onClick={() => {
              loadMentorships(0);
              setActiveTab("2");
            }}
          >
            НАСТАВНИКИ
          </NavLink>
        </NavItem>
      </Nav>
      <TabContent activeTab={activeTab}>
        <TabContent activeTab={activeTab}>
          <TabPane tabId="1">
            <PracticesTab
              load={loadData}
              results={practices}
              limit={limit}
              companyId={data.Id}
              onChangePage={loadPractices}
            />
          </TabPane>
          <TabPane tabId="2">
            <MentorshipsTab
              load={loadData}
              results={mentorships}
              limit={limit}
              companyId={data.Id}
              onChangePage={loadMentorships}
            />
          </TabPane>
        </TabContent>
      </TabContent>
    </div>
  );
}

export default Addition;
