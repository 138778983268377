import React from "react";
import clsx from "clsx";

import styles from "./styles/cell.module.css";

function Cell({
  children,
  className,
  isActive,
  isFocus,
  first,
  last,
  top,
  bottom,
  onClick,
  customStyles,
}) {
  return (
    <div
      onClick={onClick}
      className={clsx(
        styles.cell,
        isActive
          ? customStyles
            ? customStyles.isActive
              ? customStyles.isActive
              : styles.active
            : styles.active
          : null,
        first ? styles.first : null,
        last ? styles.last : null,
        top ? styles.top : null,
        bottom ? styles.bottom : null,
        !top && !bottom && !first && !last ? styles.center : null,
        isFocus
          ? customStyles
            ? customStyles.isFocus
              ? customStyles.isFocus
              : styles.focus
            : styles.focus
          : null,
        className
      )}
    >
      {children}
    </div>
  );
}

export default Cell;
