import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  Form,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Col,
  FormFeedback,
  Input,
  Label,
  Row,
} from "reactstrap";
import { useFormik } from "formik";
import * as Yup from "yup";
import RiseLoader from "react-spinners/RiseLoader";

import { authActions, layoutActions } from "@store";

import styles from "./styles/customModal.module.css";

function LoginModal() {
  const dispatch = useDispatch();
  const { modalNext } = useSelector((state) => state.layout);
  const [load, setLoad] = useState(false);
  const [authError, setAuthError] = useState(null);

  const inititalValues = () => {
    return {
      login: null,
      password: null,
    };
  };

  const validationSchema = () => {
    return Yup.object({
      login: Yup.string()
        .email("Некорректный email")
        .required("Значение обязателено"),
      password: Yup.string().required("Значение обязателено"),
    });
  };

  const toggle = () => {
    dispatch(layoutActions.setModal(null));
    dispatch(layoutActions.setModalNext(null));
  };

  const validation = useFormik({
    enableReinitialize: true,
    initialValues: inititalValues(),
    validationSchema: validationSchema(),
    onSubmit: ({ login, password }) => {
      setLoad(true);
      setAuthError(null);
      return dispatch(authActions.login({ login, password }))
        .unwrap()
        .then((result) => {
          if (!result.message) {
            dispatch(authActions.me()).then(() => {
              dispatch(layoutActions.setModal(modalNext));
              dispatch(layoutActions.setModalNext(null));
              setLoad(false);
            });
          } else {
            setAuthError(result);
            setLoad(false);
          }
        })
        .catch((rejectedValueOrSerializedError) => {
          setAuthError(rejectedValueOrSerializedError);
          setLoad(false);
        });
    },
  });

  return (
    <Modal
      isOpen={true}
      toggle={toggle}
      centered
      size={"sm"}
      className={styles.modal}
      modalClassName="zoomIn"
    >
      <ModalHeader toggle={toggle} className={styles.header}>
        <div className={styles.title}>Вход</div>
      </ModalHeader>
      <Form
        className="tablelist-form"
        onSubmit={(e) => {
          e.preventDefault();
          validation.handleSubmit();
          return false;
        }}
      >
        <ModalBody className={styles.body}>
          <Row className="g-2">
            <Col lg={12}>
              {authError ? (
                <FormFeedback type="invalid" className={styles.invalid}>
                  {authError.message}
                </FormFeedback>
              ) : null}
            </Col>
            <Col lg={12}>
              <div>
                <Label htmlFor="loginLogin-field" className={styles.label}>
                  Email
                </Label>
                <Input
                  name="login"
                  id="loginLogin-field"
                  className={`form-control ${styles.input}`}
                  placeholder="Введите email"
                  type="text"
                  value={validation.values.login || ""}
                  onChange={validation.handleChange}
                  onBlur={validation.handleBlur}
                  invalid={
                    validation.touched.login && validation.errors.login
                      ? true
                      : false
                  }
                />
                {validation.touched.login && validation.errors.login ? (
                  <FormFeedback type="invalid" className={styles.invalid}>
                    {validation.errors.login}
                  </FormFeedback>
                ) : null}
              </div>
            </Col>
            <Col lg={12}>
              <div>
                <Label htmlFor="loginPassword-field" className={styles.label}>
                  Пароль
                </Label>
                <Input
                  name="password"
                  id="loginPassword-field"
                  className={`form-control ${styles.input}`}
                  placeholder="Введите пароль"
                  type="password"
                  value={validation.values.password || ""}
                  onChange={validation.handleChange}
                  onBlur={validation.handleBlur}
                  invalid={
                    validation.touched.password && validation.errors.password
                      ? true
                      : false
                  }
                />
                {validation.touched.password && validation.errors.password ? (
                  <FormFeedback type="invalid" className={styles.invalid}>
                    {validation.errors.password}
                  </FormFeedback>
                ) : null}
              </div>
            </Col>
          </Row>
        </ModalBody>
        <ModalFooter className={`${styles.footer} justify-content-center`}>
          {load ? (
            <div className="d-flex justify-content-center w-100 mb-3">
              <RiseLoader color="#014170" />
            </div>
          ) : (
            <button
              type="submit"
              className={`btn btn-success ${styles.submit} w-50`}
              id="add-btn"
            >
              Войти
            </button>
          )}
        </ModalFooter>
      </Form>
    </Modal>
  );
}

export default LoginModal;
