import React from "react";
import { useSearchParams } from "react-router-dom";

import Forgot from "./blocks/Forgot";

import Animation from "@areas/shared/components/chooseLayout/Animation";
import animationStyles from "@areas/shared/components/chooseLayout/styles/animations.module.css";
import NewPassword from "./blocks/NewPassword";

function Frame(props) {
  const [searchParams] = useSearchParams();

  return (
    <div style={{ minHeight: "calc(100vh - 320px)" }}>
      <Animation
        animationIn={animationStyles.fadeIn}
        animationOut={animationStyles.fadeOut}
      >
        {searchParams.get("Login") && searchParams.get("HashId") ? (
          <NewPassword
            login={searchParams.get("Login")}
            hash={searchParams.get("HashId")}
          />
        ) : (
          <Forgot />
        )}
      </Animation>
    </div>
  );
}

export default Frame;
