import React from "react";
import { Col, FormFeedback, Input, Label, Row } from "reactstrap";
import * as Yup from "yup";

import { fetchWrapper } from "@helpers/fetchwrapper";

import styles from "@areas/shared/components/personal/modals/styles/customModal.module.css";

const initialValues = (type, company) => {
  return {
    type: type || 1,
    inn: null,
    kpp: null,
  };
};

const validationSchema = () => {
  return Yup.object({
    inn: Yup.string().required("ИНН обязателен"),
  });
};

const confirm = (values, successFunc) => {
  fetchWrapper
    .post(
      `/v1/api/admin/AddOrUpdateOrganizationInfo?type=${values.type}&inn=${values.inn}&kpp=${values.kpp}`
    )
    .then(successFunc);
};

function ModalBodyAdd({ validation }) {
  return (
    <Row className="g-2">
      <Col sm={6}>
        <div>
          <Label htmlFor="companyInn-field" className={styles.label}>
            ИНН
          </Label>
          <Input
            name="inn"
            id="companyInn-field"
            className={`form-control ${styles.input}`}
            placeholder="Введите ИНН"
            type="text"
            validate={{
              required: { value: true },
            }}
            onChange={validation.handleChange}
            onBlur={validation.handleBlur}
            value={validation.values.inn || ""}
            invalid={
              validation.touched.inn && validation.errors.inn ? true : false
            }
          />
          {validation.touched.inn && validation.errors.inn ? (
            <FormFeedback type="invalid" className={styles.invalid}>
              {validation.errors.inn}
            </FormFeedback>
          ) : null}
        </div>
      </Col>
      <Col sm={6}>
        <div>
          <Label htmlFor="companyKpp-field" className={styles.label}>
            КПП
          </Label>
          <Input
            name="kpp"
            id="companyKpp-field"
            className={`form-control ${styles.input}`}
            placeholder="Введите КПП"
            type="text"
            validate={{
              required: { value: true },
            }}
            onChange={validation.handleChange}
            onBlur={validation.handleBlur}
            value={validation.values.kpp || ""}
            invalid={
              validation.touched.kpp && validation.errors.kpp ? true : false
            }
          />
          {validation.touched.kpp && validation.errors.kpp ? (
            <FormFeedback type="invalid" className={styles.invalid}>
              {validation.errors.kpp}
            </FormFeedback>
          ) : null}
        </div>
      </Col>
    </Row>
  );
}

export { initialValues, validationSchema, confirm, ModalBodyAdd };
