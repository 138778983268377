import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Nav, Navbar, Container, NavItem } from "react-bootstrap";
import { Link } from "react-router-dom";

import ClipLoader from "react-spinners/ClipLoader";

import { layoutActions } from "@store";

import Logo from "../icons/Logo";
import SearchIcon from "../icons/Search";
import LoginIcon from "../icons/Login";
import PersonalIcon from "../icons/Pesonal";
import Search from "./Search";

import styles from "./styles/navbar.module.css";

function Menu({ filter }) {
  const dispatch = useDispatch();
  const [querySearch, setQuerySearch] = useState(null);
  const { user: authUser, token: authToken } = useSelector((x) => x.auth);

  return (
    <Container>
      <Navbar expand="lg">
        <Navbar className="m-0 p-0">
          <div
            className={`navbar-brand ${styles.logo}`}
            onClick={() => dispatch(layoutActions.setMount(false))}
          >
            <Logo filter={filter} />
          </div>
        </Navbar>
        <Nav className="ms-auto d-flex">
          <NavItem className={`me-5 ${styles["search-container"]}`}>
            <SearchIcon
              style={styles.icon}
              gradientId="layoutSearchIcon"
              gradientSteps={[
                {
                  offset: 0,
                  stopColor: "#0072FF",
                },
                {
                  offset: 1,
                  stopColor: "#29CD8F",
                },
              ]}
            />
            <input
              className={styles.input}
              placeholder="Поиск"
              onChange={(e) => setQuerySearch(e.target.value)}
            ></input>
          </NavItem>
          <NavItem>
            <Link target="_blank" className={styles["login-container"]} to="/personal/login">
              {authToken ? (
                <PersonalIcon
                  style={styles.icon}
                  gradientId="layoutLoginIcon"
                  gradientSteps={[
                    {
                      offset: 0,
                      stopColor: "#0072FF",
                    },
                    {
                      offset: 1,
                      stopColor: "#29CD8F",
                    },
                  ]}
                />
              ) : (
                <LoginIcon
                  style={styles.icon}
                  gradientId="layoutLoginIcon"
                  gradientSteps={[
                    {
                      offset: 0,
                      stopColor: "#0072FF",
                    },
                    {
                      offset: 1,
                      stopColor: "#29CD8F",
                    },
                  ]}
                />
              )}
              <span className={styles.label}>
                {authToken ? (
                  authUser ? (
                    `${authUser.Lastname} ${authUser.Firstname}`
                  ) : (
                    <ClipLoader />
                  )
                ) : (
                  "Войти"
                )}
              </span>
            </Link>
          </NavItem>
        </Nav>
        <Search query={querySearch} />
      </Navbar>
    </Container>
  );
}

export default Menu;
