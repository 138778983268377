import React from "react";
import { AsyncPaginate } from "react-select-async-paginate";

import { fetchWrapper } from "@helpers/fetchwrapper";

const SelectAsyncPaginate = ({
  url,
  loadFunc,
  labelFunc,
  valueFunc,
  placeholder,
  value,
  onChangeValue,
  onBlurInput,
  isMulti,
}) => {
  const loadOptions = async (searchQuery, loadedOptions, { page }) => {
    let result = await fetchWrapper.get(
      `${url}?text=${searchQuery}&offset=${page * 10}&limit=10`
    );

    let options = result.data.map((p) => ({
      value: valueFunc(p),
      label: labelFunc(p),
    }));
    return {
      options: options,
      hasMore: result.total > page * 10,
      additional: {
        page: searchQuery ? 1 : page + 1,
      },
    };
  };

  const onChange = (option) => {
    if (typeof onChangeValue === "function") {
      onChangeValue(option);
    }
  };

  const onBlur = (option) => {
    if (typeof onBlurInput === "function") {
      onBlurInput(option);
    }
  };

  return (
    <AsyncPaginate
      value={value || null}
      loadOptions={loadFunc ? loadFunc : loadOptions}
      getOptionValue={(option) => option.value}
      getOptionLabel={(option) => option.label}
      onChange={onChange}
      onBlur={onBlur}
      isSearchable={true}
      placeholder={placeholder}
      additional={{
        page: 0,
      }}
      isMulti={isMulti}
    />
  );
};

export default SelectAsyncPaginate;
