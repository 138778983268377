import React from "react";

import styles from "./styles/hamburger.module.css";

function Hamburger({ onClick, open }) {
  return (
    <span
      onClick={onClick}
      className={open ? `${styles.icon} ${styles.open}` : styles.icon}
    >
      <span></span>
      <span></span>
      <span></span>
    </span>
  );
}

export default Hamburger;
