import React, { useCallback, useEffect, useState } from "react";
import { Card, CardBody, CardHeader, Row, Col } from "reactstrap";
import ClipLoader from "react-spinners/ClipLoader";

import Animation from "@areas/shared/components/chooseLayout/Animation";
import animationStyles from "@areas/shared/components/chooseLayout/styles/animations.module.css";

import LoadBlock from "@areas/shared/components/personal/loads/LoadBlock";

import { fetchWrapper } from "@helpers/fetchwrapper";

import AllTable from "./blocks/AllTable";
import ChoosedTable from "./blocks/ChoosedTable";

function Frame() {
  const [load, setLoad] = useState(false);
  const [data, setData] = useState({
    results: [],
    total: 0,
  });
  const [search, setSearch] = useState("");
  const [choosed, setChoosed] = useState(null);
  const [choosedData, setChoosedData] = useState({
    results: [],
    total: 0,
  });

  const loadData = useCallback(
    (offset = 0, text) => {
      setLoad(true);
      if (text === null || text === undefined) {
        text = search;
      } else {
        setSearch(text);
      }
      fetchWrapper
        .get(
          `/v1/api/statistic/getCompaniesWithVacancies?offset=${offset}&text=${text}&limit=10`
        )
        .then((res) => {
          setData({
            results: res.data,
            total: res.total,
          });
          setLoad(false);
        });
    },
    [search]
  );

  const loadChoosedData = useCallback(
    (offset = 0, id) => {
      if (!id) {
        id = choosed.Id;
      }

      setLoad(true);
      fetchWrapper
        .get(`/v1/api/app/getVacanciesByCompanyId?idCompany=${id}&offset=${offset}&limit=10`)
        .then((res) => {
          setChoosedData({
            results: res.data,
            total: res.total,
          });
          setLoad(false);
        });
    },
    [choosed]
  );

  useEffect(() => {
    loadData();
  }, [loadData]);

  useEffect(() => {
    if (choosed) {
      loadChoosedData(0, choosed.Id);
    }
  }, [choosed, loadChoosedData]);

  return (
    <div>
      <Animation
        animationIn={animationStyles.fadeIn}
        animationOut={animationStyles.fadeOut}
      >
        <Row className="ms-0 me-0">
          <Col className="ps-0 pe-lg-2 pe-0" lg={6}>
            <Card style={{ minHeight: 500 }}>
              <CardHeader>
                <div className="d-flex justify-content-between align-items-center">
                  <h5 className="mb-0">Всего компаний</h5>
                  <h5 className="mb-0">{data.total}</h5>
                </div>
              </CardHeader>
              <CardBody className="ps-0 pe-0">
                <AllTable
                  load={load}
                  data={data}
                  choosed={choosed}
                  setChoosed={setChoosed}
                  onChangePage={loadData}
                  setSearch={setSearch}
                />
                <LoadBlock visible={load}>
                  <ClipLoader />
                </LoadBlock>
              </CardBody>
            </Card>
          </Col>
          <Col className="ps-0 pe-lg-2 pe-0" lg={6}>
            <Card style={{ minHeight: 500 }}>
              <CardHeader>
                <div className="d-flex justify-content-between align-items-center">
                  <h5 className="mb-0">Всего вакансий</h5>
                  <h5 className="mb-0">{choosedData.total}</h5>
                </div>
              </CardHeader>
              <CardBody>
                <ChoosedTable
                  load={load}
                  data={choosedData}
                  onChangePage={loadChoosedData}
                />
                <LoadBlock visible={load}>
                  <ClipLoader />
                </LoadBlock>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Animation>
    </div>
  );
}

export default Frame;
