import React from "react";
import ReactDOM from "react-dom/client";
import { YMInitializer } from "react-yandex-metrika";
import { createBrowserRouter, RouterProvider } from "react-router-dom";
import { routes } from "./helpers/routes";
import Loader from "@areas/shared/components/chooseLayout/Loader";
import reportWebVitals from "./reportWebVitals";
import { Provider } from "react-redux";
import { HelmetProvider } from "react-helmet-async";
import { helmetContext } from "@helpers/meta";
import { store } from "@store";

import "./index.css";

const router = createBrowserRouter(routes);

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <React.StrictMode>
    <YMInitializer accounts={[97488457]} />
    <HelmetProvider context={helmetContext}>
      <Provider store={store}>
        <RouterProvider router={router} fallbackElement={<Loader />} />
      </Provider>
    </HelmetProvider>
  </React.StrictMode>
);

reportWebVitals();
