import React, { useRef } from "react";
import { IoMdSearch } from "react-icons/io";

import Pagination from "@areas/shared/components/paginations/Pagination";

import styles from "./styles/allTable.module.css";

function AllTable({
  data,
  choosed,
  setChoosed,
  load,
  onChangePage,
  setSearch,
}) {
  const { results, total } = data;
  const searchRef = useRef();
  return (
    <div className="g-2">
      <div className={styles.search}>
        <input type="text" placeholder="Поиск" ref={searchRef} />
        <div
          className={`btn btn-outline-secondary ${styles.btn}`}
          onClick={() => {
            setSearch(searchRef.current.value);
            setChoosed(null);
          }}
        >
          <IoMdSearch size={25} />
        </div>
      </div>
      <table className={styles.table}>
        <thead className={styles.header}>
          <tr>
            <th className={styles.main}>Договоры</th>
            <th className={styles.info}>кол-во</th>
            <th className={styles.opened}></th>
          </tr>
        </thead>
        <tbody>
          {results.map((item, index) => (
            <tr
              className={`${styles.row} ${
                choosed && choosed.Id === item.Id ? `${styles.choosed}` : ""
              }`}
              key={index}
              onClick={() => setChoosed(item)}
            >
              <td className={styles.main}>{item.Shortname}</td>
              <td className={styles.info}>{item.Length}</td>
              <td className={styles.opened}>
                {`${choosed && choosed.Id === item.Id ? `<` : ">"}`}
              </td>
            </tr>
          ))}
        </tbody>
      </table>
      <Pagination
        load={load}
        itemsPerPage={10}
        totalItems={total}
        onChangePage={onChangePage}
      />
    </div>
  );
}

export default AllTable;
