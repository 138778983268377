import React, { useCallback } from "react";
import DataTable from "react-data-table-component";
import ClipLoader from "react-spinners/ClipLoader";

function TableCompanies({
  load,
  data,
  total,
  handlePageChange,
  handlePerRowsChange,
  company,
  setCompany,
}) {
  const paginationOptions = {
    rowsPerPageText: "Данных на странице:",
    rangeSeparatorText: "из",
    noRowsPerPage: false,
    selectAllRowsItem: false,
    selectAllRowsItemText: "Все",
  };
  const columns = [
    {
      name: <span className="font-weight-bold fs-13">№</span>,
      selector: (row) => row.ToIdCompany,
      sortable: true,
      width: "10%",
    },
    {
      name: <span className="font-weight-bold fs-13">Название</span>,
      selector: (row) => row.CompanyName,
      sortable: true,
      width: "70%",
    },
    {
      name: <span className="font-weight-bold fs-13">Кол-во</span>,
      selector: (row) => row.Length,
      sortable: true,
      width: "10%",
    },
  ];

  const conditionalRowStyles = [
    {
      when: useCallback(
        (row) => {
          return row.ToIdCompany === company;
        },
        [company]
      ),
      style: {
        backgroundColor: "rgba(100, 149, 237, 1)",
        color: "white",
      },
    },
    {
      when: useCallback(
        (row) => {
          return row.ToIdCompany !== company;
        },
        [company]
      ),
      style: {
        backgroundColor: "white",
        color: "black",
        cursor: "pointer",
      },
    },
  ];

  return (
    <DataTable
      columns={columns}
      data={data}
      progressComponent={<ClipLoader />}
      progressPending={load}
      paginationTotalRows={total}
      onChangeRowsPerPage={handlePerRowsChange}
      onChangePage={handlePageChange}
      pagination
      paginationServer
      paginationComponentOptions={paginationOptions}
      onRowClicked={(row) => setCompany(row.ToIdCompany)}
      conditionalRowStyles={conditionalRowStyles}
    />
  );
}

export default TableCompanies;
