import React from "react";

import Register from "./blocks/Register";

import Animation from "@areas/shared/components/chooseLayout/Animation";
import animationStyles from "@areas/shared/components/chooseLayout/styles/animations.module.css";

function Frame(props) {
  return (
    <div style={{ minHeight: "calc(100vh - 320px)" }}>
      <Animation
        animationIn={animationStyles.fadeIn}
        animationOut={animationStyles.fadeOut}
      >
        <Register />
      </Animation>
    </div>
  );
}

export default Frame;
