import React from "react";
import { useDispatch, useSelector } from "react-redux";

import { layoutActions } from "@store";

import honeycombListDot from "@content/img/svg/honeycombListDot.svg";
import successDot from "@content/img/svg/successDot.svg";
import backgroundCardHeader from "@content/img/svg/backgroundCardHeader.svg";
import paperCardHeader from "@content/img/svg/paperCardHeader.svg";
import computerCardHeader from "@content/img/svg/computerCardHeader.svg";
import phoneCardHeader from "@content/img/svg/phoneCardHeader.svg";
import loginPersonal from "@content/img/svg/loginPersonal.svg";
import adsFree from "@content/img/svg/adsFree.svg";
import paperPlane from "@content/img/svg/paperPlane.svg";
import individualRequest from "@content/img/svg/individualRequest.svg";
import listUniversity from "@content/img/svg/listUniversity.svg";
import message from "@content/img/svg/message.svg";

import styles from "./styles/actions.module.css";
import { Link } from "react-router-dom";

function Actions(props) {
  const dispatch = useDispatch();
  const { user } = useSelector((x) => x.auth);
  const { mentorship, request } = useSelector((x) => x.offerMentoring);

  return (
    <div className={styles.block}>
      <div className={`container ${styles.content}`}>
        <div className={styles.header}>3 способа предложить наставничество</div>
        <div className={styles.cards}>
          <div className={styles.card}>
            <div className={styles.title}>
              <div className={styles.container}>
                <div className={styles.iconContainer}>
                  <div className={styles.icon}>
                    <img
                      src={backgroundCardHeader}
                      alt="Разместить объявление на платформе"
                    />
                    <img src={paperCardHeader} alt="Разместить объявление на платформе" />
                  </div>
                </div>
                <div className={styles.text}>Разместить объявление на платформе</div>
              </div>
            </div>
            <div className={styles.steps}>
              <div
                className={`${styles.step} ${styles.auth}${
                  user ? ` ${styles.passed}` : ""
                }`}
              >
                <div className={styles.icon}>
                  <img
                    className={styles.background}
                    src={honeycombListDot}
                    alt="•"
                  />
                  <img
                    className={styles.normal}
                    src={loginPersonal}
                    alt="Вход"
                  />
                  <img
                    className={styles.success}
                    src={successDot}
                    alt="Успех"
                  />
                </div>
                <div className={styles.text}>
                  <span
                    onClick={() => dispatch(layoutActions.setModal("login"))}
                  >
                    Войди
                  </span>
                  или
                  <span
                    onClick={() => dispatch(layoutActions.setModal("register"))}
                  >
                    зарегистрируйся
                  </span>
                  в личном кабинете
                </div>
              </div>
              <div
                className={`${styles.step}${
                  user && mentorship ? ` ${styles.passed}` : ""
                }`}
              >
                <div className={styles.icon}>
                  <img
                    className={styles.background}
                    src={honeycombListDot}
                    alt="•"
                  />
                  <img className={styles.normal} src={adsFree} alt="Вход" />
                  <img
                    className={styles.success}
                    src={successDot}
                    alt="Успех"
                  />
                </div>
                <div className={styles.text}>
                  <span
                    onClick={() => {
                      if (user) {
                        dispatch(layoutActions.setModal("mentorship"));
                      } else {
                        dispatch(layoutActions.setModalText("Выполни предыдущие шаги перед заполнением заявки"));
                        dispatch(layoutActions.setModal("info"));
                      }
                    }}
                  >
                    Заполни и отправь
                  </span>
                  <div>заявку</div>
                </div>
              </div>
              <div className={`${styles.step}`}>
                <div className={styles.icon}>
                  <img
                    className={styles.background}
                    src={honeycombListDot}
                    alt="•"
                  />
                  <img
                    className={styles.normal}
                    src={individualRequest}
                    alt="Вход"
                  />
                  <img
                    className={styles.success}
                    src={successDot}
                    alt="Успех"
                  />
                </div>
                <div className={styles.text}>
                  <div style={{ width: "100%" }}>Продолжай обсуждение</div>
                  заявки и
                  <Link to={"/personal"} target="_blank">
                    отслеживай статусы
                  </Link>
                  в личном кабинете
                </div>
              </div>
            </div>
          </div>
          <div className={`${styles.card} ${styles.big}`}>
            <div className={styles.title}>
              <div className={styles.container}>
                <div className={styles.iconContainer}>
                  <div className={styles.icon}>
                    <img
                      src={backgroundCardHeader}
                      alt="Отправить заявку в учебное заведение"
                    />
                    <img
                      src={computerCardHeader}
                      alt="Отправить заявку в учебное заведение"
                    />
                  </div>
                </div>
                <div className={styles.text}>
                  Отправить заявку в учебное заведение
                </div>
              </div>
            </div>
            <div className={styles.steps}>
              <div
                className={`${styles.step} ${styles.auth}${
                  user ? ` ${styles.passed}` : ""
                }`}
              >
                <div className={styles.icon}>
                  <img
                    className={styles.background}
                    src={honeycombListDot}
                    alt="•"
                  />
                  <img
                    className={styles.normal}
                    src={loginPersonal}
                    alt="Вход"
                  />
                  <img
                    className={styles.success}
                    src={successDot}
                    alt="Успех"
                  />
                </div>
                <div className={styles.text}>
                  <span
                    onClick={() => dispatch(layoutActions.setModal("login"))}
                  >
                    Войди
                  </span>
                  или
                  <span
                    onClick={() => dispatch(layoutActions.setModal("register"))}
                  >
                    зарегистрируйся
                  </span>
                  в личном кабинете
                </div>
              </div>
              <div
                className={`${styles.step}${
                  user && mentorship ? ` ${styles.passed}` : ""
                }`}
              >
                <div className={styles.icon}>
                  <img
                    className={styles.background}
                    src={honeycombListDot}
                    alt="•"
                  />
                  <img className={styles.normal} src={adsFree} alt="Вход" />
                  <img
                    className={styles.success}
                    src={successDot}
                    alt="Успех"
                  />
                </div>
                <div className={styles.text}>
                  <span
                    onClick={() => {
                      if (user) {
                        dispatch(layoutActions.setModal("mentorship"));
                      } else {
                        dispatch(layoutActions.setModalText("Выполни предыдущие шаги перед заполнением заявки"));
                        dispatch(layoutActions.setModal("info"));
                      }
                    }}
                  >
                    Заполни
                  </span>
                  <div>заявку для конкретного</div> учебного заведения
                </div>
              </div>
              <div
                className={`${styles.step}${
                  user && request ? ` ${styles.passed}` : ""
                }`}
              >
                <div className={styles.icon}>
                  <img
                    className={styles.background}
                    src={honeycombListDot}
                    alt="•"
                  />
                  <img className={styles.normal} src={paperPlane} alt="Вход" />
                  <img
                    className={styles.success}
                    src={successDot}
                    alt="Успех"
                  />
                </div>
                <div className={styles.text}>
                  Одним кликом
                  <span
                    onClick={() => {
                      if (user && mentorship) {
                        dispatch(layoutActions.setModalBefore("mentorship"));
                        dispatch(layoutActions.setModal("chooseEducation"));
                      } else {
                        dispatch(layoutActions.setModalText("Выполни предыдущие шаги перед отправлением заявки"));
                        dispatch(layoutActions.setModal("info"));
                      }
                    }}
                  >
                    отправь
                  </span>
                  заявку учебному заведению
                </div>
              </div>
              <div className={`${styles.step}`}>
                <div className={styles.icon}>
                  <img
                    className={styles.background}
                    src={honeycombListDot}
                    alt="•"
                  />
                  <img
                    className={styles.normal}
                    src={individualRequest}
                    alt="Вход"
                  />
                  <img
                    className={styles.success}
                    src={successDot}
                    alt="Успех"
                  />
                </div>
                <div className={styles.text}>
                  <div style={{ width: "100%" }}>Продолжай обсуждение</div>
                  заявки и
                  <Link to={"/personal"} target="_blank">
                    отслеживай статусы
                  </Link>
                  в личном кабинете
                </div>
              </div>
            </div>
          </div>
          <div className={styles.card}>
            <div className={styles.title}>
              <div className={styles.container}>
                <div className={styles.iconContainer}>
                  <div className={styles.icon}>
                    <img
                      src={backgroundCardHeader}
                      alt="Напрямую найти и связаться"
                    />
                    <img
                      src={phoneCardHeader}
                      alt="Напрямую найти и связаться"
                    />
                  </div>
                </div>
                <div className={styles.text}>Напрямую найти и связаться</div>
              </div>
            </div>
            <div className={styles.steps}>
              <div className={`${styles.step}`}>
                <div className={styles.icon}>
                  <img
                    className={styles.background}
                    src={honeycombListDot}
                    alt="•"
                  />
                  <img
                    className={styles.normal}
                    src={listUniversity}
                    alt="Вход"
                  />
                  <img
                    className={styles.success}
                    src={successDot}
                    alt="Успех"
                  />
                </div>
                <div className={styles.text}>Найди учебные заведения</div>
              </div>
              <div className={`${styles.step}`}>
                <div className={styles.icon}>
                  <img
                    className={styles.background}
                    src={honeycombListDot}
                    alt="•"
                  />
                  <img className={styles.normal} src={message} alt="Вход" />
                  <img
                    className={styles.success}
                    src={successDot}
                    alt="Успех"
                  />
                </div>
                <div className={styles.text}>
                  Связаться по телефону или почте
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Actions;
