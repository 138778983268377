import React from "react";
import * as IoIcons from "react-icons/io";
import * as RiIcons from "react-icons/ri";

import logo from "@content/img/shared/layout/svg/logo.svg";

import styles from "./styles/sidebar.module.css";

export const area = "/personal";
export const SidebarData = (area) => [
  {
    type: "logo",
    path: "",
    iconSmall: (
      <img className={styles.iconSmall} src={logo} alt="Логотип" width={45} />
    ),
    iconBig: (
      <div className={styles.iconBig}>
        <div style={{ width: "100%", fontSize: "20px" }}>Команда</div>
        <div style={{ width: "100%", fontSize: "19px" }}>будущего</div>
      </div>
    ),
    cName: styles.sidebarLogo,
  },
  {
    type: "separator",
    title: "Меню",
    cName: styles.sidebarSeparator,
  },
  {
    type: "link",
    title: "Новости",
    path: `${area}/news`,
    icon: <IoIcons.IoMdPaper />,
    cName: styles.sidebarText,
  },
  {
    type: "link",
    title: "Компания",
    path: `${area}/company`,
    companyPath: true,
    icon: <IoIcons.IoIosAperture />,
    iconClosed: <RiIcons.RiArrowDownSFill />,
    iconOpened: <RiIcons.RiArrowUpSFill />,
    cName: styles.sidebarText,
    subNav: [
      {
        title: "Информация",
        path: `${area}/company/info`,
        companyPath: true,
        icon: <IoIcons.IoMdInformationCircle />,
        cName: styles.sidebarText,
      },
      {
        title: "Контактные лица",
        path: `${area}/company/contact-persons`,
        companyPath: true,
        icon: <IoIcons.IoMdPerson />,
        cName: styles.sidebarText,
      },
      {
        title: "Практики",
        path: `${area}/company/practices`,
        companyPath: true,
        icon: <IoIcons.IoMdJournal />,
        cName: styles.sidebarText,
      },
      {
        title: "Вакансии",
        path: `${area}/company/vacancies`,
        companyPath: true,
        icon: <IoIcons.IoMdBrowsers />,
        cName: styles.sidebarText,
      },
      {
        title: "Наставники",
        path: `${area}/company/mentorships`,
        companyPath: true,
        icon: <IoIcons.IoMdSchool />,
        cName: styles.sidebarText,
      },
      {
        title: "Параметры",
        path: `${area}/company/props`,
        companyPath: true,
        icon: <IoIcons.IoMdPricetag />,
        cName: styles.sidebarText,
      },
    ],
  },
  {
    type: "link",
    title: "Образование",
    path: `${area}/education`,
    companyPath: true,
    icon: <IoIcons.IoMdPodium />,
    iconClosed: <RiIcons.RiArrowDownSFill />,
    iconOpened: <RiIcons.RiArrowUpSFill />,
    cName: styles.sidebarText,
    subNav: [
      {
        title: "Центр карьеры",
        path: `${area}/education/career-center`,
        companyPath: true,
        icon: <IoIcons.IoMdBriefcase />,
        cName: styles.sidebarText,
      },
      {
        title: "Проекты студентов",
        path: `${area}/education/projects-students`,
        companyPath: true,
        icon: <IoIcons.IoMdAlbums />,
        cName: styles.sidebarText,
      },
      {
        title: "Мероприятия",
        path: `${area}/education/events`,
        companyPath: true,
        icon: <IoIcons.IoMdPodium />,
        cName: styles.sidebarText,
      },
      {
        title: "Специальности",
        path: `${area}/education/specialities`,
        companyPath: true,
        icon: <IoIcons.IoMdFlask />,
        cName: styles.sidebarText,
      },
      {
        title: "Институты",
        path: `${area}/education/instituties`,
        companyPath: true,
        icon: <IoIcons.IoMdUmbrella />,
        cName: styles.sidebarText,
      },
      {
        title: "Приемная комиссия",
        path: `${area}/education/selection-committee`,
        companyPath: true,
        icon: <IoIcons.IoMdGitMerge />,
        cName: styles.sidebarText,
      },
    ],
  },
  {
    type: "link",
    title: "Контент",
    path: `${area}/content`,
    icon: <IoIcons.IoMdNavigate />,
    iconClosed: <RiIcons.RiArrowDownSFill />,
    iconOpened: <RiIcons.RiArrowUpSFill />,
    cName: styles.sidebarText,
    subNav: [
      {
        title: "Общая",
        path: `${area}/content/shared`,
        icon: <IoIcons.IoMdPaper />,
        cName: styles.sidebarText,
      },
      {
        title: "Абитуриенты",
        path: `${area}/content/schoolboy`,
        icon: <IoIcons.IoMdPerson />,
        cName: styles.sidebarText,
      },
      {
        title: "О нас",
        path: `${area}/content/about`,
        icon: <IoIcons.IoMdInformation />,
        cName: styles.sidebarText,
      },
      {
        title: "Страницы",
        path: `${area}/content/pages`,
        icon: <IoIcons.IoMdLaptop />,
        cName: styles.sidebarText,
      },
      {
        title: "Дополнения страниц",
        path: `${area}/content/pages-additional`,
        icon: <IoIcons.IoMdApps />,
        cName: styles.sidebarText,
      },
    ],
  },
  {
    type: "link",
    title: "Заявки",
    companyPath: true,
    path: `${area}/requests`,
    icon: <IoIcons.IoMdMail />,
    iconClosed: <RiIcons.RiArrowDownSFill />,
    iconOpened: <RiIcons.RiArrowUpSFill />,
    cName: styles.sidebarText,
    subNav: [
      {
        title: "Входящие заявки",
        path: `${area}/requests/list-to`,
        companyPath: true,
        icon: <IoIcons.IoMdArrowDown />,
        cName: styles.sidebarText,
      },
      {
        title: "Исходящие заявки",
        path: `${area}/requests/list-from`,
        companyPath: true,
        icon: <IoIcons.IoMdArrowUp />,
        cName: styles.sidebarText,
      },
    ],
  },
  {
    type: "link",
    title: "Администрирование",
    path: `${area}/admin`,
    icon: <IoIcons.IoMdSettings />,
    iconClosed: <RiIcons.RiArrowDownSFill />,
    iconOpened: <RiIcons.RiArrowUpSFill />,
    cName: styles.sidebarText,
    subNav: [
      {
        title: "Пользователи",
        path: `${area}/admin/users`,
        icon: <IoIcons.IoMdPeople />,
        cName: styles.sidebarText,
      },
      {
        title: "Роли",
        path: `${area}/admin/roles`,
        icon: <IoIcons.IoMdHammer />,
        cName: styles.sidebarText,
      },
      {
        title: "Компании",
        path: `${area}/admin/companies`,
        icon: <IoIcons.IoMdPlanet />,
        cName: styles.sidebarText,
      },
      {
        title: "ОКСО",
        path: `${area}/admin/okso`,
        icon: <IoIcons.IoMdSubway />,
        cName: styles.sidebarText,
      },
    ],
  },
  {
    type: "link",
    title: "Статистика",
    path: `${area}/statistics`,
    icon: <IoIcons.IoMdPie />,
    iconClosed: <RiIcons.RiArrowDownSFill />,
    iconOpened: <RiIcons.RiArrowUpSFill />,
    cName: styles.sidebarText,
    subNav: [
      {
        title: "Договоры",
        path: `${area}/statistics/contracts`,
        icon: <IoIcons.IoMdPaper />,
        cName: styles.sidebarText,
      },
      {
        title: "Вакансии",
        path: `${area}/statistics/vacancies`,
        icon: <IoIcons.IoMdClipboard />,
        cName: styles.sidebarText,
      },
      {
        title: "Учебные заведения",
        path: `${area}/statistics/educationals`,
        icon: <IoIcons.IoMdSchool />,
        cName: styles.sidebarText,
      },
    ],
  },
  {
    type: "separator",
    title: "О приложении",
    cName: styles.sidebarSeparator,
  },
  {
    type: "link",
    title: "Поддержка",
    path: `${area}/support`,
    icon: <IoIcons.IoMdHelp />,
    cName: styles.sidebarText,
  },
];
