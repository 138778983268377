export const companyProps = [
  {
    name: "Наличие общежития",
    type: 4,
  },
  {
    name: "Военная кафедра",
    type: 4,
  },
  {
    name: "Очное",
    type: 4,
  },
  {
    name: "Заочное",
    type: 4,
  },
  {
    name: "Очно-заочное",
    type: 4,
  },
  {
    name: "Мест в общежитии",
    type: 1,
  },
  {
    name: "Доп.информация",
    type: 2,
  },
];
