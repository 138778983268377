import React from "react";
import { Form, Modal, ModalBody, ModalFooter, ModalHeader } from "reactstrap";
import { useFormik } from "formik";

import styles from "./styles/customModal.module.css";

function CustomModal({
  isOpen,
  toggle,
  header,
  body,
  inititalValues,
  validateYupSchema,
  cancel,
  confirm,
  confirmFunction,
  size = "lg",
}) {
  const validation = useFormik({
    enableReinitialize: true,
    initialValues: inititalValues(),
    validationSchema: validateYupSchema(),
    onSubmit: (values) => {
      confirmFunction(values);
      validation.resetForm();
      toggle();
    },
  });

  return (
    <Modal
      isOpen={isOpen}
      toggle={toggle}
      centered
      size={size}
      className={styles.modal}
      modalClassName="zoomIn"
    >
      <ModalHeader toggle={toggle} className={styles.header}>
        <div className={styles.title}>{header}</div>
      </ModalHeader>
      <Form
        className="tablelist-form"
        onSubmit={(e) => {
          e.preventDefault();
          validation.handleSubmit();
          return false;
        }}
      >
        <ModalBody className={styles.body}>{body(validation)}</ModalBody>
        <ModalFooter className={styles.footer}>
          <div className="hstack gap-2 justify-content-end">
            <button
              onClick={toggle}
              type="button"
              className={`btn btn-light ${styles.close}`}
            >
              {cancel}
            </button>
            <button
              type="submit"
              className={`btn btn-success ${styles.submit}`}
              id="add-btn"
            >
              {confirm}
            </button>
          </div>
        </ModalFooter>
      </Form>
    </Modal>
  );
}

export default CustomModal;
