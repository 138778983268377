import React, { useState } from "react";

import styles from "./styles/button.module.css";

function Button({ children, style, onClick, active, disable,issel=false}) {
  const [selected,setSelect] = useState(false);   
  return (
    <div
      x-selected = {selected}
      className={`${styles.button} ${active ? styles.active : ""} ${selected ? styles.selected : "vs"} ${
        disable ? styles.disable : ""
      } ${style ? style : ""}`}
      onClick={disable ? null : ()=>{
        onClick();        
        if(issel)
          setSelect(selected?false:true);
        
      }}      
    >
      {children}
    </div>
  );
}

export default Button;
