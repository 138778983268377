import React from "react";
import ClipLoader from "react-spinners/ClipLoader";

import Pagination from "@areas/shared/components/paginations/Pagination";

import { ReactComponent as ArrowIcon } from "@content/img/shared/home/svg/arrow-icon.svg";
import defaultLogo from "@content/img/shared/company/png/default-logo.png";

import styles from "./styles/results.module.css";
import paginationStyles from "@areas/college/components/pagination/styles/pagination.module.css";
import { Link } from "react-router-dom";

function Results({ load, results, onChangePage }) {
  const getCountCompaniesByType = (type) => {
    let data = results.totals.filter(
      (p) => p.Name === "companies" && p.IdType === type
    );
    if (data.length > 0) {
      return data[0].Value;
    }
    return 0;
  };

  const getCountPractices = () => {
    let data = results.totals.filter((p) => p.Name === "practices");
    if (data.length > 0) {
      return data[0].Value;
    }
    return 0;
  };

  const getCountMentorships = () => {
    let data = results.totals.filter((p) => p.Name === "mentorships");
    if (data.length > 0) {
      return data[0].Value;
    }
    return 0;
  };

  const getPractices = (company) => {
    let res = [];
    company.Practices.forEach((practice, index) => {
      if (index < 2) {
        res.push(practice);
      }
    });
    return res;
  };

  const getMentorships = (company) => {
    let res = [];
    company.Mentorships.forEach((mentorship, index) => {
      if (index < 2) {
        res.push(mentorship);
      }
    });
    return res;
  };

  const getVacancies = (company) => {
    let res = [];
    company.Vacancies.forEach((vacancy, index) => {
      if (index < 2) {
        res.push(vacancy);
      }
    });
    return res;
  };

  return (
    <div className={styles.info}>
      {load ? (
        <div className="d-flex w-100 justify-content-center mt-4">
          <ClipLoader />
        </div>
      ) : (
        <>
          {/* <div className={styles.totals}>
            <span>Найдено</span>
            <span>{getCountCompaniesByType(1)}</span>
            <span>компаний,</span>
            <span>{getCountPractices()}</span>
            <span>практик,</span>
            <span>{getCountMentorships()}</span>
            <span>наставников</span>
          </div> */}
          <div className={styles.results}>
            {results.data.map((item) => (
              <div key={item.Id} className={styles.result}>
                <div className={styles.card}>
                  <div className={styles.info}>
                    <div className={styles.about}>
                      <img src={defaultLogo} alt="Результат" />
                      <div className={styles.title}>
                        {item.Shortname ? item.Shortname : item.Fullname}
                      </div>
                    </div>
                    <Link
                      to={`/company/${item.Slug}`}
                      className={styles.toCompany}
                      target="_blank"
                    >
                      Перейти к компании
                      <ArrowIcon />
                    </Link>
                    <div className={styles.actions}>
                      <div className={styles.practices}>
                        {item.Practices.length > 0 ? (
                          <div className={styles.title}>
                            <span>Практики</span>
                          </div>
                        ) : (
                          ""
                        )}
                        {getPractices(item).map((practice) => (
                          <div key={practice.Id} className={styles.practice}>
                            <div className={styles.name}>{practice.Name}</div>
                          </div>
                        ))}
                      </div>
                      <div className={styles.mentorships}>
                        {item.Mentorships.length > 0 ? (
                          <div className={styles.title}>
                            <span>Наставники</span>
                          </div>
                        ) : (
                          ""
                        )}
                        {getMentorships(item).map((mentorship) => (
                          <div
                            key={mentorship.Id}
                            className={styles.mentorship}
                          >
                            <div className={styles.name}>{mentorship.Fio}</div>
                            <span className={styles.description}>
                              {mentorship.Area}
                            </span>
                          </div>
                        ))}
                      </div>
                      <div className={styles.vacancies}>
                        {item.Vacancies.length > 0 ? (
                          <div className={styles.title}>
                            <span>Вакансии</span>
                          </div>
                        ) : (
                          ""
                        )}
                        {getVacancies(item).map((vacancy) => (
                          <div key={vacancy.Id} className={styles.vacancy}>
                            <div className={styles.name}>{vacancy.OkpdtrName}</div>
                          </div>
                        ))}
                      </div>
                    </div>
                  </div>
                  <Link
                    to={`/company/${item.Slug}`}
                    className={styles.more}
                    target="_blank"
                  >
                    <div className={styles.all}>Посмотреть все</div>
                  </Link>
                </div>
              </div>
            ))}
          </div>
        </>
      )}
      <Pagination
        load={load}
        itemsPerPage={2}
        totalItems={results.total}
        onChangePage={onChangePage}
        customStyles={{
          prev: paginationStyles.prev,
          current: paginationStyles.current,
          next: paginationStyles.next,
        }}
      />
    </div>
  );
}

export default Results;
