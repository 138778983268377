import React from "react";

import Layout from "./components/shared/Layout";

import Home from "./pages/home/Page";
import Education from "./pages/education/Page";
import Speciality from "./pages/speciality/Page";
import Event from "./pages/event/Page";
import Search from "./pages/search/Page";

import { routes as PersonalRoutes } from "@areas/shared/pages/personal/PageRoutes";

import { fetchWrapper } from "@helpers/fetchwrapper";
import { getIdBySlug } from "@helpers/extension";

export const routes = [
  {
    path: "",
    element: <Layout />,
    errorElement: <Layout error={true} />,
    children: [
      {
        path: "",
        element: <Home />,
        loader: async () => {
          return fetchWrapper.get(
            `/v1/api/app/mainPageDataAdvanced?climit=2&plimit=6&rlimit=6&elimit=5&type=companies`
          );
        },
      },
      {
        path: "search",
        element: <Search />,
      },
      {
        path: "kolledzhi-rb/:companyId",
        element: <Education />,
        loader: async ({ params }) => {
          let company = await fetchWrapper.get(
            `/v1/api/app/company/${getIdBySlug(params.companyId)}`
          );
          if (company.IdType === 1 || company.IdType === 2 || company.Slug !== params.companyId) {
            return Promise.reject("Not found");
          }
          return company;
        },
      },
      {
        path: "kolledzhi-rb/:companyId/speciality/:specialityId",
        element: <Speciality />,
        loader: async ({ params }) => {
          let idSpeciality = getIdBySlug(params.specialityId);
          let speciality = await fetchWrapper.get(
            `/v1/api/app/getfacultiyviewmodel/${idSpeciality}`
          );

          if (speciality.slug !== params.specialityId) {
            return Promise.reject("Not found");
          }

          let id = getIdBySlug(params.companyId);
          if (speciality.IdCompany !== id) {
            return Promise.reject("Not found");
          }

          let company = await fetchWrapper.get(
            `/v1/api/app/company/${id}`
          );

          if (company.Slug !== params.companyId) {
            return Promise.reject("Not found");
          }

          return { company, speciality };
        },
      },
      {
        path: "kolledzhi-rb/:companyId/event/:eventId",
        element: <Event />,
        loader: async ({ params }) => {
          let event = await fetchWrapper.get(
            `/v1/api/app/getEventById?idEvent=${params.eventId}`
          );

          let id = getIdBySlug(params.companyId);
          if (event.IdCompany !== id) {
            return Promise.reject("Not found");
          }

          let company = await fetchWrapper.get(
            `/v1/api/app/company/${id}`
          );

          if (company.Slug !== params.companyId) {
            return Promise.reject("Not found");
          }

          return { company, event };
        },
      },
      {
        path: "vuzy-rb/:companyId",
        element: <Education />,
        loader: async ({ params }) => {
          let company = await fetchWrapper.get(
            `/v1/api/app/company/${getIdBySlug(params.companyId)}`
          );
          if (company.IdType === 1 || company.IdType === 3 || company.Slug !== params.companyId) {
            return Promise.reject("Not found");
          }
          return company;
        },
      },
      {
        path: "vuzy-rb/:companyId/speciality/:specialityId",
        element: <Speciality />,
        loader: async ({ params }) => {
          let idSpeciality = getIdBySlug(params.specialityId);
          let speciality = await fetchWrapper.get(
            `/v1/api/app/getfacultiyviewmodel/${idSpeciality}`
          );

          if (speciality.slug !== params.specialityId) {
            return Promise.reject("Not found");
          }

          let id = getIdBySlug(params.companyId);
          if (speciality.IdCompany !== id) {
            return Promise.reject("Not found");
          }

          let company = await fetchWrapper.get(
            `/v1/api/app/company/${id}`
          );

          if (company.Slug !== params.companyId) {
            return Promise.reject("Not found");
          }

          return { company, speciality };
        },
      },
      {
        path: "vuzy-rb/:companyId/event/:eventId",
        element: <Event />,
        loader: async ({ params }) => {
          let event = await fetchWrapper.get(
            `/v1/api/app/getEventById?idEvent=${params.eventId}`
          );

          let id = getIdBySlug(params.companyId);
          if (event.IdCompany !== id) {
            return Promise.reject("Not found");
          }

          let company = await fetchWrapper.get(
            `/v1/api/app/company/${id}`
          );

          if (company.Slug !== params.companyId) {
            return Promise.reject("Not found");
          }
          
          return { company, event };
        },
      },
    ],
  },
  {
    path: "personal",
    children: PersonalRoutes,
  },
];
