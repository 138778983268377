import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  Form,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Col,
  FormFeedback,
  Input,
  Label,
  Row,
} from "reactstrap";
import { useFormik } from "formik";
import * as Yup from "yup";
import RiseLoader from "react-spinners/RiseLoader";
import ClipLoader from "react-spinners/ClipLoader";
import LoadBlock from "@areas/shared/components/loads/LoadBlock";

import { layoutActions, offerMentoringActions } from "@store";
import { fetchWrapper } from "@helpers/fetchwrapper";

import SelectAsyncPaginate from "@areas/shared/components/select/SelectAsyncPaginate";

import styles from "./styles/customModal.module.css";

function MentorshipModal() {
  const dispatch = useDispatch();
  const { company } = useSelector((state) => state.auth);
  const [load, setLoad] = useState(false);
  const [error, setError] = useState(null);

  const inititalValues = () => {
    return {
      fio: null,
      jobTitle: null,
      description: null,
      industry: null,
      public: false,
    };
  };

  const validationSchema = () => {
    return Yup.object({
      fio: Yup.string().required("Значение обязателено"),
      jobTitle: Yup.string().required("Значение обязателено"),
      description: Yup.string().required("Значение обязателено"),
      industry: Yup.object().required("Значение обязателено"),
    });
  };

  const toggle = () => {
    dispatch(layoutActions.setModal(null));
  };

  const validation = useFormik({
    enableReinitialize: true,
    initialValues: inititalValues(),
    validationSchema: validationSchema(),
    onSubmit: (values) => {
      setLoad(true);
      setError(null);
      let data = new FormData();

      data.append("fio", values.fio);
      data.append("jobTitle", values.jobTitle);
      data.append("description", values.description);
      data.append("idOkved2", values.industry.value);
      data.append("isPublic", values.public);
      data.append("idCompany", company.IdCompany);

      fetchWrapper
        .post(`/v1/personal/insertMentorship`, data, false)
        .then((mentor) => {
          dispatch(offerMentoringActions.setMentorship(mentor.Id));
          setLoad(false);
          toggle();
        })
        .catch((err) => {
          setError(err);
          setLoad(false);
        });
    },
  });

  const loadIndustryOptions = async (searchQuery, loadedOptions, { page }) => {
    const url = `/v1/api/app/getOkved2/?text=${searchQuery}&offset=${
      page * 10
    }&limit=10`;

    let result = await fetchWrapper.get(url);

    let options = result.data.map((p) => ({
      value: p.Id,
      label: p.Name,
    }));
    return {
      options: options,
      hasMore: result.total > page * 10,
      additional: {
        page: searchQuery ? 1 : page + 1,
      },
    };
  };

  return (
    <Modal
      isOpen={true}
      toggle={toggle}
      centered
      size={"lg"}
      className={styles.modal}
      modalClassName="zoomIn"
    >
      <ModalHeader toggle={toggle} className={styles.header}>
        <div className={styles.title}>Заявка</div>
      </ModalHeader>
      <Form
        className="tablelist-form"
        onSubmit={(e) => {
          e.preventDefault();
          console.log(validation.errors);
          validation.handleSubmit();
          return false;
        }}
      >
        <ModalBody className={styles.body}>
          <Row className="g-2">
            <Col lg={12}>
              {error ? (
                <FormFeedback type="invalid" className={styles.invalid}>
                  {error.message}
                </FormFeedback>
              ) : null}
            </Col>
            <Col lg={12}>
              <div>
                <Label htmlFor="mentorshipFio-field" className={styles.label}>
                  ФИО
                </Label>
                <Input
                  name="fio"
                  id="mentorshipFio-field"
                  className={`form-control ${styles.input}`}
                  placeholder="Введите ФИО контактного лица"
                  type="text"
                  value={validation.values.fio || ""}
                  onChange={validation.handleChange}
                  onBlur={validation.handleBlur}
                  invalid={
                    validation.touched.fio && validation.errors.fio
                      ? true
                      : false
                  }
                />
                {validation.touched.fio && validation.errors.fio ? (
                  <FormFeedback type="invalid" className={styles.invalid}>
                    {validation.errors.fio}
                  </FormFeedback>
                ) : null}
              </div>
            </Col>
            <Col lg={12}>
              <div>
                <Label
                  htmlFor="mentorshipJobTitle-field"
                  className={styles.label}
                >
                  Должность
                </Label>
                <Input
                  name="jobTitle"
                  id="mentorshipJobTitle-field"
                  className={`form-control ${styles.input}`}
                  placeholder="Введите должность контактного лица"
                  type="text"
                  value={validation.values.jobTitle || ""}
                  onChange={validation.handleChange}
                  onBlur={validation.handleBlur}
                  invalid={
                    validation.touched.jobTitle && validation.errors.jobTitle
                      ? true
                      : false
                  }
                />
                {validation.touched.jobTitle && validation.errors.jobTitle ? (
                  <FormFeedback type="invalid" className={styles.invalid}>
                    {validation.errors.jobTitle}
                  </FormFeedback>
                ) : null}
              </div>
            </Col>
            <Col lg={12}>
              <div>
                <Label
                  htmlFor="mentorshipDescription-field"
                  className={styles.label}
                >
                  Описание
                </Label>
                <Input
                  name="description"
                  id="mentorshipDescription-field"
                  className={`form-control ${styles.input}`}
                  placeholder="Опишите кратко свое предложение о наставничестве"
                  type="textarea"
                  value={validation.values.description || ""}
                  onChange={validation.handleChange}
                  onBlur={validation.handleBlur}
                  invalid={
                    validation.touched.description &&
                    validation.errors.description
                      ? true
                      : false
                  }
                  style={{ height: 60, resize: "none" }}
                />
                {validation.touched.description &&
                validation.errors.description ? (
                  <FormFeedback type="invalid" className={styles.invalid}>
                    {validation.errors.description}
                  </FormFeedback>
                ) : null}
              </div>
            </Col>
            <Col sm={12}>
              <div>
                <Label
                  htmlFor="practiceIndustry-field"
                  className={styles.label}
                >
                  Направление
                </Label>
                <SelectAsyncPaginate
                  name="industry"
                  id="practiceIndustry-field"
                  isMulti={false}
                  loadFunc={loadIndustryOptions}
                  labelFunc={(e) => e.Name}
                  valueFunc={(e) => e.Id}
                  value={validation.values.industry || 0}
                  placeholder="Выберите направление"
                  onChangeValue={(e) => {
                    validation.setFieldValue("industry", e);
                  }}
                  onBlurInput={() => validation.setFieldTouched("industry")}
                />
                {validation.touched.industry && validation.errors.industry ? (
                  <FormFeedback type="invalid" className={styles.invalid}>
                    {validation.errors.industry}
                  </FormFeedback>
                ) : null}
              </div>
            </Col>
            <Col lg={12}>
              <div className="d-flex gap-2 align-items-center">
                <Input
                  name="public"
                  id="mentorshipPublic-field"
                  className={`form-control ${styles.inputCheckbox}`}
                  placeholder="Опишите кратко свое предложение о наставничестве"
                  type="checkbox"
                  value={validation.values.public || ""}
                  onChange={validation.handleChange}
                  onBlur={validation.handleBlur}
                  invalid={
                    validation.touched.public && validation.errors.public
                      ? true
                      : false
                  }
                  style={{ height: 20, width: 20, resize: "none" }}
                />
                {validation.touched.public && validation.errors.public ? (
                  <FormFeedback type="invalid" className={styles.invalid}>
                    {validation.errors.public}
                  </FormFeedback>
                ) : null}
                <Label
                  htmlFor="mentorshipPublic-field"
                  className={`${styles.label} mt-2`}
                >
                  Открыть просмотр всем
                </Label>
              </div>
            </Col>
          </Row>
        </ModalBody>
        <ModalFooter className={`${styles.footer} justify-content-center`}>
          {load ? (
            <div className="d-flex justify-content-center w-100 mb-3">
              <RiseLoader color="#014170" />
            </div>
          ) : (
            <button
              type="submit"
              className={`btn btn-success ${styles.submit} w-50`}
              id="add-btn"
            >
              Сохранить
            </button>
          )}
        </ModalFooter>
        <LoadBlock visible={load}>
          <ClipLoader />
        </LoadBlock>
      </Form>
    </Modal>
  );
}

export default MentorshipModal;
