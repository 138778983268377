import React from "react";
import { Link } from "react-router-dom";

const Logo = ({ item }) => {
  return (
    <Link className={item.cName} to={item.path}>
      {item.iconSmall}
      {item.iconBig}
      <span>{item.title}</span>
    </Link>
  );
};

export default Logo;
