import React, { useCallback, useEffect, useState } from "react";
import { Col, FormFeedback, Input, Label, Row } from "reactstrap";
import Select from "react-select";
import * as Yup from "yup";
import moment from "moment";

import { fetchWrapper } from "@helpers/fetchwrapper";

import SelectAsyncPaginate from "@areas/shared/components/select/SelectAsyncPaginate";

import styles from "@areas/shared/components/personal/modals/styles/customModal.module.css";

const operatingModeOptions = [
  { value: "Полная", label: "Полная" },
  { value: "Частичная", label: "Частичная" },
];

const initialValues = (vacancy, company) => {
  return {
    id: (vacancy && vacancy.Id) || null,
    name: (vacancy && vacancy.Name) || "",
    number: (vacancy && vacancy.Number) || "",
    contactFioId: 0,
    contactFio: null,
    contactPositionId: 0,
    contactPosition: null,
    contactSiteId: 0,
    contactSite: null,
    contactPhoneId: 0,
    contactPhone: null,
    contactEmailId: 0,
    contactEmail: null,
    okz: vacancy
      ? {
          value: vacancy.IdOkz,
          label: vacancy.OkzName,
        }
      : null,
    okpdtr: vacancy
      ? {
          value: vacancy.IdOkpdtr,
          label: vacancy.OkpdtrName,
        }
      : null,
    count: (vacancy && vacancy.Count) || "",
    moneyFrom: (vacancy && vacancy.MoneyFrom) || "",
    moneyUntil: (vacancy && vacancy.MoneyUntil) || "",
    operatingMode: (vacancy && vacancy.OperatingMode) || "",
    experience: (vacancy && vacancy.Experience) || "",
    date: (vacancy && vacancy.Date) || "",
    additionalWishes: (vacancy && vacancy.AdditionalWishes) || "",
    skills: (vacancy && vacancy.Skills) || "",
    idCompany: (company && company.IdCompany) || 0,
  };
};

const validationSchema = () => {
  return Yup.object({
    name: Yup.string().required("Значение обязателено"),
    number: Yup.string().required("Значение обязателено"),
    okz: Yup.object().required("Значение обязателено"),
    okpdtr: Yup.object().required("Значение обязателено"),
    count: Yup.number(),
    moneyFrom: Yup.number(),
    moneyUntil: Yup.number(),
    operatingMode: Yup.string().required("Значение обязателено"),
    experience: Yup.number(),
    date: Yup.string().required("Значение обязателено"),
    additionalWishes: Yup.string().required("Значение обязателено"),
    skills: Yup.string().required("Значение обязателено"),
    contactFio: Yup.string().required("Значение обязателено"),
  });
};

const confirm = (values, successFunc) => {
  let data = new FormData();

  let id = values.id;

  data.append("name", values.name);
  data.append("number", values.number);
  data.append("idOkz", values.okz.value);
  data.append("idOkpdtr", values.okpdtr.value);
  data.append("count", values.count);
  data.append("moneyFrom", values.moneyFrom);
  data.append("moneyUntil", values.moneyUntil);
  data.append("operatingMode", values.operatingMode);
  data.append("experience", values.experience);
  data.append("date", values.date);
  data.append("additionalWishes", values.additionalWishes);
  data.append("skills", values.skills);
  data.append("idCompany", values.idCompany);

  if (id) {
    data.append("id", id);
    fetchWrapper
      .post(`/v1/personal/updateVacancy`, data, false)
      .then((res) => {
        checkContact(
          values.contactFioId,
          4,
          values.contactFio,
          values.idCompany,
          1,
          false,
          values.idCompany
        ).then((res) => {
          let contactId = 0;

          if (res.Id) {
            contactId = res.Id;
            checkContact(
              res.Id,
              4,
              values.contactFio,
              id,
              13,
              true,
              values.idCompany
            );
          } else {
            contactId = values.contactFioId;
            checkContact(
              values.contactFioId,
              4,
              values.contactFio,
              id,
              13,
              true,
              values.idCompany
            );
          }

          checkContact(
            values.contactEmailId,
            1,
            values.contactEmail,
            contactId,
            4,
            false,
            values.idCompany
          );
          checkContact(
            values.contactPhoneId,
            2,
            values.contactPhone,
            contactId,
            4,
            false,
            values.idCompany
          );
          checkContact(
            values.contactPositionId,
            5,
            values.contactPosition,
            contactId,
            4,
            false,
            values.idCompany
          );
          checkContact(
            values.contactSiteId,
            3,
            values.contactSite,
            contactId,
            4,
            false,
            values.idCompany
          );
          successFunc();
        });
      });
  } else {
    fetchWrapper
      .post(`/v1/personal/insertVacancy`, data, false)
      .then((pract) => {
        checkContact(
          values.contactFioId,
          4,
          values.contactFio,
          values.idCompany,
          1,
          false,
          values.idCompany
        ).then((res) => {
          let contactId = 0;

          if (res.Id) {
            contactId = res.Id;
            checkContact(
              res.Id,
              4,
              values.contactFio,
              pract.Id,
              13,
              true,
              values.idCompany
            );
          } else {
            contactId = values.contactFioId;
            checkContact(
              values.contactFioId,
              4,
              values.contactFio,
              pract.Id,
              13,
              true,
              values.idCompany
            );
          }

          checkContact(
            values.contactEmailId,
            1,
            values.contactEmail,
            contactId,
            4,
            false,
            values.idCompany
          );
          checkContact(
            values.contactPhoneId,
            2,
            values.contactPhone,
            contactId,
            4,
            false,
            values.idCompany
          );
          checkContact(
            values.contactPositionId,
            5,
            values.contactPosition,
            contactId,
            4,
            false,
            values.idCompany
          );
          checkContact(
            values.contactSiteId,
            3,
            values.contactSite,
            contactId,
            4,
            false,
            values.idCompany
          );

          successFunc();
        });
      });
  }
};

const checkContact = (
  id,
  type,
  value,
  parent,
  typeParent,
  needInsert,
  idCompany
) => {
  if (id > 0 && !needInsert) {
    let data = new FormData();
    data.append("id", id);
    data.append("idType", type);
    data.append("value", value);
    return fetchWrapper.post(`/v1/personal/updatecontact`, data, false);
  } else {
    let data = new FormData();
    if (id !== undefined && id !== null) {
      data.append("id", id);
    } else {
      data.append("id", 0);
    }
    data.append("idType", type);
    data.append("value", value);
    data.append("idParent", typeParent);
    return fetchWrapper.post(
      `/v1/personal/insertcontact?idCompany=${idCompany}&idParent=${parent}&typeParent=${typeParent}`,
      data,
      false
    );
  }
};

function ModalBodyAdd({ validation }) {
  const [contactPersons, setContactPersons] = useState([]);

  const { setFieldValue } = validation;

  const loadOkzOptions = async (searchQuery, loadedOptions, { page }) => {
    const url = `/v1/api/app/getOkzs/?text=${searchQuery}&offset=${
      page * 10
    }&limit=10`;

    let result = await fetchWrapper.get(url);

    let options = result.data.map((p) => ({
      value: p.Id,
      label: p.Name,
    }));
    return {
      options: options,
      hasMore: result.total > page * 10,
      additional: {
        page: searchQuery ? 1 : page + 1,
      },
    };
  };

  const loadOkpdtrOptions = async (searchQuery, loadedOptions, { page }) => {
    const url = `/v1/api/app/getOkpdtrs/?text=${searchQuery}&offset=${
      page * 10
    }&limit=10`;

    let result = await fetchWrapper.get(url);

    let options = result.data.map((p) => ({
      value: p.Id,
      label: p.Name,
    }));
    return {
      options: options,
      hasMore: result.total > page * 10,
      additional: {
        page: searchQuery ? 1 : page + 1,
      },
    };
  };

  const setContactPerson = useCallback(
    (id, value) => {
      if (id > 0) {
        fetchWrapper
          .get(`/v1/api/app/getContacts/?id_parent=${id}&id_parent_type=4`)
          .then((res) => {
            let sites = res.filter((p) => p.IdType === 3);
            let phones = res.filter((p) => p.IdType === 2);
            let emails = res.filter((p) => p.IdType === 1);
            let positions = res.filter((p) => p.IdType === 5);
            setFieldValue("contactFioId", id);
            setFieldValue("contactFio", value);
            setFieldValue(
              "contactPositionId",
              positions.length > 0 ? positions[0].Id : null
            );
            setFieldValue(
              "contactPosition",
              positions.length > 0 ? positions[0].Value : null
            );
            setFieldValue(
              "contactSiteId",
              sites.length > 0 ? sites[0].Id : null
            );
            setFieldValue(
              "contactSite",
              sites.length > 0 ? sites[0].Value : null
            );
            setFieldValue(
              "contactPhoneId",
              phones.length > 0 ? phones[0].Id : null
            );
            setFieldValue(
              "contactPhone",
              phones.length > 0 ? phones[0].Value : null
            );
            setFieldValue(
              "contactEmailId",
              emails.length > 0 ? emails[0].Id : null
            );
            setFieldValue(
              "contactEmail",
              emails.length > 0 ? emails[0].Value : null
            );
          });
      } else {
        setFieldValue("contactFio", null);
        setFieldValue("contactPositionId", 0);
        setFieldValue("contactPosition", null);
        setFieldValue("contactSiteId", 0);
        setFieldValue("contactSite", null);
        setFieldValue("contactPhoneId", 0);
        setFieldValue("contactPhone", null);
        setFieldValue("contactEmailId", 0);
        setFieldValue("contactEmail", null);
      }
    },
    [setFieldValue]
  );

  useEffect(() => {
    if (validation.values.idCompany) {
      fetchWrapper
        .get(
          `/v1/api/app/getContacts/?id_parent=${validation.values.idCompany}&id_parent_type=1`
        )
        .then((res) => {
          let data = res
            .filter((e) => e.IdType === 4)
            .map((e) => {
              return {
                value: e.Id,
                label: e.Value,
              };
            });
          data.unshift({
            value: 0,
            label: "Выберите контактное лицо из списка или заполните данные нового ниже",
          });
          setContactPersons(data);
        });
    }
  }, [validation.values.idCompany]);

  useEffect(() => {
    if (validation.values.id) {
      fetchWrapper
        .get(
          `/v1/api/app/getContacts/?id_parent=${validation.values.id}&id_parent_type=13`
        )
        .then((res) => {
          if (res.length > 0) {
            setContactPerson(res[0].Id, res[0].Value);
          }
        });
    }
  }, [validation.values.id, setContactPerson]);

  return (
    <Row className="g-2">
      <Input
        type="hidden"
        name="id"
        defaultValue={validation.values.id || null}
      />
      <Input
        type="hidden"
        name="idCompany"
        defaultValue={validation.values.idCompany || null}
      />
      <Col lg={8}>
        <div>
          <Label htmlFor="vacancyName-field" className={styles.label}>
            Название
          </Label>
          <Input
            name="name"
            id="vacancyName-field"
            className={`form-control ${styles.input}`}
            placeholder="Введите название"
            type="text"
            value={validation.values.name || ""}
            onChange={validation.handleChange}
            onBlur={validation.handleBlur}
            invalid={
              validation.touched.name && validation.errors.name ? true : false
            }
          />
          {validation.touched.name && validation.errors.name ? (
            <FormFeedback type="invalid" className={styles.invalid}>
              {validation.errors.name}
            </FormFeedback>
          ) : null}
        </div>
      </Col>
      <Col lg={4}>
        <div>
          <Label htmlFor="vacancyNumber-field" className={styles.label}>
            Номер
          </Label>
          <Input
            name="number"
            id="vacancyNumber-field"
            className={`form-control ${styles.input}`}
            placeholder="Введите номер"
            type="text"
            value={validation.values.number || ""}
            onChange={validation.handleChange}
            onBlur={validation.handleBlur}
            invalid={
              validation.touched.number && validation.errors.number
                ? true
                : false
            }
          />
          {validation.touched.number && validation.errors.number ? (
            <FormFeedback type="invalid" className={styles.invalid}>
              {validation.errors.number}
            </FormFeedback>
          ) : null}
        </div>
      </Col>
      <div className="border rounded p-2 border-2">
        <Col sm={12}>
          <div>
            <Label
              htmlFor="practiceSelectContactPerson-field"
              className={styles.label}
            >
              Контактное лицо
            </Label>
            <Select
              name="selectContactPerson"
              id="practiceSelectContactPerson-field"
              options={contactPersons}
              value={{
                value: validation.values.contactFioId || 0,
                label: validation.values.contactFio || "Выберите контактное лицо из списка или заполните данные нового ниже",
              }}
              placeholder="Выберите контактное лицо из списка или заполните данные нового ниже"
              onChange={(e) => {
                setContactPerson(e.value, e.label);
              }}
            />
          </div>
        </Col>
        <Col lg={12}>
          <div>
            <Label htmlFor="practiceContactFio-field" className={styles.label}>
              ФИО
            </Label>
            <Input
              name="contactFio"
              id="practiceContactFio-field"
              className={`form-control ${styles.input}`}
              placeholder="Введите ФИО нового контактного лица"
              type="text"
              value={validation.values.contactFio || ""}
              onChange={validation.handleChange}
              onBlur={validation.handleBlur}
              invalid={
                validation.touched.contactFio && validation.errors.contactFio
                  ? true
                  : false
              }
            />
            {validation.touched.contactFio && validation.errors.contactFio ? (
              <FormFeedback type="invalid" className={styles.invalid}>
                {validation.errors.contactFio}
              </FormFeedback>
            ) : null}
          </div>
        </Col>
        <Row>
          <Col lg={6}>
            <div>
              <Label
                htmlFor="practiceContactPosition-field"
                className={styles.label}
              >
                Должность
              </Label>
              <Input
                name="contactPosition"
                id="practiceContactPosition-field"
                className={`form-control ${styles.input}`}
                placeholder="Введите должность контактного лица"
                type="text"
                value={validation.values.contactPosition || ""}
                onChange={validation.handleChange}
                onBlur={validation.handleBlur}
                invalid={
                  validation.touched.contactPosition &&
                  validation.errors.contactPosition
                    ? true
                    : false
                }
              />
              {validation.touched.contactPosition &&
              validation.errors.contactPosition ? (
                <FormFeedback type="invalid" className={styles.invalid}>
                  {validation.errors.contactPosition}
                </FormFeedback>
              ) : null}
            </div>
          </Col>
          <Col lg={6}>
            <div>
              <Label
                htmlFor="practiceContactSite-field"
                className={styles.label}
              >
                Сайт
              </Label>
              <Input
                name="contactSite"
                id="practiceContactSite-field"
                className={`form-control ${styles.input}`}
                placeholder="Введите сайт контактного лица"
                type="text"
                value={validation.values.contactSite || ""}
                onChange={validation.handleChange}
                onBlur={validation.handleBlur}
                invalid={
                  validation.touched.contactSite &&
                  validation.errors.contactSite
                    ? true
                    : false
                }
              />
              {validation.touched.contactSite &&
              validation.errors.contactSite ? (
                <FormFeedback type="invalid" className={styles.invalid}>
                  {validation.errors.contactSite}
                </FormFeedback>
              ) : null}
            </div>
          </Col>
          <Col lg={6}>
            <div>
              <Label
                htmlFor="practiceContactPhone-field"
                className={styles.label}
              >
                Телефон
              </Label>
              <Input
                name="contactPhone"
                id="practiceContactPhone-field"
                className={`form-control ${styles.input}`}
                placeholder="Введите телефон контактного лица"
                type="text"
                value={validation.values.contactPhone || ""}
                onChange={validation.handleChange}
                onBlur={validation.handleBlur}
                invalid={
                  validation.touched.contactPhone &&
                  validation.errors.contactPhone
                    ? true
                    : false
                }
              />
              {validation.touched.contactPhone &&
              validation.errors.contactPhone ? (
                <FormFeedback type="invalid" className={styles.invalid}>
                  {validation.errors.contactPhone}
                </FormFeedback>
              ) : null}
            </div>
          </Col>
          <Col lg={6}>
            <div>
              <Label
                htmlFor="practiceContactEmail-field"
                className={styles.label}
              >
                Email
              </Label>
              <Input
                name="contactEmail"
                id="practiceContactEmail-field"
                className={`form-control ${styles.input}`}
                placeholder="Введите почту контактного лица"
                type="text"
                value={validation.values.contactEmail || ""}
                onChange={validation.handleChange}
                onBlur={validation.handleBlur}
                invalid={
                  validation.touched.contactEmail &&
                  validation.errors.contactEmail
                    ? true
                    : false
                }
              />
              {validation.touched.contactEmail &&
              validation.errors.contactEmail ? (
                <FormFeedback type="invalid" className={styles.invalid}>
                  {validation.errors.contactEmail}
                </FormFeedback>
              ) : null}
            </div>
          </Col>
        </Row>
      </div>
      <Col sm={6}>
        <div>
          <Label htmlFor="vacancyOkz-field" className={styles.label}>
            ОКЗ
          </Label>
          <SelectAsyncPaginate
            name="okz"
            id="vacancyOkz-field"
            isMulti={false}
            loadFunc={loadOkzOptions}
            labelFunc={(e) => e.Name}
            valueFunc={(e) => e.Id}
            value={validation.values.okz || 0}
            placeholder="Выберите ОКЗ"
            onChangeValue={(e) => {
              validation.setFieldValue("okz", e);
            }}
            onBlurInput={() => validation.setFieldTouched("okz")}
          />
          {validation.touched.okz && validation.errors.okz ? (
            <FormFeedback type="invalid" className={styles.invalid}>
              {validation.errors.okz}
            </FormFeedback>
          ) : null}
        </div>
      </Col>
      <Col sm={6}>
        <div>
          <Label htmlFor="vacancyOkpdtr-field" className={styles.label}>
            ОКПДТР
          </Label>
          <SelectAsyncPaginate
            name="okpdtr"
            id="vacancyOkpdtr-field"
            isMulti={false}
            loadFunc={loadOkpdtrOptions}
            labelFunc={(e) => e.Name}
            valueFunc={(e) => e.Id}
            value={validation.values.okpdtr || 0}
            placeholder="Выберите ОКПДТР"
            onChangeValue={(e) => {
              validation.setFieldValue("okpdtr", e);
            }}
            onBlurInput={() => validation.setFieldTouched("okpdtr")}
          />
          {validation.touched.okpdtr && validation.errors.okpdtr ? (
            <FormFeedback type="invalid" className={styles.invalid}>
              {validation.errors.okpdtr}
            </FormFeedback>
          ) : null}
        </div>
      </Col>
      <Col sm={4}>
        <div>
          <Label htmlFor="vacancyCount-field" className={styles.label}>
            Кол-во мест
          </Label>
          <Input
            name="count"
            id="vacancyCount-field"
            className={`form-control ${styles.input}`}
            placeholder="Введите кол-во мест"
            type="number"
            value={validation.values.count || 0}
            onChange={validation.handleChange}
            onBlur={validation.handleBlur}
            invalid={
              validation.touched.count && validation.errors.count ? true : false
            }
          />
          {validation.touched.count && validation.errors.count ? (
            <FormFeedback type="invalid" className={styles.invalid}>
              {validation.errors.count}
            </FormFeedback>
          ) : null}
        </div>
      </Col>
      <Col sm={4}>
        <div>
          <Label htmlFor="vacancyExperience-field" className={styles.label}>
            Стаж, в годах
          </Label>
          <Input
            name="experience"
            id="vacancyExperience-field"
            className={`form-control ${styles.input}`}
            placeholder="Введите стаж"
            type="number"
            value={validation.values.experience || 0}
            onChange={validation.handleChange}
            onBlur={validation.handleBlur}
            invalid={
              validation.touched.experience && validation.errors.experience
                ? true
                : false
            }
          />
          {validation.touched.experience && validation.errors.experience ? (
            <FormFeedback type="invalid" className={styles.invalid}>
              {validation.errors.experience}
            </FormFeedback>
          ) : null}
        </div>
      </Col>
      <Col lg={4}>
        <div>
          <Label htmlFor="vacancyDate-field" className={styles.label}>
            Дата актуальности
          </Label>
          <Input
            name="date"
            id="vacancyDate-field"
            className={`form-control ${styles.input}`}
            placeholder="Введите дату актуальности"
            type="date"
            value={
              validation.values.date
                ? moment(validation.values.date).format("YYYY-MM-DD")
                : ""
            }
            onChange={validation.handleChange}
            onBlur={validation.handleBlur}
            invalid={
              validation.touched.date && validation.errors.date ? true : false
            }
          />
          {validation.touched.date && validation.errors.date ? (
            <FormFeedback type="invalid" className={styles.invalid}>
              {validation.errors.date}
            </FormFeedback>
          ) : null}
        </div>
      </Col>
      <Col sm={4}>
        <div>
          <Label htmlFor="practiceWorkSchedule-field" className={styles.label}>
            Режим работы
          </Label>
          <Select
            name="operatingMode"
            id="practiceWorkSchedule-field"
            options={operatingModeOptions}
            placeholder="Выберите режим"
            value={
              validation.values.operatingMode
                ? operatingModeOptions.find(
                    (p) => p.value === validation.values.operatingMode
                  )
                : null
            }
            onChange={(e) => {
              validation.setFieldValue("operatingMode", e.value);
            }}
            onBlur={() => validation.setFieldTouched("operatingMode")}
          />
          {validation.touched.operatingMode &&
          validation.errors.operatingMode ? (
            <FormFeedback type="invalid" className={styles.invalid}>
              {validation.errors.operatingMode}
            </FormFeedback>
          ) : null}
        </div>
      </Col>
      <Col lg={4}>
        <div>
          <Label htmlFor="vacancyMoneyFrom-field" className={styles.label}>
            Зарплата от, рублей в месяц
          </Label>
          <Input
            name="moneyFrom"
            id="vacancyMoneyFrom-field"
            className={`form-control ${styles.input}`}
            placeholder="Введите зарплату"
            type="number"
            value={validation.values.moneyFrom || 0}
            onChange={validation.handleChange}
            onBlur={validation.handleBlur}
            invalid={
              validation.touched.moneyFrom && validation.errors.moneyFrom
                ? true
                : false
            }
          />
          {validation.touched.moneyFrom && validation.errors.moneyFrom ? (
            <FormFeedback type="invalid" className={styles.invalid}>
              {validation.errors.moneyFrom}
            </FormFeedback>
          ) : null}
        </div>
      </Col>
      <Col lg={4}>
        <div>
          <Label htmlFor="vacancyMoneyUntil-field" className={styles.label}>
            Зарплата до, рублей в месяц
          </Label>
          <Input
            name="moneyUntil"
            id="vacancyMoneyUntil-field"
            className={`form-control ${styles.input}`}
            placeholder="Введите зарплату"
            type="number"
            value={validation.values.moneyUntil || 0}
            onChange={validation.handleChange}
            onBlur={validation.handleBlur}
            invalid={
              validation.touched.moneyUntil && validation.errors.moneyUntil
                ? true
                : false
            }
          />
          {validation.touched.moneyUntil && validation.errors.moneyUntil ? (
            <FormFeedback type="invalid" className={styles.invalid}>
              {validation.errors.moneyUntil}
            </FormFeedback>
          ) : null}
        </div>
      </Col>
      <Col lg={12}>
        <div>
          <Label htmlFor="vacancySkills-field" className={styles.label}>
            Нужные навыки
          </Label>
          <Input
            name="skills"
            id="vacancySkills-field"
            className={`form-control ${styles.input}`}
            placeholder="Введите описание навыков"
            type="textarea"
            value={validation.values.skills || ""}
            onChange={validation.handleChange}
            onBlur={validation.handleBlur}
            invalid={
              validation.touched.skills && validation.errors.skills
                ? true
                : false
            }
            style={{ height: 60, resize: "none" }}
          />
          {validation.touched.skills && validation.errors.skills ? (
            <FormFeedback type="invalid" className={styles.invalid}>
              {validation.errors.skills}
            </FormFeedback>
          ) : null}
        </div>
      </Col>
      <Col lg={12}>
        <div>
          <Label
            htmlFor="vacancyAdditionalWishes-field"
            className={styles.label}
          >
            Дополнительные пожелания
          </Label>
          <Input
            name="additionalWishes"
            id="vacancyAdditionalWishes-field"
            className={`form-control ${styles.input}`}
            placeholder="Введите описание дополнительных пожеланий"
            type="textarea"
            value={validation.values.additionalWishes || ""}
            onChange={validation.handleChange}
            onBlur={validation.handleBlur}
            invalid={
              validation.touched.additionalWishes &&
              validation.errors.additionalWishes
                ? true
                : false
            }
            style={{ height: 60, resize: "none" }}
          />
          {validation.touched.additionalWishes &&
          validation.errors.additionalWishes ? (
            <FormFeedback type="invalid" className={styles.invalid}>
              {validation.errors.additionalWishes}
            </FormFeedback>
          ) : null}
        </div>
      </Col>
    </Row>
  );
}

export { initialValues, validationSchema, confirm, ModalBodyAdd };
