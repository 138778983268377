import React, { useCallback, useEffect, useState } from "react";
import { Card, CardBody, CardHeader, Row, Col } from "reactstrap";
import ClipLoader from "react-spinners/ClipLoader";

import Animation from "@areas/shared/components/chooseLayout/Animation";
import animationStyles from "@areas/shared/components/chooseLayout/styles/animations.module.css";

import LoadBlock from "@areas/shared/components/personal/loads/LoadBlock";

import { fetchWrapper } from "@helpers/fetchwrapper";

import AllTable from "./blocks/AllTable";

function Frame() {
  const [load, setLoad] = useState(false);
  const [data, setData] = useState({
    results: [],
    total: 0,
    totals: [],
  });
  const [search, setSearch] = useState("");

  const loadData = useCallback(
    (offset = 0, text) => {
      setLoad(true);
      if (text === null || text === undefined) {
        text = search;
      } else {
        setSearch(text);
      }
      fetchWrapper
        .get(
          `/v1/api/statistic/getEducationals?offset=${offset}&text=${text}&limit=10`
        )
        .then((res) => {
          setData({
            results: res.data,
            total: res.total,
            totals: res.totals,
          });
          setLoad(false);
        });
    },
    [search]
  );

  useEffect(() => {
    loadData();
  }, [loadData]);

  return (
    <div>
      <Animation
        animationIn={animationStyles.fadeIn}
        animationOut={animationStyles.fadeOut}
      >
        <Row className="ms-0 me-0">
          <Col className="ps-0 pe-lg-2 pe-0" lg={12}>
            <Card style={{ minHeight: 500 }}>
              <CardHeader>
                <Row className="d-flex justify-content-between align-items-center">
                  <Col className="d-flex justify-content-between align-items-center">
                    <h5 className="mb-0">Всего учебных заведений</h5>
                    <h5 className="mb-0">{data.total}</h5>
                  </Col>
                  <Col className="d-flex justify-content-between align-items-center">
                    <h5 className="mb-0">СПО</h5>
                    <h5 className="mb-0">
                      {" "}
                      {data.totals
                        .filter((x) => x.IdType === 3)
                        .reduce((a, b) => a + b.Value, 0)}
                    </h5>
                  </Col>
                  <Col className="d-flex justify-content-between align-items-center">
                    <h5 className="mb-0">ВПО</h5>
                    <h5 className="mb-0">
                      {data.totals
                        .filter((x) => x.IdType === 2)
                        .reduce((a, b) => a + b.Value, 0)}
                    </h5>
                  </Col>
                </Row>
              </CardHeader>
              <CardBody className="ps-0 pe-0">
                <AllTable
                  load={load}
                  data={data}
                  onChangePage={loadData}
                  setSearch={setSearch}
                />
                <LoadBlock visible={load}>
                  <ClipLoader />
                </LoadBlock>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Animation>
    </div>
  );
}

export default Frame;
