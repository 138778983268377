import React from "react";
import { Link } from "react-router-dom";
import * as IoIcons from "react-icons/tb";

import styles from "./styles/return.module.css";

function About({ data }) {
  return (
    <div className={styles.block}>
      <div className={`container ${styles.second}`}>
        <Link
          to={`/${
            data.company.IdType === 1
              ? "company"
              : data.company.IdType === 2
              ? "obrazovanie-v-bashkortostane/vuzy-rb"
              : "obrazovanie-v-bashkortostane/kolledzhi-rb"
          }/${data.company.Slug}`}
          className={styles.link}
          target="_blank"
        >
          <IoIcons.TbArrowLeft style={{ marginRight: 5 }} />
          {data.company.IdType === 1 ? "К компании" : "К учреждению "+data.company.Shortname}
        </Link>
      </div>
    </div>
  );
}

export default About;
