import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Modal, ModalBody, ModalFooter, ModalHeader } from "reactstrap";
import RiseLoader from "react-spinners/RiseLoader";

import {
  layoutActions,
  offerMentoringActions,
  findInternsActions,
} from "@store";

import { fetchWrapper } from "@helpers/fetchwrapper";

import Search from "./search/Page";

import styles from "../styles/customModal.module.css";

function ChooseEducationModal() {
  const dispatch = useDispatch();
  const { company } = useSelector((state) => state.auth);
  const { modalBefore } = useSelector((state) => state.layout);
  const { mentorship } = useSelector((state) => state.offerMentoring);
  const { practice } = useSelector((state) => state.findInterns);
  const [load, setLoad] = useState(false);
  const [selected, setSelected] = useState([]);

  const toggle = () => {
    dispatch(layoutActions.setModal(null));
  };

  const onSubmit = () => {
    setLoad(true);
    switch (modalBefore) {
      case "practice":
        fetchWrapper
          .post(
            `/v1/personal/sendTicketFromPractic?fromCompanyId=${company.IdCompany}&subject=Поиск стажеров&idPractice=${practice}`,
            selected.map((element) => element.Id)
          )
          .then(() => {
            dispatch(findInternsActions.setRequest(true));
            toggle();
          })
          .finally(() => setLoad(false));
        break;
      case "mentorship":
        fetchWrapper
          .post(
            `/v1/personal/sendTicketFromMentorship?fromCompanyId=${company.IdCompany}&subject=Предложение наставничества&idMentorship=${mentorship}`,
            selected.map((element) => element.Id)
          )
          .then(() => {
            dispatch(offerMentoringActions.setRequest(true));
            toggle();
          })
          .finally(() => setLoad(false));
        break;
      default:
        toggle();
        setLoad(false);
        break;
    }
    dispatch(layoutActions.setModalBefore(null));
  };

  return (
    <Modal
      isOpen={true}
      toggle={toggle}
      centered
      size={"xl"}
      className={styles.modal}
      modalClassName="zoomIn"
    >
      <ModalHeader toggle={toggle} className={styles.header}>
        <div className={styles.title}>Выбор учебных заведений</div>
      </ModalHeader>
      <ModalBody className={styles.body}>
        <Search selected={selected} setSelected={setSelected} />
      </ModalBody>
      <ModalFooter className={`${styles.footer} justify-content-center`}>
        {load ? (
          <div className="d-flex justify-content-center w-100 mb-3">
            <RiseLoader color="#014170" />
          </div>
        ) : (
          <button
            type="submit"
            className={`btn btn-success ${styles.submit} w-50`}
            id="add-btn"
            onClick={onSubmit}
          >
            Отправить
          </button>
        )}
      </ModalFooter>
    </Modal>
  );
}

export default ChooseEducationModal;
