import React from "react";
import { Row } from "reactstrap";

function FormBody() {
  return (
    <Row className="g-2">
      <div>Спасибо за обратную связь!</div>
    </Row>
  );
}

export { FormBody };
