import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";

import { fetchWrapper } from "../helpers/fetchwrapper";

const name = "auth";
const initialState = createInitialState();
const reducers = createReducers();
const extraActions = createExtraActions();
const extraReducers = createExtraReducers();
const slice = createSlice({ name, initialState, reducers, extraReducers });

export const authActions = { ...slice.actions, ...extraActions };
export const authReducer = slice.reducer;

function createInitialState() {
  return {
    user: null,
    company: null,
    token: localStorage.getItem("token"),
    error: null,
  };
}

function createReducers() {
  return {
    selectCompany,
    hasError,
    clearError,
    logout,
  };

  function selectCompany(state, action) {
    let find = state.user.Usercompanies.find(
      (c) => c.IdCompany === action.payload
    );
    if (find) {
      state.company = find;
    }
  }

  function clearError(state) {
    state.error = null;
  }

  function hasError(state, error) {
    state.error = error.payload;
  }

  function logout(state) {
    state.token = null;
    localStorage.removeItem("token");
  }
}

function createExtraActions() {
  const baseUrl = `/v1/personal`;

  return {
    me: me(),
    register: register(),
    registerV2: registerV2(),
    login: login(),
    refresh: refresh(),
    logout: logout(),
  };
  function me() {
    return createAsyncThunk(
      `${name}/me`,
      async () => await fetchWrapper.post(`${baseUrl}/getme`)
    );
  }

  function register() {
    return createAsyncThunk(
      `${name}/register`,
      async ({ lastname, firstname, middlename, email, password }) =>
        await fetchWrapper.post(`${baseUrl}/register`, {
          lastname,
          firstname,
          middlename,
          email,
          password,
        })
    );
  }

  function registerV2() {
    return createAsyncThunk(
      `${name}/registerV2`,
      async ({
        lastname,
        firstname,
        middlename,
        email,
        password,
        inn,
        kpp,
        companyType,
      }) =>
        await fetchWrapper.post(
          `${baseUrl}/registerV2?companyType=${companyType}`,
          {
            lastname,
            firstname,
            middlename,
            email,
            password,
            inn,
            kpp,
          }
        )
    );
  }

  function login() {
    return createAsyncThunk(
      `${name}/login`,
      async ({ login, password }) =>
        await fetchWrapper.post(`${baseUrl}/login`, {
          login,
          password,
        })
    );
  }

  function refresh() {
    return createAsyncThunk(
      `${name}/refresh-token`,
      async () =>
        await fetchWrapper.post(`${baseUrl}/refresh-token`, { token: null })
    );
  }

  function logout() {
    return createAsyncThunk(
      `${name}/logoff`,
      async () => await fetchWrapper.post(`${baseUrl}/logoff`, { token: null })
    );
  }
}

function createExtraReducers() {
  return (builder) => {
    me();
    register();
    registerV2();
    login();
    refresh();
    logout();
    function me() {
      let { pending, fulfilled, rejected } = extraActions.me;
      builder
        .addCase(pending, (state) => {
          state.error = null;
        })
        .addCase(fulfilled, (state, action) => {
          const user = action.payload;
          if (user.message) {
            state.error = user.message;
          } else {
            state.user = user;
            state.company =
              user.Usercompanies.length > 0 ? user.Usercompanies[0] : null;
          }
        })
        .addCase(rejected, (state, action) => {
          state.error = action.error;
        });
    }
    function register() {
      let { pending, fulfilled, rejected } = extraActions.register;
      builder
        .addCase(pending, (state) => {
          state.error = null;
        })
        .addCase(fulfilled, (state, action) => {
          const user = action.payload;
          if (user.message) {
            state.error = user.message;
          } else {
            localStorage.setItem("token", user.Token);
            state.token = user.Token;
          }
        })
        .addCase(rejected, (state, action) => {
          state.error = action.error;
        });
    }
    function registerV2() {
      let { pending, fulfilled, rejected } = extraActions.registerV2;
      builder
        .addCase(pending, (state) => {
          state.error = null;
        })
        .addCase(fulfilled, (state, action) => {
          const user = action.payload;
          if (user.message) {
            state.error = user.message;
          } else {
            localStorage.setItem("token", user.Token);
            state.token = user.Token;
          }
        })
        .addCase(rejected, (state, action) => {
          state.error = action.error;
        });
    }
    function login() {
      let { pending, fulfilled, rejected } = extraActions.login;
      builder
        .addCase(pending, (state) => {
          state.error = null;
        })
        .addCase(fulfilled, (state, action) => {
          const user = action.payload;
          if (user.message) {
            state.error = user.message;
          } else {
            localStorage.setItem("token", user.Token);
            state.token = user.Token;
          }
        })
        .addCase(rejected, (state, action) => {
          state.error = action.error;
        });
    }

    function refresh() {
      let { pending, fulfilled, rejected } = extraActions.refresh;
      builder
        .addCase(pending, (state) => {
          state.error = null;
        })
        .addCase(fulfilled, (state, action) => {
          const user = action.payload;

          localStorage.setItem("token", user.Token);
          state.token = user.Token;
        })
        .addCase(rejected, (state, action) => {
          state.error = action.error;
          state.user = null;
          state.token = null;
          localStorage.removeItem("token");
        });
    }

    function logout() {
      let { pending, fulfilled, rejected } = extraActions.logout;
      builder
        .addCase(pending, (state) => {
          state.error = null;
        })
        .addCase(fulfilled, (state, action) => {
          state.user = null;
          state.token = null;
          localStorage.removeItem("token");
        })
        .addCase(rejected, (state, action) => {
          state.error = action.error;
          state.user = null;
          state.token = null;
          localStorage.removeItem("token");
        });
    }
  };
}
