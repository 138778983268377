import React, { useEffect } from "react";
import { useLoaderData } from "react-router-dom";
import { useDispatch } from "react-redux";

import Animation from "@areas/shared/components/chooseLayout/Animation";
import animationStyles from "@areas/shared/components/chooseLayout/styles/animations.module.css";

import { metaActions } from "@store";

import About from "./blocks/About";
import Actions from "./blocks/Actions";
import Map from "./blocks/Map/Map";
import Categories from "./blocks/Categories";
import News from "./blocks/News";
import Partners from "./blocks/Partners";

function Page(props) {
  const data = useLoaderData();
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(
      metaActions.setData({
        icon: "faviconShared.ico",
        title: "Команда будущего",
        description:
          "Единая цифровая платформа, объединяющая колледжи, вузы и работодателей Республики Башкортостан",
      })
    );
  }, [dispatch]);

  return (
    <div>
      <Animation
        animationIn={animationStyles.swipeLeftIn}
        animationOut={animationStyles.swipeRightOut}
      >
        <About />
      </Animation>
      <Animation>
        <Actions data={data.Important} />
      </Animation>
      <Animation>
        <Map data={data.Companies} />
      </Animation>
      <Animation>
        <Categories />
      </Animation>
      <Animation>
        <News data={data.Pages} />
      </Animation>
      <Animation>
        <Partners />
      </Animation>
    </div>
  );
}

export default Page;
