import React from "react";
import { Col, FormFeedback, Input, Label, Row } from "reactstrap";
import * as Yup from "yup";

import { fetchWrapper } from "@helpers/fetchwrapper";

import SelectAsyncPaginate from "@areas/shared/components/select/SelectAsyncPaginate";

import styles from "@areas/shared/components/personal/modals/styles/customModal.module.css";

const initialValues = (way, parent) => {
  let parentSelect = null;
  if (parent) {
    parentSelect = {
      value: parent.Id,
      label: `${parent.Code} ${parent.Title}`,
    };
  }

  return {
    id: (way && way.Id) || null,
    code: (way && way.Code) || "",
    name: (way && way.Title) || "",
    parent: parentSelect,
  };
};

const validationSchema = () => {
  return Yup.object({});
};

const confirm = (values, successFunc) => {
  let data = new FormData();
  data.append("code", values.code);
  data.append("title", values.name);
  data.append("parent", values.parent ? values.parent.value : null);

  if (values.id) {
    data.append("id", values.id);

    fetchWrapper
      .post(`/v1/personal/updateOkso`, data, false)
      .then(() => successFunc(values.idCompany));
  } else {
    fetchWrapper
      .post(`/v1/personal/insertOkso`, data, false)
      .then(() => successFunc(values.idCompany));
  }
};

function ModalBodyAdd({ validation }) {
  return (
    <Row className="g-2">
      <Input
        type="hidden"
        name="id"
        defaultValue={validation.values.id || null}
      />
      <Col sm={4}>
        <div>
          <Label htmlFor="specialityCode-field" className={styles.label}>
            Код
          </Label>
          <Input
            name="code"
            id="specialityCode-field"
            className={`form-control ${styles.input}`}
            placeholder="Введите наименование"
            type="text"
            validate={{
              required: { value: true },
            }}
            onChange={validation.handleChange}
            onBlur={validation.handleBlur}
            value={validation.values.code || ""}
            invalid={
              validation.touched.code && validation.errors.code ? true : false
            }
          />
          {validation.touched.code && validation.errors.code ? (
            <FormFeedback type="invalid" className={styles.invalid}>
              {validation.errors.code}
            </FormFeedback>
          ) : null}
        </div>
      </Col>
      <Col sm={8}>
        <div>
          <Label htmlFor="specialityName-field" className={styles.label}>
            Наименование
          </Label>
          <Input
            name="name"
            id="specialityName-field"
            className={`form-control ${styles.input}`}
            placeholder="Введите наименование"
            type="text"
            validate={{
              required: { value: true },
            }}
            onChange={validation.handleChange}
            onBlur={validation.handleBlur}
            value={validation.values.name || ""}
            invalid={
              validation.touched.name && validation.errors.name ? true : false
            }
          />
          {validation.touched.name && validation.errors.name ? (
            <FormFeedback type="invalid" className={styles.invalid}>
              {validation.errors.name}
            </FormFeedback>
          ) : null}
        </div>
      </Col>
      <Col sm={12}>
        <div>
          <Label htmlFor="specialityParent-field" className={styles.label}>
            Родитель
          </Label>
          <SelectAsyncPaginate
            name="parent"
            id="specialityParent-field"
            url="/v1/api/app/getOkso"
            labelFunc={(e) => `${e.Code} ${e.Title}`}
            valueFunc={(e) => e.Id}
            placeholder="Выберите родителя"
            value={validation.values.parent || null}
            onChangeValue={(e) => {
              validation.setFieldValue("parent", e);
            }}
            onBlurInput={() => validation.setFieldTouched("parent")}
          />
        </div>
      </Col>
    </Row>
  );
}

export { initialValues, validationSchema, confirm, ModalBodyAdd };
