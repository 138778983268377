import React from "react";
import { Helmet } from "react-helmet-async";
import { useSelector } from "react-redux";

const SEO = () => {
  const { title, description, meta, link, icon } = useSelector((x) => x.meta);

  return (
    <Helmet
      title={title}
      htmlAttributes={{ lang: "ru" }}
      meta={meta.concat([
        {
          name: `description`,
          content: description,
        },
      ])}
      link={link.concat([{ rel: "icon", href: icon }])}
    />
  );
};

export default SEO;
