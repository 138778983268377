import React, { useEffect, useRef, useState } from "react";
import ClipLoader from "react-spinners/ClipLoader";

import CardArrow from "@areas/shared/components/cards/CardArrow";

import SearchIcon from "@areas/shared/components/icons/Search";

import { ReactComponent as RunSearchIcon } from "@content/img/shared/home/svg/map-search-run-icon.svg";
import { ReactComponent as ArrowIcon } from "@content/img/shared/home/svg/arrow-icon.svg";
import defaultLogo from "@content/img/shared/company/png/default-logo.png";

import Filter from "./Filter";

import { filterNames } from "@helpers/data/filterNames";

import styles from "./styles/search.module.css";
import { Link } from "react-router-dom";

function Search({
  load,
  query,
  search,
  filterUser,
  setFilterUser,
  results,
  setResults,
}) {
  const searchInput = useRef();
  const [filterUrl, setFilterUrl] = useState("");

  const getCountCompaniesByType = (type) => {
    let data = results.totals.filter(
      (p) => p.Name === "companies" && p.IdType === type
    );
    if (data.length > 0) {
      return data[0].Value;
    }
    return 0;
  };

  const getCountPractices = () => {
    let data = results.totals.filter((p) => p.Name === "practices");
    if (data.length > 0) {
      return data[0].Value;
    }
    return 0;
  };

  const getCountMentorships = () => {
    let data = results.totals.filter((p) => p.Name === "mentorships");
    if (data.length > 0) {
      return data[0].Value;
    }
    return 0;
  };

  const getPractices = (company) => {
    let res = [];
    company.Practices.forEach((practice, index) => {
      if (index < 2) {
        res.push(practice);
      }
    });
    return res;
  };

  const getMentorships = (company) => {
    let res = [];
    company.Mentorships.forEach((mentorship, index) => {
      if (index < 2) {
        res.push(mentorship);
      }
    });
    return res;
  };

  const getVacancies = (company) => {
    let res = [];
    company.Vacancies.forEach((vacancy, index) => {
      if (index < 2) {
        res.push(vacancy);
      }
    });
    return res;
  };

  useEffect(() => {
    let url = "";
    for (let i = 0; i < filterNames.length; i++) {
      url += `&${filterNames[i]}=${filterUser
        .filter((p) => p.name === filterNames[i])
        .map((p) => p.value)
        .join(",")}`;
    }
    setFilterUrl(url);
  }, [filterUser]);

  return (
    <CardArrow
      customStyles={{
        main: styles.cardSearch,
        border: styles.border,
        arrow: styles.arrow,
      }}
      sizeArrow={{ width: 33, height: 33 }}
    >
      <div className={styles.inner}>
        <Filter
          load={load}
          data={results.filter}
          values={filterUser}
          set={setFilterUser}
          submit={search}
        />
        <div className={styles.content}>
          <div className={styles.search}>
            <SearchIcon
              style={styles.icon}
              gradientId="searchIcon"
              gradientSteps={[
                {
                  offset: 0,
                  stopColor: "#00ECB3",
                },
                {
                  offset: 0,
                  stopColor: "#1CD1BD",
                },
                {
                  offset: 1,
                  stopColor: "#24D07D",
                },
              ]}
            />
            <input
              className={styles.input}
              type="text"
              placeholder="Поиск"
              onKeyDown={(e) => {
                if (e.key === "Enter") {
                  search(searchInput.current.value);
                }
              }}
              onChange={() => {
                setResults((p) => {
                  return {
                    ...p,
                    filter: p.filter.map((f) => ({
                      Type: f.Type,
                      Title: f.Title,
                      Name: f.Name,
                      Options: [],
                    })),
                  };
                });
                setFilterUser([]);
              }}
              ref={searchInput}
            />
            <button
              className={styles.button}
              onClick={() => search(searchInput.current.value)}
            >
              <RunSearchIcon />
            </button>
          </div>
          <div className={styles.info}>
            {load ? (
              <div className="d-flex w-100 justify-content-center mt-4">
                <ClipLoader />
              </div>
            ) : (
              <>
                {/* <div className={styles.totals}>
                  <span>Найдено</span>
                  <span>{getCountCompaniesByType(1)}</span>
                  <span>компаний,</span>
                  <span>{getCountPractices()}</span>
                  <span>практик,</span>
                  <span>{getCountMentorships()}</span>
                  <span>наставников</span>
                </div> */}
                <div className={styles.results}>
                  {results.data.map((item) => (
                    <div key={item.Id} className={styles.result}>
                      <div className={styles.card}>
                        <div className={styles.info}>
                          <div className={styles.about}>
                            <img src={defaultLogo} alt="Результат" />
                            <div className={styles.title}>
                              {item.Shortname ? item.Shortname : item.Fullname}
                            </div>
                          </div>
                          <Link
                            to={`/company/${item.Slug}`}
                            className={styles.toCompany}
                            target="_blank"
                          >
                            Перейти к компании
                            <ArrowIcon />
                          </Link>
                          <div className={styles.actions}>
                            <div className={styles.practices}>
                              {item.Practices.length > 0 ? (
                                <div className={styles.title}>
                                  <span>Практики</span>
                                </div>
                              ) : (
                                ""
                              )}
                              {getPractices(item).map((practice) => (
                                <div
                                  key={practice.Id}
                                  className={styles.practice}
                                >
                                  <div className={styles.name}>
                                    {practice.Name}
                                  </div>
                                </div>
                              ))}
                            </div>
                            <div className={styles.mentorships}>
                              {item.Mentorships.length > 0 ? (
                                <div className={styles.title}>
                                  <span>Наставники</span>
                                </div>
                              ) : (
                                ""
                              )}
                              {getMentorships(item).map((mentorship) => (
                                <div
                                  key={mentorship.Id}
                                  className={styles.mentorship}
                                >
                                  <div className={styles.name}>
                                    {mentorship.Fio}
                                  </div>
                                  <span className={styles.description}>
                                    {mentorship.Area}
                                  </span>
                                </div>
                              ))}
                            </div>
                            <div className={styles.vacancies}>
                              {item.Vacancies.length > 0 ? (
                                <div className={styles.title}>
                                  <span>Вакансии</span>
                                </div>
                              ) : (
                                ""
                              )}
                              {getVacancies(item).map((vacancy) => (
                                <div
                                  key={vacancy.Id}
                                  className={styles.vacancy}
                                >
                                  <div className={styles.name}>
                                    {vacancy.OkpdtrName}
                                  </div>
                                </div>
                              ))}
                            </div>
                          </div>
                        </div>
                        <Link
                          to={`/company/${item.Slug}`}
                          className={styles.more}
                          target="_blank"
                        >
                          <div className={styles.all}>Посмотреть все</div>
                        </Link>
                      </div>
                    </div>
                  ))}
                </div>
                <div className={styles.full}>
                  <Link
                    to={`search?query=${query}${filterUrl}`}
                    className={`${styles.more}`}
                    target="_blank"
                  >
                    <div className={`${styles.all}`}>Посмотреть все</div>
                  </Link>
                </div>
              </>
            )}
          </div>
        </div>
      </div>
    </CardArrow>
  );
}

export default Search;
