import React from "react";
import { Input, Row } from "reactstrap";
import * as Yup from "yup";
import { FilePond, registerPlugin } from "react-filepond";

import FilePondPluginImageExifOrientation from "filepond-plugin-image-exif-orientation";
import FilePondPluginImagePreview from "filepond-plugin-image-preview";

registerPlugin(FilePondPluginImageExifOrientation, FilePondPluginImagePreview);

const initialValues = (ticket, files) => {
  return {
    ticketId: (ticket && ticket.Id) || null,
    files: files || [],
  };
};

const validationSchema = () => {
  return Yup.object({});
};

function ModalBodyAddFiles({ validation }) {
  return (
    <Row className="g-2">
      <Input
        type="hidden"
        name="ticketId"
        defaultValue={validation.values.ticketId || null}
      />
      <FilePond
        files={validation.values.files || []}
        onupdatefiles={(e) => {
          validation.setFieldValue("files", e);
        }}
        name="files"
        allowMultiple={true}
        className="filepond filepond-input-multiple"
        labelIdle="Перетащите или нажмите, чтобы загрузить"
        onaddfile={() => validation.setFieldTouched("files")}
      />
    </Row>
  );
}

export { initialValues, validationSchema, ModalBodyAddFiles };
