import React from "react";

import Loader from "@areas/shared/components/chooseLayout/Loader";
import LayoutShared from "@areas/shared/components/chooseLayout/Layout";
import LayoutCommon from "@areas/shared/components/shared/Layout";

import { routes as SharedRoutes } from "@areas/shared/AreaRoutes";
import { routes as CollegeRoutes } from "@areas/college/AreaRoutes";
import { routes as EmployerRoutes } from "@areas/employer/AreaRoutes";
import { routes as SchoolboyRoutes } from "@areas/schoolboy/AreaRoutes";

export const routes = [
  {
    path: "/",
    element: <Loader />,
    children: [
      {
        path: "/",
        element: <LayoutShared />,
        children: [
          {
            path: "/",
            children: SharedRoutes,
          },
          {
            path: "/obrazovanie-v-bashkortostane",
            children: CollegeRoutes,
          },
          {
            path: "/company",
            children: EmployerRoutes,
          },
          {
            path: "/schoolboy",
            children: SchoolboyRoutes,
          },
        ],
      },
    ],
  },
  {
    path: "*",
    element: <Loader />,
    children: [
      {
        path: "*",
        element: <LayoutShared />,
        children: [
          {
            path: "*",
            element: <LayoutCommon error={true} />,
          },
        ],
      },
    ],
  },
];
