import React, { useEffect, useState } from "react";
import ClipLoader from "react-spinners/ClipLoader";

import { fetchWrapper } from "@helpers/fetchwrapper";

import CardArrow from "@areas/shared/components/cards/CardArrow";

import styles from "./styles/info.module.css";

function Info() {
  const [load, setLoad] = useState(false);
  const [data, setData] = useState({
    body: "",
  });

  useEffect(() => {
    setLoad(true);
    fetchWrapper
      .get(`/v1/api/app/getpagebyslug?slug=employer-offer-mentoring-addition`)
      .then((res) => setData(res))
      .finally(() => setLoad(false));
  }, []);
  return (
    <div className={styles.block}>
      <div className={`container ${styles.inner}`}>
        <CardArrow
          customStyles={{
            main: styles.card,
            border: styles.border,
            arrow: styles.arrow,
          }}
          rotate="rightTop"
          sizeArrow={{ width: 33, height: 33 }}
        >
          <div className={styles.content}>
            {load ? (
              <div className="d-flex justify-content-center w-100 mt-5">
                <ClipLoader />
              </div>
            ) : (
              <div dangerouslySetInnerHTML={{ __html: data.Body }}></div>
            )}
          </div>
        </CardArrow>
      </div>
    </div>
  );
}

export default Info;
