import React from "react";

import Layout from "./components/shared/Layout";

import Home from "./pages/home/Page";
import FindInterns from "./pages/findInterns/Page";
import OfferMentoring from "./pages/offerMentoring/Page";
import Company from "./pages/company/Page";
import Practice from "./pages/practice/Page";
import Mentorship from "./pages/mentorship/Page";
import Search from "./pages/search/Page";
import SearchCategories from "./pages/search-categories/Page";

import { routes as PersonalRoutes } from "@areas/shared/pages/personal/PageRoutes";

import { fetchWrapper } from "@helpers/fetchwrapper";
import { getIdBySlug } from "@helpers/extension";

export const routes = [
  {
    path: "",
    element: <Layout />,
    errorElement: <Layout error={true} />,
    children: [
      {
        path: "",
        element: <Home />,
        loader: async () => {
          return fetchWrapper.get(
            `/v1/api/app/mainPageDataAdvanced?climit=2&plimit=6&rlimit=6&type=collegesAndInstitutions`
          );
        },
      },
      {
        path: "services/predlozhit-nastavnichestvo",
        element: <OfferMentoring />,
        loader: async () => {
          return fetchWrapper.get(
            `/v1/api/app/mainPageDataAdvanced?climit=2&plimit=6&rlimit=6&type=collegesAndInstitutions`
          );
        },
      },
      {
        path: "services/naiti-praktikantov",
        element: <FindInterns />,
        loader: async () => {
          return fetchWrapper.get(
            `/v1/api/app/mainPageDataAdvanced?climit=2&plimit=6&rlimit=6&type=collegesAndInstitutions`
          );
        },
      },
      {
        path: "search",
        element: <Search />,
      },
      {
        path: "search-categories",
        element: <SearchCategories />,
      },
      {
        path: ":companyId",
        element: <Company />,
        loader: async ({ params }) => {
          let company = await fetchWrapper.get(
            `/v1/api/app/company/${getIdBySlug(params.companyId)}`
          );
          if (company.IdType !== 1 || company.Slug !== params.companyId) {
            return Promise.reject("Not found");
          }
          return company;
        },
      },
      {
        path: ":companyId/practice/:practiceId",
        element: <Practice />,
        loader: async ({ params }) => {
          let practice = await fetchWrapper.get(
            `/v1/api/app/getPracticyById?idPracticy=${params.practiceId}`
          );

          let id = getIdBySlug(params.companyId);
          if (practice.IdCompany !== id) {
            return Promise.reject("Not found");
          }

          let company = await fetchWrapper.get(
            `/v1/api/app/company/${id}`
          );

          if (company.Slug !== params.companyId) {
            return Promise.reject("Not found");
          }

          return { company, practice };
        },
      },
      {
        path: ":companyId/mentorship/:mentorshipId",
        element: <Mentorship />,
        loader: async ({ params }) => {
          let mentorship = await fetchWrapper.get(
            `/v1/api/app/getMentorshipById?idMentorship=${params.mentorshipId}`
          );

          let id = getIdBySlug(params.companyId);
          if (mentorship.IdCompany !== id) {
            return Promise.reject("Not found");
          }

          let company = await fetchWrapper.get(
            `/v1/api/app/company/${id}`
          );

          if (company.Slug !== params.companyId) {
            return Promise.reject("Not found");
          }

          return { company, mentorship };
        },
      },
    ],
  },
  {
    path: "personal",
    children: PersonalRoutes,
  },
];
