import React from "react";
import { Col, FormFeedback, Input, Label, Row } from "reactstrap";
import * as Yup from "yup";
import { toast } from "react-toastify";

import { fetchWrapper } from "@helpers/fetchwrapper";

import { companyProps } from "@helpers/data/companyProps";

import stylesModal from "@areas/shared/components/personal/modals/styles/customModal.module.css";

const initialValues = (company, prop) => {
  let keyIndex = 0;
  let value = null;

  if (prop) {
    companyProps.forEach((element, index) => {
      if (element.name === prop.Key) {
        keyIndex = index;
      }
    });
    switch (companyProps[keyIndex].type) {
      case 4:
        value = prop.Value === "0" ? 0 : 1;
        break;
      default:
        value = prop.Value;
        break;
    }
  }

  return {
    id: (prop && prop.Id) || null,
    keyIndex: keyIndex,
    value: value,
    idCompany: (company && company.IdCompany) || null,
  };
};

const validationSchema = () => {
  return Yup.object({});
};

const confirm = (values, successFunc) => {
  let data = new FormData();
  let type = companyProps[values.keyIndex].type;
  data.append("key", companyProps[values.keyIndex].name);
  data.append("idCompany", values.idCompany);
  data.append("idType", type);
  if (type === 4) {
    data.append("value", values.value ? 1 : 0);
  } else {
    data.append("value", values.value);
  }
  if (values.idCompany) {
    if (values.id && values.id > 0) {
      data.append("id", values.id);
      fetchWrapper
        .post(`/v1/personal/updateCompanyProperty`, data, false)
        .then(() => successFunc(values.idCompany));
    } else {
      fetchWrapper
        .post(`/v1/personal/insertCompanyProperty`, data, false)
        .then(() => successFunc(values.idCompany))
        .catch((error) => toast.error(error));
    }
  }
};

function FormBody({ validation }) {
  const checkTypeInputValue = (index, checkType) => {
    let prop = companyProps[index];
    let type;
    switch (prop.type) {
      case 1:
        type = "number";
        break;
      case 2:
        type = "text";
        break;
      case 3:
        type = "text";
        break;
      case 4:
        type = "checkbox";
        break;
      default:
        type = "text";
        break;
    }

    return type === checkType;
  };

  return (
    <Row className="g-2">
      <Input
        type="hidden"
        name="id"
        defaultValue={validation.values.id || null}
      />
      <Input
        type="hidden"
        name="idCompany"
        defaultValue={validation.values.idCompany || null}
      />
      <Col lg={12}>
        <div>
          <Label htmlFor="propKeyIndex-field" className={stylesModal.label}>
            Название
          </Label>
          <Input
            name="keyIndex"
            id="propKeyIndex-field"
            className={`form-control ${stylesModal.input}`}
            placeholder="Выберите параметр"
            type="select"
            value={validation.values.keyIndex || 0}
            onChange={validation.handleChange}
            onBlur={validation.handleBlur}
            invalid={
              validation.touched.keyIndex && validation.errors.keyIndex
                ? true
                : false
            }
            disabled={validation.values.id !== null ? true : false}
          >
            {companyProps.map((item, index) => (
              <option key={index} value={index}>
                {item.name}
              </option>
            ))}
          </Input>
          {validation.touched.keyIndex && validation.errors.keyIndex ? (
            <FormFeedback type="invalid" className={stylesModal.invalid}>
              {validation.errors.keyIndex}
            </FormFeedback>
          ) : null}
        </div>
      </Col>
      <Col
        lg={12}
        style={{
          display: `${
            checkTypeInputValue(validation.values.keyIndex, "text")
              ? "block"
              : "none"
          }`,
        }}
      >
        <div>
          <Label htmlFor="propValue-field" className={stylesModal.label}>
            Значение
          </Label>
          <Input
            name="value"
            id="propValue-field"
            className={`form-control ${stylesModal.input}`}
            placeholder="Введите значение"
            type="text"
            value={validation.values.value || ""}
            onChange={validation.handleChange}
            onBlur={validation.handleBlur}
            invalid={
              validation.touched.value && validation.errors.value ? true : false
            }
          />
          {validation.touched.value && validation.errors.value ? (
            <FormFeedback type="invalid" className={stylesModal.invalid}>
              {validation.errors.value}
            </FormFeedback>
          ) : null}
        </div>
      </Col>
      <Col
        lg={12}
        style={{
          display: `${
            checkTypeInputValue(validation.values.keyIndex, "number")
              ? "block"
              : "none"
          }`,
        }}
      >
        <div>
          <Label htmlFor="propValue-field" className={stylesModal.label}>
            Значение
          </Label>
          <Input
            name="value"
            id="propValue-field"
            className={`form-control ${stylesModal.input}`}
            placeholder="Введите значение"
            type="number"
            value={validation.values.value || 0}
            onChange={validation.handleChange}
            onBlur={validation.handleBlur}
            invalid={
              validation.touched.value && validation.errors.value ? true : false
            }
          />
          {validation.touched.value && validation.errors.value ? (
            <FormFeedback type="invalid" className={stylesModal.invalid}>
              {validation.errors.value}
            </FormFeedback>
          ) : null}
        </div>
      </Col>
      <Col
        lg={12}
        style={{
          display: `${
            checkTypeInputValue(validation.values.keyIndex, "checkbox")
              ? "block"
              : "none"
          }`,
        }}
      >
        <div>
          <Label htmlFor="propValue-field" className={stylesModal.label}>
            Значение
          </Label>
          <Input
            name="value"
            id="propValue-field"
            className={`form-control ${stylesModal.inputCheckbox}`}
            placeholder="Введите значение"
            type="checkbox"
            checked={validation.values.value || false}
            onChange={(event) => {
              validation.setFieldValue("value", !validation.values.value);
            }}
            onBlur={() => validation.setFieldTouched("value")}
            invalid={
              validation.touched.value && validation.errors.value ? true : false
            }
          />
          {validation.touched.value && validation.errors.value ? (
            <FormFeedback type="invalid" className={stylesModal.invalid}>
              {validation.errors.value}
            </FormFeedback>
          ) : null}
        </div>
      </Col>
    </Row>
  );
}

export { initialValues, validationSchema, confirm, FormBody };
