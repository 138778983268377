import React from "react";
import { Modal, ModalBody, ModalFooter, ModalHeader } from "reactstrap";

import styles from "./styles/customModal.module.css";

function CustomModalInfo({
  isOpen,
  toggle,
  header,
  body,
  cancel,
  size = "lg",
}) {
  return (
    <Modal
      isOpen={isOpen}
      toggle={toggle}
      size={size}
      className={styles.modal}
    >
      <ModalHeader toggle={toggle} className={styles.header}>
        <div className={styles.title}>{header}</div>
      </ModalHeader>
      <ModalBody className={styles.body}>{body()}</ModalBody>
      <ModalFooter className={styles.footer}>
        <div className="hstack gap-2 justify-content-end">
          <button
            onClick={toggle}
            type="button"
            className={`btn btn-light ${styles.close}`}
          >
            {cancel}
          </button>
        </div>
      </ModalFooter>
    </Modal>
  );
}

export default CustomModalInfo;
