import React, { useEffect, useState, useCallback } from "react";
import { Link } from "react-router-dom";

import { fetchWrapper } from "@helpers/fetchwrapper";

import telegramLogo from "@content/img/shared/layout/svg/telegram-logo.svg";
import whatsappLogo from "@content/img/shared/layout/svg/whatsapp-logo.svg";
import vkLogo from "@content/img/shared/layout/svg/vk-logo.svg";

import styles from "./styles/footer.module.css";

function Footer() {
  const [data, setData] = useState(null);

  const loadData = useCallback(() => {
    fetchWrapper.get("/data/footer.json").then((res) => {
      setData(res);
    });
  }, []);

  const getIcon = (name) => {
    switch (name) {
      case "telegram":
        return telegramLogo;
      case "whatsapp":
        return whatsappLogo;
      case "vk":
        return vkLogo;
      default:
        return telegramLogo;
    }
  };

  useEffect(() => {
    loadData();
  }, [loadData]);

  return (
    <footer className={`border-top mb-3 pt-3 ${styles.block}`}>
      {data ? (
        <div className="container d-flex justify-content-between align-items-center">
          <div className="d-flex flex-column align-items-center">
            <img src={data.byIcon} width={150} alt="logo" className="mb-3" />
            <p className={`${styles.copyright}`}>
              Copyright &copy; {new Date().getFullYear()}
            </p>
          </div>
          <div className={styles.contacts}>
            {data.social.map((item, index) => (
              <div
                key={index}
                className={`d-flex justify-content-end align-items-center ${styles.social}`}
              >
                <Link to={item.link} target="_blank" className={styles.link}>
                  {item.title}
                </Link>
                <img
                  width={item.iconWidth}
                  src={getIcon(item.icon)}
                  alt={item.title}
                />
              </div>
            ))}
          </div>
        </div>
      ) : null}
    </footer>
  );
}

export default Footer;
