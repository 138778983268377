import React from "react";

import Animation from "@areas/shared/components/chooseLayout/Animation";
import animationStyles from "@areas/shared/components/chooseLayout/styles/animations.module.css";

function Error(props) {
  return (
    <div
      className="d-flex justify-content-center align-items-center"
      style={{ minHeight: "calc(100vh - 320px)" }}
    >
      <Animation
        animationIn={animationStyles.swipeLeftIn}
        animationOut={animationStyles.swipeRightOut}
      >
        <div className="text-center">
          <div className="h3">404</div>
          <div>Страница не найдена</div>
        </div>
      </Animation>
    </div>
  );
}

export default Error;
