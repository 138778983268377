import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as Yup from "yup";
import { useSelector, useDispatch } from "react-redux";
import RiseLoader from "react-spinners/RiseLoader";
import { TbEye, TbEyeOff } from "react-icons/tb";

import { history } from "@helpers/history";
import { authActions } from "@store";

import CardArrow from "@areas/shared/components/cards/CardArrow";

import styles from "./styles/login.module.css";

function Login({ confirmed, error }) {
  const dispatch = useDispatch();
  const authToken = useSelector((x) => x.auth.token);
  const [authError, setAuthError] = useState(null);
  const [showPassword, setShowPassword] = useState(false);

  useEffect(() => {
    if (authToken) {
      history.navigate("/personal", { replace: true });
    }
  }, [authToken]);

  const validationSchema = Yup.object().shape({
    login: Yup.string()
      .email("Некорректный email")
      .required("Email обязателен"),
    password: Yup.string().required("Пароль обязателен"),
  });
  const formOptions = { resolver: yupResolver(validationSchema) };

  const { register, handleSubmit, formState } = useForm(formOptions);
  const { errors, isSubmitting } = formState;

  function onSubmit({ login, password }) {
    return dispatch(authActions.login({ login, password }))
      .unwrap()
      .then((result) => {
        if (!result.message) {
          const { from } = history.location.state || {
            from: { pathname: "/personal" },
          };
          history.navigate(from.pathname);
        } else {
          setAuthError(result);
        }
      })
      .catch((rejectedValueOrSerializedError) => {
        setAuthError(rejectedValueOrSerializedError);
      });
  }

  return (
    <div className={styles.block}>
      <div>
        {confirmed ? (
          <div className="alert alert-success text-center">
            Email подтвержден!
          </div>
        ) : null}
        {error ? (
          <div className="alert alert-danger text-center">{error}</div>
        ) : null}
        <CardArrow
          customStyles={{
            main: styles.card,
          }}
          sizeArrow={{ width: 20, height: 20 }}
          rotate="rightTop"
        >
          <form className={styles.inner} onSubmit={handleSubmit(onSubmit)}>
            <div className={styles.title}>Вход</div>
            <div className={styles.formGroup}>
              <input
                type="text"
                {...register("login")}
                className={`${errors.login ? styles.inputError : styles.input}`}
                placeholder="Email"
              />
              <div className={styles.errorMessage}>{errors.login?.message}</div>
            </div>
            <div className={styles.formGroup}>
              <input
                type={showPassword ? "text" : "password"}
                {...register("password")}
                className={`${
                  errors.password ? styles.inputError : styles.input
                }`}
                placeholder="Пароль"
              />
              <div className={styles.showPassword}>
                {showPassword ? (
                  <TbEyeOff onClick={() => setShowPassword(false)} />
                ) : (
                  <TbEye onClick={() => setShowPassword(true)} />
                )}
              </div>
              <div className={styles.errorMessage}>
                {errors.password?.message}
              </div>
            </div>
            <div className={styles.submit}>
              {isSubmitting ? (
                <RiseLoader className={styles.bar} color="#014170" />
              ) : (
                <>
                  <button className={styles.btn}>Войти</button>
                  {authError && (
                    <div className={styles.submitError}>
                      {authError.message}
                    </div>
                  )}
                </>
              )}
            </div>
            <div className={styles.addition}>
              <Link to="/personal/register" className={styles.link}>
                Создать аккаунт
              </Link>
              <Link to="/personal/forgot" className={styles.link}>
                Забыли пароль?
              </Link>
            </div>
          </form>
        </CardArrow>
      </div>
    </div>
  );
}

export default Login;
