import React, { useState } from "react";

import Item from "./Item";

import styles from "../styles/sidebar.module.css";

const HasSubItems = ({
  hide,
  item,
  comparePaths,
  location,
  checkPermission,
}) => {
  const [subNav, setSubNav] = useState(false);

  const showSubNav = () => setSubNav(!subNav);

  return (
    checkPermission(item) && (
      <div
        className={
          subNav ? `${styles.hasSubNav} ${styles.active}` : styles.hasSubNav
        }
      >
        <div
          onClick={showSubNav}
          className={
            comparePaths("sub", location.pathname, item.path)
              ? `${item.cName} ${styles.active}`
              : item.cName
          }
        >
          <>
            <div className={styles.title}>
              {item.icon}
              <span>{item.title}</span>
            </div>
            {item.subNav ? item.iconClosed : item.iconOpen}
          </>
        </div>
        <div className={styles.subNav}>
          {item.subNav.map((item, index) => {
            return (
              <Item
                item={item}
                key={index}
                hide={hide}
                location={location}
                comparePaths={comparePaths}
                checkPermission={checkPermission}
              />
            );
          })}
        </div>
      </div>
    )
  );
};

export default HasSubItems;
