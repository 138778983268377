import React from "react";
import styles from "./styles/results.module.css";
import { Link } from "react-router-dom";

function Mentor({ data }) {
  return (
    <>
      {data.map((mentor) => (
        <div className={styles.result}>
          <div className={styles.card} key={mentor.Id}>
            <div className={styles.type}>{mentor.NameCompany}</div>
            <div className={styles.header}>{mentor.Fio}</div>
            <div className={styles.description}>{mentor.Area}</div>
            <div className={styles.footer}>
              <div className={styles.date}></div>
              <Link
                to={`/company/${mentor.SlugCompany}/mentorship/${mentor.Id}`}
                target="_blank"
                className={styles.addition}
              >
                Ознакомиться подробнее →
              </Link>
            </div>
          </div>
        </div>
      ))}
    </>
  );
}
export default Mentor;
