import React from "react";

import Honeycombs from "@areas/shared/components/background/Honeycombs";
import Chooser from "@areas/employer/components/choose/Chooser";

import findInternsMain from "@content/img/png/findInternsMain.png";
import honeycombListDot from "@content/img/svg/honeycombListDot.svg";
import interactionWithUniversity from "@content/img/svg/interactionWithUniversity.svg";
import listUniversity from "@content/img/svg/listUniversity.svg";
import adsFree from "@content/img/svg/adsFree.svg";
import individualRequest from "@content/img/svg/individualRequest.svg";

import styles from "./styles/about.module.css";

function About(props) {
  return (
    <div className={styles.block}>
      <Honeycombs style={styles.background} filter="#9A4610" />
      <div className={`container ${styles.content}`}>
        <div className={styles.image}>
          <img src={findInternsMain} alt="Найти стажеров" />
        </div>
        <div className={styles.info}>
          <div className={styles.header}>
            ВОСПОЛЬЗУЙТЕСЬ УНИКАЛЬНЫМ СЕРВИСОМ ПОИСКА СТАЖЕРОВ/ПРАКТИКАНТОВ НА
            ПЛАТФОРМЕ “КОМАНДА БУДУЩЕГО”
          </div>
          <ul className={styles.list}>
            <li>
              <div className={styles.dot}>
                <img
                  className={styles.container}
                  src={honeycombListDot}
                  alt="•"
                />
                <img
                  className={styles.icon}
                  src={interactionWithUniversity}
                  alt="Взаимодействие"
                />
              </div>
              <div className={styles.text}>
                Напрямую взаимодействовать с нужными учебными заведениями
              </div>
            </li>
            <li>
              <div className={styles.dot}>
                <img
                  className={styles.container}
                  src={honeycombListDot}
                  alt="•"
                />
                <img
                  className={styles.icon}
                  src={listUniversity}
                  alt="Перечень"
                />
              </div>
              <div className={styles.text}>
                Выбирать из полноценного перечня профессиональных учебных
                заведений Республики Башкортостан
              </div>
            </li>
            <li>
              <div className={styles.dot}>
                <img
                  className={styles.container}
                  src={honeycombListDot}
                  alt="•"
                />
                <img className={styles.icon} src={adsFree} alt="Объявление" />
              </div>
              <div className={styles.text}>
                Бесплатно разместить свое объявление
              </div>
            </li>
            <li>
              <div className={styles.dot}>
                <img
                  className={styles.container}
                  src={honeycombListDot}
                  alt="•"
                />
                <img
                  className={styles.icon}
                  src={individualRequest}
                  alt="Заявка"
                />
              </div>
              <div className={styles.text}>
                Оформить индивидуальную заявку и обсуждать ее, не выходя с
                платформы
              </div>
            </li>
          </ul>
        </div>
      </div>
      <Chooser
        gradientId="chooserBackground"
        gradientSteps={[
          {
            offset: 0,
            stopColor: "#CBEC00",
          },
          {
            offset: 0.5,
            stopColor: "#700101",
          },
          {
            offset: 1,
            stopColor: "#D0A024",
          },
        ]}
      />
    </div>
  );
}

export default About;
