import { createSlice } from "@reduxjs/toolkit";

const name = "offerMentoring";
const initialState = createInitialState();
const reducers = createReducers();
const slice = createSlice({ name, initialState, reducers });

export const offerMentoringActions = { ...slice.actions };
export const offerMentoringReducer = slice.reducer;

function createInitialState() {
  return {
    mentorship: localStorage.getItem("offerMentoringMentorship"),
    request: localStorage.getItem("offerMentoringRequest"),
  };
}

function createReducers() {
  return {
    setMentorship,
    setRequest,
  };

  function setMentorship(state, mentorship) {
    if (!mentorship.payload) {
      localStorage.removeItem("offerMentoringMentorship");
      state.mentorship = null;
    } else {
      localStorage.setItem("offerMentoringMentorship", mentorship.payload);
      state.mentorship = mentorship.payload;
    }
  }

  function setRequest(state, request) {
    if (!request.payload) {
      localStorage.removeItem("offerMentoringRequest");
      state.request = null;
    } else {
      localStorage.setItem("offerMentoringRequest", request.payload);
      state.request = request.payload;
    }
  }
}
