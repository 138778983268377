import React, { useEffect, useState } from "react";
import { Form, Card, CardBody, CardHeader } from "reactstrap";
import { useFormik } from "formik";
import { useSelector } from "react-redux";
import ClipLoader from "react-spinners/ClipLoader";

import Animation from "@areas/shared/components/chooseLayout/Animation";
import animationStyles from "@areas/shared/components/chooseLayout/styles/animations.module.css";

import {
  FormBody,
  initialValues,
  validationSchema,
  confirm,
} from "./blocks/Form";
import LoadBlock from "@areas/shared/components/personal/loads/LoadBlock";

import { fetchWrapper } from "@helpers/fetchwrapper";

import styles from "@areas/shared/components/personal/modals/styles/customModal.module.css";

function Frame(props) {
  const { company: currentCompany } = useSelector((x) => x.auth);
  const [load, setLoad] = useState(false);
  const [data, setData] = useState([]);

  const loadData = (id) => {
    setLoad(true);
    fetchWrapper.get(`/v1/api/app/companyPage?company=${id}&type=9`).then((res) => {
      setData(res);
      setLoad(false);
    });
  };

  useEffect(() => {
    if (currentCompany) {
      loadData(currentCompany.IdCompany);
    }
  }, [currentCompany]);

  const inititalValues = () => {
    return initialValues(currentCompany, data);
  };

  const validation = useFormik({
    enableReinitialize: true,
    initialValues: inititalValues(),
    validationSchema: validationSchema(),
    onSubmit: (values) => {
      confirm(values, setLoad);
    },
  });

  return (
    <div>
      <Animation
        animationIn={animationStyles.fadeIn}
        animationOut={animationStyles.fadeOut}
      >
        <Card>
          <CardHeader>
            <div className="d-flex justify-content-between align-items-center">
              <h5 className="mb-0">Проекты студентов</h5>
            </div>
          </CardHeader>
          <CardBody>
            <Form
              className="tablelist-form"
              onSubmit={(e) => {
                e.preventDefault();
                validation.handleSubmit();
                return false;
              }}
            >
              <FormBody validation={validation} />
              <div className={`mt-3 ${styles.footer}`}>
                <div className="hstack gap-2 justify-content-end">
                  <button
                    type="submit"
                    className={`btn btn-success ${styles.submit}`}
                    id="add-btn"
                  >
                    Сохранить
                  </button>
                </div>
              </div>
            </Form>
            <LoadBlock visible={load}>
              <ClipLoader />
            </LoadBlock>
          </CardBody>
        </Card>
      </Animation>
    </div>
  );
}

export default Frame;
