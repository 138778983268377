import React, { useState } from "react";
import { TbDownload, TbTrash } from "react-icons/tb";
import { Link } from "react-router-dom";
import { Card, CardBody, CardHeader, Col, Table } from "reactstrap";
import ClipLoader from "react-spinners/ClipLoader";

import LoadBlock from "@areas/shared/components/personal/loads/LoadBlock";
import CustomModalDelete from "@areas/shared/components/personal/modals/CustomModalDelete";

import { fetchWrapper } from "@helpers/fetchwrapper";

import styles from "./styles/detail.module.css";

const Details = ({ load, data, refreshData, list }) => {
  const [showModalDelete, setShowModalDelete] = useState(false);
  const [fileId, setFileId] = useState(null);

  const toggleDelete = (id) => {
    setFileId(id);
    setShowModalDelete(true);
  };

  const handleDelete = (id, fileId) => {
    fetchWrapper
      .post(`/v1/personal/deleteFileTicket/?idTicket=${id}&idFile=${fileId}`)
      .then(() => {
        setShowModalDelete(false);
        refreshData(id);
      });
  };

  return (
    <React.Fragment>
      <Col xxl={3}>
        <Card>
          <CardHeader>
            <h5 className="card-title mb-0">Детали</h5>
          </CardHeader>
          <CardBody>
            <div className="table-responsive table-card">
              <Table className="table-borderless align-middle mb-0">
                <tbody>
                  <tr>
                    <td className="fw-medium">Номер:</td>
                    <td>
                      <span id="t-no">{data.Id}</span>
                    </td>
                  </tr>
                  <tr>
                    <td className="fw-medium">От кого:</td>
                    <td id="t-client">{data.FromCompany}</td>
                  </tr>
                  <tr>
                    <td className="fw-medium">Кому:</td>
                    <td id="t-client">{data.ToCompany}</td>
                  </tr>
                  <tr>
                    <td className="fw-medium">Статус:</td>
                    <td>
                      <div
                        className="badge rounded-pill bg-info fs-12"
                        id="ticket-status"
                      >
                        {data.Status}
                      </div>
                    </td>
                  </tr>
                </tbody>
              </Table>
            </div>
            <LoadBlock visible={load}>
              <ClipLoader />
            </LoadBlock>
          </CardBody>
        </Card>
        <Card>
          <CardHeader>
            <div className="d-flex align-items-center justify-content-between">
              <h6 className="card-title mb-0">Прикрепленные файлы</h6>
            </div>
          </CardHeader>
          <CardBody>
            {data &&
              data.Filelinks &&
              data.Filelinks.map((item) => {
                return (
                  <div
                    key={item.Id}
                    className="d-flex align-items-center border border-dashed p-2 rounded mb-2"
                  >
                    <div className="flex-grow-1 ms-3">
                      <h6 className="mb-0">{item.Name}</h6>
                    </div>
                    <div className="hstack gap-3 fs-16">
                      <Link
                        to={`/v1/api/fileManager/getFile?path=${item.Link}`}
                        className="text-muted"
                        download={true}
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        <TbDownload />
                      </Link>
                      {list === "from" ? (
                        <div
                          className="text-muted"
                          role="button"
                          onClick={() => toggleDelete(item.Id)}
                        >
                          <TbTrash />
                        </div>
                      ) : null}
                    </div>
                  </div>
                );
              })}
            <LoadBlock visible={load}>
              <ClipLoader />
            </LoadBlock>
          </CardBody>
        </Card>
        <Card>
          <CardBody className="d-flex align-items-center justify-content-center">
            <Link
              to={`/personal/requests/list-${list}`}
              className={styles.link}
            >
              Список заявок
            </Link>
          </CardBody>
        </Card>
      </Col>
      <CustomModalDelete
        isOpen={showModalDelete}
        onDeleteClick={() => handleDelete(data.Id, fileId)}
        onCloseClick={() => setShowModalDelete(false)}
      />
    </React.Fragment>
  );
};

export default Details;
