import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as Yup from "yup";
import { useSelector } from "react-redux";
import RiseLoader from "react-spinners/RiseLoader";

import { history } from "@helpers/history";
import { fetchWrapper } from "@helpers/fetchwrapper";

import CardArrow from "@areas/shared/components/cards/CardArrow";

import styles from "./styles/forgot.module.css";

function Forgot() {
  const authToken = useSelector((x) => x.auth.token);
  const [forgotResult, setForgotResult] = useState(null);
  const [error, setError] = useState(null);

  useEffect(() => {
    if (authToken) {
      history.navigate("/personal");
    }
  }, [authToken]);

  const validationSchema = Yup.object().shape({
    login: Yup.string()
      .email("Некорректный email")
      .required("Email обязателен"),
  });
  const formOptions = { resolver: yupResolver(validationSchema) };

  const { register, handleSubmit, formState } = useForm(formOptions);
  const { errors, isSubmitting } = formState;

  function onSubmit({ login }) {
    setError(null);
    setForgotResult(null);
    return fetchWrapper
      .post(`/v1/personal/sendrestorepassword?login=${login}`)
      .then((result) => {
        setForgotResult(result);
        if (!result) {
          setError("Не удалось отправить подтверждение");
        }
      })
      .catch((rejectedValueOrSerializedError) => {
        setForgotResult(false);
        setError(rejectedValueOrSerializedError);
      });
  }

  return (
    <div className={styles.block}>
      <CardArrow
        customStyles={{
          main: styles.card,
        }}
        sizeArrow={{ width: 20, height: 20 }}
        rotate="rightTop"
      >
        <form className={styles.inner} onSubmit={handleSubmit(onSubmit)}>
          <div className={styles.title}>Забыли пароль</div>
          <div className={styles.formGroup}>
            <input
              type="text"
              {...register("login")}
              className={`${errors.login ? styles.inputError : styles.input}`}
              placeholder="Email"
            />
            <div className={styles.errorMessage}>{errors.login?.message}</div>
          </div>
          <div className={styles.submit}>
            {isSubmitting ? (
              <RiseLoader className={styles.bar} color="#014170" />
            ) : (
              <>
                <button className={styles.btn}>Сбросить пароль</button>
                {forgotResult !== null && (
                  <>
                    {forgotResult ? (
                      <div className={styles.submitSuccess}>
                        Подтвержение отправлено на почту
                      </div>
                    ) : (
                      <div className={styles.submitError}>{error}</div>
                    )}
                  </>
                )}
              </>
            )}
          </div>
          <div className={styles.addition}>
            <Link to="/personal/login" className={styles.link}>
              Войти
            </Link>
          </div>
        </form>
      </CardArrow>
    </div>
  );
}

export default Forgot;
