import React, { useCallback, useEffect, useState } from "react";
import { Nav, NavItem, NavLink, TabContent, TabPane } from "reactstrap";
import ClipLoader from "react-spinners/ClipLoader";

import PraticesTab from "../results/PraticesTab";
import EventsTab from "../results/EventsTab";

import { fetchWrapper } from "@helpers/fetchwrapper";

import styles from "./styles/addition.module.css";
import SpecialitiesTab from "../results/SpecialitiesTab";

function Addition({ data }) {
  const [activeTab, setActiveTab] = useState("1");
  const [directions, setDirections] = useState([]);
  const [specialties, setSpecialties] = useState({
    data: [],
    total: 0,
  });
  const [institutes, setInstitutes] = useState({
    data: [],
    total: 0,
  });
  const [practices, setPractices] = useState({
    data: [],
    total: 0,
  });
  const [events, setEvents] = useState({
    data: [],
    total: 0,
  });
  const [limit] = useState(8);
  const [loadData, setLoadData] = useState(false);
  const [tabContent, setTabContent] = useState({});
  const [loadContent, setLoadContent] = useState(false);

  const loadPageByType = useCallback((companyId, typeId) => {
    setLoadContent(true);
    setTabContent(null);
    fetchWrapper
      .get(`/v1/api/app/companyPage?company=${companyId}&type=${typeId}`)
      .then((res) => {
        setTabContent(res);
        setLoadContent(false);
      });
  }, []);

  const loadDirections = useCallback((companyId, instituteId) => {
    setLoadData(true);
    setDirections([]);
    fetchWrapper
      .get(
        `/v1/api/app/allOksoCategories?company=${companyId}&institute=${instituteId}`
      )
      .then((res) => {
        setDirections(res);
        setLoadData(false);
      });
  }, []);

  const loadSpecialties = useCallback(
    (offset, query, filter, instituteId, categoryId, companyId) => {
      let filterParams = "";
      if (filter.length > 0) {
        filter.forEach((element) => {
          filterParams += `${element.name}=${element.value}&`;
        });
      }
      fetchWrapper
        .get(
          `/v1/api/app/findFaculty?${filterParams}text=${query}&idInstitute=${instituteId}&idCategory=${categoryId}&idCompany=${companyId}&limit=${limit}&offset=${offset}`
        )
        .then((res) => {
          setSpecialties(res);
          setLoadData(false);
        });
    },
    [limit]
  );

  const loadPractices = useCallback(
    (offset) => {
      setLoadData(true);
      fetchWrapper
        .get(
          `/v1/api/app/getPracticyByIdCompany?idCompany=${data.Id}&limit=${limit}&offset=${offset}`
        )
        .then((res) => {
          setPractices(res);
          setLoadData(false);
        });
    },
    [data, limit]
  );

  const loadEvents = useCallback(
    (offset) => {
      setLoadData(true);
      fetchWrapper
        .get(
          `/v1/api/app/getEventByIdCompany?idCompany=${data.Id}&limit=${limit}&offset=${offset}`
        )
        .then((res) => {
          setEvents(res);
        })
        .finally(() => {
          setLoadData(false);
        });
    },
    [data, limit]
  );

  useEffect(() => {
    setLoadData(true);
    loadDirections(data.Id, 0);
    fetchWrapper
      .get(`/v1/api/app/getInstituteByIdCompany?idCompany=${data.Id}`)
      .then((res) => {
        setInstitutes(res);
      })
      .finally(() => {
        setLoadData(false);
      });
  }, [data, loadDirections, loadSpecialties]);

  const getTabContent = (loadContent, tabContent) => {
    return (
      <div className={styles.tabContent}>
        {loadContent ? (
          <div className="d-flex justify-content-center w-100">
            <ClipLoader />
          </div>
        ) : (
          <>
            {tabContent ? (
              <div dangerouslySetInnerHTML={{ __html: tabContent.Body }}></div>
            ) : (
              "Страница не найдена"
            )}
          </>
        )}
      </div>
    );
  };

  return (
    <div className={`${styles.block} container`}>
      <Nav tabs className={styles.tabs}>
        <NavItem>
          <NavLink
            className={`${styles.tab} ${
              activeTab === "1" ? styles.active : null
            }`}
            onClick={() => setActiveTab("1")}
          >
            НАПРАВЛЕНИЯ
          </NavLink>
        </NavItem>
        <NavItem>
          <NavLink
            className={`${styles.tab} ${
              activeTab === "2" ? styles.active : null
            }`}
            onClick={() => {
              loadPractices(0);
              setActiveTab("2");
            }}
          >
            ПРАКТИКИ
          </NavLink>
        </NavItem>
        <NavItem>
          <NavLink
            className={`${styles.tab} ${
              activeTab === "3" ? styles.active : null
            }`}
            onClick={() => {
              loadPageByType(data.Id, 8);
              setActiveTab("3");
            }}
          >
            ЦЕНТР КАРЬЕРЫ
          </NavLink>
        </NavItem>
        <NavItem>
          <NavLink
            className={`${styles.tab} ${
              activeTab === "4" ? styles.active : null
            }`}
            onClick={() => {
              loadPageByType(data.Id, 9);
              setActiveTab("4");
            }}
          >
            ПРОЕКТЫ СТУДЕНТОВ
          </NavLink>
        </NavItem>
        <NavItem>
          <NavLink
            className={`${styles.tab} ${
              activeTab === "5" ? styles.active : null
            }`}
            onClick={() => {
              loadEvents(0);
              setActiveTab("5");
            }}
          >
            МЕРОПРИЯТИЯ
          </NavLink>
        </NavItem>
      </Nav>
      <TabContent activeTab={activeTab}>
        <TabContent activeTab={activeTab}>
          <TabPane tabId="1">
            <SpecialitiesTab
              load={loadData}
              initDirection={0}
              directions={directions}
              institutes={institutes}
              results={specialties}
              limit={limit}
              companyId={data.Id}
              onChangeInstitute={loadDirections}
              onChangePage={loadSpecialties}
            />
          </TabPane>
          <TabPane tabId="2">
            <PraticesTab
              load={loadData}
              results={practices}
              limit={limit}
              companyId={data.Id}
              onChangePage={loadPractices}
            />
          </TabPane>
          <TabPane tabId="3">{getTabContent(loadContent, tabContent)}</TabPane>
          <TabPane tabId="4">{getTabContent(loadContent, tabContent)}</TabPane>
          <TabPane tabId="5">
            <EventsTab
              load={loadData}
              results={events}
              limit={limit}
              companyId={data.Id}
              onChangePage={loadEvents}
            />
          </TabPane>
        </TabContent>
      </TabContent>
    </div>
  );
}

export default Addition;
