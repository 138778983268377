import React from "react";
import { useLoaderData } from "react-router-dom";

import Animation from "@areas/shared/components/chooseLayout/Animation";
import animationStyles from "@areas/shared/components/chooseLayout/styles/animations.module.css";

function Page(props) {
  const data = useLoaderData();

  return (
    <div style={{minHeight: "calc(100vh - 320px)"}}>
      <Animation
        animationIn={animationStyles.swipeLeftIn}
        animationOut={animationStyles.swipeRightOut}
      >
        <div className="container">
          <h1>{data.Title}</h1>
          <div >
                      {data.ImageLink ? (
                        <img
                          src={`/v1/api/fileManager/getFile?path=${data.ImageLink}`}
                          alt={data.Title}
                        />
                      ) : (
                        <img src={notPhoto} alt={data.Title} />
                      )}
                    </div>
          <div dangerouslySetInnerHTML={ { __html: data.Body } }></div>
        </div>
      </Animation>
    </div>
  );
}

export default Page;
