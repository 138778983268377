import React from "react";
import { Link } from "react-router-dom";

import Honeycombs from "@areas/shared/components/background/Honeycombs";
import TextFilter from "@areas/shared/components/text/TextFilter";
import Helper from "@areas/shared/components/helper/Helper";
import Chooser from "@areas/employer/components/choose/Chooser";

import honeycombPeople1 from "@content/img/png/honeycombPeople1.png";
import honeycombPeople2 from "@content/img/png/honeycombPeople2.png";
import honeycombPeople3 from "@content/img/png/honeycombPeople3.png";

import btnStyles from "@areas/employer/components/buttons/styles/button.module.css";
import styles from "./styles/about.module.css";

function About() {
  return (
    <div className={styles.block}>
      <Honeycombs style={styles.background} filter="#9A4610" />
      <div className={`container ${styles.content}`}>
        <div>
          <div className={styles.info}>
            <TextFilter
              customStyles={{
                all: styles.header,
                text: styles.text,
                filter: styles.filter,
              }}
            >
              РАБОТОДАТЕЛЯМ
            </TextFilter>
            <div className={styles.subheader}>
              Прямая коммуникация с учебными заведениями профессиональной
              подготовки Республики Башкортостан, взаимодействие с
              потенциальными сотрудниками: со студентами и профессионалами,
              ищущими работу.
            </div>
            <div className={styles.cards}>
              <div className={styles.card}>
                <div className={styles.icon}>
                  <img src={honeycombPeople1} alt="Взаимодействие" />
                </div>
                <Link
                  to={"services/predlozhit-nastavnichestvo"}
                  target="_blank"
                  className={styles.button}
                >
                  Перейти
                </Link>
                <div className={styles.title}>Предложить наставничество</div>
              </div>
              <div className={styles.card}>
                <div className={styles.icon}>
                  <img src={honeycombPeople2} alt="Взаимодействие" />
                </div>
               <div class="main_tooltip"> <Link
                  to={"/personal/requests/list-from"}
                  target="_blank"
                  className={styles.button}
                >
                  Перейти
                </Link>
                <div class="main_tooltiptext">
                Войдите в Личный кабинет и оставьте заявку в одно или несколько учебных заведений
                </div>
                </div>
               
                <div className={styles.title}>Обучить своих сотрудников</div>
              </div>
              <div className={styles.card}>
                <div className={styles.icon}>
                  <img src={honeycombPeople3} alt="Взаимодействие" />
                </div>
                <Link
                  to={"services/naiti-praktikantov"}
                  target="_blank"
                  className={styles.button}
                >
                  Перейти
                </Link>
                <div className={styles.title}>
                  Найти стажеров и практикантов
                </div>
              </div>
              <div className={styles.card}>
                <div className={styles.icon}>
                  <img
                    src={
                      "https://trudvsem.ru/information/resources/upload/index/logo.svg"
                    }
                    alt="Взаимодействие"
                  />
                </div>
                <Link
                  to={"https://trudvsem.ru/"}
                  target="_blank"
                  className={styles.button}
                >
                  Перейти
                </Link>
                <div className={styles.title}>Разместить вакансии на «Работа России»</div>
              </div>
              <div className={styles.card}>
                <div className={styles.icon}>
                  <img
                    src={
                      "https://trudvsem.ru/information/resources/upload/index/logo.svg"
                    }
                    alt="Взаимодействие"
                  />
                </div>
                <Link
                  to={"https://trudvsem.ru/information-pages/landing-practices"}
                  target="_blank"
                  className={styles.button}
                >
                  Перейти
                </Link>
                <div className={styles.title}>Календарь практик на «Работа России»</div>
              </div>
            </div>
          </div>
        </div>
        <Helper
          cardStyles={{
            main: styles.card,
            border: styles.border,
            arrow: styles.arrow,
          }}
          buttonStyle={btnStyles.button}
          filterBackground="#9A4610"
          filename="helper-employer.json"
        />
      </div>
      <Chooser
        gradientId="chooserBackground"
        gradientSteps={[
          {
            offset: 0,
            stopColor: "#CBEC00",
          },
          {
            offset: 0.5,
            stopColor: "#700101",
          },
          {
            offset: 1,
            stopColor: "#D0A024",
          },
        ]}
      />
    </div>
  );
}

export default About;
