import React from "react";
import { Card, CardBody, Col, Row } from "reactstrap";
import ClipLoader from "react-spinners/ClipLoader";

import LoadBlock from "@areas/shared/components/personal/loads/LoadBlock";

function Section({ load, data }) {
  return (
    <React.Fragment>
      <Col lg={12}>
        <Card className="mt-n4 mx-n4">
          <div className="bg-soft-warning">
            <CardBody>
              <Row className="align-items-center">
                <div className="col-md">
                  <h4 className="fw-semibold" id="ticket-title">
                    №{data.Id} - {data.Subject}
                  </h4>
                  <div className="hstack gap-3 flex-wrap">
                    <div
                      className="badge rounded-pill bg-info fs-12"
                      id="ticket-status"
                    >
                      {data.Status}
                    </div>
                  </div>
                </div>
              </Row>
              <LoadBlock visible={load}>
                <ClipLoader />
              </LoadBlock>
            </CardBody>
          </div>
        </Card>
      </Col>
    </React.Fragment>
  );
}

export default Section;
