import React from "react";
import ClipLoader from "react-spinners/ClipLoader";

import Pagination from "@areas/shared/components/paginations/Pagination";

import styles from "./styles/results.module.css";
import paginationStyles from "@areas/employer/components/pagination/styles/pagination.module.css";

import Practicy from "../results/Practicy";
import Mentor from "../results/Mentor";

function Results({ load, results, onChangePage, searchFor }) {
  const getResultsView = (searchFor, results) => {
    switch (searchFor) {
      case "findPracticy":
        return <Practicy data={results.data} />;
      case "findMentorship":
        return <Mentor data={results.data} />;
      default:
        return <Practicy data={results.data} />;
    }
  };

  return (
    <div className={styles.info}>
      {load ? (
        <div className="d-flex w-100 justify-content-center mt-4">
          <ClipLoader />
        </div>
      ) : (
        <div className={styles.results}>
          {getResultsView(searchFor, results)}
        </div>
      )}
      <Pagination
        load={load}
        itemsPerPage={6}
        totalItems={results.total}
        onChangePage={onChangePage}
        customStyles={{
          prev: paginationStyles.prev,
          current: paginationStyles.current,
          next: paginationStyles.next,
        }}
      />
    </div>
  );
}

export default Results;
