import React, { useCallback, useRef } from "react";
import { useSelector, useDispatch } from "react-redux";

import { layoutActions } from "../../../../store";

import styles from "./styles/animations.module.css";

export default Animation;

function Animation({ animationIn, animationOut, children }) {
  const { mount } = useSelector((x) => x.layout);
  const dispatch = useDispatch();
  const animation = useRef();

  const onAnimationStart = useCallback(() => {
    if (mount && !animation.current.getAttribute("mainFrame")) {
      animation.current.setAttribute("mainFrame", true);
      animation.current.style.opacity = 1;
      dispatch(layoutActions.addElem());
    }
  }, [mount, dispatch]);

  const onAnimationEnd = useCallback(() => {
    if (!mount) {
      animation.current.style.opacity = 0;
      dispatch(layoutActions.removeElem());
    }
  }, [mount, dispatch]);

  return (
    <div
      style={{
        animation: `${
          mount
            ? `${animationIn ? animationIn : styles.fadeIn}`
            : `${animationOut ? animationOut : styles.fadeOut}`
        } 1s`,
        opacity: 0,
      }}
      onAnimationStart={onAnimationStart}
      onAnimationEnd={onAnimationEnd}
      ref={animation}
    >
      {children}
    </div>
  );
}
