import React, { useEffect, useState, useCallback } from "react";
import { Link } from "react-router-dom";

import { Swiper, SwiperSlide } from "swiper/react";
import { Scrollbar } from "swiper";

import { fetchWrapper } from "@helpers/fetchwrapper";

import styles from "./styles/docs.module.css";

function Docs() {
  const [showLineRight, setShowLineRight] = useState(true);
  const [data, setData] = useState([]);

  const loadData = useCallback(() => {
    fetchWrapper.get("/data/documents.json").then((res) => {
      setData(res);
    });
  }, []);

  useEffect(() => {
    loadData();
  }, [loadData]);

  const separateData = (data) => {
    let res = [];
    let temp = [];
    let tempIndex = 0;
    for (let i = 0; i < data.length; i++) {
      temp[tempIndex] = data[i];
      tempIndex++;
      if ((i + 1) % 3 === 0 && i !== 0) {
        res.push(temp);
        temp = [];
        tempIndex = 0;
      }
    }
    if (temp.length > 0) {
      res.push(temp);
    }
    return res;
  };

  return (
    <div className={`${styles.block} container`}>
      <div className={styles.header}>Документы</div>
      <div className={styles.registries}>
        <Swiper
          modules={[Scrollbar]}
          scrollbar={{
            hide: false,
          }}
          slidesPerView={"auto"}
          spaceBetween={20}
          className={styles.swiper}
          onSlideChange={(e) => {
            if (e.isEnd) {
              setShowLineRight(false);
            } else {
              setShowLineRight(true);
            }
          }}
        >
          {data
            ? separateData(data).map((item, indexItem) => (
                <SwiperSlide key={indexItem} className={styles.slide}>
                  <div className={styles.card}>
                    {item.map((doc, indexDoc) => (
                      <Link key={indexDoc} to={doc.link} className={styles.link}>
                        <div className={styles.dot}></div>
                        <div className={styles.title}>{doc.title}</div>
                      </Link>
                    ))}
                  </div>
                </SwiperSlide>
              ))
            : null}
        </Swiper>
      </div>
      {showLineRight ? <div className={styles.hidden}></div> : null}
    </div>
  );
}

export default Docs;
