import React from "react";
import ClipLoader from "react-spinners/ClipLoader";

import Pagination from "@areas/shared/components/paginations/Pagination";

import { ReactComponent as ArrowIcon } from "@content/img/shared/home/svg/arrow-icon.svg";
import defaultLogo from "@content/img/shared/education/png/default-logo.png";

import styles from "./styles/results.module.css";
import { Link } from "react-router-dom";

function Results({ load, results, onChangePage }) {
  const getCountCompaniesByType = (type) => {
    let data = results.totals.filter(
      (p) => p.Name === "companies" && p.IdType === type
    );
    if (data.length > 0) {
      return data[0].Value;
    }
    return 0;
  };

  const getCountSpecialities = () => {
    let data = results.totals.filter((p) => p.Name === "specialities");
    if (data.length > 0) {
      return data[0].Value;
    }
    return 0;
  };

  const getSpecialities = (company) => {
    let res = [];
    company.Faculty.forEach((faculty, index) => {
      if (index < 4) {
        res.push(faculty);
      }
    });
    return res;
  };

  return (
    <div className={styles.info}>
      {load ? (
        <div className="d-flex w-100 justify-content-center mt-4">
          <ClipLoader />
        </div>
      ) : (
        <>
          <div className={styles.totals}>
            <span>Найдено:</span>
            <span>ВУЗы -</span>
            <span>{getCountCompaniesByType(2)},</span>
            <span>СПО -</span>
            <span>{getCountCompaniesByType(3)},</span>
            <span>Специальности -</span>
            <span>{getCountSpecialities()}</span>
          </div>
          <div className={styles.results}>
            {results.data.map((item) => (
              <div key={item.Id} className={styles.result}>
                <div className={styles.card}>
                  <div className={styles.city}>{item.Address}</div>
                  <div className={styles.info}>
                    <div className={styles.about}>
                      <img src={defaultLogo} alt="Результат" />
                      <div className={styles.title}>
                        {item.Shortname ? item.Shortname : item.Fullname}
                      </div>
                    </div>
                    <Link
                      to={`/obrazovanie-v-bashkortostane/${
                        item.IdType === 2 ? "vuzy-rb" : "kolledzhi-rb"
                      }/${item.Slug}`}
                      className={styles.toCompany}
                      target="_blank"
                    >
                      Перейти к учебному заведению
                      <ArrowIcon />
                    </Link>
                  </div>
                  <div className={styles.specialities}>
                    {item.Faculty.length > 0 ? (
                      <div className={styles.title}>
                        <span>
                          {item.Faculty[0].Name
                            ? item.Faculty[0].Name
                            : item.Faculty[0].Title}
                        </span>
                        <span>и</span>
                        <span className={styles.colored}>
                          еще {item.Faculty.length - 1} специальностей
                        </span>
                      </div>
                    ) : (
                      "Нет специальностей"
                    )}
                    {getSpecialities(item).map((faculty) => (
                      <div key={faculty.Id} className={styles.speciality}>
                        <div className={styles.name}>
                          {faculty.Name ? faculty.Name : faculty.Title}
                        </div>
                        <div className={styles.props}>
                          <div className={styles.prop}>
                            <span className={styles.name}>
                              Кол-во бюдж. мест
                            </span>
                            <span className={styles.value}>
                              {faculty.BudgetPlaces}
                            </span>
                            <div className={styles.separator}></div>
                          </div>
                          <div className={styles.prop}>
                            <span className={styles.name}>
                              Кол-во плат. мест
                            </span>
                            <span className={styles.value}>
                              {faculty.PaidPlaces}
                            </span>
                          </div>
                          <Link
                            to={`/obrazovanie-v-bashkortostane/${
                              item.IdType === 2 ? "vuzy-rb" : "kolledzhi-rb"
                            }/${item.Slug}/speciality/${faculty.slug}`}
                            className={styles.go}
                            target="_blank"
                          >
                            <ArrowIcon />
                          </Link>
                        </div>
                      </div>
                    ))}
                  </div>
                  <Link
                    to={`/obrazovanie-v-bashkortostane/${
                      item.IdType === 2 ? "vuzy-rb" : "kolledzhi-rb"
                    }/${item.Slug}`}
                    className={styles.more}
                    target="_blank"
                  >
                    <div className={styles.all}>Посмотреть все</div>
                  </Link>
                </div>
              </div>
            ))}
          </div>
        </>
      )}
      <Pagination
        load={load}
        itemsPerPage={2}
        totalItems={results.total}
        onChangePage={onChangePage}
      />
    </div>
  );
}

export default Results;
