import React, { useState } from "react";
import { Row, Col } from "reactstrap";
import { useSelector } from "react-redux";

import Animation from "@areas/shared/components/chooseLayout/Animation";
import animationStyles from "@areas/shared/components/chooseLayout/styles/animations.module.css";

import ContactsTable from "./blocks/ContactsTable";
import ContactPersonTable from "./blocks/ContactPersonTable";

function Frame(props) {
  const { company: currentCompany } = useSelector((x) => x.auth);
  const [person, setPerson] = useState(null);

  return (
    <div>
      <Animation
        animationIn={animationStyles.fadeIn}
        animationOut={animationStyles.fadeOut}
      >
        <Row className="ms-0 me-0">
          <Col className="ps-0 pe-lg-2 pe-0" lg={8}>
            <ContactPersonTable
              person={person}
              setPerson={setPerson}
              company={currentCompany}
            />
          </Col>
          <Col className="ps-lg-3 ps-0 pe-0" lg={4}>
            <ContactsTable person={person} company={currentCompany} />
          </Col>
        </Row>
      </Animation>
    </div>
  );
}

export default Frame;
