import React from "react";
import {
  Outlet,
  useNavigation,
  useNavigate,
  useLocation,
} from "react-router-dom";
import PropagateLoader from "react-spinners/PropagateLoader";

import { history } from "@helpers/history";

import styles from "./styles/loader.module.css";

export default Loader;

function Loader(props) {
  const { state } = useNavigation();
  history.navigate = useNavigate();
  history.location = useLocation();

  if (state === "loading") {
    return (
      <div className={styles.block}>
        <div className={styles.loader}>
          <div className={styles.header}>
            <p className={styles.first}>Команда</p>
            <p className={styles.second}>будущего</p>
          </div>
          <PropagateLoader
            size={"2vw"}
            className={styles.bar}
            color="#014170"
          />
        </div>
      </div>
    );
  }

  return <Outlet />;
}
