import React, { useCallback, useState, useEffect } from "react";
import { Card, CardBody, CardHeader } from "reactstrap";
import { TbPlus } from "react-icons/tb";

import Animation from "@areas/shared/components/chooseLayout/Animation";
import animationStyles from "@areas/shared/components/chooseLayout/styles/animations.module.css";

import Table from "./blocks/Table";
import ButtonDanger from "@areas/shared/components/buttons/ButtonDanger";
import CustomModal from "@areas/shared/components/personal/modals/CustomModal";
import {
  initialValues,
  validationSchema,
  confirm,
  ModalBodyAdd,
} from "./blocks/ModalAdd";

import { fetchWrapper } from "@helpers/fetchwrapper";

function Frame(props) {
  const [showModal, setShowModal] = useState(false);
  const [headerModal, setHeaderModal] = useState("");
  const [perPage, setPerPage] = useState(10);
  const [page, setPage] = useState(1);
  const [total, setTotal] = useState(0);
  const [data, setData] = useState([]);
  const [load, setLoad] = useState(false);
  const [item, setItem] = useState([]);

  const loadData = useCallback(() => {
    setLoad(true);
    fetchWrapper
      .post(
        `/v1/api/app/GetPagesByTypes?offset=${
          page * perPage - perPage
        }&limit=${perPage}`,
        [12, 13]
      )
      .then((res) => {
        setData(res.data);
        setTotal(res.total);
        setLoad(false);
      });
  }, [page, perPage]);

  useEffect(() => {
    loadData();
  }, [loadData]);

  const handlePageChange = (page) => {
    setPage(page);
  };

  const handlePerRowsChange = async (newPerPage, page) => {
    setPerPage(newPerPage);
    setPage(page);
  };

  const toggle = useCallback(
    (data) => {
      if (showModal) {
        setShowModal(false);
        setItem(null);
      } else {
        if (!data) {
          setHeaderModal("Добавление страницы");
        } else {
          setHeaderModal("Редактирование страницы");
          setItem(data);
        }
        setShowModal(true);
      }
    },
    [showModal]
  );

  return (
    <div>
      <Animation
        animationIn={animationStyles.fadeIn}
        animationOut={animationStyles.fadeOut}
      >
        <Card>
          <CardHeader>
            <div className="d-flex justify-content-between align-items-center">
              <h5 className="mb-0">Абитуриенты</h5>
              <ButtonDanger onClick={() => toggle()}>
                <TbPlus />
                Добавить страницу
              </ButtonDanger>
            </div>
          </CardHeader>
          <CardBody>
            <Table
              data={data}
              load={load}
              total={total}
              handlePageChange={handlePageChange}
              handlePerRowsChange={handlePerRowsChange}
              toggleModal={toggle}
            />
          </CardBody>
        </Card>
      </Animation>
      <CustomModal
        isOpen={showModal}
        toggle={toggle}
        header={headerModal}
        body={(validation) => <ModalBodyAdd validation={validation} />}
        validateYupSchema={validationSchema}
        inititalValues={() => initialValues(item)}
        cancel="Закрыть"
        confirm="Сохранить"
        confirmFunction={(values) => {
          confirm(values, loadData);
        }}
      />
    </div>
  );
}

export default Frame;
